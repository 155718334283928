import { useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { ReactComponent as Arrow } from "../../../../assets/common/arrowDown.svg"
import spinnerNavy from "../../../../assets/common/spinnerNavy.svg"
import spinnerBlack from "../../../../assets/common/spinnerBlack.svg"
import axios from "axios"
import ProgramInfo from "../../components/layout/program-info/ProgramInfo"

type Opportunity = {
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
  additional_price_percent?: number
}

type Customer = {
  first_name: string,
  last_name: string,
  email: string
}

function RefundRequest() {
  const [isDisabled, setIsDisabled] = useState(true)
  const [fetchingCustomer, setFetchingCustomer] = useState(true)
  const [fetchError, setFetchError] = useState("")
  const [customer, setCustomer] = useState<Customer>()
  const [loading, setLoading] = useState(true)
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [opportunitiesError, setOpportunitiesError] = useState("")
  const [refundSelectedItem, setRefundSelectedItem] =
    useState("Select refund type")
  const [isRefundOpen, setIsRefundOpen] = useState<boolean>(false)
  const [isReasonOpen, setIsReasonOpen] = useState<boolean>(false)
  const [reasonSelectedItem, setReasonSelectedItem] = useState("Select reason")
  const [notes, setNotes] = useState("")
  const [proceedLoading, setProceedLoading] = useState(false)

  const history = useHistory()
  const params: { contact_id: string; opportunity_id: string; region: string } =
    useParams()
  const { contact_id, opportunity_id, region } = params
  const dropDownRef = useRef<HTMLDivElement>(null)

  const refund_select = [{ id: "Full" }, { id: "Partial" }]

  const reason_select = [
    { id: "Medically unsuitable" },
    { id: "Buyers’ remorse" },
    { id: "Financial" },
    { id: "Bank dispute raised" },
    { id: "Partner disagree" },
    { id: "Lack of service or communication" },
    { id: "Supply or logistics problem" },
    { id: "Program downgrade or change" },
  ]

  const handleProceed = async () => {
    setProceedLoading(true)
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/refund-request/create`, {
        country:
          region === "52657875"
            ? "AU"
            : region === "760139034"
            ? "CA"
            : region === "771947106"
            ? "NZ"
            : region === "20067563176"
            ? "UK"
            : region === "803222882"
            ? "PCA"
            : "",
        opportunity_id: opportunity_id,
        contact_id: contact_id,
        reason: reasonSelectedItem,
        description: notes,
        program_price: sessionStorage.getItem("total_price"),
        program_name: sessionStorage.getItem("plan_name"),
        refund_type: refundSelectedItem,
        patient_name: `${customer?.first_name + " " + customer?.last_name}`,
      })
      .then(() => {
        sessionStorage.setItem("refund_type", refundSelectedItem)
        sessionStorage.setItem("refund_reason", reasonSelectedItem)
        sessionStorage.setItem("notes", notes)
        history.push("/refund-request-success")
      })
      .catch((err) => {
        sessionStorage.setItem(
          "error",
          err.response.data.data || err.response.data.message
        )
        history.push("/refund-request-error")
      })
  }

  const fetchCustomer = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/fetch-customer`,
        {
          country:
            region === "52657875"
              ? "AU"
              : region === "760139034"
              ? "CA"
              : region === "771947106"
              ? "NZ"
              : region === "20067563176"
              ? "UK"
              : region === "803222882"
              ? "PCA"
              : "",
          module: "CONTACTS",
          id: contact_id,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setFetchingCustomer(false)
        setCustomer(data)
      })
      .catch((err) => {
        setFetchError(err.response.data.message)
        setFetchingCustomer(false)
      })
  }

  const getOpportunity = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
        {
          contact_id: contact_id,
          country: sessionStorage.getItem("country"),
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setLoading(false)
        const opportunity = data.filter(
          (item: Opportunity) => item.opportunity_id === opportunity_id
        )

        sessionStorage.setItem("opportunity_id", opportunity[0].opportunity_id)
        sessionStorage.setItem("invoice_id", opportunity[0].invoice_id)
        sessionStorage.setItem("full_name", opportunity[0].opportunity_name)
        sessionStorage.setItem("plan_name", opportunity[0].plan_info.name)
        sessionStorage.setItem("total_price", opportunity[0].total_price)
        sessionStorage.setItem(
          "amount_paid",
          (opportunity[0].total_price - opportunity[0].amount_paid).toString()
        )
        sessionStorage.setItem("amount_remaining", opportunity[0].amount_paid)
        sessionStorage.setItem("invoice_number", opportunity[0].invoice_number)
        sessionStorage.setItem("opportunity_add_percent", opportunity.additional_price_percent?.toString() || "")
        setOpportunities(opportunity)
      })
      .catch((err) => {
        setLoading(false)
        setOpportunitiesError(err.response.data.message)
      })
  }

  const refundOpenHandler = (): void => {
    setIsRefundOpen(!isRefundOpen)
  }

  const refundSelectHandler = (id: string) => {
    const selected = refund_select.find((el) => el.id === id)
    setRefundSelectedItem(selected?.id as string)
    sessionStorage.setItem("refund_type", selected?.id || "")
  }

  const reasonOpenHandler = (): void => {
    setIsReasonOpen(!isReasonOpen)
  }

  const reasonSelectHandler = (id: string) => {
    const selected = reason_select.find((el) => el.id === id)
    setReasonSelectedItem(selected?.id as string)
    sessionStorage.setItem("refund_reason", selected?.id || "")
  }

  useEffect(() => {
    if (region === "52657875") {
      sessionStorage.setItem("country", "AU")
    } else if (region === "760139034") {
      sessionStorage.setItem("country", "CA")
    } else if (region === "771947106") {
      sessionStorage.setItem("country", "NZ")
    } else if (region === "20067563176") {
      sessionStorage.setItem("country", "UK")
    } else if (region === "803222882") {
      sessionStorage.setItem("country", "PCA")
    }
    sessionStorage.setItem("module", "CONTACTS")
    sessionStorage.setItem("id", contact_id)
    fetchCustomer()
    getOpportunity()
  }, [])

  useEffect(() => {
    if (customer) {
      getOpportunity()
    }
  }, [customer])

  useEffect(() => {
    if (
      !customer ||
      sessionStorage.getItem("invoice_id") === "null" ||
      sessionStorage.getItem("invoice_number") === "null" ||
      refundSelectedItem === "Select refund type" ||
      reasonSelectedItem === "Select reason" ||
      notes === "" ||
      proceedLoading
    ) {
      setIsDisabled(true)
    } else if (
      customer &&
      sessionStorage.getItem("invoice_id") !== "null" &&
      sessionStorage.getItem("invoice_number") !== "null" &&
      refundSelectedItem !== "Select refund type" &&
      reasonSelectedItem !== "Select reason" &&
      notes !== "" &&
      !proceedLoading
    ) {
      setIsDisabled(false)
    }
  }, [customer, refundSelectedItem, reasonSelectedItem, notes, proceedLoading])

  useEffect(() => {
    const removeKeys = [
      "opportunity_id",
      "card_name",
      "last_four_digits",
      "card_brand",
      "plan_name",
      "plan_id",
      "plan_type",
      "plan_price",
      "total_price",
      "amount_paid",
      "amount_remaining",
      "currency",
      "note",
      "email",
      "full_name",
      "mobile",
      "refund_type",
      "refund_reason",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  const createInvoice = async (opportunityId: string, index: number) => {
    setInvoiceMessage("")
    setInvoiceLoading(true)
    setInvoiceIndex(index)
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/invoice/create`, {
        country: sessionStorage.getItem("country"),
        email: customer!.email,
        opportunity_id: opportunityId,
      })
      .then((res) => {
        setInvoiceMessage(res.data.message)
        setInvoiceSuccess(true)
        getOpportunity()
      })
      .catch((err) => {
        setInvoiceMessage(err.response.data.message)
        setInvoiceSuccess(false)
      })
    setInvoiceLoading(false)
  }

  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceIndex, setInvoiceIndex] = useState(99)
  const [invoiceSuccess, setInvoiceSuccess] = useState(true)
  const [invoiceMessage, setInvoiceMessage] = useState("")

  return (
    <>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h3 className="text-3xl leading-6 font-medium text-gray-900">
          Refund Request
        </h3>
        <ProgramInfo isRefund />

        {fetchingCustomer ? (
          <div className="flex mt-2">
            <img
              width={40}
              className="inline"
              src={spinnerNavy}
              alt="Loading..."
            />
            <h4 className="mt-2 text-lg font-medium text-navy-theme">
              Loading customer information...
            </h4>
          </div>
        ) : fetchError !== "" ? (
          <h4 className="mt-2 text-lg font-medium text-E84545">{fetchError}</h4>
        ) : (
          <>
            <h4 className="mt-2 text-lg font-medium text-gray-500">
              Name: {customer?.first_name + " " + customer?.last_name}
            </h4>
            <h4 className="text-lg font-medium text-gray-500">
              Email: {customer?.email}
            </h4>
            <h4 className="text-lg font-medium text-gray-500">
              Contact ID: {contact_id}
            </h4>
          </>
        )}
      </div>

      <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
        <div className="w-full h-full flex flex-col justify-evenly items-center">
          <div className="w-full px-4 sm:px-6 lg:px-8 my-6">
            {loading ? (
              <>
                <img
                  width={40}
                  className="inline"
                  src={spinnerNavy}
                  alt="Loading..."
                />
                <span className="font-medium text-navy-theme">
                  Opportunity loading...
                </span>
              </>
            ) : fetchError !== "" ? (
              <div className={`font-bold text-E84545`}>{fetchError}</div>
            ) : opportunitiesError !== "" ? (
              <div className={`font-bold text-E84545`}>
                {opportunitiesError}
              </div>
            ) : (
              <>
                {opportunities.map(
                  (opportunity: Opportunity, index: number) => (
                    <div key={index}>
                      <div
                        className={`border-2 overflow-hidden shadow rounded-lg mt-2 p-4 relative bg-white`}
                      >
                        <div className="ml-4 grid grid-rows-5 grid-cols-2 gap-y-1 grid-flow-col">
                          <p className="text-sm font-semibold text-gray-500 ">
                            Name:{" "}
                            <span className="font-normal">
                              {opportunity.opportunity_name}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Enquiry:{" "}
                            <span className="font-normal">
                              {opportunity.enquiry}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Opp ID:{" "}
                            <span className="font-normal">
                              {opportunity.opportunity_id}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Invoice ID:{" "}
                            <span className="font-normal">
                              {opportunity.invoice_id || "Unavailable"}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Invoice #:{" "}
                            <span className="font-normal">
                              {opportunity.invoice_number || "Unavailable"}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Plan Name:{" "}
                            <span className="font-normal">
                              {opportunity.plan_info?.name}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Total price:{" "}
                            <span className="font-normal">
                              {opportunity?.currency === "EUR"
                                ? "€"
                                : opportunity?.currency === "GBP"
                                ? "£"
                                : "$"}
                              {opportunity?.total_price?.toFixed(2)}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Amount paid:{" "}
                            <span className="font-normal">
                              {opportunity?.currency === "EUR"
                                ? "€"
                                : opportunity?.currency === "GBP"
                                ? "£"
                                : "$"}
                              {(
                                opportunity.total_price -
                                opportunity.amount_paid
                              ).toFixed(2)}
                            </span>
                          </p>
                          {opportunity.amount_paid == 0 ? (
                            <p className="text-sm font-bold text-3fc69d">
                              Paid in full
                            </p>
                          ) : opportunity.amount_paid < 0 ? (
                            <p className="text-sm font-bold text-E84545">
                              Customer overcharged{" "}
                              {opportunity?.currency === "EUR"
                                ? "€"
                                : opportunity?.currency === "GBP"
                                ? "£"
                                : "$"}
                              {Number(
                                String(opportunity.amount_paid).substring(1)
                              ).toFixed(2)}
                            </p>
                          ) : (
                            <p className="text-sm font-semibold text-gray-500">
                              Amount remaining:{" "}
                              <span className="font-normal">
                                {opportunity?.currency === "EUR"
                                  ? "€"
                                  : opportunity?.currency === "GBP"
                                  ? "£"
                                  : "$"}
                                {opportunity.amount_paid.toFixed(2)}
                              </span>
                            </p>
                          )}
                          <div
                            className={`h-full w-4 absolute top-0 left-0 bg-3FB1FC ${
                              opportunity.amount_paid == 0 && "bg-3fc69d"
                            } ${
                              (opportunity.amount_paid < 0 ||
                                !opportunity.invoice_id ||
                                !opportunity.invoice_number) &&
                              "bg-E84545"
                            }`}
                          />
                        </div>
                        {(!opportunity.invoice_id ||
                          !opportunity.invoice_number) && (
                          <>
                            <p className="ml-4 mt-4 text-sm font-bold text-E84545">
                              Invoice data is unavailable within the CRM. Please
                              click on the &quot;Create Invoice&quot; button to proceed.
                            </p>
                            {invoiceMessage && index === invoiceIndex ? (
                              <button
                                className={`w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-auto ${
                                  invoiceSuccess ? "bg-3fc69d" : "bg-E84545"
                                } text-white rounded-lg h-10`}
                              >
                                {invoiceMessage}
                              </button>
                            ) : (
                              <>
                                {invoiceLoading && index === invoiceIndex && (
                                  <img
                                    width={40}
                                    className="absolute left-[194px] bottom-4 invert border-none"
                                    src={spinnerBlack}
                                    alt="Loading..."
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    createInvoice(
                                      opportunity.opportunity_id,
                                      index
                                    )
                                  }
                                  className="w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-pointer bg-navy-theme text-white rounded-lg h-10"
                                >
                                  {invoiceLoading && index === invoiceIndex
                                    ? "Creating invoice..."
                                    : "Create Invoice"}
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
        <div className="w-full h-full flex flex-col justify-evenly items-center my-6">
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
            <label
              htmlFor="refund_type"
              className="block text-sm font-medium text-gray-700"
            >
              Full or Partial Refund
            </label>
            <div className={`relative mt-2`}>
              <div
                className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
                onClick={refundOpenHandler}
                ref={dropDownRef}
              >
                <div className="flex items-center pl-4 font-medium text-828282 w-full">
                  {refundSelectedItem}
                </div>
                <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
                  <Arrow />
                </div>
                {isRefundOpen && (
                  <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
                    {refund_select.map(({ id }, index) => (
                      <div
                        className={`${
                          index === 0 ? "" : "border-t border-F0F0F0"
                        } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                        key={id}
                        onClick={() => refundSelectHandler(id)}
                      >
                        {id}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
            <label
              htmlFor="refund_reason"
              className="block text-sm font-medium text-gray-700"
            >
              Reason for Refund
            </label>
            <div className={`relative mt-2`}>
              <div
                className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
                onClick={reasonOpenHandler}
                ref={dropDownRef}
              >
                <div className="flex items-center pl-4 font-medium text-828282 w-full">
                  {reasonSelectedItem}
                </div>
                <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
                  <Arrow />
                </div>
                {isReasonOpen && (
                  <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
                    {reason_select.map(({ id }, index) => (
                      <div
                        className={`${
                          index === 0 ? "" : "border-t border-F0F0F0"
                        } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                        key={id}
                        onClick={() => reasonSelectHandler(id)}
                      >
                        {id}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-4 sm:px-6 lg:px-8">
            <label
              htmlFor="notes"
              className="block text-sm font-medium text-gray-700"
            >
              Notes
            </label>
            <textarea
              className="mt-2 w-full h-36 bg-E0E0E0 border-c4c4c4 border rounded-md focus:border-c4c4c4 focus:outline-none focus:ring-0 font-medium text-828282 leading-5"
              style={{ resize: "none" }}
              placeholder="Enter details of refund"
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col">
        <button
          type="button"
          className={`${
            isDisabled
              ? "bg-E0E0E0 text-828282"
              : "text-white bg-FF5733 hover:bg-FF5733_hover"
          } mt-4 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 mx-auto flex justify-center items-center relative`}
          onClick={() => {
            handleProceed()
          }}
          disabled={isDisabled}
        >
          {proceedLoading && (
            <img
              width={40}
              className="absolute left-4"
              src={spinnerBlack}
              alt="Loading..."
            />
          )}
          {proceedLoading ? "Processing..." : "Proceed"}
        </button>
      </div>
    </>
  )
}

export default RefundRequest