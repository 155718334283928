import { FC } from "react"

interface PropTypes {
  quizData: {
    category_id: number
    id: number
    mhc_quiz_assessments: {
      assessment_answer: {
        assessment_options: {
          id: number
          option: string
        }
      }[]
      assessment_questions: {
        id: number
        question: string
      }
      id: number
    }[]
    mhc_quiz_category: {
      category_description: string
      category_name: string
      id: number
    }
    user_id: string
  }
}

const ErectileDysfunctionQuiz: FC<PropTypes> = ({ quizData }) => {
  return (
    <>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
        {`${quizData?.mhc_quiz_category?.category_name}`}
      </p>
      <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow-lg rounded-lg mt-2">
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            1.{" "}
            {`${quizData?.mhc_quiz_assessments[0]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[0]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            2.{" "}
            {`${quizData?.mhc_quiz_assessments[1]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[1]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            3.{" "}
            {`${quizData?.mhc_quiz_assessments[2]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[2]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            4.{" "}
            {`${quizData?.mhc_quiz_assessments[3]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[3]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            5.{" "}
            {`${quizData?.mhc_quiz_assessments[4]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[4]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            6.{" "}
            {`${quizData?.mhc_quiz_assessments[5]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[5]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            7.{" "}
            {`${quizData?.mhc_quiz_assessments[6]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[6]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            8.{" "}
            {`${quizData?.mhc_quiz_assessments[7]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[7]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
        <div className="px-4 py-3 flex flex-col">
          <span className="font-bold">
            9.{" "}
            {`${quizData?.mhc_quiz_assessments[8]?.assessment_questions?.question}`}
          </span>
          <span>{`${quizData?.mhc_quiz_assessments[8]?.assessment_answer[0]?.assessment_options?.option}`}</span>
        </div>
      </div>
    </>
  )
}

export default ErectileDysfunctionQuiz
