import { FC } from "react"

interface Props {
  programStartDate: Date | null
  setProgramEndDate: (date: Date | null) => void
  selectedDuration: number
  setSelectedDuration: (duration: number) => void
}

const ProgramDurationButtons: FC<Props> = ({
  programStartDate,
  setProgramEndDate,
  selectedDuration,
  setSelectedDuration,
}) => {
  const programDurationButtons = [
    { id: "1 month", value: 1 },
    { id: "2 months", value: 2 },
    { id: "3 months", value: 3 },
    { id: "6 months", value: 6 },
    { id: "12 months", value: 12 },
  ]

  const calculateEndDate = (startDate: Date, value: number) => {
    setSelectedDuration(value)
    const date = new Date(startDate)
    setProgramEndDate(new Date(date.setMonth(startDate.getMonth() + value)))
  }

  return (
    <div className="flex my-3 gap-x-4">
      {programDurationButtons.map((button) => (
        <button
          className={`${
            selectedDuration === button.value
              ? "bg-FF5733"
              : programStartDate
              ? "bg-navy-theme hover:bg-navy-theme_hover"
              : "bg-E0E0E0 text-828282 cursor-auto"
          } text-white p-2 rounded-md`}
          onClick={() => {
            if (programStartDate) {
              calculateEndDate(programStartDate, button.value)
            }
          }}
          key={button.id}
        >
          {button.id}
        </button>
      ))}
    </div>
  )
}
export default ProgramDurationButtons
