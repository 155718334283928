import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import ModuleSelectMenu from "./components/ModuleSelectMenu"
import CustomerSearch from "./components/CustomerSearch"
import CustomerInfoModal from "../../components/customer-info-modal/CustomerInfoModal"
import spinnerNavy from "../../../../assets/common/spinnerNavy.svg"
import axios from "axios"

type Customer = {
  first_name: string
  last_name: string
  email: string
  phone: string
  mobile: string
  lead_id: string
  lead_status: string
  lead_created_time: string
  lead_modified_time: string
  lead_source: string
  date_of_birth: string
  contact_id?: string
}

type Opportunity = {
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
}

const CustomerSelect = () => {
  const moduleList = [{ id: "Lead" }, { id: "Contact" }]
  const customerListInitial = [
    {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      mobile: "",
      lead_id: "",
      lead_status: "",
      lead_created_time: "",
      lead_modified_time: "",
      lead_source: "",
      date_of_birth: "",
      contact_id: "",
    },
  ]
  const history = useHistory()

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [moduleSelectedItem, setModuleSelectedItem] = useState("Select status")
  const [zohoHidden, setZohoHidden] = useState(true)
  const [opportunityHidden, setOpportunityHidden] = useState(true)
  const [opportunityData, setOpportunityData] = useState<Opportunity[]>([])
  const [customerSelectedItem, setCustomerSelectedItem] = useState(
    customerListInitial[0].email
  )
  const [selectModuleError, setSelectModuleError] = useState("")

  const [customerList, setCustomerList] =
    useState<Customer[]>(customerListInitial)
  const [customerSearchLoading, setCustomerSearchLoading] = useState(false)
  const [opportunitiesLoading, setOpportunitiesLoading] = useState(false)
  const [queryError, setQueryError] = useState("")
  const [opportunitiesQueryError, setOpportunitiesQueryError] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [planSelected, setPlanSelected] = useState<number>(0)
  const [customerInfoModalOpen, setCustomerInfoModalOpen] = useState(false)

  const handleProceed = () => {
    if (sessionStorage.getItem("module") === "LEADS") {
      setCustomerInfoModalOpen(true)
    } else {
      history.push("/payment-portal/transaction-type")
    }
  }

  const searchLead = async () => {
    if (moduleSelectedItem !== "Lead" && moduleSelectedItem !== "Contact") {
      setSelectModuleError("Please select status before searching")
      setDropdownOpen(false)
      return
    } else {
      setCustomerList(customerListInitial)
      setDropdownOpen(false)
      if (moduleSelectedItem === "Lead") {
        setOpportunityHidden(true)
      }
      setZohoHidden(true)
      setOpportunityHidden(true)
      setOpportunityData([])
      setPlanSelected(0)
      sessionStorage.removeItem("opportunity_id")
      sessionStorage.removeItem("full_name")
      sessionStorage.removeItem("plan_name")
      sessionStorage.removeItem("total_price")
      sessionStorage.removeItem("amount_paid")
      sessionStorage.removeItem("amount_remaining")

      setNextButtonDisabled(true)
      setSelectModuleError("")
      setCustomerSearchLoading(true)
      setQueryError("")
      setOpportunitiesQueryError("")

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/search-lead`,
          {
            module: `${moduleSelectedItem === "Lead" ? "leads" : "contacts"}`,
            searchString: customerSelectedItem,
            country: sessionStorage.getItem("country"),
          }
        )
        .then((res) => {
          const {
            data: { data },
          } = res
          setCustomerSearchLoading(false)
          setCustomerList(data)
          setDropdownOpen(true)
        })
        .catch((err) => {
          setCustomerSearchLoading(false)
          setQueryError("")
          if (typeof err?.response?.data.message === "string") {
            setQueryError(err.response.data.message)
            return
          }
        })
    }
  }

  useEffect(() => {
    if (customerSelectedItem === "") {
      setCustomerList(customerListInitial)
    }
  }, [customerSelectedItem])

  useEffect(() => {
    setModuleSelectedItem("Select status")
    setCustomerList(customerListInitial)
    setCustomerSelectedItem(customerList[0].email)
    setZohoHidden(true)
    setOpportunityHidden(true)
    setQueryError("")
    setOpportunitiesQueryError("")
    setPlanSelected(0)
    setOpportunityData([])
    setCustomerInfoModalOpen(false)

    const removeKeys = [
      "module",
      "email",
      "id",
      "full_name",
      "mobile",
      "lead_source",
      "opportunity_id",
      "card_name",
      "last_four_digits",
      "card_brand",
      "plan_name",
      "plan_id",
      "total_price",
      "amount_paid",
      "amount_remaining",
      "invoice_id",
      "opportunity_add_percent",
      "additional_info",
      "date_of_birth",
      "isCreateOpportunity",
      "isUpgradeOpportunity",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  return (
    <Layout
      pageNumber={2}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="status"
          className="block text-sm font-medium text-gray-700"
        >
          Status
        </label>
        <ModuleSelectMenu
          selectList={moduleList}
          selectedItem={moduleSelectedItem}
          setSelectedItem={setModuleSelectedItem}
          setZohoHidden={setZohoHidden}
          setOpportunityHidden={setOpportunityHidden}
          setOpportunityData={setOpportunityData}
          setCustomerSelectedItem={setCustomerSelectedItem}
          setSelectModuleError={setSelectModuleError}
        />
        {selectModuleError && (
          <div className="mt-2 font-semibold text-E84545">
            {selectModuleError}
          </div>
        )}
      </div>
      <div className="w-full px-4 sm:px-6 lg:px-8 mt-6">
        <label
          htmlFor="customer"
          className="block text-sm font-medium text-gray-700"
        >
          Customer{" "}
          {customerSearchLoading && (
            <>
              <img
                width={32}
                className="inline"
                src={spinnerNavy}
                alt="Loading..."
              />
              <span className="font-medium text-navy-theme">
                Customers loading...
              </span>
            </>
          )}
          {opportunitiesLoading && (
            <>
              <img
                width={32}
                className="inline"
                src={spinnerNavy}
                alt="Loading..."
              />
              <span className="font-medium text-navy-theme">
                Opportunities loading...
              </span>
            </>
          )}
          {queryError && (
            <span className="font-medium text-E84545 ml-2">{queryError}</span>
          )}
          {opportunitiesQueryError && (
            <span className="font-medium text-E84545 ml-2">
              {opportunitiesQueryError}
            </span>
          )}
        </label>
        <CustomerSearch
          customerList={customerList}
          setCustomerList={setCustomerList}
          selectedItem={customerSelectedItem}
          setSelectedItem={setCustomerSelectedItem}
          searchLead={searchLead}
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          setNextButtonDisabled={setNextButtonDisabled}
          setOpportunitiesLoading={setOpportunitiesLoading}
          opportunityData={opportunityData}
          setOpportunityData={setOpportunityData}
          planSelected={planSelected}
          setPlanSelected={setPlanSelected}
          zohoHidden={zohoHidden}
          setZohoHidden={setZohoHidden}
          opportunityHidden={opportunityHidden}
          setOpportunityHidden={setOpportunityHidden}
          setOpportunitiesQueryError={setOpportunitiesQueryError}
        />
      </div>
      <CustomerInfoModal
        customerInfoModalOpen={customerInfoModalOpen}
        setCustomerInfoModalOpen={setCustomerInfoModalOpen}
      />
    </Layout>
  )
}

export default CustomerSelect