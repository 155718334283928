import UserProfileCard from "./UserProfileCard/UserProfileCard"
import PersonalInfoList from "./PersonalInfoList/PersonalInfoList"
import "./userProfile.scss"

const UserProfile = () => {
  return (
    <div>
      <UserProfileCard />
      <PersonalInfoList />
    </div>
  )
}

export default UserProfile
