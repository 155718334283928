import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import { Store } from "redux"

export default function setupAxios(axios: AxiosInstance, store: Store) {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const {
        userData: { authToken },
      } = store.getState()

      if (!config.headers) {
        config.headers = {}
      }

      if (authToken) {
        config.headers.Authorization = `${authToken}`
      }

      config.headers.Accept = "application/json"
      config.headers["Content-type"] = "application/json;charset=UTF-8"
      return config
    },
    (err: AxiosError) => Promise.reject(err)
  )
}
