import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from "react"
import { ReactComponent as Search } from "../../../../../assets/common/search.svg"
import SpinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"

interface PerformanceManagerSearchMenuProps {
  setPmName: (args: string) => void
  setPmID: (args: string) => void
  region: string | null
}

const PerformanceManagerSearchMenu: FC<PerformanceManagerSearchMenuProps> = ({
  setPmName,
  setPmID,
  region,
}) => {
  const performance_manager_list: {
    full_name: string
    id: string
    email: string
  }[] = [
    {
      full_name: "",
      id: "",
      email: "",
    },
  ]

  const [loading, setLoading] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<string>("")
  const [selectList, setSelectList] = useState(performance_manager_list)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [queryError, setQueryError] = useState<string>("")

  const searchProductPlans = async () => {
    setLoading(true)
    setQueryError("")

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-performance-manager`,
        {
          country: region,
          searchString: selectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setLoading(false)
        setSelectList(data)
        setIsOpen(true)
      })
      .catch((err) => {
        setLoading(false)
        setIsOpen(false)
        if (typeof err?.response?.data.message === "string") {
          setQueryError(err.response.data.message)
          return
        }
      })
  }

  const dropDownRef = useRef<HTMLDivElement>(null)
  const openHandler = (): void => {
    if (selectedItem !== "") {
      setIsOpen(!isOpen)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedItem(e.target.value)
  }

  const selectPmHandler = (id: string) => {
    const selected = selectList.find((el) => el.id === id)
    setPmID(selected!.id)
    setPmName(selected!.full_name)
    setSelectedItem(selected!.full_name)
    setIsOpen(false)
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setIsOpen(false)
      }
    },
    [setIsOpen]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    if (selectList.length < 1) {
      setQueryError("No records found. Please try again.")
    } else {
      setQueryError("")
    }
  }, [selectList])

  return (
    <div>
      <div>
        <div className="flex">
          <label
            htmlFor="performance_manager"
            className="block text-sm font-medium text-gray-700"
          >
            Performance Manager
          </label>
          {loading && (
            <div className="flex items-center">
              <img src={SpinnerBlack} alt="" width="20px" />
              <div className="block text-sm font-medium text-gray-700">
                Searching for PMs...
              </div>
            </div>
          )}
          {queryError && (
            <span className="block text-sm font-medium text-red-700 ml-2">
              {queryError}
            </span>
          )}
        </div>
        <div className={`relative mt-2`}>
          <div
            className="bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border"
            onClick={openHandler}
            ref={dropDownRef}
          >
            <div className="ml-4 flex items-center justify-center min-w-2.375 w-2.375 h-full">
              <Search />
            </div>
            <input
              className="bg-E0E0E0 flex items-center pl-2 text-828282 w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
              placeholder="Enter the name of performance manager"
              value={selectedItem}
              onChange={handleChange}
              onKeyPress={(event) =>
                event.key === "Enter" && searchProductPlans()
              }
            />
            {isOpen && (
              <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-40">
                {selectList?.map((item, index) => (
                  <div
                    className={`${
                      index === 0 ? "" : "border-t border-F0F0F0"
                    } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                    key={index}
                    onClick={() => {
                      selectPmHandler(item.id)
                    }}
                  >
                    {
                      <div className="flex text-sm">
                        <div className="w-36">{item.full_name}</div>
                        <div className="w-64">{item.email}</div>
                      </div>
                    }
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PerformanceManagerSearchMenu
