import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { User } from "../../../types/user"

export default function ViewScheduleModal({
  isOpen,
  setIsOpen,
  user,
}: {
  isOpen: boolean
  setIsOpen: (args: boolean) => void
  user: User | null
}) {
  const convertToAMPM = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number)
    const period = hours >= 12 && hours !== 24 ? "PM" : "AM"
    const hours12 = hours % 12 || 12

    const result = `${hours12}:${String(minutes).padStart(2, "0")}${period}`
    return result
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-6"
                >
                  Schedule for {user?.first_name} {user?.last_name}
                </Dialog.Title>
                <div className="mt-2">
                  {user?.time_schedules && user?.time_schedules.length < 1 ? (
                    <p>No schedule data available.</p>
                  ) : (
                    user?.time_schedules.map((day) => (
                      <div
                        key={day.day}
                        className="bg-3FB1FC_hover30 mb-4 rounded-lg py-3"
                      >
                        <div className="flex justify-center items-center gap-8">
                          <div className="bg-navy-theme text-white w-[55px] h-[38px] rounded-md flex items-center justify-center">
                            <span className="font-medium">{day.day}</span>
                          </div>
                          <div className="flex flex-col w-[150px]">
                            <div className="flex items-center gap-[2px] font-medium">
                              <p>{convertToAMPM(day.start_time)}</p>
                              <span>-</span>
                              <p>{convertToAMPM(day.finish_time)}</p>
                            </div>
                            {day.allowed_overtime && (
                              <div className="flex items-center text-sm">
                                Max overtime: {day.max_overtime}hrs
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
