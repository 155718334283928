export const handleSearch = <T extends object>(
  query: string,
  originalUsers: T[],
  searchParam: string[],
  setUsers: (arg: T[]) => void
) => {
  if (query === "") {
    setUsers(originalUsers)
  } else {
    setUsers(
      originalUsers.filter((val) => {
        return searchParam.some((newUser) => {
          const value = (val as Record<string, string>)[newUser] as string | undefined
          if (value)
            return value?.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
      })
    )
  }
}
