import { useEffect } from "react"
import UserInformationItem from "./UserInformationItem"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

const PersonalInfoList = () => {
  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  useEffect(() => {}, [userInformation])

  const admins_features: { value: string }[] = userInformation.admins_features
  const region_access = admins_features.map((item) => item.value)

  return (
    <>
      <div className="mt-3.75 user-information">
        <span>Personal Info</span>

        {userInformation != null && (
          <div className="bg-white rounded-lg py-4 px-5 md:px-7 flex flex-col gap-8 mt-2.125 md:mt-18px">
            <UserInformationItem
              keyName={"Email Address"}
              title={"Email Address"}
              content={userInformation.email}
            />
            <UserInformationItem
              keyName={"Phone Number"}
              title={"Mobile"}
              content={userInformation.mobile ? userInformation.mobile : ""}
            />
            <UserInformationItem
              keyName={"Account Type"}
              title={"Account Type"}
              content={userInformation.type ? userInformation.type : ""}
            />
            <UserInformationItem
              keyName={"User Access"}
              title={"User Access"}
              content={region_access.toString().replaceAll(",", ", ")}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default PersonalInfoList
