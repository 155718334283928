import { createSlice } from "@reduxjs/toolkit"

const initialAuthState = {
  userLoggedIn: false,
  userInformation: {
    profileImage: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    admins_features: [],
    id: "",
    type: "",
    time_schedules: [{
      day: "",
      start_time: "",
      finish_time: "",
      allowed_overtime: false,
      max_overtime: 0
    }]
  },
  loginSuccess: false,
  authToken: null,
  verificationCodeSent: false,
  numberVerified: false,
  userSuccessfullyRegistered: false,
  error: { error: "", type: "" },
  showLogin: false,
  showSignUp: false,
  showProfileEdit: false,
  stage: 3 as number,
  firstLogin: false,
  show: false,
  role: null,
  type: null,
  fileUrl: "",
}

export type UserProfileType = typeof initialAuthState

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    loginUser: (state, action) => {
      const result = action.payload
      state.authToken = result.user.data.authorization
      state.firstLogin = result.user.data.firstTimeLogin
      state.userLoggedIn = true
      state.error = { error: "", type: "" }
      state.loginSuccess = true
    },
    logout: (state) => {
      state.authToken = null
      state.userLoggedIn = false
      state.userInformation = {
        profileImage: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        admins_features: [],
        id: "",
        type: "",
        time_schedules: [{
          day: "",
          start_time: "",
          finish_time: "",
          allowed_overtime: false,
          max_overtime: 0
        }]
      }
    },
    catchError: (state, action) => {
      state.error = action.payload
      state.userSuccessfullyRegistered = false
    },
    clearError: (state) => {
      state.error = { error: "", type: "" }
    },
    setProfile: (state, action) => {
      state.userInformation = { ...action.payload.profile }
    },
    setShow: (state) => {
      state.show = true
    },
    hideShow: (state) => {
      state.show = false
    },
    setFileUrl: (state, data) => {
      state.fileUrl = data.payload.fileUrl
    },
  },
})
