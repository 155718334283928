import { FC, Fragment, useState, useRef, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import axios from "axios"

interface MyProps {
  passwordOpen: boolean
  setPasswordOpen: (args: boolean) => void
  originalUsers: User[]
  selectedUsers: object
}

type User = {
  id: string
  first_name: string
  last_name: string
  email: string
  mobile: number
  profileImage: string
  type: string
  admins_features: [
    {
      value: string
    },
    {
      value: string
    },
    {
      value: string
    },
    {
      value: string
    },
    {
      value: string
    }
  ]
  user_access: string
  index: number
}

const PasswordModal: FC<MyProps> = ({
  passwordOpen,
  setPasswordOpen,
  originalUsers,
  selectedUsers,
}) => {
  const [responseMsg, setResponseMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [passwordText, setPasswordText] = useState("")
  const [confirmPasswordText, setConfirmPasswordText] = useState("")
  const [showError, setShowError] = useState(false)

  const cancelButtonRef = useRef(null)
  const selectedUserCount = Object.values(selectedUsers).filter(
    (user) => user === true
  ).length

  const handleCancel = () => {
    setPasswordOpen(false)
    setPasswordText("")
    setConfirmPasswordText("")
    setShowError(false)
  }

  const handlePassword = async (index: string) => {
    const result: User[] = originalUsers.filter(
      (_, key: number) => key === +index
    )

    if (passwordText !== confirmPasswordText) {
      setShowError(true)
      return
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/account/reset-admin-password`,
        {
          admin_id: result[0].id,
          password: passwordText,
        }
      )
      .then(() => {
        setResponseMsg("Account password successfully changed")
        setErrorMsg("")
        setTimeout(() => {
          setPasswordOpen(false)
        }, 3000)
        setTimeout(() => {
          setResponseMsg("")
          setPasswordText("")
          setConfirmPasswordText("")
          setShowError(false)
        }, 4000)
      })
      .catch(() => {
        setErrorMsg("Server request failed")
        setResponseMsg("")
        setTimeout(() => {
          setPasswordOpen(false)
        }, 3000)
        setTimeout(() => {
          setErrorMsg("")
          setPasswordText("")
          setConfirmPasswordText("")
          setShowError(false)
        }, 4000)
      })
  }

  useEffect(() => {
    if (selectedUserCount <= 0) {
      handleCancel()
    }
  })

  return (
    <Transition.Root show={passwordOpen && selectedUserCount > 0} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Reset Account{" "}
                    {selectedUserCount === 1 ? `Password` : `Passwords`}
                  </Dialog.Title>
                </div>
              </div>

              <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                  {errorMsg && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2 text-center">
                      <span className="block sm:inline">{errorMsg}</span>
                    </div>
                  )}
                  {responseMsg && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-2 text-center">
                      <span className="block sm:inline">{responseMsg}</span>
                    </div>
                  )}

                  <div className="bg-white pt-8 px-4 rounded-md sm:px-7">
                    <div className="pb-5">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          autoComplete="current-password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                          value={passwordText}
                          onChange={(e) => setPasswordText(e.target.value)}
                          placeholder="Enter new password"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="bg-white pb-4 px-4 rounded-md sm:px-7">
                    <div className="pb-5">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="confirm-password"
                          name="confirm-password"
                          type="password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                          value={confirmPasswordText}
                          onChange={(e) =>
                            setConfirmPasswordText(e.target.value)
                          }
                          placeholder="Re-enter new password"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                      onClick={handleCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-30 rounded-md border border-transparent shadow-sm px-4 py-2 bg-navy-theme text-base font-medium text-white focus:outline-none sm:text-sm"
                      onClick={() => {
                        Object.entries(selectedUsers).forEach(
                          (selectedUser) => {
                            const userIdx = selectedUser[0]
                            const isSelected = selectedUser[1]
                            if (isSelected) {
                              handlePassword(userIdx)
                            }
                          }
                        )
                      }}
                    >
                      {selectedUserCount === 1
                        ? "Reset Password"
                        : "Reset Passwords"}
                    </button>
                  </div>

                  {showError && (
                    <p className="pt-4 text-E84545 text-center font-semibold">
                      Passwords must match
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PasswordModal
