import { FC, Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ClockIcon } from "@heroicons/react/outline"
import DatePicker from "react-datepicker"
import axios from "axios"

interface CreateEntryModalProps {
  open: boolean
  setOpen: (args: boolean) => void
  getTimeEntriesReport: (args: string) => void
  adminId: string
}

const CreateEntryModal: FC<CreateEntryModalProps> = ({
  open,
  setOpen,
  getTimeEntriesReport,
  adminId,
}) => {
  const cancelButtonRef = useRef(null)
  const noFocusRef = useRef(null)
  
  const [startDate, setStartDate] = useState(new Date())
  const [startHours, setStartHours] = useState(0)
  const [startMinutes, setStartMinutes] = useState(0)
  const [startTimeOfDay, setStartTimeOfDay] = useState("AM")

  const [endDate, setEndDate] = useState(new Date())
  const [endHours, setEndHours] = useState(0)
  const [endMinutes, setEndMinutes] = useState(0)
  const [endTimeOfDay, setEndTimeOfDay] = useState("AM")

  const handleHoursChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isStart: boolean
  ) => {
    const newHours = parseInt(e.target.value, 10)

    isStart
      ? setStartHours(isNaN(newHours) ? 0 : newHours)
      : setEndHours(isNaN(newHours) ? 0 : newHours)
  }

  const handleMinutesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isStart: boolean
  ) => {
    const newMinutes = parseInt(e.target.value, 10)

    isStart
      ? setStartMinutes(isNaN(newMinutes) ? 0 : newMinutes)
      : setEndMinutes(isNaN(newMinutes) ? 0 : newMinutes)
  }

  const handleAMPMChange = (isStart: boolean) => {
    isStart
      ? setStartTimeOfDay((prev) => (prev === "AM" ? "PM" : "AM"))
      : setEndTimeOfDay((prev) => (prev === "AM" ? "PM" : "AM"))
  }

  const addLeadingZero = (value: number): string => {
    return value.toString().padStart(2, "0")
  }

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const createEntry = async () => {
    const adjustHours = (isStart: boolean) => {
      if (isStart) {
        let adjustedStartHours = startHours

        // Adjust hours for PM
        if (startTimeOfDay === "PM" && startHours !== 12) {
          adjustedStartHours += 12
        }

        // Adjust hours for midnight (12:00 AM)
        if (startTimeOfDay === "AM" && startHours === 12) {
          adjustedStartHours = 0
        }

        return adjustedStartHours
      } else {
        let adjustedEndHours = endHours

        // Adjust hours for PM
        if (endTimeOfDay === "PM" && endHours !== 12) {
          adjustedEndHours += 12
        }

        // Adjust hours for midnight (12:00 AM)
        if (endTimeOfDay === "AM" && endHours === 12) {
          adjustedEndHours = 0
        }

        return adjustedEndHours
      }
    }

    // time should be sent in UTC format, without influence of timezone
    const combinedStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), adjustHours(true), startMinutes, 0))

    const formattedStartDate = combinedStartDate.toISOString()

    const combinedEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), adjustHours(false), endMinutes, 0))

    const formattedEndDate = combinedEndDate.toISOString()

    setLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/time-entries/create-time-entry`,
        {
          admin_id: adminId,
          start_time: formattedStartDate,
          finish_time: formattedEndDate,
        }
      )
      .then(() => {
        getTimeEntriesReport(adminId)
        setOpen(false)
      })
      .catch((err) => setError(err.message))
    setLoading(false)

    setStartDate(new Date())
    setStartHours(0)
    setStartMinutes(0)
    setStartTimeOfDay("AM")
    setEndDate(new Date())
    setEndHours(0)
    setEndMinutes(0)
    setEndTimeOfDay("AM")
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
        initialFocus={noFocusRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="flex">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-[#ffdcd4] sm:mx-0 sm:h-10 sm:w-10">
                  <ClockIcon
                    className="h-6 w-6 text-FF5733"
                    aria-hidden="true"
                  />
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                  <input ref={noFocusRef} type="text" className="hidden" />
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 mb-4"
                  >
                    Create Time Entry
                  </Dialog.Title>
                  <div className="flex justify-between">
                    <div>
                      <div className="w-full mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Start Date
                        </label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select start date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date as Date)}
                          showPopperArrow={false}
                          showMonthDropdown
                          useShortMonthInDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={5}
                          className="h-12 w-full rounded-md flex bg-gray-200 items-center pl-6 text-black border border-transparent focus:outline-none focus:ring-0 font-medium"
                        />
                      </div>
                      <p className="block text-sm font-medium text-gray-700 mb-1">
                        Start Time
                      </p>
                      <div className="flex items-center gap-2 mb-4">
                        <input
                          type="number"
                          min="1"
                          max="12"
                          className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-1/4"
                          value={addLeadingZero(startHours)}
                          onChange={(e) => handleHoursChange(e, true)}
                        />
                        <span>:</span>
                        <input
                          type="number"
                          min="0"
                          max="59"
                          className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-1/4"
                          value={addLeadingZero(startMinutes)}
                          onChange={(e) => handleMinutesChange(e, true)}
                        />
                        <button
                          type="button"
                          onClick={() => handleAMPMChange(true)}
                          className="font-medium hover:text-gray-500"
                        >
                          {startTimeOfDay}
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="w-full mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          End Date
                        </label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select end date"
                          selected={endDate}
                          onChange={(date) => setEndDate(date as Date)}
                          showPopperArrow={false}
                          showMonthDropdown
                          useShortMonthInDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={5}
                          className="h-12 w-full rounded-md flex bg-gray-200 items-center pl-6 text-black border border-transparent focus:outline-none focus:ring-0 font-medium"
                        />
                      </div>
                      <p className="block text-sm font-medium text-gray-700 mb-1">
                        End Time
                      </p>
                      <div className="flex items-center gap-2">
                        <input
                          type="number"
                          min="1"
                          max="12"
                          className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-1/4"
                          value={addLeadingZero(endHours)}
                          onChange={(e) => handleHoursChange(e, false)}
                        />
                        <span>:</span>
                        <input
                          type="number"
                          min="0"
                          max="59"
                          className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-1/4"
                          value={addLeadingZero(endMinutes)}
                          onChange={(e) => handleMinutesChange(e, false)}
                        />
                        <button
                          type="button"
                          onClick={() => handleAMPMChange(false)}
                          className="font-medium hover:text-gray-500"
                        >
                          {endTimeOfDay}
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4 text-sm">
                    Note: Click on AM/PM to switch between
                  </p>
                </div>
              </div>
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded text-center absolute translate-y-4 left-1/2 transform -translate-x-1/2">
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              <div className="mt-[150px] sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`${
                    !loading
                      ? "bg-FF5733 text-white hover:bg-FF5733_hover"
                      : "text-black bg-gray-300"
                  } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium sm:ml-3 sm:w-auto sm:text-sm`}
                  onClick={createEntry}
                  disabled={loading}
                >
                  {!loading ? "Create Entry" : "Creating..."}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CreateEntryModal
