export const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "long",
  }
  const formattedDate = date.toLocaleString(undefined, options)

  return formattedDate
}

export const formatTime = (dateString: string) => {
  const date = new Date(dateString)
  const userLocale = navigator.language
  const formattedTime = new Intl.DateTimeFormat(userLocale, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date)

  return formattedTime
}

export const formatDuration = (seconds: number) => {
  const hours = seconds / (60 * 60)
  const formattedHours = hours.toFixed(2)

  return formattedHours
}
