import { FC, Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import {
  CheckCircleIcon,
  XCircleIcon,
  BadgeCheckIcon,
  ClockIcon,
} from "@heroicons/react/solid"

interface PropTypes {
  open: boolean
  setOpen: (args: boolean) => void
  data: {
    performance_manager: {
      id: string
      name: string
    }
    product_plan: {
      id: string
      name: string
      type: string
    }
    id: number
    module_name: string
    email: string
    country: string
    payment_type: string
    opportunity_id: string
    lead_id: string
    contact_id: string
    admin_id: string
    url: string
    amount: number
    eft_status: string
    stage: string
    program_start: string
    program_end: string
    province: string
    currency: string
    status: true
    createdById: string
    updatedById: string
    createdAt: string
    updatedAt: string
    admin: {
      id: string
      first_name: string
      last_name: string
    }
    createdBy: {
      first_name: string
      last_name: string
      email: string
    }
    updatedBy: {
      first_name: string
      last_name: string
      email: string
    }
    salesperson: string
    date: string
  }
}

const DetailsModal: FC<PropTypes> = ({ open, setOpen, data }) => {
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:min-w-1280 sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl leading-6 font-medium text-gray-900"
                    >
                      EFT Payment Details
                    </Dialog.Title>
                  </div>
                </div>

                <div className="flex justify-center max-w-md mx-auto w-full my-10">
                  {data.eft_status === "APPROVED" ? (
                    <div className="mx-1 inline-flex items-center px-6 py-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 focus:outline-none focus:ring-0">
                      <CheckCircleIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Approved
                    </div>
                  ) : data.eft_status === "REJECTED" ? (
                    <div className="mx-1 inline-flex items-center px-6 py-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 focus:outline-none focus:ring-0">
                      <XCircleIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Rejected
                    </div>
                  ) : data.eft_status === "CONVERTED" ? (
                    <div className="mx-1 inline-flex items-center px-6 py-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-900 focus:outline-none focus:ring-0">
                      <BadgeCheckIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Converted
                    </div>
                  ) : data.eft_status === "PENDING" ? (
                    <div className="mx-1 inline-flex items-center px-6 py-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 focus:outline-none focus:ring-0">
                      <ClockIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Pending
                    </div>
                  ) : null}
                </div>

                <div className="w-full sm:w-full mt-7 mb-2">
                  <dl>
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Customer Email
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.email}
                      </dd>
                    </div>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Region
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.country}
                      </dd>
                    </div>
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Payment Type
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.payment_type}
                      </dd>
                    </div>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Payment Amount
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.amount}
                      </dd>
                    </div>
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Salesperson
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.salesperson}
                      </dd>
                    </div>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Module
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.module_name}
                      </dd>
                    </div>
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        {data.module_name === "LEADS" ? "Lead" : ""} ID
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.lead_id}
                      </dd>
                    </div>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Date submitted
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {data.date}
                      </dd>
                    </div>
                  </dl>
                </div>

                <div className="my-10 flex flex-col relative">
                  <img src={data.url} alt="" className="mx-auto" />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default DetailsModal
