import Decimal from "decimal.js";

export const MedicationFeeDetails = () => {
  const groundDeliveryFee =
    Number(sessionStorage.getItem("pharmacy_ground_delivery_fee")) || 0;
  const dispensingFee =
    Number(sessionStorage.getItem("pharmacy_dispensing_fee")) || 0;
  const planPrice = Number(sessionStorage.getItem("plan_price")) || 0;
  const taxRate = Number(sessionStorage.getItem("tax_rate")) || 0;
  const taxAmount = (planPrice * taxRate) / 100;

  console.log("groundDeliveryFee", groundDeliveryFee);
  console.log("dispensingFee", dispensingFee);
  console.log("planPrice", planPrice);
  console.log("taxRate", taxRate);
  console.log("taxAmount", taxAmount);


  return (
    <>
      <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
          Ground Delivery Fee
        </dt>
        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
          ${groundDeliveryFee.toFixed(2)}
        </dd>
      </div>
      <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
          Dispensing Fee
        </dt>
        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
          ${dispensingFee.toFixed(2)}
        </dd>
      </div>
      <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
          Subtotal
        </dt>
        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
          ${planPrice.toFixed(2)}
        </dd>
      </div>
      <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
          Tax ({taxRate}%)
        </dt>
        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
          ${new Decimal(taxAmount).toFixed(2)}
        </dd>
      </div>

      <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
          Total
        </dt>
        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
          $
          {(
            Number(planPrice) +
            (taxRate
              ? Number(groundDeliveryFee + dispensingFee) +
              Number(
                new Decimal(
                  ((groundDeliveryFee + dispensingFee) * taxRate) / 100
                )
              )
              : Number(groundDeliveryFee + dispensingFee))
          ).toFixed(2)}
        </dd>
      </div>
    </>
  );
};
