import React, { useState, useEffect, useRef, useCallback } from "react"
import { ReactComponent as Arrow } from "../../../../../assets/common/arrowDown.svg"

type PaymentTypeSelectMenuProps = {
  paymentTypeList: { id: string }[]
  paymentTypeSelectedItem: string
  setPaymentTypeSelectedItem: (args: string) => void
  setNextButtonDisabled: (args: boolean) => void
}

const PaymentTypeSelectMenu: React.FC<PaymentTypeSelectMenuProps> = ({
  paymentTypeList,
  paymentTypeSelectedItem,
  setPaymentTypeSelectedItem,
  setNextButtonDisabled,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const openHandler = (): void => {
    setIsOpened(!isOpened)
  }

  const selectHandler = (id: string) => {
    const selected = paymentTypeList.find((el) => el.id === id)
    setNextButtonDisabled(false)
    setPaymentTypeSelectedItem(selected!.id)
    setIsOpened(false)
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setIsOpened(false)
      }
    },
    [setIsOpened]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div className="relative mt-2">
      <div
        className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
        onClick={openHandler}
        ref={dropDownRef}
      >
        <div className="flex items-center pl-4 font-medium text-828282 w-full">
          {paymentTypeSelectedItem}
        </div>
        <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
          <Arrow />
        </div>
        {isOpened && (
          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
            {paymentTypeList.map(({ id }, index) => (
              <div
                className={`${
                  index === 0 ? "" : "border-t border-F0F0F0"
                } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                key={id}
                onClick={() => selectHandler(id)}
              >
                {id}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentTypeSelectMenu
