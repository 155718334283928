import { FC, Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

interface ProgramInfoModalProps {
  programInfoOpen: boolean
  setProgramInfoOpen: (args: boolean) => void
}

const ProgramInfoModal: FC<ProgramInfoModalProps> = ({
  programInfoOpen,
  setProgramInfoOpen,
}) => {

  const startDate = sessionStorage.getItem("program_start_date") || ""
  const [startYear, startMonth, startDay]: string[] = startDate.split("-")
  const formattedStartDate = [startDay, startMonth, startYear].join("/")

  const endDate = sessionStorage.getItem("program_end_date") || ""
  const [endYear, endMonth, endDay]: string[] = endDate.split("-")
  const formattedEndDate = [endDay, endMonth, endYear].join("/")

  const scheduledDepositDate =
    sessionStorage.getItem("scheduled_deposit_date") || ""
  const [sdYear, sdMonth, sdDay]: string[] = scheduledDepositDate.split("-")
  const formattedScheduledDepositDate = [sdDay, sdMonth, sdYear].join("/")

  const recurringStartDate =
    sessionStorage.getItem("recurring_start_date") || ""
  const [rsYear, rsMonth, rsDay]: string[] = recurringStartDate.split("-")
  const formattedRecurringStartDate = [rsDay, rsMonth, rsYear].join("/")

  const recurringEndDate = sessionStorage.getItem("recurring_end_date") || ""
  const [reYear, reMonth, reDay]: string[] = recurringEndDate.split("-")
  const formattedRecurringEndDate = [reDay, reMonth, reYear].join("/")

  return (
    <Transition.Root show={programInfoOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setProgramInfoOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Selected program info
                  </Dialog.Title>
                  {(sessionStorage.getItem("module") === "LEADS") ||
                    (sessionStorage.getItem("province") === "Quebec" &&
                    sessionStorage.getItem("isCreateOpportunity") === "Yes") ||
                    (
                    (sessionStorage.getItem("module") === "CONTACTS" &&
                      sessionStorage.getItem("createOpportunitySkipped") === "Yes"
                    ))
                    ? (
                    <>
                      <div className="my-4 flex flex-col gap-1">
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Customer:</span>{" "}
                          {sessionStorage.getItem("full_name")}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">
                            Performance Manager:
                          </span>{" "}
                          {sessionStorage.getItem("pm_name")}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Product Plan:</span>{" "}
                          {sessionStorage.getItem("plan_name")}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Program Start: </span>
                          {formattedStartDate}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Program End: </span>
                          {formattedEndDate}
                        </p>
                      </div>

                      <div className="my-4 flex flex-col gap-1">
                        {(sessionStorage.getItem("isScheduledDeposit") ===
                          "Yes" ||
                          sessionStorage.getItem("isRecurring") === "Yes") && (
                          <p className="text-sm text-gray-500">
                            <span className="font-bold">Initial Deposit:</span>{" "}
                            $
                            {Number(
                              sessionStorage.getItem("initial_deposit")
                            ).toFixed(2)}
                          </p>
                        )}
                        {sessionStorage.getItem("isScheduledDeposit") ===
                          "Yes" && (
                          <>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Scheduled Deposit:
                              </span>{" "}
                              $
                              {Number(
                                sessionStorage.getItem(
                                  "scheduled_deposit_amount"
                                )
                              ).toFixed(2)}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Scheduled Deposit Date:
                              </span>{" "}
                              {formattedScheduledDepositDate}
                            </p>
                          </>
                        )}
                        {sessionStorage.getItem("isRecurring") === "Yes" && (
                          <>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Recurring Payment:
                              </span>{" "}
                              $
                              {Number(
                                sessionStorage.getItem("recurring_amount")
                              ).toFixed(2)}{" "}
                              {sessionStorage
                                .getItem("frequency")
                                ?.toLowerCase()}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Recurring Payment Start:
                              </span>{" "}
                              {formattedRecurringStartDate}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Recurring Payment End:
                              </span>{" "}
                              {formattedRecurringEndDate}
                            </p>
                          </>
                        )}
                      </div>

                      <div className="my-4 flex flex-col gap-1">
                        {sessionStorage.getItem("country") === "CA" ? (
                          <>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">Province:</span>{" "}
                              {sessionStorage.getItem("province")}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">Plan Price: $</span>
                              {Number(
                                sessionStorage.getItem("plan_price")
                              ).toFixed(2)}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">Taxes: $</span>
                              {(
                                (Number(sessionStorage.getItem("tax_rate")) /
                                  100) *
                                Number(sessionStorage.getItem("plan_price"))
                              ).toFixed(2)}{" "}
                              ({sessionStorage.getItem("tax_rate")}%)
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Total program price: $
                              </span>
                              {Number(
                                (
                                  Number(sessionStorage.getItem("plan_price")) +
                                  +Number(
                                    sessionStorage.getItem("tax_rate")
                                      ? Number(
                                          (Number(
                                            sessionStorage.getItem("plan_price")
                                          ) *
                                            Number(
                                              sessionStorage.getItem("tax_rate")
                                            )) /
                                            100
                                        )
                                      : 0
                                  ).toFixed(2)
                                ).toFixed(2)
                              ).toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p className="text-sm text-gray-500">
                            <span className="font-bold">
                              Total program price:
                            </span>{" "}
                            {sessionStorage.getItem("currency") === "EUR"
                              ? "€"
                              : sessionStorage.getItem("currency") === "GBP"
                              ? "£"
                              : "$"}
                            {Number(
                              sessionStorage.getItem("plan_price")
                            ).toFixed(2)}
                          </p>
                        )}
                      </div>
                    </>
                  ) : sessionStorage.getItem("module") === "CONTACTS" ? (
                    <>
                      <div className="my-4 flex flex-col gap-1">
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Customer:</span>{" "}
                          {sessionStorage.getItem("full_name")}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Product Plan:</span>{" "}
                          {sessionStorage.getItem("plan_name")}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Total Price:</span>{" "}
                          {sessionStorage.getItem("currency") === "EUR"
                            ? "€"
                            : sessionStorage.getItem("currency") === "GBP"
                            ? "£"
                            : "$"}
                          {Number(
                            sessionStorage.getItem("total_price")
                          ).toFixed(2)}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Amount Paid:</span>{" "}
                          {sessionStorage.getItem("currency") === "EUR"
                            ? "€"
                            : sessionStorage.getItem("currency") === "GBP"
                            ? "£"
                            : "$"}
                          {Number(
                            sessionStorage.getItem("amount_paid")
                          ).toFixed(2)}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Amount Remaining:</span>{" "}
                          {sessionStorage.getItem("currency") === "EUR"
                            ? "€"
                            : sessionStorage.getItem("currency") === "GBP"
                            ? "£"
                            : "$"}
                          {Number(
                            sessionStorage.getItem("amount_remaining")
                          ).toFixed(2)}
                        </p>
                      </div>

                      <div className="my-4 flex flex-col gap-1">
                        {(sessionStorage.getItem("isScheduledDeposit") ===
                          "Yes" ||
                          sessionStorage.getItem("isRecurring") === "Yes") && (
                          <p className="text-sm text-gray-500">
                            <span className="font-bold">Initial Deposit:</span>{" "}
                            $
                            {Number(
                              sessionStorage.getItem("initial_deposit")
                            ).toFixed(2)}
                          </p>
                        )}
                        {sessionStorage.getItem("isScheduledDeposit") ===
                          "Yes" && (
                          <>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Scheduled Deposit:
                              </span>{" "}
                              $
                              {Number(
                                sessionStorage.getItem(
                                  "scheduled_deposit_amount"
                                )
                              ).toFixed(2)}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Scheduled Deposit Date:
                              </span>{" "}
                              {formattedScheduledDepositDate}
                            </p>
                          </>
                        )}
                        {sessionStorage.getItem("isRecurring") === "Yes" && (
                          <>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Recurring Payment:
                              </span>{" "}
                              $
                              {Number(
                                sessionStorage.getItem("recurring_amount")
                              ).toFixed(2)}{" "}
                              {sessionStorage
                                .getItem("frequency")
                                ?.toLowerCase()}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Recurring Payment Start:
                              </span>{" "}
                              {formattedRecurringStartDate}
                            </p>
                            <p className="text-sm text-gray-500">
                              <span className="font-bold">
                                Recurring Payment End:
                              </span>{" "}
                              {formattedRecurringEndDate}
                            </p>
                          </>
                        )}
                      </div>          
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ProgramInfoModal