import axios from "axios"

const LOGIN_URL = `${process.env.REACT_APP_API_URL}admin/v1/login`
const PROFILE_URL = `${process.env.REACT_APP_API_URL}admin/v1/account/get-profile`
const UPDATE_PROFILE = `${process.env.REACT_APP_API_URL}admin/v1/account/update-profile`

export function login(obj: string) {
  return axios.post(LOGIN_URL, obj)
}

export function getProfile() {
  return axios.get(PROFILE_URL)
}

export function updateProfile(obj: object) {
  return axios.post(UPDATE_PROFILE, obj)
}
