export const tableData = [
  {
    name: "First Name",
    sort: "first_name",
  },
  {
    name: "Last Name",
    sort: "last_name",
  },
  {
    name: "Email",
    sort: "email",
  },
  {
    name: "Mobile",
    sort: "mobile",
  },
  {
    name: "Role",
    sort: "role",
  },
  {
    name: "Type",
    sort: "type",
  },
  {
    name: "City",
    sort: "city",
  },
  {
    name: "Country",
    sort: "country",
  },
  {
    name: "Location",
    sort: "location",
  },
  {
    name: "Residential Address",
    sort: "residentialAddress",
  },
  {
    name: "Shipping Address",
    sort: "shippingAddress",
  },
  {
    name: "Created by ID",
    sort: "createdByID",
  },
  {
    name: "Updated by ID",
    sort: "updatedByID",
  },
  {
    name: "Updated at",
    sort: "updatedAt",
  },
  {
    name: "User ID",
    sort: "id",
  },
  {
    name: "Client ID",
    sort: "client_id",
  },
]
