import { FC, Fragment, useState, useRef, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import axios from "axios"

interface MyProps {
  modalOpen: boolean
  setModalOpen: (args: boolean) => void
  selectedPercentData: AdditionalPercentData | null
  getAdditionalPercentData: () => void
}

type AdditionalPercentData = {
  id: number
  region: string
  percent: number
  active: boolean
}

const UpdatePercentModal: FC<MyProps> = ({
  modalOpen,
  setModalOpen,
  selectedPercentData,
  getAdditionalPercentData,
}) => {
  const [responseMsg, setResponseMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [percentText, setPercentText] = useState("")
  const [confirmDisabled, setConfirmDisabled] = useState(false)
  const [active, setActive] = useState(false)

  const cancelButtonRef = useRef(null)

  useEffect(() => {
    setPercentText(String(selectedPercentData?.percent))
    setActive(selectedPercentData?.active ?? false)
  }, [modalOpen])

  const handleCancel = () => {
    setModalOpen(false)
  }

  const handleUpdate = async () => {

    setConfirmDisabled(true)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/additional-percent/update`,
        {
          id: selectedPercentData?.id,
          percent: Number(percentText),
          active: active,
        }
      )
      .then(() => {
        setResponseMsg("Percentage successfully updated")
        setErrorMsg("")
        setTimeout(() => {
          setModalOpen(false)
          getAdditionalPercentData()
        }, 3000)
        setTimeout(() => {
          setResponseMsg("")
          setPercentText("")
          setActive(false)
          setConfirmDisabled(false)
        }, 4000)
      })
      .catch(() => {
        setErrorMsg("Server request failed")
        setResponseMsg("")
        setTimeout(() => {
          setModalOpen(false)
        }, 3000)
        setTimeout(() => {
          setErrorMsg("")
          setPercentText("")
          setActive(false)
          setConfirmDisabled(false)
        }, 4000)
      })
  }

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Update Percentage {selectedPercentData?.region}
                  </Dialog.Title>
                </div>
              </div>

              <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                  {errorMsg && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2 text-center">
                      <span className="block sm:inline">{errorMsg}</span>
                    </div>
                  )}
                  {responseMsg && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-2 text-center">
                      <span className="block sm:inline">{responseMsg}</span>
                    </div>
                  )}

                  <div className="bg-white pt-8 px-4 rounded-md sm:px-7">
                    <div className="pb-3">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Percentage (%)
                      </label>
                      <div className="mt-1">
                        <input
                          id="percent"
                          name="percent"
                          type="number"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                          value={percentText}
                          onChange={(e) => setPercentText(e.target.value)}
                          placeholder="Enter new percent"
                        />
                      </div>
                    </div>

                    <div className="relative flex pb-5">
                      <div className="pr-5 text-sm">
                        <label
                          htmlFor="Checked"
                          className="font-medium text-gray-700 select-none"
                        >
                          Active
                        </label>
                      </div>
                      <div className="flex items-center h-5">
                        <input
                          id="active"
                          name="active"
                          type="checkbox"
                          className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                          value="active"
                          checked={active}
                          onClick={() => setActive(!active)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center space-x-3">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm"
                      onClick={handleCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={` ${confirmDisabled
                        ? "bg-gray-300 text-gray-900 cursor-auto"
                        : "bg-navy-theme text-white"
                      }
                        inline-flex justify-center w-30 rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:text-sm`}
                      onClick={handleUpdate}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpdatePercentModal
