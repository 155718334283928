import { FC, Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ClockIcon } from "@heroicons/react/outline"
import { User } from "../../types/user"

interface BillingConfirmationModalProps {
  open: boolean
  setOpen: (args: boolean) => void
  payRate: number
  totalHours: number
  totalPay: number
  description: string
  commission: string
  exchangeRate: string
  salaryAmount: string
  user: User
  createTimeEntriesBill: () => void
}

const BillingConfirmationModal: FC<BillingConfirmationModalProps> = ({
  open,
  setOpen,
  payRate,
  totalHours,
  totalPay,
  description,
  commission,
  exchangeRate,
  salaryAmount,
  user,
  createTimeEntriesBill,
}) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ClockIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 mb-4"
                  >
                    Billing Confirmation
                  </Dialog.Title>
                  <div className="mb-2">
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">User:</span>{" "}
                      {user?.first_name} {user?.last_name}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Email:</span> {user?.email}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Pay Rate:</span> {payRate}{" "}
                      {user?.currency}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Total Hours:</span>{" "}
                      {totalHours.toFixed(2)} hrs
                    </p>
                    {!user?.is_monthly_contracted && (
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Total Pay:</span>{" "}
                        {totalPay.toFixed(2)} {user?.currency}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Description:</span>{" "}
                      {description}
                    </p>
                    <p className="text-sm text-gray-500">
                      <span className="font-bold">Commission:</span>{" "}
                      {Number(commission).toFixed(2)} {user?.currency}
                    </p>
                    {!user?.is_monthly_contracted ? (
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Exchange Rate:</span>{" "}
                        {exchangeRate} {user?.salary_currency} = 1{" "}
                        {user?.currency}
                      </p>
                    ) : (
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Salary Amount:</span>{" "}
                        {Number(salaryAmount)?.toFixed(2)} {user?.currency}
                      </p>
                    )}
                  </div>
                  <p className="text-sm text-gray-700">
                    Are you sure you want to continue?
                  </p>
                </div>
              </div>
              <div className="mt-8 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setOpen(false)
                    createTimeEntriesBill()
                  }}
                >
                  Send to Xero
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BillingConfirmationModal
