import { useState } from "react"
import { useHistory } from "react-router-dom"
import { DesktopLogo, LockIcon, MobileLogo } from "../assets/common/icons"
import { useFormik } from "formik"
import axios, { AxiosError, AxiosResponse } from "axios"
import * as Yup from "yup"

export default function ForgotPassword() {
  const history = useHistory()
  const [resmessage, setResponsemsg] = useState("")
  const [errmess, seterrmess] = useState("")
  const initialValues = {
    email: "",
  }

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Email address is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, actions) => {
      const data = JSON.stringify({ email: values.email })

      axios
        .post(`${process.env.REACT_APP_API_URL}admin/v1/forgot-password`, data)
        .then(function (response: AxiosResponse) {
          if (response) {
            localStorage.setItem("email", data)
            localStorage.setItem("otp", "true")
            setResponsemsg(response.data.message)
            seterrmess("")
            actions.resetForm()
            setTimeout(() => {
              history.push("/verification")
            }, 3000)
          }
        })
        .catch((error: AxiosError) => {
          const responseData = error.response?.data as { message: string }
          const errorMessage = responseData?.message || "An error occurred"
          seterrmess(errorMessage)
          setResponsemsg("")
        })
    },
  })

  return (
    <div className="quiz-container">
      <form className="space-y-6" onSubmit={formik.handleSubmit} method="POST">
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-5 relative">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <span className="m-auto mx-auto h-12 w-auto desktop-logo">
              <DesktopLogo />
            </span>

            <span className="m-auto mx-auto h-12 w-auto mobile-logo">
              <MobileLogo />
            </span>

            <h2 className="mt-6 text-center font-semibold text-3xl text-333333">
              Forgot Password
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            {resmessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5 text-center">
                <span className="block sm:inline">{resmessage}</span>
              </div>
            )}
            {errmess && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5 text-center">
                <span className="block sm:inline">{errmess}</span>
              </div>
            )}

            <div className="bg-white py-8 px-4 shadow rounded-md sm:px-7">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    {...formik.getFieldProps("email")}
                    id="email"
                    name="email"
                    type="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="flex items-center justify-between my-4"></div>

            <div>
              <button
                type="submit"
                className="bg-navy-theme relative h-70 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
              >
                <span className="absolute left-[18px]">
                  <LockIcon />
                </span>{" "}
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
