import axios from "axios"
import { useEffect, useState } from "react"
import { handleSearch } from "../../helpers/handleSearch"

type ApiClockedInUser = {
  admin: {
    first_name: string
    last_name: string
    email: string
    time_zone: string
  }
  start_time: string
  zoho_id: string
}

type ClockedInUser = {
  first_name: string
  last_name: string
  email: string
  time_zone: string
  start_time: string
  zoho_id: string
}

const ClockedIn = () => {
  const [users, setUsers] = useState([])
  const [originalUsers, setOriginalUsers] = useState([])
  const [usersCount, setUsersCount] = useState(0)

  const fetchClockedInUsers = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/time-entries/get-running`
    )

    const formattedData = data.data.data.map((item: ApiClockedInUser) => ({
      start_time: item.start_time,
      zoho_id: item.zoho_id,
      first_name: item.admin.first_name,
      last_name: item.admin.last_name,
      email: item.admin.email,
      time_zone: item.admin.time_zone,
    }))

    setUsers(formattedData)
    setOriginalUsers(formattedData)
    setUsersCount(data.data.data.length)
  }

  useEffect(() => {
    fetchClockedInUsers()
  }, [])

  const tableHeaders = ["First Name", "Last Name", "Email", "Start Time"]

  const convertToLocalTimezone = (dateString: string) => {
    const utcDatetime = new Date(dateString)
    const formattedTime = utcDatetime.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })

    return formattedTime
  }

  //* SEARCH TABLE FUNCTION
  const [query, setQuery] = useState("")
  const [searchParam] = useState(["first_name", "last_name", "email"])

  useEffect(() => {
    handleSearch(query, originalUsers, searchParam, setUsers)
  }, [query])

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-8">
        <h1 className="font-bold text-2xl">
          Currently clocked in users ({usersCount})
        </h1>
      </div>
      <input
        type="text"
        className="w-[290px] shadow-sm block sm:text-sm border-gray-300 rounded-md mb-4"
        placeholder="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="flex flex-col overflow-hidden mb-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-navy-theme">
                  <tr>
                    {tableHeaders.map((header) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                        key={header}
                      >
                        <span className="pt-1">{header}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {usersCount > 0 ? (
                    users.map((user: ClockedInUser, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {user.first_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {user.last_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {user.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {convertToLocalTimezone(user.start_time)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        No users clocked in at the moment.
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ClockedIn
