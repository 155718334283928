export const sortData = <T extends Record<string, unknown>>(
  type: string,
  order: string,
  users: T[],
  col: string,
  setUsers: (arg: T[]) => void,
  setOrder: (arg: string) => void,
  setSortingColumn: (arg: string) => void
) => {
  if (type === "string") {
    if (order === "ASC") {
      const sorted = [...users].sort((a, b) =>
        (a[col] as string)?.toLowerCase() > (b[col] as string)?.toLowerCase() ? 1 : -1
      )
      setUsers(sorted)
      setOrder("DSC")
      setSortingColumn(col)
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) =>
      (a[col] as string)?.toLowerCase() < (b[col] as string)?.toLowerCase() ? 1 : -1
      )
      setUsers(sorted)
      setOrder("ASC")
      setSortingColumn(col)
    }
  }

  if (type === "number") {
    if (order === "ASC") {
      const sorted = [...users].sort((a, b) => ((a[col] as string) > (b[col] as string) ? 1 : -1))
      setUsers(sorted)
      setOrder("DSC")
      setSortingColumn(col)
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) => ((a[col] as string) < (b[col] as string) ? 1 : -1))
      setUsers(sorted)
      setOrder("ASC")
      setSortingColumn(col)
    }
  }
}
