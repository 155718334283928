import axios from "axios";
import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../../components/layout/Layout";
import PMSearchMenu from "./components/PMSearchMenu";
import ProductPlanSearchMenu from "../../components/product-plan-search-menu/ProductPlanSearchMenu";
import UpdateLeadOwnerModal from "./components/UpdateLeadOwnerModal";
import InsuranceLeadConfirmationModal from "./components/InsuranceLeadConfirmationModal";
import PaymentFrequencySelectMenu from "./components/PaymentFrequencySelectMenu";
import spinnerNavy from "../../../../assets/common/spinnerNavy.svg";
// import ProductTypeSelectMenu from "./components/ProductTypeSelectMenu";
import PharmacySelectMenu from "./components/PharmacySelectMenu";

type ProductPlanType = {
  Books_Item_Id: null;
  Description: string;
  Product_Name: string;
  id: string;
  Product_Active: boolean;
  Unit_Price: number;
  Product_Region: string;
  Product_Type: string;
};

const ProductPlan = () => {
  const history = useHistory();
  const pmArr = [
    {
      full_name: "",
      id: "",
      email: "",
    },
  ];

  const productPlanArr = [
    {
      Books_Item_Id: "",
      Description: "",
      Product_Name: "",
      Product_Type: "",
      Unit_Price: 0,
      id: "",
    },
  ];

  const programDurationButtons = [
    { id: "1 month", value: 1 },
    { id: "2 months", value: 2 },
    { id: "3 months", value: 3 },
    { id: "6 months", value: 6 },
    { id: "12 months", value: 12 },
  ];

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [searchingPms, setSearchingPms] = useState(false);
  const [pmDropdownOpen, setPmDropdownOpen] = useState(false);
  const [pmList, setPmList] = useState(pmArr);
  const [pmSelectedItem, setPmSelectedItem] = useState("");
  const [isPmSelected, setIsPmSelected] = useState(false);
  const [pmQueryError, setPmQueryError] = useState("");

  const [searchingProductPlans, setSearchingProductPlans] = useState(false);
  const [productPlanList, setProductPlanList] = useState(productPlanArr);
  const [productPlanSelectedItem, setProductPlanSelectedItem] = useState("");
  const [productPlanDropdownOpen, setProductPlanDropdownOpen] = useState(false);
  const [isProductPlanSelected, setIsProductPlanSelected] = useState(false);
  const [productPlanQueryError, setProductPlanQueryError] = useState("");

  const [initialDeposit, setInitialDeposit] = useState(0);
  const [balanceError, setBalanceError] = useState(false);

  const [productType, setProductType] = useState<string>("");
  const [pharmacyID, setPharmacyID] = useState<string>("");
  const [pharmacyName, setPharmacyName] = useState<string>("");
  const [medicineCount, setMedicineCount] = useState<number>(0);
  const [, setMedicineTotalCost] = useState<number>(0);

  const [isScheduledDeposit, setIsScheduledDeposit] = useState(false);
  const [scheduledDepositAmount, setScheduledDepositAmount] = useState(0);
  const [scheduledDepositDate, setScheduledDepositDate] = useState(
    addDays(new Date(), 1)
  );

  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringAmount, setRecurringAmount] = useState(0);
  const [paymentFrequencySelectedItem, setPaymentFrequencySelectedItem] =
    useState("");
  const [paymentFrequencyError, setPaymentFrequencyError] = useState(false);
  const [recurringStartDate, setRecurringStartDate] = useState(
    addDays(new Date(), 1)
  );
  const [recurringEndDate, setRecurringEndDate] = useState(
    addDays(new Date(), 1)
  );
  const [formattedRecurringEndDate, setFormattedRecurringEndDate] =
    useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [planCounter, setPlanCounter] = useState(0);
  const [recurringIteration, setRecurringIteration] = useState(0);
  const [isForever, setIsForever] = useState(false);

  const [isInsurance, setIsInsurance] = useState(false);
  const [updateLeadOwnerModalOpen, setUpdateLeadOwnerModalOpen] =
    useState(false);
  const [
    insuranceLeadConfirmationModalOpen,
    setInsuranceLeadConfirmationModalOpen,
  ] = useState(false);

  const [programStartDate, setProgramStartDate] = useState(new Date());
  const [programEndDate, setProgramEndDate] = useState(new Date());
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [isMedicineCountValid, setIsMedicineCountValid] = useState(true);
  const [selectedDurationMonths, setSelectedDurationMonths] = useState<
    null | number
  >(null);

  const handleMedicineCount = (count: number) => {
    const cost30Pack = Number(
      sessionStorage.getItem("pharmacy_30_capsule_cost")
    );
    const costSingle = Number(sessionStorage.getItem("pharmacy_capsule_cost"));

    setMedicineCount(count);

    // Validate if input needs to be multiple of 30
    if (cost30Pack && !costSingle) {
      const isValid = count > 0 && count % 30 === 0;
      setIsMedicineCountValid(isValid);

      if (!isValid) {
        sessionStorage.removeItem("pharmacy_medicine_count");
        sessionStorage.removeItem("plan_price");
        return;
      }
    } else {
      const isValid = count > 0;
      setIsMedicineCountValid(isValid);

      if (!isValid) {
        sessionStorage.removeItem("pharmacy_medicine_count");
        sessionStorage.removeItem("plan_price");
        return;
      }
    }

    // Calculate total medication cost
    let totalMedicationCost = 0;
    if (cost30Pack) {
      const packs30 = Math.floor(count / 30);
      const remainingUnits = count % 30;
      totalMedicationCost =
        packs30 * cost30Pack + remainingUnits * (costSingle || 0);
    } else if (costSingle) {
      totalMedicationCost = count * costSingle;
    }

    // Add fees to get final total
    const totalCost = (
      totalMedicationCost +
      Number(sessionStorage.getItem("pharmacy_ground_delivery_fee")) +
      Number(sessionStorage.getItem("pharmacy_dispensing_fee"))
    ).toFixed(2);

    setMedicineTotalCost(+totalCost);

    sessionStorage.setItem("plan_price", totalCost);
    sessionStorage.setItem("pharmacy_medicine_count", String(count));
  };

  const handleProceed = () => {
    sessionStorage.setItem(
      "program_start_date",
      programStartDate?.toISOString().split("T")[0]
    );
    sessionStorage.setItem(
      "program_end_date",
      programEndDate?.toISOString().split("T")[0]
    );

    if (
      sessionStorage.getItem("country") === "CA" &&
      sessionStorage.getItem("province") === "Quebec"
    ) {
      sessionStorage.setItem("initial_deposit", initialDeposit.toString());
    }

    if (
      sessionStorage.getItem("country") === "PCA" ||
      (sessionStorage.getItem("country") === "CA" &&
        sessionStorage.getItem("province") === "Quebec")
    ) {
      if (isScheduledDeposit) {
        sessionStorage.setItem("isScheduledDeposit", "Yes");
        sessionStorage.setItem("initial_deposit", initialDeposit.toString());
        sessionStorage.setItem(
          "scheduled_deposit_amount",
          scheduledDepositAmount.toString()
        );
        sessionStorage.setItem(
          "scheduled_deposit_date",
          scheduledDepositDate?.toISOString().split("T")[0]
        );
      }

      if (isRecurring) {
        sessionStorage.setItem("isRecurring", "Yes");
        sessionStorage.setItem("initial_deposit", initialDeposit.toString());
        sessionStorage.setItem("recurring_amount", recurringAmount.toString());
        sessionStorage.setItem(
          "recurring_start_date",
          recurringStartDate?.toISOString().split("T")[0]
        );
        sessionStorage.setItem(
          "recurring_end_date",
          recurringEndDate?.toISOString().split("T")[0]
        );
        recurringIteration
          ? sessionStorage.setItem(
              "recurring_iteration",
              recurringIteration.toString()
            )
          : sessionStorage.setItem("recurring_iteration", "1");

        switch (paymentFrequencySelectedItem) {
          case "Weekly":
            sessionStorage.setItem("interval", "week");
            sessionStorage.setItem("interval_count", "1");
            sessionStorage.setItem("frequency", "Weekly");
            break;
          case "Fortnightly":
            sessionStorage.setItem("interval", "week");
            sessionStorage.setItem("interval_count", "2");
            sessionStorage.setItem("frequency", "Fortnightly");
            break;
          case "Monthly":
            sessionStorage.setItem("interval", "month");
            sessionStorage.setItem("interval_count", "1");
            sessionStorage.setItem("frequency", "Monthly");
            break;
          case "Bi-monthly":
            sessionStorage.setItem("interval", "month");
            sessionStorage.setItem("interval_count", "2");
            sessionStorage.setItem("frequency", "Bi-monthly");
            break;
          case "Quarterly":
            sessionStorage.setItem("interval", "month");
            sessionStorage.setItem("interval_count", "3");
            sessionStorage.setItem("frequency", "Quarterly");
            break;
        }

        if (isForever) sessionStorage.setItem("forever_flag", "true");
      }

      if (isInsurance) {
        sessionStorage.setItem("isInsurance", "Yes");
        setUpdateLeadOwnerModalOpen(true);
        return;
      }
    }

    if (
      (initialDeposit || isScheduledDeposit || isRecurring) &&
      (Number(sessionStorage.getItem("plan_price")) +
        Number(
          new Decimal(
            sessionStorage.getItem("tax_rate")
              ? (Number(sessionStorage.getItem("plan_price")) *
                  Number(sessionStorage.getItem("tax_rate"))) /
                100
              : 0
          ).toFixed(2)
        ) -
        initialDeposit -
        scheduledDepositAmount ==
        0 ||
        isRecurring)
    ) {
      sessionStorage.setItem("isFullPayment", "Yes");
    } else {
      sessionStorage.setItem("isFullPayment", "No");
    }

    history.push("/payment-portal/payment-type");
  };

  const searchPms = async () => {
    setSearchingPms(true);
    setPmQueryError("");

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-performance-manager`,
        {
          country: sessionStorage.getItem("country"),
          searchString: pmSelectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res;
        setSearchingPms(false);
        setPmList(data);
        setPmDropdownOpen(true);
      })
      .catch((err) => {
        setSearchingPms(false);
        setPmDropdownOpen(false);
        if (typeof err?.response?.data.message === "string") {
          setPmQueryError(err.response.data.message);
        }
      });
  };

  const searchPlans = async () => {
    setSearchingProductPlans(true);
    setProductPlanQueryError("");

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-product`,
        {
          country: sessionStorage.getItem("country"),
          searchString: productPlanSelectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res;
        setSearchingProductPlans(false);
        if (sessionStorage.getItem("country") === "UK") {
          setProductPlanList(
            data.filter((plan: ProductPlanType) =>
              sessionStorage.getItem("currency") === "GBP"
                ? plan.Product_Region === "United Kingdom"
                : sessionStorage.getItem("currency") === "EUR"
                ? plan.Product_Region === "Ireland"
                : plan
            )
          );
        } else if (
          sessionStorage.getItem("country") === "CA" &&
          sessionStorage.getItem("province") === "Quebec"
        ) {
          setProductPlanList(
            //   data.filter((plan: ProductPlanType) =>
            //     sessionStorage.getItem("product_type") === "Product Plan"
            //       ? plan.Product_Type === "MHC"
            //       : plan.Product_Type === "Medication"
            //   )
            // );
            data.filter((plan: ProductPlanType) => plan.Product_Type === "MHC")
          );
        } else {
          if (sessionStorage.getItem("country") === "CA") {
            setProductPlanList(
              data.filter(
                (plan: ProductPlanType) => plan.Product_Type !== "Medication"
              )
            );
          } else {
            setProductPlanList(data);
          }
        }
        setProductPlanDropdownOpen(true);
      })
      .catch((err) => {
        setSearchingProductPlans(false);
        setProductPlanDropdownOpen(false);
        if (typeof err?.response?.data.message === "string") {
          setProductPlanQueryError(err.response.data.message);
        }
      });
  };

  const calculateEndDate = (startDate: Date, value: number) => {
    setSelectedDuration(value);
    const date = new Date(startDate);
    setProgramEndDate(new Date(date.setMonth(startDate.getMonth() + value)));
  };

  const calculateSuggestedPayments = (
    remainingBalance: number,
    frequency: string
  ) => {
    // For Quebec, only show monthly options
    if (sessionStorage.getItem("province") === "Quebec") {
      const durations = [1, 2, 5, 11].filter((duration) =>
        selectedDurationMonths ? selectedDurationMonths - 1 === duration : true
      );
      const suggestions: { months: number; amount: number }[] = [];

      if (durations.length === 0) {
        return [
          {
            months: 1,
            amount: +remainingBalance.toFixed(2),
          },
        ];
      }

      durations.forEach((duration) => {
        const suggestedAmount = +new Decimal(remainingBalance)
          .dividedBy(duration)
          .toFixed(2);
        suggestions.push({ months: duration, amount: suggestedAmount });
      });

      return suggestions;
    }

    const durations = [1, 2, 5, 11].filter((duration) =>
      selectedDurationMonths ? selectedDurationMonths - 1 === duration : true
    );
    const suggestions: { months: number; amount: number }[] = [];

    if (durations.length === 0) {
      durations.push(1);
    }

    durations.forEach((duration) => {
      let multiplier = 1;
      switch (frequency) {
        case "Weekly":
          multiplier = 4 * duration; // 4 weeks per month
          break;
        case "Fortnightly":
          multiplier = 2 * duration; // 2 fortnights per month
          break;
        case "Monthly":
          multiplier = duration;
          break;
        case "Bi-monthly":
          multiplier = Math.ceil(duration / 2); // Half as many payments
          break;
      }

      const suggestedAmount = +new Decimal(remainingBalance)
        .dividedBy(multiplier)
        .toFixed(2);
      suggestions.push({ months: duration, amount: suggestedAmount });
    });

    return suggestions;
  };

  const calculateRecurringEndDate = (
    startDate: Date,
    value: number,
    frequency: string
  ) => {
    const date = new Date(startDate);
    switch (frequency) {
      case "Weekly":
        setRecurringEndDate(
          new Date(date.setDate(startDate.getDate() + 7 * value))
        );
        break;
      case "Fortnightly":
        setRecurringEndDate(
          new Date(date.setDate(startDate.getDate() + 7 * value * 2))
        );
        break;
      case "Monthly":
        setRecurringEndDate(
          new Date(date.setMonth(startDate.getMonth() + value))
        );
        break;
      case "Bi-monthly":
        setRecurringEndDate(
          new Date(date.setMonth(startDate.getMonth() + value * 2))
        );
        break;
      case "Quarterly":
        setRecurringEndDate(
          new Date(date.setMonth(startDate.getMonth() + value * 3))
        );
        break;
    }
  };

  const calculatePlanDuration = (amount: number, frequency: string) => {
    const remainingBalance = +(
      Number(sessionStorage.getItem("plan_price")) +
      Number(
        new Decimal(
          sessionStorage.getItem("tax_rate")
            ? (Number(sessionStorage.getItem("plan_price")) *
                Number(sessionStorage.getItem("tax_rate"))) /
              100
            : 0
        ).toFixed(2)
      ) -
      initialDeposit -
      scheduledDepositAmount
    ).toFixed(2);

    const counter = Math.round(remainingBalance / amount);
    const difference = Math.abs(remainingBalance - amount * counter);
    const maxDifference = counter * 0.01;

    if (
      difference > maxDifference || // Check if difference exceeds tolerance
      remainingBalance - (recurringAmount || 0) < 0 // Check if initial amount is negative
    ) {
      setPaymentFrequencyError(true);
      return;
    }

    setPaymentFrequencyError(false);
    setPlanCounter(counter);
    setRecurringIteration(counter);
    switch (frequency) {
      case "Weekly":
        setPlanDuration(`Plan duration: ${counter} weeks`);
        break;
      case "Fortnightly":
        setPlanDuration(`Plan duration: ${counter * 2} weeks`);
        break;
      case "Monthly":
        setPlanDuration(`Plan duration: ${counter} months`);
        break;
      case "Bi-monthly":
        setPlanDuration(`Plan duration: ${counter * 2} months`);
        break;
      case "Quarterly":
        setPlanDuration(`Plan duration: ${counter * 3} months`);
        break;
    }
  };

  const formatDate = (date: string) => {
    const [year, month, day]: string[] = date.split("-");
    return [day, month, year].join("/");
  };

  useEffect(() => {
    if (recurringAmount && paymentFrequencySelectedItem) {
      calculatePlanDuration(recurringAmount, paymentFrequencySelectedItem);
    }
  }, [
    recurringAmount,
    paymentFrequencySelectedItem,
    initialDeposit,
    scheduledDepositAmount,
  ]);

  useEffect(() => {
    if (recurringStartDate) {
      calculateRecurringEndDate(
        recurringStartDate,
        planCounter,
        paymentFrequencySelectedItem
      );
    }
  }, [recurringStartDate, planCounter, paymentFrequencySelectedItem]);

  useEffect(() => {
    if (
      +(
        Number(sessionStorage.getItem("plan_price")) +
        Number(
          new Decimal(
            sessionStorage.getItem("tax_rate")
              ? (Number(sessionStorage.getItem("plan_price")) *
                  Number(sessionStorage.getItem("tax_rate"))) /
                100
              : 0
          ).toFixed(2)
        ) -
        initialDeposit -
        scheduledDepositAmount
      ).toFixed(2) < 0
    ) {
      setBalanceError(true);
    } else {
      setBalanceError(false);
    }
  }, [initialDeposit, scheduledDepositAmount]);

  useEffect(() => {
    if (!isScheduledDeposit && !isRecurring) {
      setInitialDeposit(0);
    }
    if (!isScheduledDeposit) {
      setScheduledDepositAmount(0);
      setScheduledDepositDate(addDays(new Date(), 1));
    }
    if (!isRecurring) {
      setRecurringAmount(0);
      setPaymentFrequencySelectedItem("");
      setRecurringStartDate(addDays(new Date(), 1));
      setRecurringEndDate(addDays(new Date(), 1));
    }
  }, [isScheduledDeposit, isRecurring]);

  useEffect(() => {
    if (recurringEndDate) {
      setFormattedRecurringEndDate(
        formatDate(recurringEndDate?.toISOString().split("T")[0])
      );
    }
  }, [recurringEndDate]);

  useEffect(() => {
    if (
      !isPmSelected ||
      !isProductPlanSelected ||
      !programStartDate ||
      !programEndDate
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      sessionStorage.getItem("country") === "CA" &&
      sessionStorage.getItem("province") === "Quebec" &&
      initialDeposit < 575
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      sessionStorage.getItem("country") === "CA" &&
      sessionStorage.getItem("province") === "Quebec" &&
      !isScheduledDeposit &&
      !isRecurring &&
      initialDeposit <= 0
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      sessionStorage.getItem("country") === "CA" &&
      sessionStorage.getItem("province") === "Quebec" &&
      productType === "Medication" &&
      (!pharmacyID || !pharmacyName || !isMedicineCountValid)
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      (isScheduledDeposit || isRecurring) &&
      (balanceError ||
        Number(sessionStorage.getItem("plan_price")) +
          Number(
            new Decimal(
              sessionStorage.getItem("tax_rate")
                ? (Number(sessionStorage.getItem("plan_price")) *
                    Number(sessionStorage.getItem("tax_rate"))) /
                  100
                : 0
            ).toFixed(2)
          ) -
          initialDeposit -
          scheduledDepositAmount <
          0)
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      isScheduledDeposit &&
      (!scheduledDepositAmount || !scheduledDepositDate)
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      isRecurring &&
      (!recurringAmount ||
        !paymentFrequencySelectedItem ||
        paymentFrequencyError ||
        !recurringStartDate ||
        !recurringEndDate)
    ) {
      setNextButtonDisabled(true);
      return;
    }

    setNextButtonDisabled(false);
  }, [
    isPmSelected,
    isProductPlanSelected,
    programStartDate,
    programEndDate,
    isScheduledDeposit,
    isRecurring,
    initialDeposit,
    balanceError,
    scheduledDepositAmount,
    scheduledDepositDate,
    recurringAmount,
    paymentFrequencySelectedItem,
    paymentFrequencyError,
    recurringStartDate,
    recurringEndDate,
    productType,
    pharmacyID,
    pharmacyName,
    isMedicineCountValid,
    initialDeposit,
  ]);

  useEffect(() => {
    setPmSelectedItem("");
    setIsPmSelected(false);
    setIsProductPlanSelected(false);
    setProductPlanSelectedItem("");
    setProgramStartDate(new Date());
    setProgramEndDate(new Date());
    setSelectedDuration(0);

    setInitialDeposit(0);

    setIsScheduledDeposit(false);
    setScheduledDepositAmount(0);
    setScheduledDepositDate(addDays(new Date(), 1));

    setIsRecurring(false);
    setRecurringAmount(0);
    setPaymentFrequencySelectedItem("");
    setPaymentFrequencyError(false);
    setRecurringIteration(0);
    setIsForever(false);

    setProductType("");
    setPharmacyID("");
    setPharmacyName("");
    setMedicineCount(1);

    setIsInsurance(false);

    setUpdateLeadOwnerModalOpen(false);
    setInsuranceLeadConfirmationModalOpen(false);

    if (
      sessionStorage.getItem("country") === "PCA" ||
      (sessionStorage.getItem("country") === "CA" &&
        sessionStorage.getItem("province") === "Quebec")
    ) {
      sessionStorage.setItem("isRecurring", "No");
      sessionStorage.setItem("isScheduledDeposit", "No");
      sessionStorage.setItem("isInsurance", "No");
    }

    const removeKeys = [
      "pm_id",
      "pm_name",
      "plan_id",
      "plan_name",
      "plan_type",
      "plan_price",
      "program_start_date",
      "program_end_date",
      "initial_deposit",
      "scheduled_deposit_amount",
      "scheduled_deposit_date",
      "recurring_amount",
      "interval",
      "interval_count",
      "frequency",
      "recurring_iteration",
      "forever_flag",
      "pharmacy_id",
      "pharmacy_name",
      "pharmacy_location",
      "pharmacy_dispensing_fee",
      "pharmacy_ground_delivery_fee",
      "pharmacy_capsule_cost",
      "pharmacy_30_capsule_cost",
      "pharmacy_medicine_count",
      "isFullPayment",
    ];
    removeKeys.forEach((key) => sessionStorage.removeItem(key));
  }, []);

  useEffect(() => {
    setIsProductPlanSelected(false);
    setProductPlanSelectedItem("");
    setPharmacyID("");
    setPharmacyName("");
    setIsRecurring(false);
    setIsScheduledDeposit(false);

    setBalanceError(false);

    sessionStorage.removeItem("plan_id");
    sessionStorage.removeItem("plan_name");
    sessionStorage.removeItem("plan_type");
    sessionStorage.removeItem("plan_price");
  }, [productType]);

  useEffect(() => {
    if (pharmacyID) {
      handleMedicineCount(1);
    }
  }, [pharmacyID]);

  return (
    <Layout
      pageNumber={6}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
        <div className="flex">
          <label
            htmlFor="performance_manager"
            className="block text-sm font-medium text-gray-700"
          >
            Performance Manager
          </label>
          {searchingPms && (
            <>
              <img
                width={24}
                className="inline"
                src={spinnerNavy}
                alt="Loading..."
              />
              <span className="font-medium text-sm text-navy-theme">
                Searching for PMs...
              </span>
            </>
          )}
          {pmQueryError && (
            <span className="font-medium text-sm text-E84545 ml-2">
              {pmQueryError}
            </span>
          )}
        </div>
        <PMSearchMenu
          searchPms={searchPms}
          pmList={pmList}
          pmSelectedItem={pmSelectedItem}
          setPmSelectedItem={setPmSelectedItem}
          pmDropdownOpen={pmDropdownOpen}
          setPmDropdownOpen={setPmDropdownOpen}
          setIsPmSelected={setIsPmSelected}
        />
      </div>

      {/* {sessionStorage.getItem("province") === "Quebec" && (
        <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
          <label
            htmlFor="currency"
            className="block text-sm font-medium text-gray-700"
          >
            Are you charging for a Product Plan or Medication?
          </label>
          <ProductTypeSelectMenu setProductType={setProductType} />
        </div>
      )} */}

      {/* {(sessionStorage.getItem("province") !== "Quebec" ||
        (sessionStorage.getItem("province") === "Quebec" && productType)) && ( */}
      <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
        <div className="flex">
          {productType === "Medication" ? (
            <label
              htmlFor="medication"
              className="block text-sm font-medium text-gray-700"
            >
              Medication
            </label>
          ) : (
            <label
              htmlFor="product_plan"
              className="block text-sm font-medium text-gray-700"
            >
              Product Plan
            </label>
          )}
          {searchingProductPlans && (
            <>
              <img
                width={24}
                className="inline"
                src={spinnerNavy}
                alt="Loading..."
              />
              <span className="font-medium text-sm text-navy-theme">
                Searching for plans...
              </span>
            </>
          )}
          {productPlanQueryError && (
            <span className="font-medium text-sm text-E84545 ml-2">
              {productPlanQueryError}
            </span>
          )}
        </div>
        <ProductPlanSearchMenu
          searchPlans={searchPlans}
          productPlanList={productPlanList}
          productPlanSelectedItem={productPlanSelectedItem}
          setProductPlanSelectedItem={setProductPlanSelectedItem}
          productPlanDropdownOpen={productPlanDropdownOpen}
          setProductPlanDropdownOpen={setProductPlanDropdownOpen}
          setIsProductPlanSelected={setIsProductPlanSelected}
        />
      </div>
      {/* )} */}

      {sessionStorage.getItem("province") === "Quebec" &&
        productType === "Medication" &&
        isProductPlanSelected && (
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
            <PharmacySelectMenu
              setPharmacyID={setPharmacyID}
              setPharmacyName={setPharmacyName}
              productPlanID={sessionStorage.getItem("plan_id") || ""}
              region={sessionStorage.getItem("country")}
              key={sessionStorage.getItem("plan_id")}
            />
          </div>
        )}

      {sessionStorage.getItem("province") === "Quebec" &&
        productType === "Medication" &&
        isProductPlanSelected &&
        pharmacyID && (
          <div className="mb-8 sm:mt-0 w-full px-4 sm:px-6 lg:px-8">
            <div className="block text-sm font-medium text-gray-700">
              Medicine count
              {Number(sessionStorage.getItem("pharmacy_30_capsule_cost")) &&
              !Number(sessionStorage.getItem("pharmacy_capsule_cost")) ? (
                <span
                  className={`ml-2 ${
                    isMedicineCountValid ? "text-gray-500" : "text-red-500"
                  }`}
                >
                  (Must be a multiple of 30)
                </span>
              ) : null}
            </div>
            <input
              type="number"
              className={`mt-2 rounded-md border w-full h-12 bg-E0E0E0 flex pl-2 text-828282 focus:outline-none focus:ring-0 font-medium ${
                isMedicineCountValid
                  ? "border-c4c4c4 focus:border-c4c4c4"
                  : "border-red-500 focus:border-red-500"
              }`}
              value={Number(medicineCount).toString()}
              onChange={(e) => handleMedicineCount(+e.target.value)}
              onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                (e.target as HTMLInputElement).blur()
              }
            />
          </div>
        )}

      {/* PCA FLOWS */}
      {sessionStorage.getItem("country") === "PCA" && (
        <div className="mb-8 w-full px-4 sm:px-6 lg:px-8 flex justify-around">
          {!isInsurance && (
            <>
              <div className="relative flex items-start">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="region_access"
                    className="font-medium text-gray-700 select-none"
                  >
                    Scheduled Deposit
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="AU"
                    name="region_access"
                    type="checkbox"
                    className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                    value="AU"
                    checked={isScheduledDeposit}
                    onClick={() => setIsScheduledDeposit(!isScheduledDeposit)}
                  />
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="region_access"
                    className="font-medium text-gray-700 select-none"
                  >
                    Recurring Payment
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="CA"
                    name="region_access"
                    type="checkbox"
                    className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                    value="CA"
                    checked={isRecurring}
                    onClick={() => setIsRecurring(!isRecurring)}
                  />
                </div>
              </div>
            </>
          )}
          {!isScheduledDeposit && !isRecurring && (
            <div className="relative flex items-start">
              <div className="min-w-0 flex-1 text-sm">
                <label
                  htmlFor="region_access"
                  className="font-medium text-gray-700 select-none"
                >
                  Insurance
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  id="NZ"
                  name="region_access"
                  type="checkbox"
                  className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                  value="NZ"
                  checked={isInsurance}
                  onClick={() => setIsInsurance(!isInsurance)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* CA QUEBEC FLOWS */}
      {sessionStorage.getItem("province") === "Quebec" && (
        <>
          <div className="mb-8 w-full px-4 sm:px-6 lg:px-8 flex justify-around">
            <div className="relative flex items-start">
              <div className="min-w-0 flex-1 text-sm">
                <label
                  htmlFor="region_access"
                  className="font-medium text-gray-700 select-none"
                >
                  Scheduled Deposit
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  id="AU"
                  name="region_access"
                  type="checkbox"
                  className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                  value="AU"
                  checked={isScheduledDeposit}
                  onClick={() => setIsScheduledDeposit(!isScheduledDeposit)}
                />
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="min-w-0 flex-1 text-sm">
                <label
                  htmlFor="region_access"
                  className="font-medium text-gray-700 select-none"
                >
                  Recurring Payment
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  id="CA"
                  name="region_access"
                  type="checkbox"
                  className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                  value="CA"
                  checked={isRecurring}
                  onClick={() => setIsRecurring(!isRecurring)}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {sessionStorage.getItem("country") === "PCA" && isRecurring && (
        <div className="mb-8 sm:mt-0 w-full px-4 sm:px-6 lg:px-8">
          <div className="relative flex justify-center items-start">
            <div className="min-w-0 text-sm">
              <label
                htmlFor="forever_flag"
                className="font-medium text-gray-700 select-none"
              >
                Recurring Forever?
              </label>
            </div>
            <div className="ml-3 flex items-center h-5">
              <input
                id="forever_flag"
                name="forever_flag"
                type="checkbox"
                className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                value="forever_flag"
                checked={isForever}
                onClick={() => setIsForever(!isForever)}
              />
            </div>
          </div>
        </div>
      )}
      {(isScheduledDeposit ||
        isRecurring ||
        (sessionStorage.getItem("province") === "Quebec" &&
          productType !== "Medication" &&
          isProductPlanSelected) ||
        (sessionStorage.getItem("province") === "Quebec" &&
          productType === "Medication" &&
          isProductPlanSelected &&
          pharmacyName)) && (
        <div className="mb-8 sm:mt-0 w-full px-4 sm:px-6 lg:px-8">
          <div className="block text-sm font-medium text-gray-700">
            Enter Initial Deposit ($)
          </div>
          {sessionStorage.getItem("province") === "Quebec" &&
            initialDeposit < 575 && (
              <>
                <span className="text-red-600">
                  Initial deposit must be at least $575 for Quebec.
                </span>
                <br />
              </>
            )}
          {sessionStorage.getItem("plan_price") &&
          +(
            Number(sessionStorage.getItem("plan_price")) +
            Number(
              new Decimal(
                sessionStorage.getItem("tax_rate")
                  ? (Number(sessionStorage.getItem("plan_price")) *
                      Number(sessionStorage.getItem("tax_rate"))) /
                    100
                  : 0
              ).toFixed(2)
            ) -
            initialDeposit -
            scheduledDepositAmount
          ).toFixed(2) < 0 ? (
            <span className="text-red-600">
              Please enter an amount less than the total plan price.
            </span>
          ) : scheduledDepositAmount ? (
            <span className="text-green-600">
              Remaining balance after scheduled deposit: $
              {Math.abs(
                Number(sessionStorage.getItem("plan_price")) +
                  Number(
                    new Decimal(
                      sessionStorage.getItem("tax_rate")
                        ? (Number(sessionStorage.getItem("plan_price")) *
                            Number(sessionStorage.getItem("tax_rate"))) /
                          100
                        : 0
                    ).toFixed(2)
                  ) -
                  initialDeposit -
                  scheduledDepositAmount
              ).toFixed(2)}
            </span>
          ) : (
            <span className="text-green-600">
              Remaining balance after initial deposit: $
              {Math.abs(
                Number(sessionStorage.getItem("plan_price")) +
                  Number(
                    new Decimal(
                      sessionStorage.getItem("tax_rate")
                        ? (Number(sessionStorage.getItem("plan_price")) *
                            Number(sessionStorage.getItem("tax_rate"))) /
                          100
                        : 0
                    ).toFixed(2)
                  ) -
                  initialDeposit -
                  scheduledDepositAmount
              ).toFixed(2)}
            </span>
          )}
          <input
            type="number"
            className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
            value={initialDeposit}
            onChange={(e) => {
              if (e.target.value.startsWith("0"))
                e.target.value = e.target.value.replace(/^0+/, "");
              setInitialDeposit(+e.target.value);
            }}
            onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
              (e.target as HTMLInputElement).blur()
            }
          />
        </div>
      )}
      {isScheduledDeposit && (
        <>
          <div className="w-full flex justify-between mb-4">
            <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
              <div className="block text-sm font-medium text-gray-700">
                Enter Scheduled Deposit Amount ($)
              </div>
              <input
                type="number"
                className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                value={scheduledDepositAmount}
                onChange={(e) => {
                  if (e.target.value.startsWith("0"))
                    e.target.value = e.target.value.replace(/^0+/, "");
                  setScheduledDepositAmount(+e.target.value);
                }}
                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                  (e.target as HTMLInputElement).blur()
                }
              />
            </div>
            <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
              <div className="block text-sm font-medium text-gray-700">
                Select Scheduled Deposit Date
              </div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select deposit date"
                selected={scheduledDepositDate}
                onChange={(date) => setScheduledDepositDate(date as Date)}
                showPopperArrow={false}
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={5}
                minDate={addDays(new Date(), 1)}
                className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4  items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
              />
            </div>
          </div>
        </>
      )}
      {isRecurring && paymentFrequencySelectedItem && (
        <div className="w-full px-4 sm:px-6 lg:px-8 mb-4">
          <div className="text-sm font-medium text-gray-700 mb-2">
            Suggested payment amounts:
          </div>
          <div className="space-y-1">
            {calculateSuggestedPayments(
              Math.abs(
                Number(sessionStorage.getItem("plan_price")) +
                  Number(
                    new Decimal(
                      sessionStorage.getItem("tax_rate")
                        ? (Number(sessionStorage.getItem("plan_price")) *
                            Number(sessionStorage.getItem("tax_rate"))) /
                          100
                        : 0
                    ).toFixed(2)
                  ) -
                  initialDeposit -
                  scheduledDepositAmount
              ),
              paymentFrequencySelectedItem
            ).map(({ months, amount }) => (
              <div key={months} className="text-green-600">
                {months} month{months > 1 ? "s" : ""} - ${amount} per{" "}
                {paymentFrequencySelectedItem.toLowerCase()} payment
              </div>
            ))}
          </div>
        </div>
      )}
      {isRecurring &&
        sessionStorage.getItem("plan_price") &&
        Number(sessionStorage.getItem("plan_price")) +
          Number(
            new Decimal(
              sessionStorage.getItem("tax_rate")
                ? (Number(sessionStorage.getItem("plan_price")) *
                    Number(sessionStorage.getItem("tax_rate"))) /
                  100
                : 0
            ).toFixed(2)
          ) -
          initialDeposit -
          scheduledDepositAmount >
          0 && (
          <div className="w-full flex justify-between mb-4">
            <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
              <div className="block text-sm font-medium text-gray-700">
                Enter Recurring Amount (
                {sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                  ? "£"
                  : "$"}
                )
              </div>
              <input
                type="number"
                className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                value={recurringAmount}
                onChange={(e) => {
                  if (e.target.value.startsWith("0"))
                    e.target.value = e.target.value.replace(/^0+/, "");
                  setRecurringAmount(+e.target.value);
                }}
                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                  (e.target as HTMLInputElement).blur()
                }
              />
            </div>
            <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
              <div className="block text-sm font-medium text-gray-700">
                Select Payment Frequency
              </div>
              <PaymentFrequencySelectMenu
                paymentFrequencyList={
                  sessionStorage.getItem("country") === "PCA"
                    ? [
                        { id: "Weekly", value: "Weekly" },
                        { id: "Fortnightly", value: "Fortnightly" },
                        { id: "Monthly", value: "Monthly" },
                        { id: "Bi-monthly", value: "Bi-monthly" },
                      ]
                    : sessionStorage.getItem("province") === "Quebec"
                    ? [{ id: "Monthly", value: "Monthly" }]
                    : [
                        { id: "Weekly", value: "Weekly" },
                        { id: "Fortnightly", value: "Fortnightly" },
                        { id: "Monthly", value: "Monthly" },
                        { id: "Bi-monthly", value: "Bi-monthly" },
                        { id: "Quarterly", value: "Quarterly" },
                      ]
                }
                paymentFrequencySelectedItem={paymentFrequencySelectedItem}
                setPaymentFrequencySelectedItem={
                  setPaymentFrequencySelectedItem
                }
              />
            </div>
          </div>
        )}
      {!isForever && paymentFrequencyError && (
        <div className="w-full px-4 sm:px-6 lg:px-8 mb-4">
          <div className="text-red-600">
            Current payment frequency will result in an uneven or negative
            balance. Please enter a valid recurring amount.
          </div>
        </div>
      )}
      {recurringAmount > 0 && paymentFrequencySelectedItem && (
        <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
          <label
            htmlFor="program_start_date"
            className="block text-sm font-medium text-gray-700"
          >
            Select Start Date of Recurring Payment
          </label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText="Select start date of recurring payment"
            selected={recurringStartDate}
            onChange={(date) => setRecurringStartDate(date as Date)}
            showPopperArrow={false}
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            scrollableYearDropdown
            minDate={addDays(new Date(), 1)}
            yearDropdownItemNumber={5}
            className="my-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4  items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
          />
          {!isForever && (
            <div className="mb-4">
              <div className="text-green-600">{planDuration}</div>
              {recurringStartDate && (
                <div className="text-green-600">
                  End date: {formattedRecurringEndDate}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
        <label
          htmlFor="program_start_date"
          className="block text-sm font-medium text-gray-700"
        >
          Select Start Date of Program
        </label>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          placeholderText="Select program start"
          selected={programStartDate}
          onChange={(date) => setProgramStartDate(date as Date)}
          showPopperArrow={false}
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={5}
          className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
        />
      </div>
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="program_end_date"
          className="block text-sm font-medium text-gray-700"
        >
          Select End Date of Program
        </label>
        <div className="flex my-3 gap-x-4">
          {programDurationButtons.map((button) => (
            <button
              className={`${
                selectedDuration === button.value
                  ? "bg-FF5733"
                  : programStartDate
                  ? "bg-navy-theme hover:bg-navy-theme_hover"
                  : "bg-E0E0E0 text-828282 cursor-auto"
              } text-white p-2 rounded-md`}
              onClick={() => {
                if (programStartDate) {
                  setSelectedDurationMonths(button.value);
                  calculateEndDate(programStartDate, button.value);
                }
              }}
              key={button.id}
            >
              {button.id}
            </button>
          ))}
        </div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          placeholderText="Select program end"
          selected={programEndDate}
          onChange={(date) => {
            setSelectedDuration(0);
            setProgramEndDate(date as Date);
          }}
          showPopperArrow={false}
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={5}
          className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4   items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
        />
      </div>

      {isInsurance && (
        <>
          <InsuranceLeadConfirmationModal
            insuranceLeadConfirmationModalOpen={
              insuranceLeadConfirmationModalOpen
            }
            setInsuranceLeadConfirmationModalOpen={
              setInsuranceLeadConfirmationModalOpen
            }
          />
          <UpdateLeadOwnerModal
            updateLeadOwnerModalOpen={updateLeadOwnerModalOpen}
            setUpdateLeadOwnerModalOpen={setUpdateLeadOwnerModalOpen}
            setInsuranceLeadConfirmationModalOpen={
              setInsuranceLeadConfirmationModalOpen
            }
          />
        </>
      )}
    </Layout>
  );
};

export default ProductPlan;
