function customSort(obj) {
  if (obj.approval_level === "PENDING") {
    return 0
  } else if (obj.approval_level === "LEVEL_1") {
    return 1
  } else if (obj.approval_level === "LEVEL_2") {
    return 2
  } else {
    return 3
  }
}

export default customSort
