import { FC } from "react"
import { Schedule } from "../../../types/schedule"

interface Props {
  schedule: Schedule[]
  setSchedule: React.Dispatch<React.SetStateAction<Schedule[]>>
}

const ShiftSchedule: FC<Props> = ({ schedule, setSchedule }) => {
  const toggleDay = (index: number) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      updatedSchedule[index] = {
        ...updatedSchedule[index],
        checked: !updatedSchedule[index].checked,
      }
      return updatedSchedule
    })
  }

  const handleHoursChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isStart: boolean,
    index: number
  ) => {
    const newHours = parseInt(e.target.value, 10)

    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      updatedSchedule[index] = {
        ...prevSchedule[index],
        [isStart ? "startHours" : "endHours"]: isNaN(newHours) ? 0 : newHours,
      }
      return updatedSchedule
    })
  }

  const handleMinutesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isStart: boolean,
    index: number
  ) => {
    const newMinutes = parseInt(e.target.value, 10)

    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      updatedSchedule[index] = {
        ...prevSchedule[index],
        [isStart ? "startMinutes" : "endMinutes"]: isNaN(newMinutes)
          ? 0
          : newMinutes,
      }
      return updatedSchedule
    })
  }

  const handleAMPMChange = (isStart: boolean, index: number) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      if (isStart) {
        updatedSchedule[index] = {
          ...prevSchedule[index],
          startTimeOfDay:
            prevSchedule[index].startTimeOfDay === "AM" ? "PM" : "AM",
        }
      } else {
        updatedSchedule[index] = {
          ...prevSchedule[index],
          endTimeOfDay: prevSchedule[index].endTimeOfDay === "AM" ? "PM" : "AM",
        }
      }
      return updatedSchedule
    })
  }

  const handleAllowedOvertime = (index: number) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      updatedSchedule[index] = {
        ...prevSchedule[index],
        allowedOvertime: !prevSchedule[index].allowedOvertime,
      }
      return updatedSchedule
    })
  }

  const handleMaxOvertime = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSchedule((prevSchedule) => {
      const updatedSchedule = [...prevSchedule]
      updatedSchedule[index] = {
        ...prevSchedule[index],
        maxOvertime: isNaN(+e.target.value) ? 0 : +e.target.value,
      }
      return updatedSchedule
    })
  }

  const addLeadingZero = (value: number): string => {
    return value.toString().padStart(2, "0")
  }

  return (
    <fieldset className="w-full">
      <legend className="text-lg mb-2 font-medium text-center">
        Shift Schedule
      </legend>
      <p className="text-center mb-4">Click on day name to toggle on/off</p>
      {schedule.map((day, index) => (
        <div
          key={day.name}
          className={`${
            day.checked ? "bg-3FB1FC_hover30" : ""
          } mb-4 rounded-lg py-3`}
        >
          <div className="flex justify-center items-center gap-8 mb-2">
            <div
              className={`${
                day.checked
                  ? "bg-navy-theme text-white hover:bg-navy-theme_hover"
                  : "bg-gray-300 text-gray-700 hover:text-white hover:bg-navy-theme"
              } w-[55px] h-[38px] rounded-md flex items-center justify-center cursor-pointer`}
              onClick={() => toggleDay(index)}
            >
              <span className="font-medium">{day.name}</span>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-[2px]">
                <input
                  type="number"
                  min="1"
                  max="12"
                  className={`${
                    day.checked ? "font-bold bg-white" : "bg-gray-300"
                  } appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm`}
                  value={addLeadingZero(day.startHours)}
                  onChange={(e) => handleHoursChange(e, true, index)}
                  disabled={!day.checked}
                />
                <span>:</span>
                <input
                  type="number"
                  min="0"
                  max="59"
                  className={`${
                    day.checked ? "font-bold bg-white" : "bg-gray-300"
                  } appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm`}
                  value={addLeadingZero(day.startMinutes)}
                  onChange={(e) => handleMinutesChange(e, true, index)}
                  disabled={!day.checked}
                />
                <button
                  type="button"
                  onClick={() => handleAMPMChange(true, index)}
                  className={`${
                    day.checked
                      ? "hover:text-gray-500 cursor-pointer"
                      : "text-gray-500 cursor-auto"
                  } font-medium `}
                  disabled={!day.checked}
                >
                  {day.startTimeOfDay}
                </button>
              </div>
              <p>-</p>
              <div className="flex items-center gap-[2px]">
                <input
                  type="number"
                  min="1"
                  max="12"
                  className={`${
                    day.checked ? "font-bold bg-white" : "bg-gray-300"
                  } appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm`}
                  value={addLeadingZero(day.endHours)}
                  onChange={(e) => handleHoursChange(e, false, index)}
                  disabled={!day.checked}
                />
                <span>:</span>
                <input
                  type="number"
                  min="0"
                  max="59"
                  className={`${
                    day.checked ? "font-bold bg-white" : "bg-gray-300"
                  } appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm`}
                  value={addLeadingZero(day.endMinutes)}
                  onChange={(e) => handleMinutesChange(e, false, index)}
                  disabled={!day.checked}
                />
                <button
                  type="button"
                  onClick={() => handleAMPMChange(false, index)}
                  className={`${
                    day.checked
                      ? "hover:text-gray-500 cursor-pointer"
                      : "text-gray-500 cursor-auto"
                  } font-medium `}
                  disabled={!day.checked}
                >
                  {day.endTimeOfDay}
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-6">
            <div className="flex items-center gap-2">
              <div className="min-w-0 text-sm">
                <label
                  htmlFor={`${day.name}_overtime`}
                  className="font-medium text-gray-700 select-none"
                >
                  Allowed overtime?
                </label>
              </div>
              <div className="flex items-center h-5">
                <input
                  id={`${day.name}_overtime`}
                  type="checkbox"
                  className={`${
                    day.checked ? "font-bold bg-white" : "bg-gray-300"
                  } h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none`}
                  checked={day.allowedOvertime}
                  onChange={() => handleAllowedOvertime(index)}
                  disabled={!day.checked}
                />
              </div>
            </div>

            <input
              type="number"
              className={`${
                day.allowedOvertime && day.checked ? "bg-white" : "bg-gray-300"
              } appearance-none block px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-1/6`}
              value={day.maxOvertime}
              onChange={(e) => handleMaxOvertime(e, index)}
              disabled={!day.allowedOvertime || !day.checked}
            />
            <p className="-ml-4">hrs</p>
          </div>
        </div>
      ))}
    </fieldset>
  )
}
export default ShiftSchedule
