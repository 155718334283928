import { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import DatePicker from "react-datepicker"
import { formatDate, formatTime } from "../../helpers/timeClockFunctions"
import { User } from "../../types/user"
import spinnerBlack from "../../assets/common/spinnerBlack.svg"
import BillingConfirmationModal from "./BillingConfirmationModal"
import {
  CalendarIcon,
  ClipboardCheckIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/outline"
import CreateEntryModal from "./CreateEntryModal"
import UpdateEntryModal from "./UpdateEntryModal"
import { Entry } from "../../types/entry"
import ViewScheduleModal from "../manageusers/modal/ViewScheduleModal"
import { CSVLink } from "react-csv"
import ApproveOvertimeModal from "./ApproveOvertimeModal"

type TotalPay = {
  id: number
  totalPay: number
  xeroBillNum: string
}

const TimeClockBilling = ({
  masterAdmin,
  financeManager,
  feTeamManager,
  pmTeamManager,
}: {
  masterAdmin: boolean
  financeManager: boolean
  feTeamManager: boolean
  pmTeamManager: boolean
}) => {
  const params: { admin_id: string } = useParams()
  const { admin_id } = params

  // FETCH USER INFORMATION
  const [user, setUser] = useState<User | null>(null)

  const fetchUser = async () => {
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}admin/v1/account/get`)

    data.rows.map(
      (user: User) =>
        (user.user_access = user.admins_features
          .map((item) => item.value)
          .toString()
          .replaceAll(",", ", "))
    )

    data.rows.map((user: User, index: number) => (user.index = index + 1))

    const user = data.rows.filter((user: User) => user.id === admin_id)
    setUser(user[0])
  }

  // FILTER TIME ENTRIES
  const [loading, setLoading] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [timeEntries, setTimeEntries] = useState<Entry[]>([])
  const [selectedTimeEntries, setSelectedTimeEntries] = useState<Entry[]>([])
  const [totalPayData, setTotalPayData] = useState<TotalPay[]>([])
  const [totalPaySum, setTotalPaySum] = useState(0)
  const [commission, setCommission] = useState("")
  const [payRate, setPayRate] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [totalHours, setTotalHours] = useState(0)
  const [allTotalHours, setAllTotalHours] = useState(0)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [sortOrder, setSortOrder] = useState("asc")

  const handleSort = () => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc"
    setSortOrder(newOrder)
    sortDates(newOrder)
  }

  const sortDates = (order: "asc" | "desc") => {
    const sortedDatesArray = [...timeEntries]
    sortedDatesArray.sort((a: Entry, b: Entry): number => {
      const dateA: Date = new Date(a.start_time)
      const dateB: Date = new Date(b.start_time)
      return order === "asc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime()
    })
    setTimeEntries(sortedDatesArray)
  }

  const twoWeeksAgo = new Date()
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14)

  const timeDurationButtons = [
    { id: "Last 1 week", value: 1 },
    { id: "Last 2 weeks", value: 2 },
    { id: "Last 3 weeks", value: 3 },
  ]

  const [selectedDuration, setSelectedDuration] = useState<number>(2)
  const [programStartDate, setProgramStartDate] = useState<Date>(twoWeeksAgo)
  const [programEndDate, setProgramEndDate] = useState<Date>(new Date())

  useEffect(() => {
    const newStartDate = new Date()
    newStartDate.setDate(newStartDate.getDate() - 7 * selectedDuration)
    setProgramStartDate(newStartDate)
  }, [selectedDuration])

  const calculateStartDate = (value: number) => {
    setSelectedDuration(value)
  }

  const formatApiDate = (date: Date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const formattedStartDate = formatApiDate(programStartDate)
  const formattedEndDate = formatApiDate(programEndDate)

  const getTimeEntriesReport = async (adminId: string) => {
    setLoading(true)
    const data = await axios.post(
      `${process.env.REACT_APP_API_URL}admin/v1/time-entries/get-time-entries-report`,
      {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        admin_id: adminId,
      }
    )

    const timeEntriesData = data.data.data[0]
    const filteredTimeEntries = timeEntriesData?.time_entries.filter(
      (entry: Entry) => entry.billable_hours !== "0.00"
    )

    const sortedTimeEntries = filteredTimeEntries?.sort(
      (a: Entry, b: Entry) => {
        const dateA = new Date(a.start_time)
        const dateB = new Date(b.start_time)

        return dateA.getTime() - dateB.getTime()
      }
    )
    setSortOrder("asc")

    if (timeEntriesData && filteredTimeEntries.length > 0) {
      setTimeEntries(sortedTimeEntries)
      setPayRate(timeEntriesData.pay_rate)
      setTotalPayData(
        filteredTimeEntries.map((entry: Entry, index: number) => ({
          id: index,
          totalPay: (timeEntriesData.pay_rate * +entry.billable_hours).toFixed(
            2
          ),
          xeroBillNum: entry.xero_bill_number,
        }))
      )
      setName(timeEntriesData.first_name + " " + timeEntriesData.last_name)
      setEmail(timeEntriesData.email)

      const allTotalHoursArr = filteredTimeEntries.map((entry: Entry) =>
        Number(entry.billable_hours)
      )
  
      setAllTotalHours(
        allTotalHoursArr.reduce((acc: number, cv: number) => acc + cv, 0)
      )

      setErrorMessage("")
      setShowTable(true)
    } else {
      setErrorMessage("No entries found for the selected filter.")
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!name) {
      setName(localStorage.getItem("billing_name") || "")
      setEmail(localStorage.getItem("billing_email") || "")
    }
  }, [name])

  useEffect(() => {
    const totalPayArr = selectedTimeEntries.map((entry: Entry) =>
      Number((payRate * +entry.billable_hours).toFixed(2))
    )
    setTotalPaySum(totalPayArr.reduce((acc, cv) => acc + cv, 0))

    const totalHoursArr = selectedTimeEntries.map((entry: Entry) =>
      Number(entry.billable_hours)
    )

    setTotalHours(
      totalHoursArr.reduce((acc: number, cv: number) => acc + cv, 0)
    )
  }, [selectedTimeEntries])

  // SELECT LOGIC
  const [toggleSelectAll, setToggleSelectAll] = useState(false)
  const [entryCheckStatus, setEntryCheckStatus] = useState<
    Record<number, boolean>
  >({})

  useEffect(() => {
    const entryCheckStatusValues = Object.values(entryCheckStatus)
    if (
      entryCheckStatusValues.some((entry) => entry === false) ||
      entryCheckStatusValues.length === 0
    ) {
      const initialUserCheckStatus: Record<number, boolean> = {}
      timeEntries.forEach((entry, entryIdx) => {
        if (!entry.xero_bill_number) initialUserCheckStatus[entryIdx] = true
      })
      setEntryCheckStatus(initialUserCheckStatus)
    } else if (
      entryCheckStatusValues.length ===
        timeEntries.filter((entry) => !entry.xero_bill_number).length &&
      entryCheckStatusValues.every((entry) => entry === true)
    ) {
      const initialUserCheckStatus: Record<number, boolean> = {}
      timeEntries.forEach((entry, entryIdx) => {
        if (!entry.xero_bill_number) initialUserCheckStatus[entryIdx] = false
      })
      setEntryCheckStatus(initialUserCheckStatus)
    }
  }, [toggleSelectAll])

  useEffect(() => {
    setSelectedTimeEntries(
      timeEntries
        .filter((_: Entry, index) => entryCheckStatus[index])
        .filter((entry: Entry) => !entry.xero_bill_number)
    )
  }, [entryCheckStatus])

  // CREATE TIME ENTRY
  const [createModalOpen, setCreateModalOpen] = useState(false)

  // UPDATE TIME ENTRY
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState<Entry>({
    start_time: "",
    finish_time: "",
    billable_hours: "",
    id: "",
    xero_bill_number: "",
    absence_hours: "",
    approved_by_email: "",
    approved_description: "",
    zoho_id: "",
  })

  const handleCreateModal = () => {
    setCreateModalOpen(true)
  }

  const handleUpdateModal = (entry: Entry) => {
    setSelectedEntry(entry)
    setUpdateModalOpen(true)
  }

  // APPROVE OVERTIME
  const [overtimeModalOpen, setOvertimeModalOpen] = useState(false)

  const handleOvertimeModal = (entry: Entry) => {
    setSelectedEntry(entry)
    setOvertimeModalOpen(true)
  }

  // CREATE TIME ENTRIES BILL
  const [createBillLoading, setCreateBillLoading] = useState(false)
  const [createBillSuccess, setCreateBillSuccess] = useState("")
  const [createBillError, setCreateBillError] = useState("")
  const [description, setDescription] = useState("")
  const [billingModalOpen, setBillingModalOpen] = useState(false)
  const [salaryAmount, setSalaryAmount] = useState("")

  const createTimeEntriesBill = async () => {
    setCreateBillLoading(true)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/time-entries/create-time-entries-bill`,
        !user?.is_monthly_contracted
          ? {
              total_pay: totalPaySum,
              admin_id: admin_id,
              commission_value: commission ? Number(commission) : 0,
              time_entry_ids: selectedTimeEntries.map((entry) => entry.id),
              description: description,
              exchange_rate: exchangeRate ? Number(exchangeRate) : 0,
            }
          : {
              total_pay: totalPaySum,
              admin_id: admin_id,
              commission_value: commission ? Number(commission) : 0,
              time_entry_ids: selectedTimeEntries.map((entry) => entry.id),
              description: description,
              exchange_rate: 1,
              salary_amount: salaryAmount,
            }
      )
      .then(() => {
        getTimeEntriesReport(admin_id)
        setCreateBillSuccess("Time entries bill successfully created")
        setDescription("")
        setCommission("")
        setSalaryAmount("")
        setEntryCheckStatus({})
      })
      .catch((err) => {
        setCreateBillError(err.response.data.message)
      })

    setCreateBillLoading(false)
  }

  const tableHeaders = (feTeamManager || pmTeamManager) 
    ? [
      "Date",
      "Start",
      "End",
      "Hours",
    ]
    : !user?.is_monthly_contracted
    ? [
        "Date",
        "Start",
        "End",
        "Hours",
        "Total Pay",
        "Absence Hours",
        "Overtime",
        "Xero Bill #",
        "Zoho ID",
      ]
    : [
        "Date",
        "Start",
        "End",
        "Hours",
        "Absence Hours",
        "Overtime",
        "Xero Bill #",
        "Zoho ID",
      ]

  useEffect(() => {
    fetchUser()
    getTimeEntriesReport(admin_id)
  }, [])

  // EXCHANGE RATE
  const [exchangeRate, setExchangeRate] = useState("")
  const [suggestedExchangeRate, setSuggestedExchangeRate] = useState("")

  const getExchangeRate = async () => {
    const data = await axios.post(
      `${process.env.REACT_APP_API_URL}admin/v1/time-entries/get-exchange-rate`,
      {
        currency: user?.currency,
        salary_currency: user?.salary_currency,
      }
    )

    setExchangeRate(data.data.data.exchange_rate)
    setSuggestedExchangeRate(data.data.data.exchange_rate)
  }

  useEffect(() => {
    if (user?.currency && user?.salary_currency) {
      getExchangeRate()
    }
  }, [user])

  // VIEW SCHEDULE MODAL
  const [viewScheduleModal, setViewScheduleModal] = useState(false)

  return (
    <>
      <div>
        <div className="flex my-3 gap-x-4">
          {timeDurationButtons.map((button) => (
            <button
              className={`${
                selectedDuration === button.value
                  ? "bg-FF5733"
                  : programStartDate
                  ? "bg-navy-theme hover:bg-navy-theme_hover"
                  : "bg-E0E0E0 text-828282 cursor-auto"
              } text-white p-2 rounded-md`}
              onClick={() => {
                if (programStartDate) {
                  calculateStartDate(button.value)
                }
              }}
              key={button.id}
            >
              {button.id}
            </button>
          ))}
        </div>
      </div>

      <div className="flex gap-8 mb-8">
        <div className="w-full">
          <label
            htmlFor="program_start_date"
            className="block text-sm font-medium text-gray-700"
          >
            Select Start Date
          </label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText="Select start date"
            selected={programStartDate}
            onChange={(date) => {
              setSelectedDuration(0)
              setProgramStartDate(date as Date)
            }}
            showPopperArrow={false}
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={5}
            className="mt-2 h-12 w-full rounded-md flex border-c4c4c4 items-center pl-6 text-black border border-transparent focus:outline-none focus:ring-0 font-medium"
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="program_end_date"
            className="block text-sm font-medium text-gray-700"
          >
            Select End Date
          </label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText="Select end date"
            selected={programEndDate}
            onChange={(date) => {
              setSelectedDuration(0)
              setProgramEndDate(date as Date)
            }}
            showPopperArrow={false}
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={5}
            className="mt-2 h-12 w-full rounded-md flex border-c4c4c4 items-center pl-6 text-black border border-transparent focus:outline-none focus:ring-0 font-medium"
          />
        </div>
        <button
          type="button"
          className="self-end items-center px-6 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-3FB1FC hover:bg-3FB1FC_hover h-12"
          onClick={() => getTimeEntriesReport(admin_id)}
        >
          Filter
        </button>
      </div>
      {name && (
        <div className="flex gap-x-8">
          <div className="flex flex-col gap-1 mb-6">
            <h1 className="text-lg font-medium leading-6 text-gray-900 mr-12">
              Time Entries for {name}
            </h1>
            <p>{email}</p>
          </div>
          <button
            className="items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-FF5733 hover:bg-FF5733_hover h-10"
            onClick={() => setViewScheduleModal(true)}
          >
            View Schedule
          </button>
          {masterAdmin && (
            <button
              className="items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-FF5733 hover:bg-FF5733_hover h-10"
              onClick={() => handleCreateModal()}
            >
              Create Entry
            </button>
          )}
        </div>
      )}
      {loading ? (
        <p className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Loading time entries...
        </p>
      ) : errorMessage ? (
        <p className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {errorMessage}
        </p>
      ) : showTable ? (
        <>
          {(masterAdmin || financeManager) && 
            <>
              <p className="mb-1">
                Pay rate:{" "}
                <span className="font-medium">
                  {payRate.toFixed(2)} {user?.currency}
                </span>
                {!user?.is_monthly_contracted ? "/hr" : "/month"}
              </p>
              <p className="text-sm mb-1 text-red-500">
                NOTE: Data for previously billed entries (marked in gray) is not
                counted towards total hours and total pay.
              </p>
              <div className="flex items-center mb-6 text-red-500 text-sm gap-1">
                <p>In hours column, a </p>
                <ExclamationIcon className="h-5 w-5" />
                <p>denotes entry exceeding 10 hrs</p>
              </div>
            </>
          }
          <div className="flex flex-col overflow-hidden mb-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-navy-theme">
                      <tr>
                        {(masterAdmin || financeManager) && 
                          <>
                            <th
                              scope="col"
                              className="hover:text-FF5733 px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider cursor-pointer w-[100px]"
                              onClick={() =>
                                setToggleSelectAll(
                                  (prevToggleSelectAll) => !prevToggleSelectAll
                                )
                              }
                            >
                              Select All
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider w-[100px]"
                            >
                              Update Entry
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider w-[100px]"
                            >
                              Approve Overtime
                            </th>
                          </>
                        }
                        {tableHeaders.map((header) =>
                          header === "Date" ? (
                            <th
                              scope="col"
                              className="hover:text-FF5733 px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer tracking-wider"
                              key={header}
                              onClick={handleSort}
                            >
                              <span className="pt-1">
                                {header} {sortOrder === "asc" ? "▲" : "▼"}
                              </span>
                            </th>
                          ) : (
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                              key={header}
                            >
                              <span className="pt-1">{header}</span>
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {timeEntries.map((entry, index) => (
                        <tr
                          key={index}
                          className={
                            entry.xero_bill_number ? "bg-gray-300" : "bg-white"
                          }
                        >
                          {(masterAdmin || financeManager) && 
                            <>
                              {!entry.xero_bill_number ? (
                                <td className="flex items-center">
                                  <input
                                    className="mx-auto my-4 text-navy-theme focus:ring-0"
                                    type="checkbox"
                                    checked={entryCheckStatus?.[index] ?? false}
                                    onChange={() =>
                                      setEntryCheckStatus(
                                        (prevEntryCheckStatus) => {
                                          return {
                                            ...prevEntryCheckStatus,
                                            [`${index}`]:
                                              !prevEntryCheckStatus[index],
                                          }
                                        }
                                      )
                                    }
                                  />
                                </td>
                              ) : (
                                <td></td>
                              )}
                              {!entry.xero_bill_number ? (
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <CalendarIcon
                                    className="w-5 mx-auto hover:text-gray-500 cursor-pointer"
                                    onClick={() => handleUpdateModal(entry)}
                                  />
                                </td>
                              ) : (
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <XCircleIcon className="w-5 mx-auto" />
                                </td>
                              )}
                              {!entry.xero_bill_number &&
                              !entry.approved_by_email &&
                              entry.overtime_approval_required ? (
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <ClipboardCheckIcon
                                    className="w-5 mx-auto hover:text-gray-500 cursor-pointer"
                                    onClick={() => handleOvertimeModal(entry)}
                                  />
                                </td>
                              ) : (
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <XCircleIcon className="w-5 mx-auto" />
                                </td>
                              )}
                            </>
                          }
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {formatDate(entry.start_time)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {formatTime(entry.start_time)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {formatTime(entry.finish_time)}
                          </td>
                            <td
                              className={`${
                                Number(entry.billable_hours) > 10
                                  ? "text-red-500"
                                  : "text-gray-900"
                              } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900`}
                            >
                              <div className="flex items-center gap-2 relative">
                                {Number(entry.billable_hours) > 10 && (
                                  <ExclamationIcon className="h-5 w-5 text-red-500 absolute -left-[24px]" />
                                )}
                                {entry.billable_hours} hrs
                              </div>
                            </td>
                          {(masterAdmin || financeManager) && 
                            <>
                              {!user?.is_monthly_contracted && (
                                <td className="gap-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div className="appearance-none block w-full rounded-md placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm">
                                    {totalPayData
                                      .filter((data) => data.id === index)[0]
                                      ?.totalPay.toString() || ""}
                                    &nbsp;
                                    {(user && user.currency) || "$"}
                                  </div>
                                </td>
                              )}
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {entry.absence_hours || "0.00"} hrs
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {entry.approved_by_email ? (
                                  <>
                                    <p>
                                      <span className="font-medium">
                                        Approved by:
                                      </span>{" "}
                                      {entry.approved_by_email}
                                    </p>
                                    <p>
                                      <span className="font-medium">
                                        Description:
                                      </span>{" "}
                                      {entry.approved_description}
                                    </p>
                                  </>
                                ) : (
                                  <p className="font-medium">N/A or not approved</p>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {entry.xero_bill_number || "N/A"}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {entry.zoho_id || "N/A"}
                              </td>
                            </>
                          }
                        </tr>
                      ))}
                      <tr className="bg-white border-t-1 border-gray-300">
                        {
                          (feTeamManager || pmTeamManager) ? (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-sm font-medium px-6 py-4 text-gray-900">
                                <p>{allTotalHours.toFixed(2)} hrs</p>
                              </td>
                            </>
                          )
                          : !user?.is_monthly_contracted ? (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-sm font-medium px-6 py-4 text-gray-900">
                                <p>{totalHours.toFixed(2)} hrs</p>
                              </td>
                              <td className="text-sm flex items-center gap-1 px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                <p>{totalPaySum.toFixed(2)}</p>
                                <p>{(user && user.currency) || "$"}</p>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-sm font-medium px-6 py-4 text-gray-900">
                                <p>{totalHours.toFixed(2)} hrs</p>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          )
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {(masterAdmin || financeManager) && 
            <>
              <div className="mb-4 flex gap-5 items-center">
                <p className="font-medium mb-2 w-[160px]">Enter description:</p>
                <input
                  type="text"
                  className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-1/4"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                />
              </div>
              <div className="flex items-center gap-5 mb-4">
                <p className="font-medium w-[160px]">Enter commission:</p>
                <div className="flex items-center gap-4 ">
                  <input
                    type="text"
                    className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-100"
                    value={commission}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^0-9.]/g, "") // Allow only numeric characters and one decimal point
                      setCommission(newValue)
                    }}
                  />
                  <p>{(user && user.currency) || "$"}</p>
                </div>
              </div>
              {!user?.is_monthly_contracted ? (
                <div className="mb-8">
                  <div className="flex items-center gap-5 mb-3">
                    <p className="font-medium w-[160px]">Enter exchange rate:</p>
                    <input
                      type="text"
                      className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-100"
                      value={exchangeRate}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/[^0-9.]/g, "") // Allow only numeric characters and one decimal point
                        setExchangeRate(newValue)
                      }}
                    />
                    <div>
                      <p className="font-medium inline">
                        {user?.salary_currency} ={" "}
                      </p>
                      <p className="font-medium inline">1 {user?.currency}</p>
                    </div>
                  </div>
                  <p>
                    (Suggested exchange rate: {suggestedExchangeRate}{" "}
                    {user?.salary_currency} = 1 {user?.currency})
                  </p>
                </div>
              ) : (
                <div className="flex items-center gap-5 mb-8">
                  <p className="font-medium w-[160px]">Enter salary amount:</p>
                  <div className="flex items-center gap-4 ">
                    <input
                      type="text"
                      className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-100"
                      value={salaryAmount}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/[^0-9.]/g, "") // Allow only numeric characters and one decimal point
                        setSalaryAmount(newValue)
                      }}
                    />
                    <p>{(user && user.currency) || "$"}</p>
                  </div>
                </div>
              )}
              <div className="flex justify-between">
                <div className="flex items-center">
                  <button
                    type="button"
                    className={`${
                      createBillLoading || !description || !totalPaySum
                        ? "text-black bg-gray-300"
                        : "text-white bg-FF5733 hover:bg-FF5733_hover"
                    } items-center px-[95px] py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md`}
                    onClick={() => {
                      if (
                        +exchangeRate > +suggestedExchangeRate + 10 ||
                        +exchangeRate < +suggestedExchangeRate - 10
                      ) {
                        setCreateBillError(
                          `Please enter an exchange rate amount inside the allowed range: ${
                            +suggestedExchangeRate - 10
                          } - ${+suggestedExchangeRate + 10}`
                        )
                      } else {
                        setCreateBillError("")
                        setBillingModalOpen(true)
                      }
                    }}
                    disabled={createBillLoading || !description || !totalPaySum}
                  >
                    Send to Xero
                  </button>
                  {createBillLoading && (
                    <div className="inline-flex items-center">
                      <img
                        width={40}
                        className="ml-4"
                        src={spinnerBlack}
                        alt="Loading..."
                      />
                      <p className="font-medium">Processing...</p>
                    </div>
                  )}
                </div>
                <CSVLink data={timeEntries} filename={`${name}-time-entries`}>
                  <button className="items-center px-3 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-FF5733 hover:bg-FF5733_hover h-10">
                    Export to CSV
                  </button>
                </CSVLink>
              </div>
            </>
          }
          {createBillSuccess && (
            <p className="py-4 whitespace-nowrap font-medium text-green-600">
              {createBillSuccess}
            </p>
          )}
          {createBillError && (
            <p className="py-4 whitespace-nowrap font-medium text-red-500">
              An error has occurred: {createBillError}
            </p>
          )}
        </>
      ) : null}
      <BillingConfirmationModal
        open={billingModalOpen}
        setOpen={setBillingModalOpen}
        payRate={payRate}
        totalHours={totalHours}
        totalPay={totalPaySum}
        description={description}
        commission={commission}
        exchangeRate={exchangeRate}
        salaryAmount={salaryAmount}
        user={user as User}
        createTimeEntriesBill={createTimeEntriesBill}
      />
      <CreateEntryModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        getTimeEntriesReport={getTimeEntriesReport}
        adminId={admin_id}
      />
      <UpdateEntryModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        entry={selectedEntry}
        getTimeEntriesReport={getTimeEntriesReport}
        adminId={admin_id}
      />
      <ApproveOvertimeModal
        open={overtimeModalOpen}
        setOpen={setOvertimeModalOpen}
        entry={selectedEntry}
        getTimeEntriesReport={getTimeEntriesReport}
        adminId={admin_id}
      />
      <ViewScheduleModal
        isOpen={viewScheduleModal}
        setIsOpen={setViewScheduleModal}
        user={user}
      />
    </>
  )
}

export default TimeClockBilling
