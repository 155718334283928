import { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import CurrencySelectMenu from "./components/CurrencySelectMenu"
import PerformanceManagerSearchMenu from "./components/PerformanceManagerSearchMenu"
import ProductPlanSearchMenu from "./components/ProductPlanSearchMenu"
import StageSelectMenu from "./components/StageSelectMenu"
import TerminalSelectMenu from "./components/TerminalSelectMenu"
import ConfirmationModal from "./components/ConfirmationModal"
import HelpVideo from "../../components/help-video/HelpVideo"
import "react-datepicker/dist/react-datepicker.css"

const CreateOpportunity = () => {
  const region = sessionStorage.getItem("country") as string
  const contactID = sessionStorage.getItem("id") as string
  const contactName = sessionStorage.getItem("full_name") as string

  const [pmName, setPmName] = useState<string>("")
  const [pmID, setPmID] = useState<string>("")
  const [productPlanID, setProductPlanID] = useState<string>("")
  const [productPlanName, setProductPlanName] = useState<string>("")
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [stage, setStage] = useState("")
  const [terminal, setTerminal] = useState("")
  const [selectedDuration, setSelectedDuration] = useState<number>(0)

  const [currency, setCurrency] = useState<string>("")
  const province = sessionStorage.getItem("province") as string

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [apiStatus, setApiStatus] = useState<string>("")
  const [apiResponse, setApiResponse] = useState<string>("")
  const [helpVideoOpen, setHelpVideoOpen] = useState<boolean>(false)

  const handleProceed = () => {
    setModalOpen(true)
  }

  const handleEndDate = (startDate: Date, value: number) => {
    setSelectedDuration(value)
    const date = new Date(startDate)
    setEndDate(new Date(date.setMonth(startDate.getMonth() + value)))
  }

  useEffect(() => {
    switch (region) {
      case "AU":
        setCurrency("AUD")
        break
      case "CA":
        setCurrency("CAD")
        break
      case "NZ":
        setCurrency("NZD")
        break
      case "PCA":
        setCurrency("AUD")
        break
    }
  }, [region])

  useEffect(() => {

    const removeKeys = [
      "lead_source",
      "opportunity_id",
      "card_name",
      "last_four_digits",
      "card_brand",
      "plan_name",
      "plan_id",
      "total_price",
      "amount_paid",
      "amount_remaining",
      "invoice_id",
      "opportunity_add_percent",
      "createOpportunitySkipped",
      "createOpportunitySuccess",
      "isDiscount",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  return (
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h3 className="text-3xl leading-6 font-medium text-gray-900">
        Create Opportunity
      </h3>
      <button
        className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
        onClick={() => setHelpVideoOpen(true)}
      >
        Need Help?
      </button>
      <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />

      <div className="flex gap-x-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">

            {region === "UK" && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mt-6">
                <label
                  htmlFor="currency"
                  className="block text-sm font-medium text-gray-700"
                >
                  Currency
                </label>
                <CurrencySelectMenu setCurrency={setCurrency} />
              </div>
            )}

            <div className="w-full px-4 sm:px-6 lg:px-8 my-6">
              <PerformanceManagerSearchMenu
                setPmName={setPmName}
                setPmID={setPmID}
                region={region}
              />
            </div>

            {pmID && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <ProductPlanSearchMenu
                  setProductPlanID={setProductPlanID}
                  setProductPlanName={setProductPlanName}
                  region={region}
                  currency={currency}
                />
              </div>
            )}

            {pmID && productPlanID && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <label
                  htmlFor="program_start_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Start Date of Program
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select start date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showPopperArrow={false}
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={5}
                  className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border items-center pl-6 text-828282 border-transparent focus:outline-none focus:ring-0 font-medium"
                />
              </div>
            )}

            {pmID && productPlanID && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <label
                  htmlFor="program_end_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select End Date of Program
                </label>
                <div className="flex my-3 gap-x-4">
                  <button
                    className={`${
                      selectedDuration === 1
                        ? "bg-FF5733"
                        : startDate
                        ? "bg-navy-theme hover:bg-navy-theme_hover"
                        : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                    onClick={() => {
                      if (startDate) {
                        handleEndDate(startDate, 1)
                      }
                    }}
                  >
                    1 month
                  </button>
                  <button
                    className={`${
                      selectedDuration === 2
                        ? "bg-FF5733"
                        : startDate
                        ? "bg-navy-theme hover:bg-navy-theme_hover"
                        : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                    onClick={() => {
                      if (startDate) {
                        handleEndDate(startDate, 2)
                      }
                    }}
                  >
                    2 months
                  </button>
                  <button
                    className={`${
                      selectedDuration === 3
                        ? "bg-FF5733"
                        : startDate
                        ? "bg-navy-theme hover:bg-navy-theme_hover"
                        : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                    onClick={() => {
                      if (startDate) {
                        handleEndDate(startDate, 3)
                      }
                    }}
                  >
                    3 months
                  </button>
                  <button
                    className={`${
                      selectedDuration === 6
                        ? "bg-FF5733"
                        : startDate
                        ? "bg-navy-theme hover:bg-navy-theme_hover"
                        : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                    onClick={() => {
                      if (startDate) {
                        handleEndDate(startDate, 6)
                      }
                    }}
                  >
                    6 months
                  </button>
                  <button
                    className={`${
                      selectedDuration === 12
                        ? "bg-FF5733"
                        : startDate
                        ? "bg-navy-theme hover:bg-navy-theme_hover"
                        : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                    onClick={() => {
                      if (startDate) {
                        handleEndDate(startDate, 12)
                      }
                    }}
                  >
                    12 months
                  </button>
                </div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select end date"
                  selected={endDate}
                  onChange={(date) => {
                    setSelectedDuration(0)
                    setEndDate(date)
                  }}
                  showPopperArrow={false}
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={5}
                  className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border items-center pl-6 text-828282 border-transparent focus:outline-none focus:ring-0 font-medium"
                />
              </div>
            )}

            {pmID && productPlanID && startDate && endDate && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <label
                  htmlFor="stage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Stage
                </label>
                <StageSelectMenu setStage={setStage} />
              </div>
            )}

            {pmID && productPlanID && startDate && endDate && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <label
                  htmlFor="terminal"
                  className="block text-sm font-medium text-gray-700"
                >
                  Terminal
                </label>
                <TerminalSelectMenu setTerminal={setTerminal} region={region}/>
              </div>
            )}
          </div>
        </div>
      </div>

      {pmID && productPlanID && startDate && endDate && stage && terminal && (
        <div className="w-full px-4 sm:px-6 lg:px-8 mb-6 flex justify-center">
          <button
            type="button"
            className="text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      )}

      <ConfirmationModal
        open={modalOpen}
        setOpen={setModalOpen}
        region={region}
        contactID={contactID}
        province={province}
        currency={currency}
        pmName={pmName}
        pmID={pmID}
        productPlanID={productPlanID}
        productPlanName={productPlanName}
        startDate={startDate}
        endDate={endDate}
        stage={stage}
        terminal={terminal}
        contactName={contactName}
        apiStatus={apiStatus}
        setApiStatus={setApiStatus}
        apiResponse={apiResponse}
        setApiResponse={setApiResponse}
      />
    </div>
  )
}

export default CreateOpportunity