import { Schedule } from "../../../types/schedule"

export const formatSchedule = (schedule: Schedule[]) => {
  const adjustHours = (
    isStart: boolean,
    startHours: number,
    startTimeOfDay: string,
    endHours: number,
    endTimeOfDay: string
  ) => {
    if (isStart) {
      let adjustedStartHours = startHours

      // Adjust hours for PM
      if (startTimeOfDay === "PM" && startHours !== 12) {
        adjustedStartHours += 12
      }

      // Adjust hours for midnight (12:00 AM)
      if (startTimeOfDay === "AM" && startHours === 12) {
        adjustedStartHours = 0
      }

      return adjustedStartHours
    } else {
      let adjustedEndHours = endHours

      // Adjust hours for PM
      if (endTimeOfDay === "PM" && endHours !== 12) {
        adjustedEndHours += 12
      }

      // Adjust hours for midnight (12:00 AM)
      if (endTimeOfDay === "AM" && endHours === 12) {
        adjustedEndHours = 0
      }

      return adjustedEndHours
    }
  }

  const formatApiDate = (combinedDate: Date) => {
    return `${combinedDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${combinedDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${combinedDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`
  }

  return schedule
    .filter((day) => day.checked)
    .map((day) => {
      const adjustedStartHours = adjustHours(
        true,
        day.startHours,
        day.startTimeOfDay,
        day.endHours,
        day.endTimeOfDay
      )
      const adjustedEndHours = adjustHours(
        false,
        day.startHours,
        day.startTimeOfDay,
        day.endHours,
        day.endTimeOfDay
      )

      const combinedStartDate = new Date()
      combinedStartDate.setHours(adjustedStartHours)
      combinedStartDate.setMinutes(day.startMinutes)
      combinedStartDate.setSeconds(0)
      const formattedStartDate = formatApiDate(combinedStartDate)

      const combinedEndDate = new Date()
      combinedEndDate.setHours(adjustedEndHours)
      combinedEndDate.setMinutes(day.endMinutes)
      combinedEndDate.setSeconds(0)
      const formattedEndDate = formatApiDate(combinedEndDate)

      return {
        day: day.name,
        start_time: formattedStartDate,
        finish_time: formattedEndDate,
        allowed_overtime: day.allowedOvertime,
        max_overtime: day.maxOvertime,
      }
    })
}
