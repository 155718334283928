import { useHistory } from "react-router-dom"
import { ReactComponent as Error } from "../../../../assets/common/error.svg"

function RefundRequestError() {
  const history = useHistory()

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            <div className="text-E84545 text-3xl sm:text-4xl font-semibold mt-8">
              There was an error
            </div>
            <Error className="mt-42px" />
            <div className="px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
              There was an issue while processing the refund.
            </div>

            <div className="px-9 sm:px-0 text-xl text-E84545 max-w-421 mt-9">
              {sessionStorage.getItem("error") &&
                sessionStorage.getItem("error")}
            </div>
            <button
              type="button"
              className={
                "text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-62 sm:w-64 h-14 text-center px-6 border border-transparent font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
              }
              onClick={() => history.goBack()}
            >
              Back to Refund Request
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RefundRequestError
