import { useEffect, useState } from "react"
import {
  PencilAltIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/solid"
import UpdatePercentModal from "./components/UpdatePercentModal"
import axios from "axios"

type AdditionalPercentData = {
  id: number
  region: string
  percent: number
  active: boolean
}

const AdditionalPercentDashboard = () => {
  const [percentData, setpercentData] = useState<AdditionalPercentData[]>([])
  const [percentsLoading, setPercentsLoading] = useState(true)

  const [updatePercentModalOpen, setUpdatePercentModalOpen] = useState(false)
  const [selectedPercentData, setSelectedPercentData] = useState<AdditionalPercentData | null>(null)

  useEffect(() => {
    getAdditionalPercentData()
  }, [])

  const getAdditionalPercentData = async () => {
    await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/additional-percent/get`
    ).then((response) => {
      setpercentData(response.data.data)
      setPercentsLoading(false)
    }).catch(() => {
      setPercentsLoading(false)
    })
  } 

  return (
    <>
      <h1 className="font-bold text-2xl pb-5">Additional Percent Configuration</h1>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col overflow-hidden w-full">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200=">
                    <thead className="bg-navy-theme">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer">Edit</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer">Region</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer">Percent</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer">Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {percentsLoading ? (
                        <tr>
                          <td colSpan={6} className="bg-white px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            Loading data...
                          </td>
                        </tr>
                      ) :
                      percentData.length === 0 ? (
                        <tr>
                          <td colSpan={6} className="bg-white px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            No data available
                          </td>
                        </tr>
                      ) : 
                      (
                        percentData.map((data, adminIdx) => (
                          <tr key={data.region} className={adminIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <button onClick={() => {
                                setSelectedPercentData(data)
                                setUpdatePercentModalOpen(true)
                              }}>
                                <PencilAltIcon className="h-5 w-5"/>
                                
                              </button>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{data.region}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{data.percent}%</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{data.active ? <CheckIcon className="h-5 w-5"/> : <XIcon className="h-5 w-5"/>}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdatePercentModal
        modalOpen={updatePercentModalOpen}
        setModalOpen={setUpdatePercentModalOpen}
        selectedPercentData={selectedPercentData}
        getAdditionalPercentData={getAdditionalPercentData}
      />
    </>
  )
}

export default AdditionalPercentDashboard
