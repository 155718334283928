import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as Arrow } from "../../../../../assets/common/arrowDown.svg";
import SpinnerBlack from "../../../../../assets/common/spinnerBlack.svg";
import axios from "axios";

interface PharmacySelectMenuProps {
  setPharmacyName: (args: string) => void;
  setPharmacyID: (args: string) => void;
  productPlanID: string;
  region: string | null;
}

const PharmacySelectMenu: FC<PharmacySelectMenuProps> = ({
  setPharmacyName,
  setPharmacyID,
  productPlanID,
  region,
}) => {
  const pharmacy_list: {
    id: string;
    Medication_Price: number;
    Pharmacy_Name: string;
    Medication_Cost: number;
    Pharmacy_Location: string;
    Dispensing_Fee: number;
    Ground_Delivery_Fee: number;
    Total_Cost_of_30_Caps_with_5_Disc: number;
  }[] = [
    {
      id: "",
      Medication_Price: 0,
      Pharmacy_Name: "",
      Medication_Cost: 0,
      Pharmacy_Location: "",
      Dispensing_Fee: 0,
      Ground_Delivery_Fee: 0,
      Total_Cost_of_30_Caps_with_5_Disc: 0,
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectList, setSelectList] = useState(pharmacy_list);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [queryError, setQueryError] = useState<string>("");

  const getPharmacies = async () => {
    setLoading(true);
    setQueryError("");

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-pharmacies`,
        {
          country: region,
          product_plan_id: productPlanID,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res;
        setLoading(false);
        setSelectList(data);
        setIsOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setIsOpen(false);
        if (typeof err?.response?.data.message === "string") {
          setQueryError(err.response.data.message);
          return;
        }
      });
  };

  // search product plans on mount
  useEffect(() => {
    getPharmacies();
  }, []);

  const dropDownRef = useRef<HTMLDivElement>(null);
  const openHandler = (): void => {
    if (loading) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const selectHandler = (id: string) => {
    const selected = selectList.find((el) => el.id === id);
    setPharmacyID(selected!.id);
    setPharmacyName(selected!.Pharmacy_Name);
    setSelectedItem(selected!.Pharmacy_Name);
    setIsOpen(false);

    sessionStorage.setItem("pharmacy_id", selected!.id);
    sessionStorage.setItem("pharmacy_name", selected!.Pharmacy_Name);
    sessionStorage.setItem(
      "pharmacy_location",
      selected!.Pharmacy_Location ? selected!.Pharmacy_Location : ""
    );
    sessionStorage.setItem(
      "pharmacy_dispensing_fee",
      selected!.Dispensing_Fee ? selected!.Dispensing_Fee.toString() : ""
    );
    sessionStorage.setItem(
      "pharmacy_ground_delivery_fee",
      selected!.Ground_Delivery_Fee
        ? selected!.Ground_Delivery_Fee.toString()
        : ""
    );
    sessionStorage.setItem(
      "pharmacy_capsule_cost",
      selected!.Medication_Price ? selected!.Medication_Price.toString() : ""
    );
    sessionStorage.setItem(
      "pharmacy_30_capsule_cost",
      selected!.Medication_Cost ? selected!.Medication_Cost.toString() : ""
    );
  };

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (selectList.length < 1) {
      setQueryError("No records found. Please try again.");
    } else {
      setQueryError("");
    }
  }, [selectList]);

  return (
    <div>
      <div className="flex">
        <label
          htmlFor="pharmacies"
          className="block text-sm font-medium text-gray-700"
        >
          Pharmacy
        </label>
        {loading && (
          <div className="flex items-center">
            <img src={SpinnerBlack} alt="" width="20px" />
            <div className="block text-sm font-medium text-gray-700">
              Fetching pharmacies...
            </div>
          </div>
        )}
        {queryError && (
          <span className="block text-sm font-medium text-red-700 ml-2">
            {queryError}
          </span>
        )}
      </div>
      <div className="relative mt-2">
        <div
          className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
          onClick={openHandler}
          ref={dropDownRef}
        >
          <div className="flex items-center pl-4 font-medium text-828282 w-full">
            {selectedItem}
          </div>
          <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
            <Arrow />
          </div>
          {isOpen && (
            <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
              {selectList?.map((item, index) => (
                <div
                  className={`${
                    index === 0 ? "" : "border-t border-F0F0F0"
                  } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                  key={index}
                  onClick={() => {
                    selectHandler(item.id);
                  }}
                >
                  {
                    <div className="flex text-sm">
                      <div className="w-36">{item.Pharmacy_Name}</div>
                      {item?.Medication_Price ? (
                        <div className="w-36">
                          {sessionStorage.getItem("currency") === "EUR"
                            ? "€"
                            : sessionStorage.getItem("currency") === "GBP"
                            ? "£"
                            : "$"}
                          {item.Medication_Price.toFixed(2)} {"(1 pc)"}
                        </div>
                      ) : (
                        <div className="w-36">-</div>
                      )}
                      {item?.Medication_Cost ? (
                        <div className="w-36">
                          {sessionStorage.getItem("currency") === "EUR"
                            ? "€"
                            : sessionStorage.getItem("currency") === "GBP"
                            ? "£"
                            : "$"}
                          {item?.Medication_Cost?.toFixed(2)} {"(30 pcs)"}
                        </div>
                      ) : (
                        <div className="w-36">-</div>
                      )}
                    </div>
                  }
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PharmacySelectMenu;
