import storage from "redux-persist/lib/storage"
import { combineReducers } from "redux"
import { authSlice } from "./auth/slice"
import { persistReducer } from "redux-persist"

const persistConfig = {
  key: "mhc",
  version: 1,
  storage,
  whitelist: ["userLoggedIn", "userMasterAdmin", "authToken"],
}

export const rootReducer = combineReducers({
  userData: persistReducer(persistConfig, authSlice.reducer),
})
