import React, { useState, useEffect, useRef, useCallback } from "react"
import { ReactComponent as Arrow } from "../../../../../assets/common/arrowDown.svg"

type PropsType = {
  selectList: { id: string }[]
  selectedItem: string
  setSelectedItem: (args: string) => void
  setZohoHidden: (args: boolean) => void
  setOpportunityHidden: (args: boolean) => void
  setOpportunityData: (args: Opportunity[]) => void
  setCustomerSelectedItem: (args: string) => void
  setSelectModuleError: (args: string) => void
}

type Opportunity = {
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
}

const ModuleSelectMenu: React.FC<PropsType> = ({
  selectList,
  selectedItem,
  setSelectedItem,
  setZohoHidden,
  setOpportunityHidden,
  setOpportunityData,
  setCustomerSelectedItem,
  setSelectModuleError,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const openHandler = (): void => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const selectHandler = (id: string) => {
    const selected = selectList.find((el) => el.id === id)
    setZohoHidden(true)
    setOpportunityHidden(true)
    setOpportunityData([])
    setCustomerSelectedItem("")
    setSelectModuleError("")
    sessionStorage.removeItem("opportunity_id")
    sessionStorage.removeItem("full_name")
    sessionStorage.removeItem("plan_name")
    sessionStorage.removeItem("total_price")
    sessionStorage.removeItem("amount_paid")
    sessionStorage.removeItem("amount_remaining")
    sessionStorage.removeItem("transaction_type")
    sessionStorage.removeItem("note")
    switch (selected!.id) {
      case "Lead":
        sessionStorage.setItem("module", "LEADS")
        break
      case "Contact":
        sessionStorage.setItem("module", "CONTACTS")
        break
    }
    setSelectedItem(selected!.id)
    setIsDropdownOpen(false)
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setIsDropdownOpen(false)
      }
    },
    [setIsDropdownOpen]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div className="relative mt-2">
      <div
        className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
        onClick={openHandler}
        ref={dropDownRef}
      >
        <div className="flex items-center pl-4 font-medium text-828282 w-full">
          {selectedItem}
        </div>
        <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
          <Arrow />
        </div>
        {isDropdownOpen && (
          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
            {selectList.map(({ id }, index) => (
              <div
                className={`${
                  index === 0 ? "" : "border-t border-F0F0F0"
                } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                key={id}
                onClick={() => selectHandler(id)}
              >
                {id}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ModuleSelectMenu
