import { FC, Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"

interface PropTypes {
  open: boolean
  setOpen: (arg: boolean) => void
  region: string
  contactID: string
  currency: string
  province: string
  pmName: string
  pmID: string
  productPlanID: string
  productPlanName: string
  startDate: Date | null
  endDate: Date | null
  stage: string
  terminal: string
  contactName: string
  apiStatus: string
  setApiStatus: (arg: string) => void
  apiResponse: string
  setApiResponse: (arg: string) => void
}

const ConfirmationModal: FC<PropTypes> = ({
  open,
  setOpen,
  region,
  contactID,
  currency,
  province,
  pmName,
  pmID,
  productPlanID,
  productPlanName,
  startDate,
  endDate,
  stage,
  terminal,
  contactName,
  apiStatus,
  setApiStatus,
  apiResponse,
  setApiResponse,
}) => {
  const history = useHistory()

  const [isProcessing, setIsProcessing] = useState(false)
  const [confirmDisabled, setConfirmDisabled] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [opportunityID, setOpportunityID] = useState("")
  const [regionID, setRegionID] = useState("")

  const handleCancel = () => {
    setOpen(false)
    setIsProcessing(false)
    setConfirmDisabled(false)
    setApiResponse("")
    setApiStatus("")
  }

  const handleConfirm = async () => {
    setIsProcessing(true)
    setConfirmDisabled(true)

    sessionStorage.setItem("contact_id", contactID)
    sessionStorage.setItem("stage", stage)
    sessionStorage.setItem("pm_name", pmName)
    sessionStorage.setItem("pm_id", pmID)
    sessionStorage.setItem("plan_id", productPlanID)
    sessionStorage.setItem("plan_name", productPlanName)
    sessionStorage.setItem("program_start_date", startDate?.toISOString().split("T")[0] ?? "")
    sessionStorage.setItem("program_end_date", endDate?.toISOString().split("T")[0] ?? "")
    sessionStorage.setItem("terminal", terminal)

    sessionStorage.setItem("isCreateOpportunity", "Yes")
    sessionStorage.setItem("createOpportunitySkipped", "Yes")

    history.push(
      `/payment-portal/transaction-type`
    )
    return

    // New flow for additional percent charge requires opportunity to be created at the last step
    // For future cleanup

    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/create`, {
        country: region,
        contact_id: contactID,
        stage: stage,
        performance_manager: {
          name: pmName,
          id: pmID,
        },
        product_plan: {
          id: productPlanID,
          name: productPlanName,
        },
        program_start: startDate?.toISOString().split("T")[0],
        program_end: endDate?.toISOString().split("T")[0],
        terminal: terminal,
        currency: currency,
        ...(region === "CA" && { province: province }),
      })
      .then(async (res) => {
        if (res.status === 200) {
          setApiResponse(
            "Opportunity successfully created! You may now proceed to take payment."
          )
          setApiStatus("SUCCESS")
          setOpportunityID(res.data.data.opportunity_id)
          setIsSuccess(true)
          setIsProcessing(false)
          setConfirmDisabled(false)
        }
      })
      .catch(async (err) => {
        setApiResponse(err.response.data.message)
        setApiStatus("ERROR")
        setIsProcessing(false)
        setConfirmDisabled(false)
      })
    return
  }

  enum Regions {
    AU = "52657875",
    CA = "760139034",
    NZ = "771947106",
    UK = "20067563176",
    PCA = "803222882",
  }

  useEffect(() => {
    setRegionID(Regions[region as keyof typeof Regions])
  }, [region])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-642 sm:p-6 w-800">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-medium text-gray-900"
                  >
                    Confirm Opportunity Details
                  </Dialog.Title>
                </div>
              </div>

              <div className="w-full sm:w-full mt-7 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Region
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {region}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Contact
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {contactName}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Currency
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {currency} (
                      {sessionStorage.getItem("currency") === "EUR"
                        ? "€"
                        : sessionStorage.getItem("currency") === "GBP"
                        ? "£"
                        : "$"}
                      )
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Performance Manager
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {pmName}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Product Plan
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {productPlanName}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Program Start
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {startDate?.toISOString().split("T")[0].substring(8, 10) +
                        "/" +
                        startDate?.toISOString().split("T")[0].substring(5, 7) +
                        "/" +
                        startDate?.toISOString().split("T")[0].substring(0, 4)}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Program End
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {endDate?.toISOString().split("T")[0].substring(8, 10) +
                        "/" +
                        endDate?.toISOString().split("T")[0].substring(5, 7) +
                        "/" +
                        endDate?.toISOString().split("T")[0].substring(0, 4)}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Stage
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {stage}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Terminal
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {terminal}
                    </dd>
                  </div>
                  {region === "CA" && (
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Province
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {province} {sessionStorage.getItem("tax_rate")}%
                      </dd>
                    </div>
                  )}
                </dl>
              </div>

              {apiStatus === "ERROR" && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center mt-8 mx-auto w-400">
                  <div className="block sm:inline">{apiResponse}</div>
                </div>
              )}
              {apiStatus === "SUCCESS" && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center mt-8 mx-auto w-400">
                  <div className="block sm:inline">{apiResponse}</div>
                </div>
              )}

              {isSuccess ? (
                <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                  <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <div className="mt-5 sm:mt-6 flex justify-center items-center">
                      <button
                        type="button"
                        className="bg-FF5733 hover:bg-FF5733_hover text-white w-200
                        inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:text-sm"
                        onClick={() => {
                          history.push(
                            `/opportunity-payment/${regionID}/${contactID}/${opportunityID}`
                          )
                        }}
                      >
                        Take Payment
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                  <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                    <div className="mt-5 sm:mt-6 flex justify-between">
                      <button
                        type="button"
                        className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <div className="relative">
                        {isProcessing && (
                          <img
                            width={40}
                            className="inline absolute left-0"
                            src={spinnerBlack}
                            alt="Loading..."
                          />
                        )}
                        <button
                          type="button"
                          className={`${
                            isProcessing
                              ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto w-214 pl-8"
                              : "bg-FF5733 hover:bg-FF5733_hover text-white w-200"
                          } inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:text-sm`}
                          onClick={() => {
                            if (!confirmDisabled) {
                              handleConfirm()
                            }
                          }}
                        >
                          {isProcessing ? "Creating opportunity..." : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ConfirmationModal