import { FC, ChangeEvent, useCallback, useEffect, useRef } from "react"
import { ReactComponent as Search } from "../../../../../assets/common/search.svg"

type PM = { full_name: string; id: string; email: string }
interface PMSearchMenuProps {
  searchPms: () => void
  pmList: PM[]
  pmSelectedItem: string
  setPmSelectedItem: (args: string) => void
  pmDropdownOpen: boolean
  setPmDropdownOpen: (args: boolean) => void
  setIsPmSelected: (args: boolean) => void
}

const PMSearchMenu: FC<PMSearchMenuProps> = ({
  searchPms,
  pmList,
  pmSelectedItem,
  setPmSelectedItem,
  pmDropdownOpen,
  setPmDropdownOpen,
  setIsPmSelected,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null)
  const openHandler = (): void => {
    if (pmSelectedItem !== "") {
      setPmDropdownOpen(!pmDropdownOpen)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPmSelectedItem(e.target.value)
  }

  const selectPmHandler = (id: string) => {
    const selected = pmList.find((el) => el.id === id)
    sessionStorage.setItem("pm_id", selected!.id)
    sessionStorage.setItem("pm_name", selected!.full_name)
    setPmSelectedItem(selected!.full_name)
    setIsPmSelected(true)
    setPmDropdownOpen(false)
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setPmDropdownOpen(false)
      }
    },
    [setPmDropdownOpen]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div className={`relative mt-2`}>
      <div
        className="bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border"
        onClick={openHandler}
        ref={dropDownRef}
      >
        <div className="ml-4 flex items-center justify-center min-w-2.375 w-2.375 h-full">
          <Search />
        </div>
        <div>
          <button
            className="absolute top-3 right-6 font-medium"
            onClick={() => searchPms()}
          >
            Search
          </button>
        </div>
        <input
          className="bg-E0E0E0 flex items-center pl-2 text-828282 w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
          placeholder="Enter the name of performance manager"
          value={pmSelectedItem}
          onChange={handleChange}
          onKeyPress={(event) => event.key === "Enter" && searchPms()}
        />
        {pmDropdownOpen && (
          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-40">
            {pmList?.map((item, index) => (
              <div
                className={`${
                  index === 0 ? "" : "border-t border-F0F0F0"
                } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                key={index}
                onClick={() => {
                  selectPmHandler(item.id)
                }}
              >
                {
                  <div className="flex text-sm">
                    <div className="w-36">{item.full_name}</div>
                    <div className="w-64">{item.email}</div>
                  </div>
                }
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default PMSearchMenu
