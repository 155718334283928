import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Layout from "../../components/layout/Layout";
import RegionSelectMenu from "./components/RegionSelectMenu";

const RegionSelect = () => {
  const [regionSelectedItem, setRegionSelectedItem] = useState("Select region");
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const adminRegions: { value: string }[] = useSelector(
    (store: RootState) => store.userData.userInformation.admins_features
  );
  const regionAccess = adminRegions.map((item) => item.value);
  const regionList: { id: string }[] = [];
  const history = useHistory();

  const handleProceed = () => {
    switch (sessionStorage.getItem("country")) {
      case "AU":
        sessionStorage.setItem("currency", "AU$");
        break;
      case "CA":
        sessionStorage.setItem("currency", "CA$");
        break;
      case "NZ":
        sessionStorage.setItem("currency", "NZ$");
        break;
      case "PCA":
        sessionStorage.setItem("currency", "AU$");
        break;
    }

    history.push("/payment-portal/customer-select");
  };

  useEffect(() => {
    if (regionAccess.includes("AU")) {
      regionList.push({ id: "Australia" });
    }
    if (regionAccess.includes("CA")) {
      regionList.push({ id: "Canada" });
    }
    if (regionAccess.includes("NZ")) {
      regionList.push({ id: "New Zealand" });
    }
    if (regionAccess.includes("UK")) {
      regionList.push({ id: "United Kingdom" });
    }
    if (regionAccess.includes("PCA")) {
      regionList.push({ id: "PCA" });
    }
    return;
  }, [regionAccess, regionList]);

  useEffect(() => {
    if (regionSelectedItem !== "Select region") {
      setNextButtonDisabled(false);
    }
  }, [regionSelectedItem]);

  useEffect(() => {
    setRegionSelectedItem("Select region");
    const removeKeys = [
      "country",
      "currency",
      "amount_paid",
      "total_price",
      "tax_rate",
      "module",
      "province",
      "full_name",
      "email",
      "mobile",
      "plan_name",
      "pharmacy_name",
      "pharmacy_medicine_count",
      "opportunity_id",
      "note",
      "terminal",
      "stage",
      "transaction_type",
      "amount_remaining",
      "amount_paid",
      "address",
      "date_of_birth",
      "parent_opportunity_id",
      "parent_opportunity_name",
    ];
    removeKeys.forEach((key) => sessionStorage.removeItem(key));
  }, []);

  return (
    <Layout
      pageNumber={1}
      backButtonDisabled={true}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="region"
          className="block text-sm font-medium text-gray-700"
        >
          Region
        </label>
        <RegionSelectMenu
          regionList={regionList}
          regionSelectedItem={regionSelectedItem}
          setRegionSelectedItem={setRegionSelectedItem}
        />
      </div>
    </Layout>
  );
};

export default RegionSelect;
