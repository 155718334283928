import { FC, Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationCircleIcon } from "@heroicons/react/outline"

interface UpdateLeadOwnerModalProps {
  updateLeadOwnerModalOpen: boolean
  setUpdateLeadOwnerModalOpen: (args: boolean) => void
  setInsuranceLeadConfirmationModalOpen: (args: boolean) => void
}

const UpdateLeadOwnerModal: FC<UpdateLeadOwnerModalProps> = ({
  updateLeadOwnerModalOpen,
  setUpdateLeadOwnerModalOpen,
  setInsuranceLeadConfirmationModalOpen,
}) => {
  const cancelButtonRef = useRef(null)
  const [checked, setChecked] = useState(false)
  const [proceedDisabled, setProceedDisabled] = useState(true)

  const handleProceed = () => {
    if (checked) {
      setUpdateLeadOwnerModalOpen(false)
      setChecked(false)
      setProceedDisabled(true)
      setInsuranceLeadConfirmationModalOpen(true)
    }
  }

  useEffect(() => {
    if (checked) {
      setProceedDisabled(false)
    } else {
      setProceedDisabled(true)
    }
  }, [checked])

  return (
    <Transition.Root show={updateLeadOwnerModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          setUpdateLeadOwnerModalOpen(false)
          setChecked(false)
          setProceedDisabled(true)
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start mb-2">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationCircleIcon
                    className="h-6 w-6 text-yellow-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Update Lead Owner
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 font-medium">
                      Please ensure that the Lead Owner in Zoho CRM is your own
                      name prior to proceeding.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="checkbox"
                name="confirm"
                id="confirm"
                className="ml-14 mr-2 h-4 w-4 text-yellow-500 border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <label htmlFor="confirm" className="text-sm text-gray-500">
                Lead Owner has been updated
              </label>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`${
                    proceedDisabled
                      ? "bg-E0E0E0 text-828282 cursor-auto"
                      : "text-white bg-yellow-600 hover:bg-yellow-700 cursor-pointer"
                  } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm`}
                  onClick={() => handleProceed()}
                >
                  Proceed
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setUpdateLeadOwnerModalOpen(false)
                    setChecked(false)
                    setProceedDisabled(true)
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpdateLeadOwnerModal
