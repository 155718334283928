import { useState, useEffect, FC } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { login } from "../redux/auth/actions"
import { DesktopLogo, LockIcon, MobileLogo } from "../assets/common/icons"
import { useFormik } from "formik"
import * as Yup from "yup"

interface PropTypes {
  location: {
    state?: {
      from: {
        pathname: string
      }
    }
  }
}

const SignIn: FC<PropTypes> = (props) => {
  const redirect_url = props.location.state?.from.pathname
  const history = useHistory()
  const dispatch = useDispatch()
  const [loginMessage, setLoginMessage] = useState(false)

  const navigateTo = () => history.push("/forgotpassword")
  const error = useSelector((store: RootState) => store.userData.error)
  const loginSuccess = useSelector(
    (store: RootState) => store.userData.loginSuccess
  )
  const firstLogin = useSelector(
    (store: RootState) => store.userData.firstLogin
  )
  const userLoggedIn = useSelector(
    (store: RootState) => store.userData.userLoggedIn
  )
  const userProfile = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  const initialValues = {
    email: "",
    password: "",
  }

  const Schema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email address is required"),
  })

  useEffect(() => {
    if (userLoggedIn && redirect_url) {
      sessionStorage.clear()
      history.push(`${redirect_url}`)
      return
    } else if (userLoggedIn) {
      sessionStorage.clear()
      if (
        userProfile.type === "FRONTEND SALES" ||
        userProfile.type === "PERFORMANCE MANAGER" ||
        userProfile.type === "PM TEAM MANAGER" ||
        userProfile.type === "FE TEAM MANAGER" ||
        userProfile.type === "FINANCE MANAGER"
      ) {
        history.push("/payment-portal/region-select")
        return
      } else if (
        userProfile.type === "ADMIN" ||
        userProfile.type === "MASTER"
      ) {
        history.push("/dashboard")
        return
      }
    }
  }, [userProfile, loginSuccess, firstLogin, history, userLoggedIn])

  useEffect(() => {
    if (redirect_url) {
      setLoginMessage(true)
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      const data = JSON.stringify({
        email: values.email,
        password: values.password,
      })
      dispatch(login(data))
    },
  })

  return (
    <div className="quiz-container">
      <form className="space-y-6" method="POST" onSubmit={formik.handleSubmit}>
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-5 relative">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <span className="m-auto mx-auto h-12 w-auto desktoplogo">
              <DesktopLogo />
            </span>

            <span className="m-auto mx-auto h-12 w-auto mobile-logo">
              <MobileLogo />
            </span>

            {userLoggedIn ? (
              <h2 className="mt-6 text-center font-semibold text-3xl text-333333">
                Logging in...
              </h2>
            ) : (
              <h2 className="mt-6 text-center font-semibold text-3xl text-333333">
                Sign in to your account
              </h2>
            )}

            {loginMessage && sessionStorage.getItem("authError") ? (
              <h3 className="mt-4 text-center font-medium text-E84545">
                {sessionStorage.getItem("authError")}. Please re-log to continue
              </h3>
            ) : loginMessage ? (
              <h3 className="mt-4 text-center font-medium text-E84545">
                You are not logged in. Please login to continue.
              </h3>
            ) : null}
          </div>

          {!userLoggedIn && (
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
              {error.error.length > 0 && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5 text-center">
                  <span className="block sm:inline">{error.error}</span>
                </div>
              )}

              <div className="bg-white py-8 px-4 shadow rounded-md sm:px-7">
                <div className="pb-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      {...formik.getFieldProps("email")}
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      {...formik.getFieldProps("password")}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className=" items-end justify-right my-4">
                <div className="text-sm text-right">
                  <div
                    onClick={navigateTo}
                    className="font-medium text-3FB1FC hover:text-3FB1FC_hover cursor-pointer"
                  >
                    Forgot your password?
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="bg-navy-theme relative h-70 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
                >
                  <span className="absolute left-[18px]">
                    <LockIcon />
                  </span>{" "}
                  Sign in
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default SignIn
