import { FC } from "react"

interface MyProps {
  users: CardPayment[]
  loading: boolean
  currentPage: number
}

type CardPayment = {
  approval_level: string
  contact_id: string
  country: string
  description: [
    {
      actioned_by: string
      description: string
      timestamp: string
    }
  ]
  file_url: string
  gross_refund_amount: number
  id: number
  index: number
  last_actioned_id: string
  lastActionedBy: {
    first_name: string
    last_name: string
  }
  opportunity_id: string
  patient_name: string
  program_name: string
  program_price: number
  reason: string
  refund_amount: number
  refund_type: string
  status: boolean
  Name: string
  First_Name: string
  Last_Name: string
  Contact_Name: {
    name: string
  }
  Email: string
  Status: string
  Last_4_Digit: string
  Terminal: string
  Transaction_Type: string
  $currency_symbol: string
  Amount_Received: number
  Modified_Time: string
}

const Payments: FC<MyProps> = ({ users, loading, currentPage }) => {
  if (loading) {
    return (
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Loading payments...
        </td>
      </tr>
    )
  }

  return (
    <>
      {users.map((user, userIdx) => (
        <tr
          key={user.id}
          className={userIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
        >
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.index + +`${currentPage * 10 - 10}`}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {!user.First_Name
              ? user.Contact_Name.name.split(" ")[0]
              : user.First_Name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {!user.Last_Name
              ? user.Contact_Name.name.split(" ")[1]
              : user.Last_Name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Email}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Status}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Last_4_Digit}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Terminal}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Transaction_Type}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.$currency_symbol + user.Amount_Received.toFixed(2)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
            {user.Modified_Time}
          </td>
        </tr>
      ))}
    </>
  )
}

export default Payments
