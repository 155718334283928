import { useEffect, useState } from "react"
import { tableData } from "./tabledata/tabledata"
import Payments from "./payments/Payments"
import Pagination from "../common/Pagination"
import { handleSearch } from "../../helpers/handleSearch"
import { sortData } from "../../helpers/sortData"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import axios from "axios"
import { useDocTitle } from "../../hooks/useDocTitle"

type CardPayment = {
  approval_level: string
  contact_id: string
  country: string
  description: [
    {
      actioned_by: string
      description: string
      timestamp: string
    }
  ]
  file_url: string
  gross_refund_amount: number
  id: number
  index: number
  last_actioned_id: string
  lastActionedBy: {
    first_name: string
    last_name: string
  }
  opportunity_id: string
  patient_name: string
  program_name: string
  program_price: number
  reason: string
  refund_amount: number
  refund_type: string
  status: boolean
  Name: string
  First_Name: string
  Last_Name: string
  Contact_Name: {
    name: string
  }
  Email: string
  Status: string
  Last_4_Digit: string
  Terminal: string
  Transaction_Type: string
  $currency_symbol: string
  Amount_Received: number
  Modified_Time: string
}

const CardPayments = () => {
  useDocTitle("Card Payments - MHC Dashboard")
  const [users, setUsers] = useState<CardPayment[]>([])
  const [originalUsers, setOriginalUsers] = useState<CardPayment[]>([])
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const [auSelected, setAUSelected] = useState<boolean>(true)
  const [caSelected, setCASelected] = useState<boolean>(false)
  const [nzSelected, setNZSelected] = useState<boolean>(false)
  const [ukSelected, setUKSelected] = useState<boolean>(false)
  const [countrySelected, setCountrySelected] = useState<string>("")

  const fetchData = async (currentPage: number, country: string) => {
    setLoading(true)
    const data = await axios.post(
      `${process.env.REACT_APP_API_URL}admin/v1/payment-history/get?skip=${currentPage}`,
      {
        country: country,
        page_no: currentPage,
      }
    )
    setTotalUsers(data.data.data[10].count)
    setUsers(data.data.data.slice(0, 10))
    setOriginalUsers(data.data.data.slice(0, 10))
    data.data.data.map(
      (payment: CardPayment, index: number) => (payment.index = index + 1)
    )
    setLoading(false)
  }

  // SEARCH TABLE FUNCTION
  const [query, setQuery] = useState("")
  const [searchParam] = useState([
    "Name",
    "First_Name",
    "Last_Name",
    "Email",
    "Status",
    "Last_4_Digit",
    "Terminal",
    "Transaction_Type",
    "Amount_Received",
    "Payment_Date",
  ])

  useEffect(() => {
    handleSearch(query, originalUsers, searchParam, setUsers)
  }, [query])

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1)
  const [usersPerPage] = useState(10)
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
  const pageNumbers: number[] = []

  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
    pageNumbers.push(i)
  }

  useEffect(() => {
    if (countrySelected) {
      fetchData(currentPage * 10 - 10, countrySelected)
    } else {
      setLoading(false)
    }
  }, [currentPage, countrySelected])

  // SORT BY CLICKING COLUMN HEADER
  const [order, setOrder] = useState("ASC")
  const [sortingColumn, setSortingColumn] = useState("")

  // FILTERING
  const handleCheck = (value: string) => {
    switch (value) {
      case "AU":
        setAUSelected(true)
        setCASelected(false)
        setNZSelected(false)
        setUKSelected(false)
        break
      case "CA":
        setCASelected(true)
        setAUSelected(false)
        setNZSelected(false)
        setUKSelected(false)
        break
      case "NZ":
        setNZSelected(true)
        setAUSelected(false)
        setCASelected(false)
        setUKSelected(false)
        break
      case "UK":
        setUKSelected(true)
        setAUSelected(false)
        setCASelected(false)
        setNZSelected(false)
        break
    }
  }

  useEffect(() => {
    if (auSelected) {
      setCountrySelected("AU")
      setUsers(
        originalUsers.filter((user: CardPayment) => user.country === "AU")
      )
    }
    if (caSelected) {
      setCountrySelected("CA")
      setUsers(
        originalUsers.filter((user: CardPayment) => user.country === "CA")
      )
    }
    if (nzSelected) {
      setCountrySelected("NZ")
      setUsers(
        originalUsers.filter((user: CardPayment) => user.country === "NZ")
      )
    }
    if (ukSelected) {
      setCountrySelected("UK")
      setUsers(
        originalUsers.filter((user: CardPayment) => user.country === "UK")
      )
    }
  }, [auSelected, caSelected, nzSelected, ukSelected])

  return (
    <>
      <div className="overflow-x-hidden">
        <div>
          <main>
            <div className="flex justify-between">
              <div className="">
                <input
                  type="text"
                  className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>

            <p className="mt-1 mb-4 max-w-2xl text-sm text-gray-500">
              Select region to view card payments. Sort columns by clicking the
              column header.
            </p>

            <div className="pt-4 lg:grid lg:grid-cols-8 lg:gap-x-8 xl:grid-cols-8">
              <aside>
                <div className="hidden lg:block">
                  <form className="divide-y divide-gray-200 space-y-4">
                    <div>
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-900">
                          Select Region
                        </legend>
                        <div className="pt-4 space-y-2">
                          <div className="flex items-center">
                            <input
                              id="AU"
                              name="AU"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("AU")}
                              checked={auSelected}
                            />
                            <label
                              htmlFor="AU"
                              className="ml-3 text-sm text-gray-600"
                            >
                              AU
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="CA"
                              name="CA"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("CA")}
                              checked={caSelected}
                            />
                            <label
                              htmlFor="CA"
                              className="ml-3 text-sm text-gray-600"
                            >
                              CA
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="NZ"
                              name="NZ"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("NZ")}
                              checked={nzSelected}
                            />
                            <label
                              htmlFor="NZ"
                              className="ml-3 text-sm text-gray-600"
                            >
                              NZ
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="UK"
                              name="UK"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("UK")}
                              checked={ukSelected}
                            />
                            <label
                              htmlFor="UK"
                              className="ml-3 text-sm text-gray-600"
                            >
                              UK
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </form>
                </div>
              </aside>

              <div className="mt-6 lg:mt-0 lg:col-span-7 xl:col-span-7">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-navy-theme">
                            <tr>
                              {tableData.map((column, index) => (
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                                  onClick={() => {
                                    // Check which columns include numbers for sorting (ie. Last 4 Digits, Amount Received, etc.)
                                    if (
                                      index === 5 ||
                                      index === 8 ||
                                      index === 9
                                    ) {
                                      sortData(
                                        "number",
                                        order,
                                        users,
                                        column.sort,
                                        setUsers,
                                        setOrder,
                                        setSortingColumn
                                      )
                                    } else {
                                      sortData(
                                        "string",
                                        order,
                                        users,
                                        column.sort,
                                        setUsers,
                                        setOrder,
                                        setSortingColumn
                                      )
                                    }
                                  }}
                                  key={index}
                                >
                                  <div className="flex items-center">
                                    {column.name}
                                    {sortingColumn === column.sort ? (
                                      order === "DSC" ? (
                                        <ChevronUpIcon
                                          className={`${
                                            index === 6 ||
                                            index === 8 ||
                                            index === 9
                                              ? "h-8"
                                              : "h-5"
                                          }`}
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          className={`${
                                            index === 6 ||
                                            index === 8 ||
                                            index === 9
                                              ? "h-8"
                                              : "h-5"
                                          }`}
                                        />
                                      )
                                    ) : null}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <Payments
                              users={users}
                              loading={loading}
                              currentPage={currentPage}
                            />
                          </tbody>
                        </table>
                        <Pagination
                          elementsPerPage={usersPerPage}
                          totalElements={totalUsers}
                          paginate={paginate}
                          currentPage={currentPage}
                          type="PAYMENTS"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default CardPayments
