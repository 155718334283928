import { FC, useEffect, useState } from "react"
import DetailsModal from "./modal/DetailsModal"
import ApprovalModal from "./modal/ApprovalModal"
import Pagination from "../common/Pagination"
import { sortData } from "../../helpers/sortData"
import { handleSearch } from "../../helpers/handleSearch"
import { tableData } from "./tabledata/tabledata"
import Payments from "./payments/Payments"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

interface PropTypes {
  eftPaymentsData: EftPayment[]
  setEftPaymentsData: (args: EftPayment[]) => void
  eftPaymentsOriginalData: EftPayment[]
  getEFTPayments: () => void
}

type EftPayment = {
  performance_manager: {
    id: string
    name: string
  }
  product_plan: {
    id: string
    name: string
    type: string
  }
  id: number
  module_name: string
  email: string
  country: string
  payment_type: string
  opportunity_id: string
  lead_id: string
  contact_id: string
  admin_id: string
  url: string
  amount: number
  eft_status: string
  stage: string
  program_start: string
  program_end: string
  province: string
  currency: string
  status: true
  createdById: string
  updatedById: string
  createdAt: string
  updatedAt: string
  admin: {
    id: string
    first_name: string
    last_name: string
  }
  createdBy: {
    first_name: string
    last_name: string
    email: string
  }
  updatedBy: {
    first_name: string
    last_name: string
    email: string
  }
  salesperson: string
  date: string
}

const EFTPayments: FC<PropTypes> = ({
  eftPaymentsData,
  setEftPaymentsData,
  eftPaymentsOriginalData,
  getEFTPayments,
}) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<EftPayment>({} as EftPayment)

  const [approvalModalOpen, setApprovalModalOpen] = useState(false)
  const [approvalModalType, setApprovalModalType] = useState("APPROVE")

  /* PAGINATION */
  const [currentPage, setCurrentPage] = useState(1)
  const [paymentsPerPage] = useState(10)

  const indexOfLastPayment = currentPage * paymentsPerPage
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage
  const currentPayments = eftPaymentsData?.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  )

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  // SEARCH TABLE FUNCTION
  const [query, setQuery] = useState("")
  const [searchParam] = useState(["email"])

  useEffect(() => {
    handleSearch(
      query,
      eftPaymentsOriginalData,
      searchParam,
      setEftPaymentsData
    )
  }, [query])

  /* SORT BY CLICKING COLUMN HEADER */
  const [order, setOrder] = useState("ASC")
  const [sortingColumn, setSortingColumn] = useState("")

  const [access, setAccess] = useState(false)

  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  const handleAccess = () => {
    const valuesArr = userInformation.admins_features.map(
      (item: { value: string }) => item.value
    )

    if (valuesArr.includes("EFT_APPROVER")) setAccess(true)
  }

  useEffect(() => {
    handleAccess()
  }, [userInformation])

  return (
    <div className="overflow-x-hidden">
      <DetailsModal
        open={detailsModalOpen}
        setOpen={setDetailsModalOpen}
        data={modalData}
      />
      <ApprovalModal
        open={approvalModalOpen}
        setOpen={setApprovalModalOpen}
        type={approvalModalType}
        data={modalData}
        getEFTPayments={getEFTPayments}
      />

      <div>
        <input
          type="text"
          className="shadow-sm block sm:text-sm border-gray-300 rounded-md mb-4"
          placeholder="Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <p className="mt-1 max-w-2xl text-sm text-FF5733 font-semibold">
          {access
            ? "You have EFT approval permissions."
            : "You do not have EFT approval permissions."}
        </p>
      </div>
      <p className="mt-1 mb-4 max-w-2xl text-sm text-gray-500">
        Sort columns by clicking the column header.
      </p>
      <div className="h-[60vh] flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-navy-theme">
                  <tr>
                    <th
                      scope="col"
                      className="text-center px-6 py-3 text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                    >
                      Action
                    </th>
                    {tableData.map((column, index) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                        onClick={() => {
                          // Check which columns include numbers for sorting (ie. Payment Amount, Date Submitted, etc.)
                          if (
                            index === 3 ||
                            index === 5 ||
                            index === 7 ||
                            index === 9
                          ) {
                            sortData(
                              "number",
                              order,
                              eftPaymentsData,
                              column.sort,
                              setEftPaymentsData,
                              setOrder,
                              setSortingColumn
                            )
                          } else {
                            sortData(
                              "string",
                              order,
                              eftPaymentsData,
                              column.sort,
                              setEftPaymentsData,
                              setOrder,
                              setSortingColumn
                            )
                          }
                        }}
                        key={index}
                      >
                        <div className="flex items-center">
                          <div>{column.name}</div>
                          {sortingColumn === column.sort ? (
                            order === "DSC" ? (
                              <ChevronUpIcon
                                className={`${
                                  index === 2 || index === 3 ? "h-8" : "h-5"
                                }`}
                              />
                            ) : (
                              <ChevronDownIcon
                                className={`${
                                  index === 2 || index === 3 ? "h-8" : "h-5"
                                }`}
                              />
                            )
                          ) : null}
                        </div>
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                    >
                      Details
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                    >
                      Screenshot
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <Payments
                    payments={currentPayments}
                    setModalData={setModalData}
                    setDetailsModalOpen={setDetailsModalOpen}
                    setApprovalModalOpen={setApprovalModalOpen}
                    setApprovalModalType={setApprovalModalType}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        elementsPerPage={paymentsPerPage}
        totalElements={eftPaymentsData?.length}
        paginate={paginate}
        currentPage={currentPage}
        type="EFT"
      />
    </div>
  )
}

export default EFTPayments
