import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { DesktopLogo, MobileLogo } from "../assets/common/icons"
import { useFormik } from "formik"
import * as Yup from "yup"
import Axios, { AxiosResponse, AxiosError } from "axios"

export default function CreatePassword() {
  const history = useHistory()
  const initialValues = {
    newpass: "",
    confirmpass: "",
  }
  const [resmsg, setresmsg] = useState("")
  const [errmsg, seterrmsg] = useState("")
  const Schema = Yup.object().shape({
    newpass: Yup.string().required("Password is required"),
    confirmpass: Yup.string()
      .required("Confirm password is required field")
      .oneOf([Yup.ref("newpass"), null], "Passwords must match"),
  })

  useEffect(() => {
    if (!localStorage.getItem("otpsuccess")) {
      history.push("/signin")
    }
  }, [history])

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, actions) => {
      const data = JSON.stringify({ password: values.newpass })
      const token = localStorage.getItem("token")
      const config: Record<string, unknown> = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}admin/v1/account/reset-password`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      }
      Axios(config)
        .then(function (response: AxiosResponse) {
          actions.resetForm()
          if (response) {
            setresmsg("New password created successfully...")
            seterrmsg("")
            setTimeout(() => {
              history.push("/signin")
            }, 1000)
            localStorage.removeItem("token")
            localStorage.removeItem("otpsuccess")
          }
        })
        .catch((error: AxiosError) => {
          const responseData = error.response?.data as { message: string }
          const errorMessage = responseData?.message || "An error occurred"
          seterrmsg(errorMessage)
          setresmsg("")
        })
    },
  })

  return (
    <div className="quiz-container">
      <form onSubmit={formik.handleSubmit} className="space-y-6" method="POST">
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-5 relative">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <span className="m-auto mx-auto h-12 w-auto desktop-logo">
              <DesktopLogo />
            </span>

            <span className="m-auto mx-auto h-12 w-auto mobile-logo">
              <MobileLogo />
            </span>

            <h2 className="mt-6 text-center font-semibold text-3xl text-333333">
              Create new password
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            {resmsg && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5 text-center">
                <span className="block sm:inline">{resmsg}</span>
              </div>
            )}
            {errmsg && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5 text-center">
                <span className="block sm:inline">{errmsg}</span>
              </div>
            )}
            <div className="bg-white py-8 px-4 shadow rounded-md sm:px-7">
              <div>
                <label
                  htmlFor="newpass"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1">
                  <input
                    {...formik.getFieldProps("newpass")}
                    id="newpass"
                    name="newpass"
                    type="password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                {formik.touched.newpass && formik.errors.newpass ? (
                  <div className="invalid-feedback">
                    {formik.errors.newpass}
                  </div>
                ) : null}
              </div>

              <div>
                <label
                  htmlFor="confirmpass"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    {...formik.getFieldProps("confirmpass")}
                    id="confirmpass"
                    name="confirmpass"
                    type="password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                {formik.touched.confirmpass && formik.errors.confirmpass ? (
                  <div className="invalid-feedback">
                    {formik.errors.confirmpass}
                  </div>
                ) : null}
              </div>

              <div className="mt-10">
                <button
                  type="submit"
                  className="bg-navy-theme relative h-70 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
