import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PasswordModal from "../modal/PasswordModal"
import DeleteModal from "../modal/DeleteModal"
import axios from "axios"
import { ClipboardIcon } from "@heroicons/react/outline"

interface MyProps {
  users: User[]
  columns: boolean[]
  loading: boolean
  originalUsers: User[]
  fetchUsers: (currentPage: number, fetchSearchQuery: string) => void
  deleteOpen: boolean
  passwordOpen: boolean
  setDeleteOpen: Dispatch<SetStateAction<boolean>>
  setPasswordOpen: Dispatch<SetStateAction<boolean>>
  toggleSelectAll: boolean
  currentPage: number
}

type User = {
  city: string
  country: string
  createdById: string
  email: string
  first_name: string
  hasTracking: boolean
  id: string
  index: number
  last_name: string
  location: string
  mobile: string
  residentialAddress: string
  role: string
  shippingAddress: string
  type: string
  updatedAt: string
  updatedById: string
  userId: string
  gaconnector: {
    gaconnectorfields1__GA_Client_ID: string
  }
  client_id: string
  profileImage: string
}

const Users: FC<MyProps> = ({
  users,
  loading,
  columns,
  originalUsers,
  fetchUsers,
  deleteOpen,
  passwordOpen,
  setDeleteOpen,
  setPasswordOpen,
  toggleSelectAll,
  currentPage,
}) => {
  const [userCheckStatus, setUserCheckStatus] = useState<
    Record<number, boolean>
  >({})

  useEffect(() => {
    const userCheckStatusValues = Object.values(userCheckStatus)
    if (
      userCheckStatusValues.some((user) => user === false) ||
      userCheckStatusValues.length === 0
    ) {
      const initialUserCheckStatus: Record<number, boolean> = {}
      users.forEach((_, userIdx) => {
        initialUserCheckStatus[userIdx] = true
      })
      setUserCheckStatus(initialUserCheckStatus)
    } else if (
      userCheckStatusValues.length === users.length &&
      userCheckStatusValues.every((user) => user === true)
    ) {
      const initialUserCheckStatus: Record<number, boolean> = {}
      users.forEach((_, userIdx) => {
        initialUserCheckStatus[userIdx] = false
      })
      setUserCheckStatus(initialUserCheckStatus)
    }
  }, [toggleSelectAll])

  const handleDelete = async (indexes: string[]) => {
    const indexesAsNumbers = indexes.map((index: string) => parseInt(index))
    const ids: string[] = users
      .filter((_, key: number) => indexesAsNumbers.includes(key))
      .map((user) => user.id)
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/user/remove-user-bulk`, {
        user_id: ids,
      })
      .then(() => fetchUsers(currentPage * 10 - 10, ""))
    setDeleteOpen(false)
    setUserCheckStatus({})
  }

  if (loading) {
    return (
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Loading users...
        </td>
      </tr>
    )
  }

  return (
    <>
      <PasswordModal
        passwordOpen={passwordOpen}
        setPasswordOpen={setPasswordOpen}
        originalUsers={originalUsers}
        selectedUsers={userCheckStatus}
      />
      <DeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDelete={handleDelete}
        selectedUsers={userCheckStatus}
      />
      {users.map((user, userIdx) => (
        <tr
          key={user.email}
          className={userIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
        >
          <td className="flex">
            <input
              className="mx-auto my-4"
              type="checkbox"
              checked={userCheckStatus?.[userIdx] ?? false}
              onChange={() =>
                setUserCheckStatus(
                  (prevUserCheckStatus: Record<number, boolean>) => {
                    return {
                      ...prevUserCheckStatus,
                      [`${userIdx}`]: !prevUserCheckStatus[userIdx],
                    }
                  }
                )
              }
            />
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {+user.index + +`${currentPage * 10 - 10}`}
          </td>
          <td
            className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900`}
          >
            <Link to={`/user-tracking/${user.id}`} target="_blank">
              <ClipboardIcon className="w-5 h-5 mx-auto cursor-pointer hover:text-gray-500" />
            </Link>
          </td>
          
          
          <td
            className={`${
              !columns[0] ? "hidden" : null
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900`}
          >
            <Link
              to={{
                pathname: "/user-overview",
                state: {
                  name: user.first_name + " " + user.last_name,
                  email: user.email,
                  mobile: user.mobile,
                  city: user.city,
                  country: user.country,
                  location: user.location,
                  residentialAddress: user.residentialAddress,
                  shippingAddress: user.shippingAddress,
                  role: user.role,
                  type: user.type,
                  createdById: user.createdById,
                  updatedById: user.updatedById,
                  updatedAt: user.updatedAt,
                  userId: user.id,
                  gaconnector: user.gaconnector,
                  clientId: user.gaconnector?.gaconnectorfields1__GA_Client_ID,
                },
              }}
            >
              {user.first_name}
            </Link>
          </td>
          <td
            className={`${
              !columns[1] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500`}
          >
            {user.last_name}
          </td>
          <td
            className={`${
              !columns[2] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.email}
          </td>
          <td
            className={`${
              !columns[3] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.mobile}
          </td>
          <td
            className={`${
              !columns[4] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.role}
          </td>
          <td
            className={`${
              !columns[5] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.type}
          </td>
          <td
            className={`${
              !columns[6] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.city}
          </td>
          <td
            className={`${
              !columns[7] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.country}
          </td>
          <td
            className={`${
              !columns[8] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.location}
          </td>
          <td
            className={`${
              !columns[9] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.residentialAddress}
          </td>
          <td
            className={`${
              !columns[10] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.shippingAddress}
          </td>
          <td
            className={`${
              !columns[11] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.createdById}
          </td>
          <td
            className={`${
              !columns[12] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.updatedById}
          </td>
          <td
            className={`${
              !columns[13] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.updatedAt}
          </td>
          <td
            className={`${
              !columns[14] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.id}
          </td>
          <td
            className={`${
              !columns[15] && "sm:hidden"
            } px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 hidden sm:table-cell`}
          >
            {user.gaconnector?.gaconnectorfields1__GA_Client_ID}
          </td>
        </tr>
      ))}
    </>
  )
}

export default Users
