import { FC, useEffect, useState } from "react"
import { ReactComponent as EmailAddress } from "../../../assets/userProfile/emailAddress.svg"
import { ReactComponent as PhoneNumber } from "../../../assets/userProfile/phoneNumber.svg"
import { ReactComponent as AccountType } from "../../../assets/userProfile/accountType.svg"
import { ReactComponent as UserAccess } from "../../../assets/userProfile/userAccess.svg"

interface MyProps {
  title: string
  content: string
  showEdit?: boolean
  keyName: string
}

const PatientInformationItem: FC<MyProps> = ({ title, keyName, content }) => {
  const [logo, setLogo] = useState<JSX.Element | null>(null)
  const isEmail = title === "Email Address"

  useEffect(() => {
    switch (title) {
      case "Email Address":
        setLogo(<EmailAddress />)
        break
      case "Mobile":
        setLogo(<PhoneNumber />)
        break
      case "Account Type":
        setLogo(<AccountType />)
        break
      case "User Access":
        setLogo(<UserAccess />)
        break
    }
  }, [title])

  return (
    <div className="items-center flex md:justify-between">
      <div className="flex w-full">
        <div className="h-42px w-42px min-h-42px min-w-42px bg-3FB1FC bg-opacity-20 rounded-md flex justify-center items-center text-3FB1FC">
          {logo}
        </div>
        <div className="flex flex-col md:flex-row pl-6  md:justify-between md:items-center">
          <div className="text-sm md:min-w-140px lg:min-w-16 xl:min-w-20">
            {keyName}
          </div>
          <div
            className={`text-sm text-828282 pr-2 ${
              isEmail ? "lowercase" : "capitalize"
            }`}
          >
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientInformationItem
