import { FC } from "react"
import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

interface MyProps {
  open: boolean
  setOpen: (args: boolean) => void
  columns: boolean[]
  setColumns: (args: boolean[]) => void
}

const Modal: FC<MyProps> = ({ open, setOpen, columns, setColumns }) => {
  const columnsArr = [
    { id: "first_name", label: "First Name" },
    { id: "last_name", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "mobile", label: "Mobile" },
    { id: "role", label: "Role" },
    { id: "type", label: "Type" },
    { id: "city", label: "City" },
    { id: "country", label: "Country" },
    { id: "location", label: "Location" },
    { id: "residential_address", label: "Residential Address" },
    { id: "shipping_address", label: "Shipping Address" },
    { id: "created_by_id", label: "Created by ID" },
    { id: "updated_by_id", label: "Updated by ID" },
    { id: "updated_at", label: "Updated at" },
    { id: "user_id", label: "User ID" },
    { id: "client_id", label: "Client ID" },
  ]

  const handleCancel = () => {
    setOpen(false)
  }

  const handleSave = () => {
    const newColumns = columns.slice()

    for (let i = 0; i < columnsArr.length; i++) {
      if (
        (document.getElementById(columnsArr[i].id) as HTMLInputElement).checked
      ) {
        newColumns[i] = true
      } else {
        newColumns[i] = false
      }
    }

    setColumns(newColumns)
    setOpen(false)
  }

  return (
    <Transition.Root
      show={open}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-20 px-4 pb-20 text-center sm:block sm:p-0 sm:pt-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-16 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Manage Columns
                  </Dialog.Title>
                </div>
              </div>

              <fieldset className="space-y-5">
                {columnsArr.map((column, index) => (
                  <div
                    key={index}
                    className="relative flex items-start"
                  >
                    <div className="flex items-center h-5">
                      <input
                        id={column.id}
                        name={column.id}
                        type="checkbox"
                        defaultChecked={columns[index]}
                        className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={column.id}
                        className="font-medium text-gray-700"
                      >
                        {column.label}
                      </label>
                    </div>
                  </div>
                ))}
              </fieldset>

              <div className="mt-5 sm:mt-6 flex justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-navy-theme text-base font-medium text-white focus:outline-none sm:text-sm"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
