import { FC, useState } from "react"
import ProgramInfoModal from "./modal/ProgramInfoModal"

interface PropTypes {
  isRefund?: boolean
}

const ProgramInfo: FC<PropTypes> = ({ isRefund = false }) => {
  const [programInfoOpen, setProgramInfoOpen] = useState(false)

  return (
    <>
      {isRefund ? (
        <>
          <button
            className="absolute top-0 right-[148px] bg-3fc69d rounded-xl text-white font-semibold p-3 hover:bg-3fc69d_hover transition-all duration-200 hover:scale-[1.03]"
            onClick={() => setProgramInfoOpen(true)}
          >
            <span className="font-normal">Amount paid:</span>{" "}
            {sessionStorage.getItem("currency") === "EUR"
              ? "€"
              : sessionStorage.getItem("currency") === "GBP"
              ? "£"
              : "$"}
            {Number(sessionStorage.getItem("amount_paid")).toFixed(2)}
          </button>
        </>
      ) : (
        <>
          {((sessionStorage.getItem("module") === "LEADS" &&
            sessionStorage.getItem("pm_name") &&
            sessionStorage.getItem("plan_id") &&
            sessionStorage.getItem("program_start_date") &&
            sessionStorage.getItem("program_end_date")) ||
            (sessionStorage.getItem("module") === "CONTACTS" &&
              sessionStorage.getItem("createOpportunitySkipped") === "Yes"
            ))
            && (
              <button
                className="absolute top-0 right-[148px] bg-3fc69d rounded-xl text-white font-semibold p-3 hover:bg-3fc69d_hover transition-all duration-200 hover:scale-[1.03]"
                onClick={() => setProgramInfoOpen(true)}
              >
                <span className="font-normal">Total program price:</span>{" "}
                {sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                  ? "£"
                  : "$"}
                {sessionStorage.getItem("tax_rate")
                  ? Number(
                      (
                        Number(sessionStorage.getItem("plan_price")) +
                        Number(
                          sessionStorage.getItem("tax_rate")
                            ? Number(
                                (Number(sessionStorage.getItem("plan_price")) *
                                  Number(sessionStorage.getItem("tax_rate"))) /
                                  100
                              )
                            : 0
                        )
                      ).toFixed(2)
                    ).toFixed(2)
                  : Number(sessionStorage.getItem("plan_price")).toFixed(2)}
              </button>
            )}
          {sessionStorage.getItem("module") === "CONTACTS" &&
            sessionStorage.getItem("opportunity_id") &&
            sessionStorage.getItem("invoice_id") && (
              <button
                className="absolute top-0 right-[148px] bg-3fc69d rounded-xl text-white font-semibold p-3 hover:bg-3fc69d_hover transition-all duration-200 hover:scale-[1.03]"
                onClick={() => setProgramInfoOpen(true)}
              >
                <span className="font-normal">Amount remaining:</span>{" "}
                {sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                  ? "£"
                  : "$"}
                {Number(sessionStorage.getItem("amount_remaining")).toFixed(2)}
              </button>
            )}
        </>
      )}
      <ProgramInfoModal
        programInfoOpen={programInfoOpen}
        setProgramInfoOpen={setProgramInfoOpen}
      />
    </>
  )
}

export default ProgramInfo
