import React from "react"
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { useHistory } from "react-router-dom"
import { logout } from "../../redux/auth/actions"
import { useDispatch } from "react-redux"
import AvatarImg from "../../assets/userProfile/avatar.svg"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

type PropsType = {
  className?: string
}

const Avatar: React.FC<PropsType> = ({ className }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={`inline-flex justify-center w-full py-2 ${className}`}
            >
              <img
                className="static h-8 w-8 bg-white rounded-[100%]"
                src={AvatarImg}
                alt=""
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white"
            >
              <div className="pb-0">
                <div className="topbar_noti your-acc px-6 py-3 flex items-center rounded-t-md relative">
                  <p className="m-0 text-white mr-3">Your Account</p>
                </div>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        history.push("/account")
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-400",
                        "block px-6 py-3 text-sm font-normal cursor-pointer"
                      )}
                    >
                      Profile
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        dispatch(logout())
                        history.push("/")
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-400",
                        "block px-6 py-3 text-sm font-normal cursor-pointer"
                      )}
                    >
                      Sign out
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default Avatar
