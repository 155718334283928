import { FC, useState, useEffect, useCallback } from "react"
import { NavLink, useLocation, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setShow, hideShow } from "./../../redux/auth/actions"
import { ReactComponent as DarkLogo } from "../../assets/sidebarIcons/darkLogo.svg"
import { ReactComponent as Dashboard } from "../../assets/sidebarIcons/dashboard.svg"
import { ReactComponent as PaymentPortal } from "../../assets/sidebarIcons/paymentportal.svg"
import { ReactComponent as EFTPayments } from "../../assets/sidebarIcons/eft-payments.svg"
import CardPayments from "../../assets/sidebarIcons/card-payments.png"
import { ReactComponent as RefundRequests } from "../../assets/sidebarIcons/refund-requests.svg"
import { ReactComponent as Admin } from "../../assets/sidebarIcons/manageAdmins.svg"
import { ReactComponent as TimeClock } from "../../assets/sidebarIcons/timeClock.svg"
import { ReactComponent as AdditionalPercent } from "../../assets/sidebarIcons/additionalPercent.svg"
import { ReactComponent as Logout } from "../../assets/sidebarIcons/logout.svg"
import { logout } from "../../redux/auth/actions"
import AvatarImg from "../../assets/userProfile/avatar.svg"
import "./sidebar.scss"

interface MyProps {
  masterAdmin: boolean
  admin: boolean
  viewStatus: string
  closeSidebar: () => void
  access: string[]
  financeManager: boolean
}

const Sidebar: FC<MyProps> = ({
  masterAdmin,
  admin,
  viewStatus,
  closeSidebar,
  access,
  financeManager,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [show, setShow1] = useState(false)
  const [hasScreenAccess, setHasScreenAccess] = useState(false)

  useEffect(() => {
    if (
      location.pathname.replace(/\/$/, "") !== "/signin" &&
      location.pathname !== "/" &&
      location.pathname.replace(/\/$/, "") !== "/forgotpassword" &&
      location.pathname.replace(/\/$/, "") !== "/createpassword" &&
      location.pathname.replace(/\/$/, "") !== "/verification" &&
      location.pathname.replace(/\/$/, "") !== "/service-status"
    ) {
      setShow1(true)
      dispatch(setShow())
    } else {
      setShow1(false)
      dispatch(hideShow())
    }
  }, [location, dispatch])

  const closeHandler = useCallback((): void => {
    closeSidebar()
    sessionStorage.clear()
  }, [closeSidebar])

  const smallHeight = window.innerHeight < 520

  useEffect(() => {
    const regions = ["AU", "CA", "NZ", "UK", "PCA"]
    const hasRegionAccess = regions.some((str) => access.includes(str))
    if (hasRegionAccess) {
      setHasScreenAccess(true)
    }
  }, [access])

  return (
    <>
      {show && (
        <div
          className={`${viewStatus} main md:block z-10 w-255 min-w-255 h-full fixed transition duration-300 ${
            smallHeight && "overflow-scroll"
          } bg-navy-theme`}
        >
          <div className="flex py-6 pl-4">
            <DarkLogo />
          </div>
          <ul className="list-none menu-text">
            <li className="list-none">
              <NavLink exact to="/account">
                <div
                  className={
                    "md:hidden flex h-10 items-center mx-2 my-px pl-3 rounded-md text-E0E0E0 hover:bg-0D2773"
                  }
                  onClick={closeHandler}
                >
                  <span className="pr-2.5">
                    <img
                      className="w-6 h-6 rounded-50%"
                      src={AvatarImg}
                      alt="profile img"
                    />
                  </span>
                  <span>Profile</span>
                </div>
              </NavLink>
            </li>
            {hasScreenAccess && (masterAdmin || admin) && (
              <li className="list-none">
                <NavLink activeClassName="active-dark" exact to="/">
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={closeHandler}
                  >
                    <div className="w-5">
                      <Dashboard />
                    </div>
                    <span className="pl-4">Dashboard</span>
                  </div>
                </NavLink>
              </li>
            )}
            {hasScreenAccess && (
              <li className="list-none">
                <NavLink
                  activeClassName="active-dark"
                  exact
                  to="/payment-portal/region-select"
                >
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={closeHandler}
                  >
                    <div className="w-5">
                      <PaymentPortal />
                    </div>
                    <span className="pl-4">Payment Portal</span>
                  </div>
                </NavLink>
              </li>
            )}
            {hasScreenAccess && (
              <li className="relative list-none">
                <NavLink activeClassName="active-dark" exact to="/eft-payments">
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={() => closeHandler()}
                  >
                    <div className="w-5">
                      <EFTPayments />
                    </div>
                    <span className="pl-4">EFT Payments</span>
                  </div>
                </NavLink>
              </li>
            )}
            {hasScreenAccess && (masterAdmin || admin) && (
              <li className="list-none">
                <NavLink
                  activeClassName="active-dark"
                  exact
                  to="/card-payments"
                >
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={closeHandler}
                  >
                    <div className="w-5">
                      <img src={CardPayments} />
                    </div>
                    <span className="pl-4">Card Payments</span>
                  </div>
                </NavLink>
              </li>
            )}

            {hasScreenAccess && (
              <li className="relative list-none">
                <NavLink
                  activeClassName="active-dark"
                  exact
                  to="/refund-requests"
                >
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={() => closeHandler()}
                  >
                    <div className="w-5">
                      <RefundRequests />
                    </div>
                    <span className="pl-4">Refund Requests</span>
                  </div>
                </NavLink>
              </li>
            )}

            {hasScreenAccess && (masterAdmin || admin || financeManager) && (
              <li className="list-none">
                <NavLink activeClassName="active-dark" exact to="/manage-users">
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={closeHandler}
                  >
                    <div className="w-5">
                      <Admin />
                    </div>
                    <span className="pl-4">Manage Users</span>
                  </div>
                </NavLink>
              </li>
            )}

            {(access.includes("TIME_CLOCK") || masterAdmin) && (
              <li className="list-none">
                <NavLink activeClassName="active-dark" exact to="/time-clock">
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={closeHandler}
                  >
                    <div className="w-5">
                      <TimeClock />
                    </div>
                    <span className="pl-4">Time Clock</span>
                  </div>
                </NavLink>
              </li>
            )}

            {(masterAdmin) && (
              <li className="list-none">
                <NavLink activeClassName="active-dark" exact to="/additional-percent">
                  <div
                    className={`flex h-10 items-center mx-2 my-px pl-3 rounded-md hover:text-F5F5F5 text-E0E0E0 hover:bg-0D2773`}
                    onClick={closeHandler}
                  >
                    <div className="w-5">
                      <AdditionalPercent />
                    </div>
                    <span className="pl-4">Additional Percent</span>
                  </div>
                </NavLink>
              </li>
            )}

            <li
              onClick={() => {
                dispatch(logout())
                history.push("/")
              }}
              className={
                "md:hidden cursor-pointer mx-2 pl-3 list-none rounded-md"
              }
            >
              <div className="flex h-10 items-center justify-between mt-14 pb-100 text-E0E0E0">
                <div className="flex items-center ">
                  <div className="w-5">
                    <Logout />
                  </div>
                  <span className="pl-4">Sign out</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default Sidebar
