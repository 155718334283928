import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { ReactComponent as LoggedInIcon } from "../../assets/common/loggedIn.svg"
import { ReactComponent as LoggedOutIcon } from "../../assets/common/loggedOut.svg"
import { ReactComponent as ViewedIcon } from "../../assets/common/view.svg"

type TrackingDataUser = {
  createdAt: string
  id: number
  link: string
  screen: string
  updatedAt: string
  user_id: string
  user: {
    first_name: string
    last_name: string
  }
}

type User = {
  id: string
  first_name: string
  last_name: string
  email: string
  mobile: string
  role: string
  country: string
  profileImage: string
  type: string
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  }
  const formattedDate = date.toLocaleString(undefined, options)

  return formattedDate
}

const UserTracking = () => {
  const [trackingData, setTrackingData] = useState<TrackingDataUser[]>([])
  const [trackingDataLoading, setTrackingDataLoading] = useState(true)

  const [user, setUser] = useState<User>({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    role: "",
    country: "",
    profileImage: "",
    type: "",
  })
  const [userLoading, setUserLoading] = useState(true)

  const params: { user_id: string } = useParams()
  const { user_id } = params

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (user.id) {
      getTracking()
    }
  }, [user])

  const getUser = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}admin/v1/user/get-by-id?id=${user_id}`)
      .then((response) => {
        setUser(response.data.data)
        setUserLoading(false)
      })
      .catch(() => {
        setUserLoading(false)
      })
  }

  const getTracking = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}admin/v1/tracking/get?user_id=${user_id}`)
      .then((response) => {
        //sort tracking data from latest to oldest
        const sortedData = response.data.data.sort((a: TrackingDataUser, b: TrackingDataUser) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })

        setTrackingData(sortedData)
        setTrackingDataLoading(false)
      })
      .catch(() => {
        setTrackingDataLoading(false)
      })
  }

  return (
    <>
      <h1 className="font-bold text-2xl pb-5">User Tracking</h1>
      {userLoading ? (
        <p>Loading information...</p>
      ) : !user.id ? (
        <p>Invalid user or inactive</p>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col sm:flex-row mb-8">
              <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg mb-8 sm:m-0">
                  <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-white">User Information</h3>
                  </div>
                  <div className="px-4 py-3">
                    <span className="font-bold">
                      Name: {user.first_name} {user.last_name}
                    </span>
                  </div>
                  <div className="px-4 py-3">
                    <span className="font-bold">Email: {user.email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="border-2 bg-white overflow-hidden shadow rounded-lg mb-8 sm:m-0">
                <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-white">Tracking Data</h3>
                </div>
                <div className="flex flex-col bg-white px-5 py-6 justify-around">
                  {trackingDataLoading ? (
                    <p>Loading tracking data...</p>
                  ) : trackingData.length === 0 ? (
                    <p>No tracking data available</p>
                  ) : (
                    <>
                      {trackingData.map((item, index) => (
                        
                        <>
                          {/* date of the current item is different from the date of the previous item, then put divider */}
                          {index === 0 || new Date(item.createdAt).toDateString() !== new Date(trackingData[index - 1].createdAt).toDateString() ? (
                            <div className={`text-lg font-bold my-2 ${index !== 0 && "mt-5"}`}>{formatDate(item.createdAt)}</div>
                          ) : null}

                          <div
                            key={item.id}
                            className={`${
                              item.screen === "user has logged in"
                                ? "bg-green-200"
                                : item.screen === "user has logged out"
                                ? "bg-red-200"
                                : "bg-white"
                            } border-2 overflow-hidden rounded-lg my-1`}
                          >
                            <div className="px-4 py-3 mx-3 flex flex-col">
                              {item.screen === "user has logged in" ? (
                                <div className="font-bold flex flex-row items-center gap-3">
                                  <LoggedInIcon className="w-5 h-5" />
                                  {new Date(item.createdAt).toLocaleTimeString()} - {item.screen} via {item.link}
                                </div>
                              ) : item.screen === "user has logged out" ? (
                                <div className="font-bold flex flex-row items-center gap-3">
                                  <LoggedOutIcon className="w-5 h-5" />
                                  {new Date(item.createdAt).toLocaleTimeString()} - {item.screen} via avatar
                                </div>
                              ) : (
                                <div className="font-bold flex flex-row items-center gap-3">
                                  <ViewedIcon className="w-5 h-5" />
                                  {new Date(item.createdAt).toLocaleTimeString()} - In {item.screen} screen via {item.link}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default UserTracking
