export const tableData = [
  {
    name: "Patient Name",
    sort: "patient_name",
  },
  {
    name: "Approval Level",
    sort: "approval_level",
  },
  {
    name: "Region",
    sort: "country",
  },
  {
    name: "Refund Type",
    sort: "refund_type",
  },
  {
    name: "Cash Value Refund",
    sort: "refund_amount",
  },
  {
    name: "Reason",
    sort: "reason",
  },
  {
    name: "View History",
    sort: "description",
  },
  {
    name: "Last actioned by",
    sort: "lastActionedBy",
  },
  {
    name: "Program Name",
    sort: "program_name",
  },
  {
    name: "Program Price",
    sort: "program_price",
  },
]
