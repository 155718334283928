import { FC, useEffect, useState } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"

interface MyProps {
  elementsPerPage: number
  totalElements: number
  paginate: (args: number) => void
  currentPage: number
  type: string
}

const Pagination: FC<MyProps> = ({
  elementsPerPage,
  totalElements,
  paginate,
  currentPage,
  type,
}) => {
  const pageNumbers: number[] = []
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(false)

  for (let i = 1; i <= Math.ceil(totalElements / elementsPerPage); i++) {
    pageNumbers.push(i)
  }

  useEffect(() => {
    if (currentPage === pageNumbers.length) {
      return setIsNextDisabled(true)
    } else {
      return setIsNextDisabled(false)
    }
  }, [currentPage, pageNumbers])

  useEffect(() => {
    if (currentPage === 1) {
      return setIsPrevDisabled(true)
    } else {
      return setIsPrevDisabled(false)
    }
  }, [currentPage])

  return (
    <div className="bg-white px-4 py-3 flex items-center sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={() => {
            paginate(currentPage - 1)
          }}
          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
            isPrevDisabled && "opacity-50 cursor-default hover:bg-white"
          }`}
          disabled={isPrevDisabled}
        >
          Previous
        </button>
        <button
          onClick={() => {
            paginate(currentPage + 1)
          }}
          className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
            isNextDisabled && "opacity-50 cursor-default hover:bg-white"
          }`}
          disabled={isNextDisabled}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => {
                paginate(currentPage - 1)
              }}
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                isPrevDisabled && "opacity-50 cursor-default hover:bg-white"
              }`}
              disabled={isPrevDisabled}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={`${number === currentPage && "bg-gray-200"} ${
                  number > currentPage + 2
                    ? "hidden"
                    : number < currentPage - 2
                    ? "hidden"
                    : null
                } list-none bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
              >
                <li>{number}</li>
              </button>
            ))}
            <button
              onClick={() => {
                paginate(currentPage + 1)
              }}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                isNextDisabled && "opacity-50 cursor-default hover:bg-white"
              }`}
              disabled={isNextDisabled}
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
        <div>
          <p className="ml-8 text-sm text-gray-700">
            <span className="font-medium">{totalElements}</span> total{" "}
            {type === "DASHBOARD"
              ? "users"
              : type === "EFT"
              ? "EFTs"
              : type === "PAYMENTS"
              ? "payments"
              : type === "REFUNDS"
              ? "requests"
              : type === "ENTRIES"
              ? "entries"
              : null}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Pagination
