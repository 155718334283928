import { useEffect, useState } from "react"
import axios from "axios"
import { sortData } from "../../helpers/sortData"
import { User } from "../../types/user"
import { handleSearch } from "../../helpers/handleSearch"
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
} from "@heroicons/react/solid"
import {
  formatDate,
  formatTime,
  formatDuration,
} from "../../helpers/timeClockFunctions"
import { Link } from "react-router-dom"
import ClockOutOvertimeModal from "./ClockOutOvertimeModal"
import ClockInModal from "./ClockInModal"
import ClockOutModal from "./ClockOutModal"
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

type Entry = {
  start_time: string
  finish_time: string
  total_time_worked: number
  duration: number
  is_running: boolean
}

const tableHeaders = [
  { sortType: "number", sortName: "index", name: "#" },
  { sortType: "string", sortName: "time_entries", name: "Time Entries" },
  { sortType: "string", sortName: "email", name: "Email" },
  { sortType: "string", sortName: "first_name", name: "First Name" },
  { sortType: "string", sortName: "last_name", name: "Last Name" },
  { sortType: "string", sortName: "type", name: "Type" },
]

const regionFeatures = ["AU", "CA", "NZ", "UK", "PCA"]

const TimeClockDashboard = ({
  masterAdmin,
  financeManager,
  feTeamManager,
  pmTeamManager,
}: {
  masterAdmin: boolean
  financeManager: boolean
  feTeamManager: boolean
  pmTeamManager: boolean
}) => {
  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  //* USER CLOCK IN/OUT
  const [isClockedIn, setIsClockedIn] = useState(false)
  const [clockInTime, setClockInTime] = useState<Date | null>(null)
  const [clockOutTime, setClockOutTime] = useState<Date | null>(null)
  const [statusMessage, setStatusMessage] = useState("")
  const [isMobile, setIsMobile] = useState(false)
  const [showMobileMessage, setShowMobileMessage] = useState(false)
  const [clockOutOvertimeModalOpen, setClockOutOvertimeModalOpen] =
    useState(false)
  const [clockInModalOpen, setClockInModalOpen] = useState(false)
  const [clockOutModalOpen, setClockOutModalOpen] = useState(false)

  // CLOCK IN
  const handleClockIn = async () => {
    if (isMobile) {
      setShowMobileMessage(true)
    } else {
      setButtonDisabled(true)

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/time-entries/start-time-entry`
        )
        .then(() => {
          setShowMobileMessage(false)
          setClockInTime(new Date())
          setIsClockedIn(true)
          setStatusMessage("You are now clocked in")
          setTimeout(() => {
            setStatusMessage("")
            setButtonDisabled(false)
          }, 4000)
        })
        .catch(async () => {
          setStatusMessage("Error has occured, please contact support")
          await setTimeout(() => {
            setStatusMessage("")
            setButtonDisabled(false)
          }, 4000)
        })

      getUserTimeCard()
    }
  }

  // CLOCK OUT
  const handleClockOut = async () => {
    if (isMobile) {
      setShowMobileMessage(true)
    } else {
      setButtonDisabled(true)

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/time-entries/finish-time-entry`
        )
        .then((res) => {
          setShowMobileMessage(false)
          setClockOutTime(new Date())
          setIsClockedIn(false)
          const totalTimeWorked = res.data.data.total_time_worked
          // If more than 12 hours
          if (totalTimeWorked > 43200) {
            setClockOutOvertimeModalOpen(true)
          }
          setStatusMessage("You are now clocked out")
          setTimeout(() => {
            setStatusMessage("")
            setButtonDisabled(false)
          }, 4000)
        })
        .catch(async () => {
          setStatusMessage("Error has occured, please contact support")
          await setTimeout(() => {
            setStatusMessage("")
            setButtonDisabled(false)
          }, 4000)
        })

      getUserTimeCard()
    }
  }

  useEffect(() => {
    const checkScreenWidth = () => {
      const isMobileScreen = window.innerWidth <= 768 // Adjust the threshold as needed
      setIsMobile(isMobileScreen)
    }

    // Initial check
    checkScreenWidth()

    // Attach event listener for responsiveness
    window.addEventListener("resize", checkScreenWidth)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth)
    }
  }, [])

  // LATEST TIME ENTRY
  const [elapsedTime, setElapsedTime] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const getLatestTimeEntry = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/time-entries/get-latest-time-entry`
    )

    const timeEntryData = data.data.data
    if (timeEntryData.is_running) {
      setIsClockedIn(true)
      setClockInTime(new Date(timeEntryData.start_time))
    }

    setButtonDisabled(false)
  }

  const calculateElapsedTime = () => {
    const startTime = clockInTime
    const endTime = new Date()
    const elapsedMilliseconds = +endTime - +startTime!
    const formattedElapsedTime = new Date(elapsedMilliseconds)
      .toISOString()
      .slice(11, 19)
    setElapsedTime(formattedElapsedTime)
  }

  useEffect(() => {
    if (isClockedIn) {
      const intervalId = setInterval(() => {
        calculateElapsedTime()
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [isClockedIn, clockInTime, clockOutTime])

  // USER TIME CARD
  const [totalTime, setTotalTime] = useState("")
  const [timeCard, setTimeCard] = useState<Entry[]>([])
  const [timeCardLoading, setTimeCardLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  const getUserTimeCard = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/time-entries/get-time-entries?skip=0`
    )

    const timeEntriesData = data.data.data
    if (timeEntriesData.count) {
      calculateTotalTime(timeEntriesData.rows)
      setTimeCard(timeEntriesData.rows)
      setErrorMessage("")
    } else {
      setErrorMessage("No time entries found.")
    }
    setTimeCardLoading(false)
  }

  const calculateTotalTime = (entries: Entry[]) => {
    const entryDurations = entries.map(
      (entry: Entry) => entry.total_time_worked
    )

    const timeWorkedInSeconds = entryDurations.reduce(
      (accumulator: number, currentValue: number) => accumulator + currentValue
    )

    setTotalTime(formatDuration(timeWorkedInSeconds))
  }

  //* MASTER ADMIN FEATURES
  // ADMIN TABLE DATA
  const [adminData, setAdminData] = useState<User[]>([])
  const [adminsLoading, setAdminsLoading] = useState(false)

  const fetchAdminData = async () => {
    setAdminsLoading(true)

    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}admin/v1/account/get`)

    const adminRegionFeatures = (userInformation?.admins_features as { value: string }[])
      .map((item) => item.value)
      .filter((item) => regionFeatures.includes(item))

    data.rows.map(
      (user: User) =>
        (user.user_access = user.admins_features
          .map((item) => item.value)
          .toString()
          .replaceAll(",", ", "))
    )

    if (feTeamManager) {
      data.rows = data.rows.filter(
        (user: User) =>
          user.type === "FRONTEND SALES" &&
          user.admins_features.some((item) =>
            adminRegionFeatures.includes(item.value)
          )
      ) 
    }

    if (pmTeamManager) {
      data.rows = data.rows.filter(
        (user: User) =>
          user.type === "PERFORMANCE MANAGER" &&
          user.admins_features.some((item) =>
            adminRegionFeatures.includes(item.value)
          )
      ) 
    }

    setAdminData(data.rows)
    setOriginalData(data.rows)
    data.rows.map((user: User, index: number) => (user.index = index + 1))
    setAdminsLoading(false)
  }

  const [clockedInCount, setClockedInCount] = useState(0)
  const [clockedInLoading, setClockedInLoading] = useState(true)

  const fetchClockedInUsers = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/time-entries/get-running`
    )

    setClockedInCount(data.data.data.length)
    setClockedInLoading(false)
  }

  //* SEARCH TABLE FUNCTION
  const [query, setQuery] = useState("")
  const [searchParam] = useState(["first_name", "last_name", "email", "type"])
  const [originalData, setOriginalData] = useState([])

  useEffect(() => {
    handleSearch(query, originalData, searchParam, setAdminData)
  }, [query])

  //* SORT BY CLICKING COLUMN HEADER
  const [order, setOrder] = useState("ASC")
  const [sortingColumn, setSortingColumn] = useState("")

  //* FETCH DATA
  useEffect(() => {
    getLatestTimeEntry()
    getUserTimeCard()

    if (masterAdmin || financeManager || feTeamManager || pmTeamManager) {
      fetchAdminData()
      fetchClockedInUsers()
    }
  }, [masterAdmin, financeManager, feTeamManager, pmTeamManager])

  const convertToAMPM = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number)
    const period = hours >= 12 && hours !== 24 ? "PM" : "AM"
    const hours12 = hours % 12 || 12

    const result = `${hours12}:${String(minutes).padStart(2, "0")}${period}`
    return result
  }

  return (
    <>
      <div className="flex flex-col gap-12">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl">Time Clock Dashboard</h1>
          <div className="flex gap-4 items-center justify-between">
            {showMobileMessage && (
              <p className="font-medium text-red-500">
                Please try again on a non-mobile device.
              </p>
            )}
            {statusMessage && <p className="font-medium">{statusMessage}</p>}
            {!isClockedIn ? (
              <>
                <button
                  type="button"
                  className={`${
                    buttonDisabled
                      ? "text-black bg-gray-300 cursor-auto"
                      : "text-white bg-FF5733 hover:bg-FF5733_hover"
                  } px-3 py-2 text-sm font-medium rounded-lg w-40`}
                  onClick={() => setClockInModalOpen(true)}
                  disabled={buttonDisabled}
                >
                  Clock In
                </button>
              </>
            ) : (
              <>
                {isClockedIn && !elapsedTime && (
                  <p className="font-medium">Fetching your hours...</p>
                )}
                {isClockedIn && elapsedTime && (
                  <p className="font-medium">
                    Clocked in for: {elapsedTime} hour(s)
                  </p>
                )}
                <button
                  type="button"
                  className={`${
                    buttonDisabled
                      ? "text-black bg-gray-300 cursor-auto"
                      : "text-white bg-FF5733 hover:bg-FF5733_hover"
                  } px-3 py-2 text-sm font-medium rounded-lg w-40`}
                  onClick={() => setClockOutModalOpen(true)}
                  disabled={buttonDisabled}
                >
                  Clock Out
                </button>
              </>
            )}
          </div>
        </div>

        <div className="flex text-center justify-around border-t border-b border-gray-300 py-4">
          <div className="flex flex-col">
            <p className="font-medium">
              Total Clock In/Out Time{" "}
              <span className="text-sm">(last 15 days)</span>
            </p>
            <p className="text-2xl font-medium">{totalTime}</p>
          </div>
        </div>

        <div className="flex justify-between gap-x-8">
          {userInformation?.time_schedules &&
          userInformation?.time_schedules.length < 1 ? (
            <div className="w-1/5">
              <p className="font-medium">My Shift Schedule</p>
              <p>No schedule data available.</p>
            </div>
          ) : (
            <>
              <div className="flex flex-col w-2/5">
                <div className="bg-navy-theme px-6 py-3 text-left text-sm font-medium text-white uppercase tracking-wider cursor-pointer w-full rounded-t-lg">
                  My Shift Schedule
                </div>
                {userInformation?.time_schedules.map((day, index) => (
                  <div
                    key={day.day}
                    className={`${
                      index === 0 ? "rounded-t-none" : ""
                    } bg-3FB1FC_hover30 mb-4 rounded-lg p-3`}
                  >
                    <div className="flex justify-center items-center gap-8">
                      <div className="bg-navy-theme text-white w-[55px] h-[38px] rounded-md flex items-center justify-center cursor-pointer">
                        <span className="font-medium">{day.day}</span>
                      </div>
                      <div className="flex flex-col w-[150px]">
                        <div className="flex items-center gap-[2px] font-medium">
                          <p>{convertToAMPM(day.start_time)}</p>
                          <span>-</span>
                          <p>{convertToAMPM(day.finish_time)}</p>
                        </div>
                        {day.allowed_overtime && (
                          <div className="flex items-center text-sm">
                            Max overtime: {day.max_overtime}hrs
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="flex justify-between gap-8 w-full">
            <div className="flex flex-col overflow-hidden w-full">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <div className="bg-navy-theme px-6 py-3 text-left text-sm font-medium text-white uppercase tracking-wider cursor-pointer w-full">
                      My Time Card
                    </div>
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr className="bg-white border">
                          <th className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                            Date
                          </th>
                          <th className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                            Start
                          </th>
                          <th className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                            End
                          </th>
                          <th className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                            Duration
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeCardLoading ? (
                          <tr>
                            <td
                              colSpan={4}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-white"
                            >
                              Loading time entries...
                            </td>
                          </tr>
                        ) : errorMessage ? (
                          <tr>
                            <td
                              colSpan={4}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-white"
                            >
                              {errorMessage}
                            </td>
                          </tr>
                        ) : (
                          timeCard.map((entry, index) => (
                            <tr
                              key={index}
                              className={`text-center ${
                                index % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }`}
                            >
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <div className="relative">
                                  <CalendarIcon className="h-4 w-4 absolute inset-1/2 transform -translate-x-[80px] -translate-y-1/2" />
                                  <p>{formatDate(entry.start_time)}</p>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <div className="relative">
                                  <ClockIcon className="h-4 w-4 absolute inset-1/2 transform -translate-x-[60px] -translate-y-1/2" />
                                  <p>{formatTime(entry.start_time)}</p>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <div className="relative">
                                  {!entry.is_running && (
                                    <ClockIcon className="h-4 w-4 absolute inset-1/2 transform -translate-x-[60px] -translate-y-1/2" />
                                  )}
                                  <p>
                                    {entry.is_running
                                      ? "-"
                                      : formatTime(entry.finish_time)}
                                  </p>
                                </div>
                              </td>
                              {Number(formatDuration(entry.total_time_worked)) >
                              10 ? (
                                <td className="text-red-500 px-6 py-4 whitespace-nowrap text-sm font-medium flex items-center justify-center gap-2">
                                  {Number(
                                    formatDuration(entry.total_time_worked)
                                  ) > 10 && (
                                    <div className="group relative">
                                      {!entry.is_running && (
                                        <ExclamationIcon className="h-5 w-5 text-red-500 absolute -top-[9px] -left-[26px]" />
                                      )}
                                      <p className="text-red-500 invisible w-[100px] bg-red-200 text-center absolute z-[1] px-0 py-[5px] rounded-md group-hover:visible -left-[140px] -top-3.5">
                                        Over 10 hrs
                                      </p>
                                    </div>
                                  )}
                                  {entry.is_running
                                    ? "-"
                                    : formatDuration(entry.total_time_worked) +
                                      " hrs"}
                                </td>
                              ) : (
                                <td className="text-gray-900 px-6 py-4 whitespace-nowrap text-sm font-medium flex items-center justify-center gap-2">
                                  <div className="relative">
                                    {!entry.is_running && (
                                      <CheckIcon className="h-4 w-4 absolute inset-1/2 transform -translate-x-[60px] -translate-y-1/2" />
                                    )}
                                    <p>
                                      {entry.is_running
                                        ? "-"
                                        : formatDuration(
                                            entry.total_time_worked
                                          ) + " hrs"}
                                    </p>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(masterAdmin || financeManager || feTeamManager || pmTeamManager) && (
          <>
            <div className="flex flex-col gap-2">
              <h2 className="text-xl mb-4">Employee Time Entries Billing</h2>
              {clockedInLoading ? (
                <p className="font-medium mb-4">Fetching clocked in users...</p>
              ) : (
                <Link
                  to="/time-clock/clocked-in"
                  target="_blank"
                  className="w-[290px] mb-4"
                >
                  <button
                    type="button"
                    className="w-[290px] inline-flex justify-center items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-FF5733 hover:bg-FF5733_hover"
                  >
                    <ClockIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                    {clockedInCount} {clockedInCount === 1 ? "user" : "users"}{" "}
                    clocked in right now
                  </button>
                </Link>
              )}
              <input
                type="text"
                className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div className="flex justify-between gap-8">
                <div className="flex flex-col overflow-hidden w-full">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200=">
                          <thead className="bg-navy-theme">
                            <tr>
                              {tableHeaders.map((header) => (
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                                  onClick={() =>
                                    sortData(
                                      header.sortType,
                                      order,
                                      adminData,
                                      header.sortName,
                                      setAdminData,
                                      setOrder,
                                      setSortingColumn
                                    )
                                  }
                                  key={header.name}
                                >
                                  <div className="flex items-center">
                                    <span className="pt-1">{header.name}</span>
                                    {sortingColumn === header.sortName ? (
                                      order === "DSC" ? (
                                        <ChevronUpIcon className={`h-6`} />
                                      ) : (
                                        <ChevronDownIcon className={`h-6`} />
                                      )
                                    ) : null}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {adminsLoading ? (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="bg-white px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                >
                                  Loading accounts...
                                </td>
                              </tr>
                            ) : (
                              adminData.map((admin, adminIdx) => (
                                <tr
                                  key={admin.email}
                                  className={
                                    adminIdx % 2 === 0
                                      ? "bg-white"
                                      : "bg-gray-50"
                                  }
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {+admin.index}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <Link
                                      to={`/time-clock/${admin.id}`}
                                      target="_blank"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "billing_name",
                                          `${admin.first_name} ${admin.last_name}`
                                        )
                                        localStorage.setItem(
                                          "billing_email",
                                          `${admin.email}`
                                        )
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-FF5733 hover:bg-FF5733_hover"
                                      >
                                        <ClockIcon
                                          className="mr-2 h-4 w-4"
                                          aria-hidden="true"
                                        />
                                        View
                                      </button>
                                    </Link>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {admin.email}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {admin.first_name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {admin.last_name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {admin.type}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ClockInModal
        isOpen={clockInModalOpen}
        setIsOpen={setClockInModalOpen}
        handleClockIn={handleClockIn}
      />
      <ClockOutModal
        isOpen={clockOutModalOpen}
        setIsOpen={setClockOutModalOpen}
        handleClockOut={handleClockOut}
      />
      <ClockOutOvertimeModal
        isOpen={clockOutOvertimeModalOpen}
        setIsOpen={setClockOutOvertimeModalOpen}
      />
    </>
  )
}

export default TimeClockDashboard
