import { useState, useEffect } from "react"
import Logo from "../../../assets/userProfile/avatar.svg"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../redux/store"
import { updateProfile } from "../../../redux/auth/actions"
import "./UserProfile.scss"

const UserProfileCard = () => {
  const [isTitleImageLoaded, setIsTitleImageLoaded] = useState(false)

  const dispatch = useDispatch()
  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )
  const fileUrl = useSelector((store: RootState) => store.userData.fileUrl)

  useEffect(() => {}, [userInformation])

  useEffect(() => {
    if (fileUrl !== "") {
      const obj = {
        profileImage: fileUrl,
      }
      dispatch(updateProfile(obj))
    }
  }, [fileUrl, dispatch])

  return (
    <>
      {userInformation != null && (
        <div className="px-4 md:px-7 py-7 x md:py-6 bg-white rounded-lg border border-E0E0E0 w-full h-full md:h-40">
          <div className="w-full flex ">
            <div className="w-full flex flex-col md:flex-row">
              <div className="flex flex-col items-center md:items-start">
                <div className="user-profile">
                  <img
                    onLoad={() => setIsTitleImageLoaded(true)}
                    className={`w-80 h-80 rounded-50% hidden ${
                      isTitleImageLoaded && "block"
                    }`}
                    src={Logo}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start pl-0 md:pl-7">
                <div className="pt-2.5 md:pt-0 font-semibold text-xl">
                  {userInformation.first_name} {userInformation.last_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserProfileCard
