import { useEffect, useState } from "react"
import Layout from "../../components/layout/Layout"
import CurrencySelectMenu from "./components/CurrencySelectMenu"
import ConfirmCurrencyModal from "./components/ConfirmCurrencyModal"

const CurrencySelect = () => {
  const currencyList = [
    { id: "GBP", value: "GBP (£)" },
    { id: "EUR", value: "EUR (€)" },
  ]

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [currencySelectedItem, setCurrencySelectedItem] =
    useState("Select currency")
  const [currencyModalOpen, setCurrencyModalOpen] = useState(false)

  useEffect(() => {
    setCurrencyModalOpen(false)
    setCurrencySelectedItem("Select currency")
    sessionStorage.removeItem("currency")
  }, [])

  const handleProceed = () => setCurrencyModalOpen(true)

  return (
    <Layout
      pageNumber={5}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="currency"
          className="block text-sm font-medium text-gray-700"
        >
          Currency
        </label>
        <CurrencySelectMenu
          currencyList={currencyList}
          currencySelectedItem={currencySelectedItem}
          setCurrencySelectedItem={setCurrencySelectedItem}
          setNextButtonDisabled={setNextButtonDisabled}
        />
        <ConfirmCurrencyModal
          open={currencyModalOpen}
          setOpen={setCurrencyModalOpen}
        />
      </div>
    </Layout>
  )
}

export default CurrencySelect
