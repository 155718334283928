import { FC } from "react"

interface PropTypes {
  pageNumber: number
}

const ProgressBar: FC<PropTypes> = ({ pageNumber }) => {
  const region = []
  for (let i = 0; i < 8; i++) {
    region.push(
      <div
        key={i}
        className={`w-1 h-1 ${
          pageNumber > 1 ? "bg-3fc69d" : "bg-c4c4c4"
        } rounded-2xl mx-1.6 mt-2`}
      />
    )
  }

  const details = []
  for (let i = 0; i < 8; i++) {
    details.push(
      <div
        key={i}
        className={`w-1 h-1 ${
          pageNumber > 2 ? "bg-3fc69d" : "bg-c4c4c4"
        } rounded-2xl mx-1.6 mt-2`}
      />
    )
  }

  const type = []
  for (let i = 0; i < 8; i++) {
    type.push(
      <div
        key={i}
        className={`w-1 h-1 ${
          pageNumber > 7 ? "bg-3fc69d" : "bg-c4c4c4"
        } rounded-2xl mx-1.6 mt-2`}
      />
    )
  }

  return (
    <div className="hidden sm:flex items-center mx-auto">
      {/* Select Region */}
      <div className="w-4 h-4 bg-3fc69d rounded-2xl mx-1.6 mt-2" />
      {region}

      {/* Customer Details */}
      <div
        className={`w-4 h-4 ${
          +pageNumber > 1 ? "bg-3fc69d" : "bg-c4c4c4"
        } rounded-2xl mx-1.6 mt-2`}
      />
      {details}

      {/* Transaction Type */}
      <div
        className={`w-4 h-4 ${
          pageNumber > 2 ? "bg-3fc69d" : "bg-c4c4c4"
        } rounded-2xl mx-1.6 mt-2`}
      />
      {type}

      {/* Card Details */}
      <div
        className={`w-4 h-4 ${
          pageNumber > 7 ? "bg-3fc69d" : "bg-c4c4c4"
        } rounded-2xl mx-1.6 mt-2`}
      />
    </div>
  )
}

export default ProgressBar
