import { FC, useEffect, useState } from "react"
import ApprovalModal from "./modal/ApprovalModal"
import Pagination from "../common/Pagination"
import { sortData } from "../../helpers/sortData"
import { handleSearch } from "../../helpers/handleSearch"
import { tableData } from "./tabledata/tabledata"
import Requests from "./requests/Requests"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

interface PropTypes {
  refundRequestsData: RefundRequest[]
  setRefundRequestsData: (args: RefundRequest[]) => void
  refundRequestsOriginalData: RefundRequest[]
  setRefundRequestsOriginalData: (args: RefundRequest[]) => void
  getRefundRequests: () => void
  masterAdmin: boolean
  currentPage: number
  setCurrentPage: (args: number) => void
}

type RefundRequest = {
  description: {
    description: string
    timestamp: string
    actioned_by: string
  }[]
  id: number
  country: string
  contact_id: string
  opportunity_id: string
  reason: string
  program_price: number
  program_name: string
  patient_name: string
  refund_type: string
  last_actioned_id: string
  file_url: string
  refund_amount: number
  gross_refund_amount: number
  approval_level: string
  status: boolean
  lastActionedBy: {
    first_name: string
    last_name: string
  }
}

const RefundRequests: FC<PropTypes> = ({
  refundRequestsData,
  setRefundRequestsData,
  refundRequestsOriginalData,
  getRefundRequests,
  masterAdmin,
  currentPage,
  setCurrentPage,
}) => {
  const [modalData, setModalData] = useState<RefundRequest>({} as RefundRequest)

  const [approvalModalOpen, setApprovalModalOpen] = useState(false)
  const [approvalModalType, setApprovalModalType] = useState("APPROVE")
  const [level, setLevel] = useState("")

  /* PAGINATION */
  const [requestsPerPage] = useState(50)

  const indexOfLastRequest = currentPage * requestsPerPage
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage
  const currentRequests = refundRequestsData?.slice(
    indexOfFirstRequest,
    indexOfLastRequest
  )

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  // SEARCH TABLE FUNCTION
  const [query, setQuery] = useState("")
  const [searchParam] = useState(["patient_name", "country", "approval_level"])

  useEffect(() => {
    handleSearch(
      query,
      refundRequestsOriginalData,
      searchParam,
      setRefundRequestsData
    )
  }, [query])

  /* SORT BY CLICKING COLUMN HEADER */
  const [order, setOrder] = useState("ASC")
  const [sortingColumn, setSortingColumn] = useState("")

  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  const handleLevel = () => {
    const stringArr: string[] = []
    const levelStringArr: string[] = []
    const valuesArr = userInformation.admins_features.map(
      (item: { value: string }) => item.value
    )

    for (const val of valuesArr) {
      if (val === "LEVEL_1") {
        stringArr.push("Level 1")
        levelStringArr.push("LEVEL_1")
      }
      if (val === "LEVEL_2") {
        stringArr.push("Level 2")
        levelStringArr.push("LEVEL_2")
      }
      if (val === "LEVEL_3") {
        stringArr.push("Level 3")
        levelStringArr.push("LEVEL_3")
      }
    }

    setLevel(stringArr.toString().replaceAll(",", ", "))
  }

  useEffect(() => {
    handleLevel()
  }, [userInformation])

  return (
    <div className="overflow-x-hidden">
      <ApprovalModal
        open={approvalModalOpen}
        setOpen={setApprovalModalOpen}
        type={approvalModalType}
        data={modalData}
        getRefundRequests={getRefundRequests}
      />

      <input
        type="text"
        className="shadow-sm block sm:text-sm border-gray-300 rounded-md mb-4"
        placeholder="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <p className="mt-1 mb-4 max-w-2xl text-sm text-black">
        Refund level:{" "}
        <span className="text-FF5733 font-semibold">
          {level ? level : "No levels assigned"}
        </span>
      </p>

      <div className="h-[60vh] flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-navy-theme">
                  <tr>
                    <th
                      scope="col"
                      className="text-center px-6 py-3 text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                    >
                      Action
                    </th>
                    {tableData.map((column, index) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                        onClick={() => {
                          // Check which columns include numbers for sorting (ie. Payment Amount, Date Submitted, etc.)
                          if (
                            index === 3 ||
                            index === 5 ||
                            index === 7 ||
                            index === 9
                          ) {
                            sortData(
                              "number",
                              order,
                              refundRequestsData,
                              column.sort,
                              setRefundRequestsData,
                              setOrder,
                              setSortingColumn
                            )
                          } else {
                            sortData(
                              "string",
                              order,
                              refundRequestsData,
                              column.sort,
                              setRefundRequestsData,
                              setOrder,
                              setSortingColumn
                            )
                          }
                        }}
                        key={index}
                      >
                        <div className="flex items-center">
                          <div>{column.name}</div>
                          {sortingColumn === column.sort ? (
                            order === "DSC" ? (
                              <ChevronUpIcon
                                className={`${
                                  index === 2 || index === 3 ? "h-8" : "h-5"
                                }`}
                              />
                            ) : (
                              <ChevronDownIcon
                                className={`${
                                  index === 2 || index === 3 ? "h-8" : "h-5"
                                }`}
                              />
                            )
                          ) : null}
                        </div>
                      </th>
                    ))}
                    {masterAdmin && (
                      <th
                        scope="col"
                        className="text-center px-6 py-3 text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      >
                        Delete
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <Requests
                    requests={currentRequests}
                    setModalData={setModalData}
                    setApprovalModalOpen={setApprovalModalOpen}
                    setApprovalModalType={setApprovalModalType}
                    masterAdmin={masterAdmin}
                    setRefundRequestsData={setRefundRequestsData}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        elementsPerPage={requestsPerPage}
        totalElements={refundRequestsData?.length}
        paginate={paginate}
        currentPage={currentPage}
        type="REFUNDS"
      />
    </div>
  )
}

export default RefundRequests
