import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { ReactComponent as Check } from "../../../../../assets/common/check.svg"
import { DocumentDuplicateIcon, CheckIcon } from "@heroicons/react/outline"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import html2canvas from "html2canvas"
import "../../../../../styles/fixstyles.css"

function PaymentSuccess() {
  const history = useHistory()
  const region = sessionStorage.getItem("country")
  const invoiceStatus = sessionStorage.getItem("invoice_status")
  const [invoiceSuccess, setInvoiceSuccess] = useState("")
  const [isEFTPayment, setIsEFTPayment] = useState(false)
  const [isPaymentLink, setIsPaymentLink] = useState(false)

  const leadConversionStatus = sessionStorage.getItem("lead_conversion_status")
  const [leadConversionSuccess, setLeadConversionSuccess] = useState<
    boolean | null
  >(null)

  const handleReturn = () => {
    sessionStorage.clear()
    history.push("/payment-portal/region-select")
  }

  useEffect(() => {
    invoiceStatus === "SUCCESS"
      ? setInvoiceSuccess("SUCCESS")
      : invoiceStatus === "ERROR"
      ? setInvoiceSuccess("ERROR")
      : setInvoiceSuccess("")
  }, [invoiceSuccess])

  useEffect(() => {
    leadConversionStatus === "SUCCESS"
      ? setLeadConversionSuccess(true)
      : setLeadConversionSuccess(false)
  }, [leadConversionSuccess])

  useEffect(() => {
    if (sessionStorage.getItem("eft_payment")) {
      setIsEFTPayment(true)
    }
    if (sessionStorage.getItem("paymentLink")) {
      setIsPaymentLink(true)
    }
  }, [])

  const [loadingScreenshot, setLoadingScreenshot] = useState(false)
  const [screenshotCopied, setScreenshotCopied] = useState(false)
  const [paymentLinkCopied, setPaymentLinkCopied] = useState(false)

  const handleScreenshot = () => {
    takeScreenshot()
    setLoadingScreenshot(true)
    setScreenshotCopied(false)
    setTimeout(() => {
      setLoadingScreenshot(false)
      setScreenshotCopied(true)
    }, 500)
  }

  const takeScreenshot = () => {
    html2canvas(document.getElementById("capture") as HTMLElement).then(
      (canvas) => {
        canvas.toBlob((blob) => {
          navigator.clipboard.write([
            new ClipboardItem(
              Object.defineProperty({}, blob!.type, {
                value: blob,
                enumerable: true,
              })
            ),
          ])
        })
      }
    )
  }

  return (
    <>
      <div id="capture" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            {isEFTPayment ? (
              <div className="text-3fc69d text-3xl sm:text-4xl font-semibold my-8">
                EFT Payment Sent
              </div>
            ) : isPaymentLink ? (
              <div className="text-3fc69d text-3xl sm:text-4xl font-semibold my-8">
                Payment Link Created
              </div>
            ) : (
              <div className="text-3fc69d text-3xl sm:text-4xl font-semibold my-8">
                Payment Successful
              </div>
            )}

            <Check />
            {isEFTPayment ? (
              <div className="px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
                Congrats team! The EFT payment has been successfully sent to
                accounts for approval!
              </div>
            ) : isPaymentLink ? (
              <div className="px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
                Congrats team! The Payment Link has been generated, you can now 
                share it to the patient to proceed with the next steps!
              </div>
            ) : (
              <div className="px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
                Congrats team! We just helped another man take their first steps
                to restoring confidence!
              </div>
            )}

            {/* INVOICE */}
            {invoiceSuccess === "SUCCESS" && !isEFTPayment && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center max-w-421 mt-4">
                You&apos;ve been sent a payment confirmation, and the patient
                has been sent an invoice receipt.
              </div>
            )}
            {invoiceSuccess === "ERROR" &&
              !isEFTPayment &&
              sessionStorage.getItem("module") === "CONTACTS" && (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative text-center max-w-421 mt-4">
                  <span className="block sm:inline">
                    Payment successful but invoice not sent:{" "}
                    {sessionStorage.getItem("invoice_message")}
                  </span>
                </div>
              )}

            {/* LEAD CONVERSION */}
            {
              <>
                {leadConversionSuccess &&
                  !isEFTPayment &&
                  !isPaymentLink &&
                  sessionStorage.getItem("module") === "LEADS" && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center max-w-421 mt-4">
                      <span className="block sm:inline">
                        Lead has been successfully converted into a Contact!
                      </span>
                    </div>
                  )}
                {!leadConversionSuccess &&
                  !isEFTPayment &&
                  !isPaymentLink &&
                  sessionStorage.getItem("module") === "LEADS" && (
                    <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative text-center max-w-480 mt-4">
                      <span className="block sm:inline">
                        Payment was successful, but there was an error during
                        lead conversion:{" "}
                        <span className="font-semibold">
                          {sessionStorage.getItem("lead_conversion_error")}
                        </span>
                      </span>
                    </div>
                  )}
              </>
            }

            <div className="w-full sm:w-full mt-7 mb-2">
              <dl>
                <div className="bg-c4c4c4-20 px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    Payment Amount
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("currency") === "EUR"
                      ? "€"
                      : sessionStorage.getItem("currency") === "GBP"
                      ? "£"
                      : "$"}
                    {(sessionStorage.getItem("country") === "PCA" && (sessionStorage.getItem("isScheduledDeposit") === "Yes" || sessionStorage.getItem("isRecurring") === "Yes")) || 
                      (sessionStorage.getItem("province") === "Quebec")
                      ? Number(
                          sessionStorage.getItem("initial_deposit")
                        ).toFixed(2)
                      : Number(sessionStorage.getItem("amount")).toFixed(
                          2
                        )}{" "}
                    {sessionStorage.getItem("country") === "PCA" &&
                      sessionStorage.getItem("isInsurance") === "Yes" && (
                        <span>(Insurance)</span>
                      )}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    Transaction Type
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("transaction_type")}
                  </dd>
                </div>
                <div className="bg-c4c4c4-20 px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    {sessionStorage.getItem("module") === "LEADS"
                      ? "Lead"
                      : "Contact"}{" "}
                    ID
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("id")}
                  </dd>
                </div>
                {sessionStorage.getItem("module") === "LEADS" && (
                  <div className="px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Lead Source
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("lead_source") || "N/A"}
                    </dd>
                  </div>
                )}
                {isPaymentLink && (
                  <div className="px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Payment Link
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-2 font-semibold break-all">
                      {sessionStorage.getItem("paymentLink")}
                    </dd>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0">
                      <div className="h-6 w-6 cursor-pointer" 
                        onClick = {() => {
                          navigator.clipboard.writeText(sessionStorage.getItem("paymentLink") ?? "")
                          setPaymentLinkCopied(true)
                          setTimeout(() => {
                            setPaymentLinkCopied(false)
                          }, 2000)
                        }}
                      >
                        {paymentLinkCopied ? <CheckIcon className="stroke-navy-theme"/> : <DocumentDuplicateIcon className="stroke-navy-theme"/>}
                      </div>
                    </dd>
                  </div>
                )}
              </dl>
            </div>

            {/* AU */}
            {region === "AU" && leadConversionSuccess && (
              <>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org52657875/tab/Contacts/${sessionStorage.getItem(
                      "contact_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Contact
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org52657875/tab/Potentials/${sessionStorage.getItem(
                      "opportunity_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Opportunity
                    </button>
                  </a>
                </div>
              </>
            )}
            {region === "AU" && !leadConversionSuccess && (
              <>
                {sessionStorage.getItem("module") === "LEADS" &&
                  !isEFTPayment && !isPaymentLink && (
                    <div>
                      <a
                        href={`https://crm.zoho.com/crm/org52657875/tab/Leads/${sessionStorage.getItem(
                          "id"
                        )}/convert?showPortalChange=false`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                        >
                          Convert Lead
                        </button>
                      </a>
                    </div>
                  )}
                {sessionStorage.getItem("module") === "CONTACTS" && (
                  <div>
                    <a
                      href={`https://crm.zoho.com/crm/org52657875/tab/Potentials/${sessionStorage.getItem(
                        "opportunity_id"
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        type="button"
                        className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                      >
                        View Opportunity
                      </button>
                    </a>
                  </div>
                )}
              </>
            )}

            {/* CA */}
            {region === "CA" && leadConversionSuccess && (
              <>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org760139034/tab/Contacts/${sessionStorage.getItem(
                      "contact_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Contact
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org760139034/tab/Potentials/${sessionStorage.getItem(
                      "opportunity_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Opportunity
                    </button>
                  </a>
                </div>
              </>
            )}
            {region === "CA" && !leadConversionSuccess && (
              <>
                {sessionStorage.getItem("module") === "LEADS" &&
                  !isEFTPayment && (
                    <div>
                      <a
                        href={`https://crm.zoho.com/crm/org760139034/tab/Leads/${sessionStorage.getItem(
                          "id"
                        )}/convert?showPortalChange=false`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                        >
                          Convert Lead
                        </button>
                      </a>
                    </div>
                  )}
                {sessionStorage.getItem("module") === "CONTACTS" && (
                  <div>
                    <a
                      href={`https://crm.zoho.com/crm/org760139034/tab/Potentials/${sessionStorage.getItem(
                        "opportunity_id"
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        type="button"
                        className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                      >
                        View Opportunity
                      </button>
                    </a>
                  </div>
                )}
              </>
            )}

            {/* NZ */}
            {region === "NZ" && leadConversionSuccess && (
              <>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org771947106/tab/Contacts/${sessionStorage.getItem(
                      "contact_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Contact
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org771947106/tab/Potentials/${sessionStorage.getItem(
                      "opportunity_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Opportunity
                    </button>
                  </a>
                </div>
              </>
            )}
            {region === "NZ" && !leadConversionSuccess && (
              <>
                {sessionStorage.getItem("module") === "LEADS" &&
                  !isEFTPayment && (
                    <div>
                      <a
                        href={`https://crm.zoho.com/crm/org771947106/tab/Leads/${sessionStorage.getItem(
                          "id"
                        )}/convert?showPortalChange=false`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                        >
                          Convert Lead
                        </button>
                      </a>
                    </div>
                  )}
                {sessionStorage.getItem("module") === "CONTACTS" && (
                  <div>
                    <a
                      href={`https://crm.zoho.com/crm/org771947106/tab/Potentials/${sessionStorage.getItem(
                        "opportunity_id"
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        type="button"
                        className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                      >
                        View Opportunity
                      </button>
                    </a>
                  </div>
                )}
              </>
            )}

            {/* UK */}
            {region === "UK" && leadConversionSuccess && (
              <>
                <div>
                  <a
                    href={`https://crm.zoho.eu/crm/org20067563176/tab/Contacts/${sessionStorage.getItem(
                      "contact_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Contact
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://crm.zoho.eu/crm/org20067563176/tab/Potentials/${sessionStorage.getItem(
                      "opportunity_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Opportunity
                    </button>
                  </a>
                </div>
              </>
            )}
            {region === "UK" && !leadConversionSuccess && (
              <>
                {sessionStorage.getItem("module") === "LEADS" &&
                  !isEFTPayment && (
                    <div>
                      <a
                        href={`https://crm.zoho.eu/crm/org20067563176/tab/Leads/${sessionStorage.getItem(
                          "id"
                        )}/convert?showPortalChange=false`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                        >
                          Convert Lead
                        </button>
                      </a>
                    </div>
                  )}
                {sessionStorage.getItem("module") === "CONTACTS" && (
                  <div>
                    <a
                      href={`https://crm.zoho.eu/crm/org20067563176/tab/Potentials/${sessionStorage.getItem(
                        "opportunity_id"
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        type="button"
                        className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                      >
                        View Opportunity
                      </button>
                    </a>
                  </div>
                )}
              </>
            )}

            {/* PCA */}
            {region === "PCA" && leadConversionSuccess && (
              <>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org803222882/tab/Contacts/${sessionStorage.getItem(
                      "contact_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Contact
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://crm.zoho.com/crm/org803222882/tab/Potentials/${sessionStorage.getItem(
                      "opportunity_id"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    >
                      View Opportunity
                    </button>
                  </a>
                </div>
              </>
            )}
            {region === "PCA" && !leadConversionSuccess && (
              <>
                {sessionStorage.getItem("module") === "LEADS" &&
                  !isEFTPayment && (
                    <div>
                      <a
                        href={`https://crm.zoho.com/crm/org803222882/tab/Leads/${sessionStorage.getItem(
                          "id"
                        )}/convert?showPortalChange=false`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                        >
                          Convert Lead
                        </button>
                      </a>
                    </div>
                  )}
                {sessionStorage.getItem("module") === "CONTACTS" && (
                  <div>
                    <a
                      href={`https://crm.zoho.com/crm/org803222882/tab/Potentials/${sessionStorage.getItem(
                        "opportunity_id"
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        type="button"
                        className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                      >
                        View Opportunity
                      </button>
                    </a>
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="px-4 my-6 text-white bg-FF5733 w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 hover:bg-FF5733_hover"
                onClick={() => handleReturn()}
              >
                Make Another Payment
              </button>
              {loadingScreenshot ? (
                <div className="flex items-center justify-center px-4 mb-6 bg-E0E0E0 text-828282 w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0">
                  <img className="w-12 h-12" src={spinnerBlack} alt="" />
                </div>
              ) : (
                <button
                  type="button"
                  className="px-4 mb-6 text-white bg-FF5733 w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 hover:bg-FF5733_hover"
                  onClick={handleScreenshot}
                >
                  Take a Screenshot
                </button>
              )}
              {screenshotCopied && (
                <p className="font-semibold text-green-600 w-72 text-center mb-6">
                  Copied to clipboard! You may now paste your screenshot in
                  Slack.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentSuccess
