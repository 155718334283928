export const tableData = [
  {
    name: "#",
    sort: "index"
  },
  {
    name: "Transaction ID",
    sort: "Name",
  },
  {
    name: "First Name",
    sort: "First_Name",
  },
  {
    name: "Last Name",
    sort: "Last_Name",
  },
  {
    name: "Email",
    sort: "Email",
  },
  {
    name: "Status",
    sort: "Status",
  },
  {
    name: "Last 4 Digit",
    sort: "Last_4_Digit",
  },
  {
    name: "Terminal",
    sort: "Terminal",
  },
  {
    name: "Transaction Type",
    sort: "Transaction_Type",
  },
  {
    name: "Amount Received",
    sort: "Amount_Received",
  },
  {
    name: "Payment Date",
    sort: "Payment_Date",
  },
];
