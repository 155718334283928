import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import UpdateCardConfirmationModal from "./components/UpdateCardConfirmationModal"
import AdditionalPercentModal from "../../components/additional-percent-modal/AdditionalPercentModal"
import ExpressPaymentModal from "../../components/express-payment-modal/ExpressPaymentModal"
import "@stripe/stripe-js"
import { Stripe, loadStripe } from "@stripe/stripe-js"
import { ReactComponent as Visa } from "../../../../assets/paymentCollection/visa.svg"
import { ReactComponent as Mastercard } from "../../../../assets/paymentCollection/mastercard.svg"
import { ReactComponent as Amex } from "../../../../assets/paymentCollection/amex.svg"
import { ReactComponent as Discover } from "../../../../assets/paymentCollection/discover.svg"
import { ReactComponent as Calendar } from "../../../../assets/common/calendar.svg"
import { ReactComponent as CVV } from "../../../../assets/common/lockIcon.svg"
import axios from "axios"

function UpdateCard() {
  // STRIPE JS //
  const [stripe, setStripe] = useState<Stripe | null>()
  const initializeStripe = async () => {
    if (process.env.NODE_ENV === "production") {
      await loadStripe(
        "pk_live_51Km48rGa6h2jnoFCtF0ifGbKtOLsF355FGMTQJDMlthGscRMPslI3MEIsEB2jfFSEFeT0kwmaJJoz3Ku5ehQ3j5M00Zz7fOsyu"
      ).then((res) => setStripe(res))
    } else if (process.env.NODE_ENV === "development") {
      await loadStripe(
        "pk_test_51Km48rGa6h2jnoFCnxVdHSMUKIBtgBFsjhBLxBRLlu4jTSUlYwiamvv15ReXTT0a6eLZEWlZ5A5buxE8NMLbGn7D00drmO5Kug"
      ).then((res) => setStripe(res))
    }
  }

  useEffect(() => {
    initializeStripe()
  }, [])

  useEffect(() => {
    const removeKeys = [
      "card_number",
      "card_month",
      "card_year",
      "card_cvc",
      "amount",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
    setUpdated(false)
    setUpdateCardConfirmationModalOpen(false)
    setCardName("")
    setCardNumber("")
    setCardMonth("")
    setCardYear("")
    setCardCVC("")
    setCardAmount("")
  }, [])

  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [cardName, setCardName] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [cardMonth, setCardMonth] = useState("")
  const [cardYear, setCardYear] = useState("")
  const [cardCVC, setCardCVC] = useState("")
  const [cardAmount, setCardAmount] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [cardError, setCardError] = useState(false)
  const [cardErrorName, setCardErrorName] = useState("")
  const [amountError, setAmountError] = useState(false)
  const [amountErrorName, setAmountErrorName] = useState("")
  const [updated, setUpdated] = useState(false)
  const [updateCardConfirmationModalOpen, setUpdateCardConfirmationModalOpen] =
    useState(false)
  const history = useHistory()

  const [isNoAddCharge, setIsNoAddCharge] = useState(false)

  const createOpportunitySkipped =
    sessionStorage.getItem("createOpportunitySkipped") === "Yes"

  const isPlanAddPercent =
    Boolean(sessionStorage.getItem("plan_add_percent")) && ((sessionStorage.getItem("country") === "PCA" && sessionStorage.getItem("module") === "LEADS") 
      ? !(sessionStorage.getItem("isFullPayment") === "Yes" || sessionStorage.getItem("isFullPayment") !== "Yes" && Number(cardAmount) >= Number(sessionStorage.getItem("total_program_price")))
      : Number(cardAmount) < Number(sessionStorage.getItem("total_program_price")))
  const [additionalPercentModalOpen, setAdditionalPercentModalOpen] = useState(false)

  const isExpressPaymentConfirmation =
    !createOpportunitySkipped &&
    sessionStorage.getItem("opportunity_add_percent") !== "" &&
    sessionStorage.getItem("transaction_type") === "Collection PM - Express Payment" &&
    sessionStorage.getItem("module") === "CONTACTS"
  const [expressPaymentModalOpen, setExpressPaymentModalOpen] = useState(false)

  const amountRemaining = Number(sessionStorage.getItem("amount_remaining")).toFixed(2);
  const opportunityAddPercent = Number(sessionStorage.getItem("opportunity_add_percent")).toFixed(2);
  const discountAmount = Number(Number(amountRemaining) * Number(opportunityAddPercent) / 100).toFixed(2);
  const discountedBalance = Number((Number(amountRemaining) - Number(discountAmount)).toFixed(2));

  const validateCard = (value: string) => {
    if (/[^0-9-\s]+/.test(value)) return false

    let nCheck = 0,
      bEven = false
    value = value.replace(/\D/g, "")

    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n)
      let nDigit = parseInt(cDigit, 10)

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9
      }

      nCheck += nDigit
      bEven = !bEven
    }

    return nCheck % 10 == 0
  }

  const validateNumber = (value: string) => {
    if (/[^0-9-\s]+/.test(value)) {
      return false
    } else {
      return true
    }
  }

  const handleProceed = async () => {
    if (cardName === "") {
      setCardError(true)
      setCardErrorName("Please enter cardholder name")
      return
    } else if (!validateCard(cardNumber) || cardNumber === "") {
      setCardError(true)
      setCardErrorName("Please enter valid card number")
      return
    } else if (
      !validateNumber(cardMonth) ||
      !validateNumber(cardYear) ||
      cardMonth === "" ||
      cardYear === ""
    ) {
      setCardError(true)
      setCardErrorName("Please enter valid expiration date")
      return
    } else if (!validateNumber(cardCVC) || cardCVC === "") {
      setCardError(true)
      setCardErrorName("Please enter valid CVV")
      return
    } else {
      setCardError(false)
      setCardErrorName("")
      sessionStorage.setItem("card_name", cardName)
      sessionStorage.setItem("card_number", cardNumber)
      sessionStorage.setItem("card_month", cardMonth)
      sessionStorage.setItem("card_year", cardYear)
      sessionStorage.setItem("card_cvc", cardCVC)

      if (updated) {
        let remainingBalance = Number(sessionStorage.getItem("amount_remaining"))
        if (createOpportunitySkipped) {
          remainingBalance = Number(sessionStorage.getItem("total_program_price"))
        }

        if (
          Number(cardAmount) > remainingBalance
        ) {
          setIsProcessing(false)
          setAmountError(true)
          if (createOpportunitySkipped) {
            setAmountErrorName("Cannot process amount higher than program price")
          } else {
            setAmountErrorName("Cannot process amount higher than remaining balance")
          }
        } else {
          setIsProcessing(false)
          setAmountError(false)
          sessionStorage.setItem("amount", cardAmount)

          if (isPlanAddPercent && !isNoAddCharge) {
            sessionStorage.setItem("isPlanAddPercent", "Yes")
            sessionStorage.setItem("isDiscount", "No")
            setAdditionalPercentModalOpen(true)
          } else if (isExpressPaymentConfirmation && Number(cardAmount) === discountedBalance) {
            sessionStorage.setItem("isPlanAddPercent", "No")
            sessionStorage.setItem("isDiscount", "Yes")
            setExpressPaymentModalOpen(true)
          } else {
            sessionStorage.setItem("isPlanAddPercent", "No")
            sessionStorage.setItem("isDiscount", "No")
            setUpdateCardConfirmationModalOpen(true)
          }
        }
      } else if (sessionStorage.getItem("last_four_digits") === "undefined") {
        setIsProcessing(true)
        setNextButtonDisabled(true)
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/create-customer-contact`,
            {
              country: sessionStorage.getItem("country"),
              note: sessionStorage.getItem("note") ? sessionStorage.getItem("note") : "None",
              email: sessionStorage.getItem("email"),
              card_name: cardName,
              card_number: cardNumber,
              card_month: cardMonth,
              card_year: cardYear,
              card_cvc: cardCVC,
              module: sessionStorage.getItem("module"),
              contact_id: sessionStorage.getItem("id"),
              opportunity_id: sessionStorage.getItem("opportunity_id"),
            }
          )
          .then((res) => {
            if (res.status === 200) {
              if (
                !res.data.data.paymentOption_CBA &&
                !res.data.data.paymentOption_Stripe
              ) {
                sessionStorage.setItem(
                  "error",
                  "Card is invalid. Please try a different card."
                )
                history.push("/payment-error")
              } else {
                setUpdated(true)
                setIsProcessing(false)
              }
            }
          })
          .catch((err) => {
            sessionStorage.setItem(
              "error",
              err.response.data.data || err.response.data.message
            )
            history.push("/payment-error")
          })

        setIsProcessing(false)
        setNextButtonDisabled(false)
      } else {
        setIsProcessing(true)
        setNextButtonDisabled(true)

        await axios
          .post(
            `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/update-payment-method`,
            {
              country: sessionStorage.getItem("country"),
              email: sessionStorage.getItem("email"),
              card_name: cardName,
              card_number: cardNumber,
              card_month: cardMonth,
              card_year: cardYear,
              card_cvc: cardCVC,
              module: sessionStorage.getItem("module"),
              contact_id: sessionStorage.getItem("id"),
              opportunity_id: sessionStorage.getItem("opportunity_id"),
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setUpdated(true)
              setIsProcessing(false)
            }
          })
          .catch((err) => {
            sessionStorage.setItem(
              "error",
              err.response.data.data || err.response.data.message
            )
            history.push("/payment-error")
          })

        setIsProcessing(false)
        setNextButtonDisabled(false)
      }
    }
  }

  return (
    <Layout
      pageNumber={10}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
      updateCardProcessing={isProcessing}
    >
      <UpdateCardConfirmationModal
        updateCardConfirmationModalOpen={updateCardConfirmationModalOpen}
        setUpdateCardConfirmationModalOpen={setUpdateCardConfirmationModalOpen}
        cardName={sessionStorage.getItem("card_name")}
        cardNumber={sessionStorage.getItem("card_number")}
        cardMonth={sessionStorage.getItem("card_month")}
        cardYear={sessionStorage.getItem("card_year")}
        cardCVC={sessionStorage.getItem("card_cvc")}
        cardAmount={cardAmount}
        stripe={stripe}
      />

      <AdditionalPercentModal
        additionalPercentModalOpen={additionalPercentModalOpen}
        setAdditionalPercentModalOpen={setAdditionalPercentModalOpen}
        nextModalOpen={setUpdateCardConfirmationModalOpen}
      />

      <ExpressPaymentModal
        expressPaymentModalOpen={expressPaymentModalOpen}
        setExpressPaymentModalOpen={setExpressPaymentModalOpen}
        nextModalOpen={setUpdateCardConfirmationModalOpen}
      />

      <div className="w-full h-full flex flex-col justify-evenly items-center">
        <div className="sm:text-left block text-xl font-bold text-gray-700 mb-8">
          Update payment method
        </div>

        {!updated ? (
          <div className="px-16">
            <div className="px-4 sm:px-0">
              <div className="block text-sm font-medium text-gray-700">
                Credit/Debit Card
              </div>
              <div className="flex items-center">
                <Visa className="mr-3.5" />
                <Mastercard className="mr-3.5" />
                <Amex className="mr-3.5" />
                <Discover />
              </div>
            </div>
            <div className="mt-5 px-4 sm:px-0">
              <div className="block text-sm font-medium text-gray-700">
                Cardholder&apos;s Name
              </div>
              <input
                type="text"
                className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                placeholder="First Name & Last Name"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
              />
            </div>
            <div className="mt-5 px-4 sm:px-0">
              <div className="block text-sm font-medium text-gray-700">
                Card Number
              </div>
              <input
                type="text"
                className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                maxLength={19}
              />
            </div>
            <div className="mt-5">
              <div className="flex flex-wrap sm:flex-nowrap px-4 sm:px-0">
                <div className="relative mr-6 sm:mr-7 sm:px-0">
                  <div className="block text-sm font-medium text-gray-700">
                    Expiration Date
                  </div>
                  <div className="pl-9 mt-2 rounded-md border-c4c4c4 w-32 h-12 bg-E0E0E0 flex items-center text-828282 border border-transparent focus:outline-none focus:ring-0">
                    <input
                      type="text"
                      className="bg-E0E0E0 px-0 py-1 w-7 border-none focus:border-c4c4c4 focus:outline-none focus:ring-0 text-right"
                      placeholder="MM"
                      maxLength={2}
                      value={cardMonth}
                      onChange={(e) => setCardMonth(e.target.value)}
                    />
                    <span className="pl-1 pb-1px">/</span>
                    <input
                      type="text"
                      className="ml-1 bg-E0E0E0 px-0 py-1 w-8 border-none focus:border-c4c4c4 focus:outline-none focus:ring-0"
                      placeholder="YY"
                      maxLength={2}
                      value={cardYear}
                      onChange={(e) => setCardYear(e.target.value)}
                    />
                  </div>
                  <Calendar className="absolute top-42px left-3" />
                </div>
                <div className="relative">
                  <div className="block text-sm font-medium text-gray-700">
                    CVV
                  </div>
                  <input
                    type="text"
                    className="pl-11 mt-2 rounded-md border-c4c4c4 h-12 bg-E0E0E0 flex items-center text-828282 w-154 sm:w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
                    maxLength={4}
                    value={cardCVC}
                    onChange={(e) => setCardCVC(e.target.value)}
                  />
                  <CVV className="absolute top-42px left-4" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-16">
            <div className="px-4 sm:px-0">
              <div className="text-center block text-xl font-bold text-3fc69d">
                Payment method updated
              </div>
              <div>
                {isPlanAddPercent && (
                  <div className="pt-5 px-4 sm:px-0 relative flex items-start">
                    <div className="mr-3 flex items-center h-5">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                        checked={isNoAddCharge}
                        onClick={() => setIsNoAddCharge(!isNoAddCharge)}
                      />
                    </div>
                    <div className="text-sm">
                      <label
                        htmlFor="region_access"
                        className="font-medium text-gray-700 select-none"
                      >
                        Are you collecting full payment after doctors appointment? (No additional charge will be applied if this is the case)
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full sm:w-full mt-8">
                <dl>
                  <div className="border-1 bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-7 sm:px-6 ">
                    <dt className="text-sm font-normal text-828282 sm:col-start-1 sm:col-span-4">
                      Cardholder Name
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("card_name")}
                    </dd>
                  </div>
                  <div className="border-1 bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-7 sm:px-6 ">
                    <dt className="text-sm font-normal text-828282 sm:col-start-1 sm:col-span-4">
                      Card Number
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("card_number")}
                    </dd>
                  </div>
                  <div className="border-1 bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-7 sm:px-6 ">
                    <dt className="text-sm font-normal text-828282 sm:col-start-1 sm:col-span-4">
                      Expiration Date
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("card_month")}/
                      {sessionStorage.getItem("card_year")}
                    </dd>
                  </div>
                  <div className="border-1 bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-7 sm:px-6 ">
                    <dt className="text-sm font-normal text-828282 sm:col-start-1 sm:col-span-4">
                      CVV
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("card_cvc")}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="mt-8 px-4 sm:px-0">
                <div className="block text-sm font-medium text-gray-700">
                  Amount (
                  {sessionStorage.getItem("currency") === "EUR"
                    ? "€"
                    : sessionStorage.getItem("currency") === "GBP"
                    ? "£"
                    : "$"}
                  )
                </div>
                <input
                  type="number"
                  className="mt-2 rounded-md border-c4c4c4 h-12 bg-E0E0E0 flex items-center pl-2 text-828282 w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
                  value={cardAmount}
                  onChange={(e) => setCardAmount(e.target.value)}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()}
                />
              </div>
              {(isPlanAddPercent && !isNoAddCharge) && (
                <>
                  <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
                    <div>
                      {" "}
                      Additional percent charge ({sessionStorage.getItem("plan_add_percent")}%) will be applied as payment being collected is on a payment plan.
                      Total program price will be{" "}
                      {
                        sessionStorage.getItem("currency") === "EUR" ? "€"
                        : sessionStorage.getItem("currency") === "GBP" ? "£"
                        : "$"
                      }
                      {Number(sessionStorage.getItem("total_program_price_add_percent"))}
                      {" "}
                    </div>
                  </div>
                  <div className="text-center text-md text-amber-600 mt-2 px-4 sm:px-6 lg:px-8">
                    <div>
                      {" "}
                      Discounted price is{" "}
                      {
                        sessionStorage.getItem("currency") === "EUR" ? "€"
                        : sessionStorage.getItem("currency") === "GBP" ? "£"
                        : "$"
                      }
                      {Number(sessionStorage.getItem("total_program_price"))}
                      {" "}
                    </div>
                  </div>
                </>
              )}
              {(isExpressPaymentConfirmation) && (
                <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
                  <div>
                    {" "}
                    Discount will be applied for remaining balance and must be paid in full. Discounted remaining balance is
                    {" "}
                    {
                      sessionStorage.getItem("currency") === "EUR" ? "€"
                      : sessionStorage.getItem("currency") === "GBP" ? "£"
                      : "$"
                    }
                    {discountedBalance.toFixed(2)}
                    {" "}
                    and discount amount is
                    {" "}
                    {
                      sessionStorage.getItem("currency") === "EUR" ? "€"
                      : sessionStorage.getItem("currency") === "GBP" ? "£"
                      : "$"
                    }
                    {discountAmount}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="text-center text-lg text-E84545 font-bold mt-4">
        {cardError && cardErrorName}
        {amountError && amountErrorName}
      </div>
    </Layout>
  )
}

export default UpdateCard