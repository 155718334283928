import { useEffect, useState, FC, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Avatar from "./Avatar"
import { getProfile } from "../../redux/auth/actions"
import { RootState } from "../../redux/store"
import { ReactComponent as MainLogoDark } from "../../assets/headerIcons/darkLogo.svg"
import { ReactComponent as Burger } from "../../assets/headerIcons/burgerIcon.svg"

interface MyProps {
  openSidebar: () => void
  closeSidebar: () => void
}

const Header: FC<MyProps> = ({ openSidebar, closeSidebar }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const userLoggedIn = useSelector(
    (store: RootState) => store.userData.userLoggedIn
  )
  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  useEffect(() => {
    if (
      location.pathname.replace(/\/$/, "") !== "/signin" &&
      location.pathname !== "/" &&
      location.pathname.replace(/\/$/, "") !== "/forgotpassword" &&
      location.pathname.replace(/\/$/, "") !== "/createpassword" &&
      location.pathname.replace(/\/$/, "") !== "/verification" &&
      location.pathname.replace(/\/$/, "") !== "/service-status"
    ) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [location])

  const openSidebarHandler = useCallback((): void => {
    openSidebar()
  }, [openSidebar])
  const closeSidebarHandler = useCallback((): void => {
    closeSidebar()
  }, [closeSidebar])

  useEffect(() => {
    if (userLoggedIn && !userInformation.email) {
      dispatch(getProfile())
    }
  }, [dispatch, userInformation, userLoggedIn])

  return (
    <>
      {show && (
        <div className="relative">
          <div className="fixed w-full z-50">
            <div
              className={
                "bg-navy-theme transition duration-300 relative flex md:hidden h-16 justify-center items-center"
              }
            >
              <div
                className={
                  "bg-0D2773 absolute w-10 h-10 flex justify-center items-center rounded-md left-2 cursor-pointer"
                }
                onClick={openSidebarHandler}
              >
                <Burger />
              </div>
              <div
                className="w-full flex md:hidden h-16 justify-center items-center -sm-max:justify-start -sm-max:pl-16"
                onClick={closeSidebarHandler}
              >
                <MainLogoDark />
              </div>
            </div>
          </div>
          <div className="hidden md:flex justify-between items-center h-16 shadow border-b border-l border-E0E0E0 pr-6">
            <div />
            <div className="flex items-center">
              <Avatar className={"pr-4"} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Header
