import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import ExistingCardConfirmationModal from "./components/ExistingCardConfirmationModal"
import AdditionalPercentModal from "../../components/additional-percent-modal/AdditionalPercentModal"
import ExpressPaymentModal from "../../components/express-payment-modal/ExpressPaymentModal"
import "@stripe/stripe-js"
import { Stripe, loadStripe } from "@stripe/stripe-js"
import { ReactComponent as Visa } from "../../../../assets/paymentCollection/visa.svg"
import { ReactComponent as Mastercard } from "../../../../assets/paymentCollection/mastercard.svg"
import { ReactComponent as Amex } from "../../../../assets/paymentCollection/amex.svg"
import { ReactComponent as Discover } from "../../../../assets/paymentCollection/discover.svg"
import axios from "axios"
import spinnerBlack from "../../../../assets/common/spinnerBlack.svg"

type Opportunity = {
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
}

function ExistingCard() {
  const history = useHistory()
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const initializeStripe = async () => {
    if (process.env.NODE_ENV === "production") {
      await loadStripe(
        "pk_live_51Km48rGa6h2jnoFCtF0ifGbKtOLsF355FGMTQJDMlthGscRMPslI3MEIsEB2jfFSEFeT0kwmaJJoz3Ku5ehQ3j5M00Zz7fOsyu"
      ).then((res) => setStripe(res))
    } else if (process.env.NODE_ENV === "development") {
      await loadStripe(
        "pk_test_51Km48rGa6h2jnoFCnxVdHSMUKIBtgBFsjhBLxBRLlu4jTSUlYwiamvv15ReXTT0a6eLZEWlZ5A5buxE8NMLbGn7D00drmO5Kug"
      ).then((res) => setStripe(res))
    }
  }

  const [isNoAddCharge, setIsNoAddCharge] = useState(false)

  const createOpportunitySkipped =
    sessionStorage.getItem("createOpportunitySkipped") === "Yes"

  const [
    existingCardConfirmationModalOpen,
    setExistingCardConfirmationModalOpen,
  ] = useState(false)
  const [cardAmount, setCardAmount] = useState("")
  const [selected, setSelected] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [amountErrorName, setAmountErrorName] = useState("")
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(true)
  const [, setOpportunities] = useState<Opportunity[]>([])

  const isPlanAddPercent =
    Boolean(sessionStorage.getItem("plan_add_percent")) && ((sessionStorage.getItem("country") === "PCA" && sessionStorage.getItem("module") === "LEADS") 
      ? !(sessionStorage.getItem("isFullPayment") === "Yes" || sessionStorage.getItem("isFullPayment") !== "Yes" && Number(cardAmount) >= Number(sessionStorage.getItem("total_program_price")))
      : Number(cardAmount) < Number(sessionStorage.getItem("total_program_price")))
  const [additionalPercentModalOpen, setAdditionalPercentModalOpen] = useState(false)

  const isExpressPaymentConfirmation =
    !createOpportunitySkipped &&
    sessionStorage.getItem("opportunity_add_percent") !== "" &&
    sessionStorage.getItem("transaction_type") === "Collection PM - Express Payment" &&
    sessionStorage.getItem("module") === "CONTACTS"
  const [expressPaymentModalOpen, setExpressPaymentModalOpen] = useState(false)

  const amountRemaining = Number(sessionStorage.getItem("amount_remaining")).toFixed(2);
  const opportunityAddPercent = Number(sessionStorage.getItem("opportunity_add_percent")).toFixed(2);
  const discountAmount = Number(Number(amountRemaining) * Number(opportunityAddPercent) / 100).toFixed(2);
  const discountedBalance = Number((Number(amountRemaining) - Number(discountAmount)).toFixed(2));

  const handleClick = () => {
    setSelected(true)
  }

  const handleProceed = () => {
    history.push(`/payment-portal/update-card`)
  }

  const handlePayment = async () => {

    let remainingBalance = Number(sessionStorage.getItem("amount_remaining"))
    if (createOpportunitySkipped) {
      remainingBalance = Number(sessionStorage.getItem("total_program_price"))
    }
    
    if (
      Number(cardAmount) > remainingBalance
    ) {
      setAmountError(true)
      if (createOpportunitySkipped) {
        setAmountErrorName("Cannot process amount higher than program price")
      } else {
        setAmountErrorName("Cannot process amount higher than remaining balance")
      }
    } else {
      setAmountError(false)
      setAmountErrorName("")
      sessionStorage.setItem("amount", cardAmount)

      if (isPlanAddPercent && !isNoAddCharge) {
        sessionStorage.setItem("isPlanAddPercent", "Yes")
        sessionStorage.setItem("isDiscount", "No")
        setAdditionalPercentModalOpen(true)
      } else if (isExpressPaymentConfirmation && Number(cardAmount) === discountedBalance) {
        sessionStorage.setItem("isPlanAddPercent", "No")
        sessionStorage.setItem("isDiscount", "Yes")
        setExpressPaymentModalOpen(true)
      } else {
        sessionStorage.setItem("isPlanAddPercent", "No")
        sessionStorage.setItem("isDiscount", "No")
        setExistingCardConfirmationModalOpen(true)
      }
        
    }
  }

  const getOpportunity = async () => {

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
        {
          contact_id: sessionStorage.getItem("id"),
          country: sessionStorage.getItem("country"),
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res

        let hasCard = false
        
        // loop through the opportunities and check if the opportunity has brand, last_four_digit and name_on_card
        // latest opportunities get evaluated first
        for (const opportunity of data) {
          if (opportunity.brand && opportunity.last_four_digit && opportunity.name_on_card) {
            sessionStorage.setItem("card_name", opportunity.name_on_card)
            sessionStorage.setItem("last_four_digits", opportunity.last_four_digit)
            sessionStorage.setItem("card_brand", opportunity.brand)
            sessionStorage.setItem("opportunity_id", opportunity.opportunity_id)
            hasCard = true
            break
          }
        }
        
        if (!hasCard) {
          sessionStorage.setItem("card_name", "undefined")
          sessionStorage.setItem("last_four_digits", "undefined")
          sessionStorage.setItem("card_brand", "undefined")
          if (data) {
            sessionStorage.setItem("opportunity_id", data[0].opportunity_id)
          }  
        }

        setLoadingPaymentMethod(false)
        setOpportunities(data)
      })
      .catch(() => {
        setLoadingPaymentMethod(false)
      })
  }

  useEffect(() => {
    initializeStripe()

    if (
      (sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isCreateOpportunity") === "Yes")
    ) {
      getOpportunity()
    } else {
      setLoadingPaymentMethod(false)
    }

    sessionStorage.removeItem("amount")
    
  }, [])

  useEffect(() => {
    if (cardAmount) {
      setNextButtonDisabled(false)
    } else {
      setNextButtonDisabled(true)
    }
  }, [cardAmount])

  return (
    <Layout
      pageNumber={9}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
      existingCardSelected={selected}
      handlePayment={handlePayment}
    >
      <ExistingCardConfirmationModal
        existingCardConfirmationModalOpen={existingCardConfirmationModalOpen}
        setExistingCardConfirmationModalOpen={
          setExistingCardConfirmationModalOpen
        }
        cardName={sessionStorage.getItem("card_name")}
        cardAmount={cardAmount}
        stripe={stripe}
      />

      <AdditionalPercentModal
        additionalPercentModalOpen={additionalPercentModalOpen}
        setAdditionalPercentModalOpen={setAdditionalPercentModalOpen}
        nextModalOpen={setExistingCardConfirmationModalOpen}
      />

      <ExpressPaymentModal
        expressPaymentModalOpen={expressPaymentModalOpen}
        setExpressPaymentModalOpen={setExpressPaymentModalOpen}
        nextModalOpen={setExistingCardConfirmationModalOpen}
      />

      <div className="w-full h-full flex flex-col justify-evenly items-center">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          {loadingPaymentMethod ? (
            <div className="w-full h-20 flex justify-center items-center">
              <img
                width={40}
                className="inline"
                src={spinnerBlack}
                alt="Loading..."
              />
              <div className="text-center sm:text-left block text-xl font-medium text-gray-700">
                Checking Payment Options
              </div>
            </div>
          ) : (
            <>
              <div className="text-center sm:text-left block text-xl font-medium text-gray-700">
                Use existing payment on file?
              </div>
              {sessionStorage.getItem("last_four_digits") !== "undefined" ? (
                <>
                  <div>
                    {isPlanAddPercent && (
                      <div className="pt-5 px-4 sm:px-0 relative flex items-start">
                        <div className="mr-3 flex items-center h-5">
                          <input
                            type="checkbox"
                            className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                            checked={isNoAddCharge}
                            onClick={() => setIsNoAddCharge(!isNoAddCharge)}
                          />
                        </div>
                        <div className="text-sm">
                          <label
                            htmlFor="region_access"
                            className="font-medium text-gray-700 select-none"
                          >
                            Are you collecting full payment after doctors appointment? (No additional charge will be applied if this is the case)
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${
                      selected ? "bg-white" : "bg-E0E0E0 hover:bg-E0E0E0_hover"
                    } px-8 flex h-20 w-full md:w-96 mx-auto border-1 border-c4c4c4 rounded-2xl shadow mt-8 overflow-hidden relative cursor-pointer`}
                    onClick={() => handleClick()}
                  >
                    {(sessionStorage.getItem("card_brand") === "visa" ||
                      sessionStorage.getItem("card_brand") === "VISA") && (
                      <div className="py-4">
                        <Visa />
                      </div>
                    )}
                    {(sessionStorage.getItem("card_brand") === "mastercard" ||
                      sessionStorage.getItem("card_brand") === "MASTERCARD") && (
                      <div className="py-6">
                        <Mastercard />
                      </div>
                    )}
                    {(sessionStorage.getItem("card_brand") === "amex" ||
                      sessionStorage.getItem("card_brand") === "AMEX") && (
                      <div className="py-6">
                        <Amex />
                      </div>
                    )}
                    {(sessionStorage.getItem("card_brand") === "discover" ||
                      sessionStorage.getItem("card_brand") === "DISCOVER") && (
                      <div className="py-6">
                        <Discover />
                      </div>
                    )}
                    <div className="py-7 pl-4 text-lg font-semibold">
                      Last 4 digits:
                      <span className="text-lg font-semibold ml-4">
                        {sessionStorage.getItem("last_four_digits")}
                      </span>
                    </div>
                    <div
                      className={`${
                        selected && "bg-3FB1FC"
                      } h-full w-4 absolute top-0 left-0 bg-navy-theme`}
                    ></div>
                  </div>
                </>
              ) : (
                <div className="bg-E0E0E0 py-2 px-8 flex h-20 w-full md:w-96 mx-auto  border-1 border-c4c4c4 rounded-2xl shadow mt-8 overflow-hidden relative">
                  <p className="text-lg font-semibold w-62">
                    No existing payment method found, please enter new payment
                    method.
                  </p>
                </div>
              )}
            </>
          )}
          
        </div>
        {selected && (
          <div className="w-full px-4 sm:px-6 lg:px-8 mt-8">
            <div className="mt-4 sm:mt-0 px-24">
              <div className="block text-sm font-medium text-gray-700">
                Amount (
                {sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                  ? "£"
                  : "$"}
                )
              </div>
              <input
                type="number"
                className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 sm:w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
                value={cardAmount}
                onChange={(e) => setCardAmount(e.target.value)}
                placeholder="Enter payment amount"
                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                  (e.target as HTMLInputElement).blur()
                }
              />
            </div>
          </div>
        )}
        {(isPlanAddPercent && !isNoAddCharge && selected) && (
          <>
            <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
              <div>
                {" "}
                Additional percent charge ({sessionStorage.getItem("plan_add_percent")}%) will be applied as payment being collected is on a payment plan.
                Total program price will be{" "}
                {
                  sessionStorage.getItem("currency") === "EUR" ? "€"
                  : sessionStorage.getItem("currency") === "GBP" ? "£"
                  : "$"
                }
                {Number(sessionStorage.getItem("total_program_price_add_percent"))}
                {" "}
              </div>
            </div>
            <div className="text-center text-md text-amber-600 mt-2 px-4 sm:px-6 lg:px-8">
              <div>
                {" "}
                Discounted price is{" "}
                {
                  sessionStorage.getItem("currency") === "EUR" ? "€"
                  : sessionStorage.getItem("currency") === "GBP" ? "£"
                  : "$"
                }
                {Number(sessionStorage.getItem("total_program_price"))}
                {" "}
              </div>
            </div>
          </>
        )}
        {(isExpressPaymentConfirmation && selected) && (
          <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
            <div>
              {" "}
              Discount will be applied for remaining balance and must be paid in full. Discounted remaining balance is
              {" "}
              {
                sessionStorage.getItem("currency") === "EUR" ? "€"
                : sessionStorage.getItem("currency") === "GBP" ? "£"
                : "$"
              }
              {discountedBalance.toFixed(2)}
              {" "}
              and discount amount is
              {" "}
              {
                sessionStorage.getItem("currency") === "EUR" ? "€"
                : sessionStorage.getItem("currency") === "GBP" ? "£"
                : "$"
              }
              {discountAmount}
            </div>
          </div>
        )}
      </div>
      <div className="text-center text-lg text-E84545 font-bold mt-4">
        {amountError && amountErrorName}
      </div>
    </Layout>
  )
}

export default ExistingCard