import { FC, Fragment, useRef, useState, useEffect, ChangeEvent } from "react"
import { Dialog, Transition } from "@headlessui/react"
import {
  CheckIcon,
  CheckCircleIcon,
  XIcon,
  XCircleIcon,
} from "@heroicons/react/outline"
import axios from "axios"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/auth/actions"
import { ReactComponent as Arrow } from "../../../assets/common/arrowDown.svg"
import { ReactComponent as UploadScreenshot } from "../../../assets/paymentCollection/upload-screenshot.svg"
import spinner from "../../../assets/common/spinnerNavy.svg"

interface PropTypes {
  open: boolean
  setOpen: (args: boolean) => void
  type: string
  data: RefundRequest
  getRefundRequests: () => void
}

type RefundRequest = {
  description: {
    description: string
    timestamp: string
    actioned_by: string
  }[]
  id: number
  country: string
  contact_id: string
  opportunity_id: string
  reason: string
  program_price: number
  program_name: string
  patient_name: string
  refund_type: string
  last_actioned_id: string
  file_url: string
  refund_amount: number
  gross_refund_amount: number
  approval_level: string
  status: boolean
  lastActionedBy: {
    first_name: string
    last_name: string
  }
}

type PaymentData = {
  amount: number
  email: string
  id: string
  name: string
  refund_amount_portion: number
  sales_person_email: string
  payment_type: string
}

const ApprovalModal: FC<PropTypes> = ({
  open,
  setOpen,
  type,
  data,
  getRefundRequests,
}) => {
  const cancelButtonRef = useRef(null)
  const isApproval = type === "APPROVE"
  const dispatch = useDispatch()
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [buttonsHidden, setButtonsHidden] = useState(false)
  const [approvalComplete, setApprovalComplete] = useState(false)
  const [notes, setNotes] = useState("")
  const [refundSelectedItem, setRefundSelectedItem] =
    useState("Select refund type")
  const [isRefundOpen, setIsRefundOpen] = useState<boolean>(false)
  const [amount, setAmount] = useState("")
  const [file, setFile] = useState<FormData>(new FormData())
  const [fileName, setFileName] = useState("")
  const [fileErrorMessage, setFileErrorMessage] = useState<boolean>(false)

  const dropDownRef = useRef<HTMLDivElement>(null)
  const refundOpenHandler = (): void => {
    setIsRefundOpen(!isRefundOpen)
  }
  const refund_select = [{ id: "Full" }, { id: "Partial" }]

  const refundSelectHandler = (id: string) => {
    const selected = refund_select.find((el) => el.id === id)
    setRefundSelectedItem(selected?.id as string)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile =
      event.currentTarget.files && event.currentTarget.files[0]

    if (!selectedFile) {
      setFileErrorMessage(true)
    } else if (!selectedFile.type.match("image.*")) {
      setFileErrorMessage(true)
    } else {
      const formData = new FormData()
      formData.append("files", selectedFile)
      setFileName(selectedFile.name)
      setFile(formData)
      setFileErrorMessage(false)
    }
  }

  const handleAction = async (approval: boolean) => {
    if (isApproval && +amount > totalPaymentValue) {
      setAlertType("error")
      setAlertMessage(
        `Please enter cash value amount less than or equal to total payments amount (${
          data.country === "UK" ? "€/£" : "$"
        }${totalPaymentValue.toFixed(2)})`
      )
      return
    }

    if (!notes) {
      setAlertType("error")
      setAlertMessage("Please enter description")
      return
    }

    // REJECTION
    if (!approval) {
      setButtonsHidden(true)
      setIsProcessing(true)

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/refund-request/action`,
          {
            id: data.id,
            action: "REJECTED",
            description: notes,
            level: data.approval_level,
          }
        )
        .then(async () => {
          setApprovalComplete(true)
          setAlertType("success")
          setAlertMessage("Action successful")
          await getRefundRequests()

          setTimeout(() => {
            setOpen(false)
            setApprovalComplete(false)
            setAlertType("")
            setAlertMessage("")
          }, 3000)
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "Invalid Token. Admin Authentication Token does not match"
          ) {
            sessionStorage.setItem("authError", err.response.data.message)
            dispatch(logout())
          }
          setAlertType("error")
          setAlertMessage(err.response.data.message)
        })

      setButtonsHidden(false)
      setIsProcessing(false)
      return
    }

    if (!amount) {
      setAlertType("error")
      setAlertMessage("Please enter cash value refund")
      return
    }
    if (refundSelectedItem === "Select refund type") {
      setAlertType("error")
      setAlertMessage("Please select refund type")
      return
    }

    // LEVEL 3
    if (data.approval_level === "LEVEL_2") {
      if (!file) {
        setAlertType("error")
        setAlertMessage("Please select a file")
        return
      }

      setButtonsHidden(true)
      setIsProcessing(true)

      const refundBreakdownArr: {
        id: string
        refund_amount_portion: number
        sales_person_email: string
      }[] = []

      if (paymentData.length > 0) {
        paymentData.forEach((payment) => {
          refundBreakdownArr.push({
            id: payment.id,
            refund_amount_portion: payment.refund_amount_portion,
            sales_person_email: payment.sales_person_email,
          })
        })
      }

      await axios
        .post(`${process.env.REACT_APP_API_URL}admin/v1/file-upload`, file)
        .then(async (res) => {
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}admin/v1/refund-request/action`,
              {
                id: data.id,
                action: `${approval ? "APPROVED" : "REJECTED"}`,
                level: "LEVEL_3",
                description: notes,
                refund_amount: Number(amount),
                refund_type: refundSelectedItem,
                file_url: res.data.data.fileURLs[0],
                gross_value: grossValueRefund,
                refund_breakdown: refundBreakdownArr,
                custom_gross_refund: isCustomGrossValue,
              }
            )
            .then(async () => {
              setApprovalComplete(true)
              setAlertType("success")
              setAlertMessage("Action successful")
              await getRefundRequests()

              setTimeout(() => {
                setOpen(false)
                setApprovalComplete(false)
                setAlertType("")
                setAlertMessage("")
              }, 3000)
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                "Invalid Token. Admin Authentication Token does not match"
              ) {
                sessionStorage.setItem("authError", err.response.data.message)
                dispatch(logout())
              }
              setAlertType("error")
              setAlertMessage(err.response.data.message)
            })
        })
        .catch((err) => {
          setAlertType("error")
          setAlertMessage(err.response.data.message)
        })

      setButtonsHidden(false)
      setIsProcessing(false)
      return
    }

    // LEVEL 2
    if (data.approval_level === "LEVEL_1") {
      setButtonsHidden(true)
      setIsProcessing(true)

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/refund-request/action`,
          {
            id: data.id,
            action: `${approval ? "APPROVED" : "REJECTED"}`,
            level: "LEVEL_2",
            description: notes,
            refund_amount: Number(amount),
            refund_type: refundSelectedItem,
            ...(isCustomGrossValue && { gross_value: grossValueRefund }),
            custom_gross_refund: isCustomGrossValue,
          }
        )
        .then(async () => {
          setApprovalComplete(true)
          setAlertType("success")
          setAlertMessage("Action successful")
          await getRefundRequests()

          setTimeout(() => {
            setOpen(false)
            setApprovalComplete(false)
            setAlertType("")
            setAlertMessage("")
          }, 3000)
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "Invalid Token. Admin Authentication Token does not match"
          ) {
            sessionStorage.setItem("authError", err.response.data.message)
            dispatch(logout())
          }
          setAlertType("error")
          setAlertMessage(err.response.data.message)
        })

      setButtonsHidden(false)
      setIsProcessing(false)
      return
    }

    // LEVEL 1
    if (data.approval_level === "PENDING") {
      setButtonsHidden(true)
      setIsProcessing(true)

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/refund-request/action`,
          amount
            ? {
                id: data.id,
                action: `${approval ? "APPROVED" : "REJECTED"}`,
                level: "LEVEL_1",
                description: notes,
                refund_amount: Number(amount),
              }
            : {
                id: data.id,
                action: `${approval ? "APPROVED" : "REJECTED"}`,
                level: "LEVEL_1",
                description: notes,
              }
        )
        .then(async () => {
          setApprovalComplete(true)
          setAlertType("success")
          setAlertMessage("Action successful")
          await getRefundRequests()

          setTimeout(() => {
            setOpen(false)
            setApprovalComplete(false)
            setAlertType("")
            setAlertMessage("")
          }, 3000)
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "Invalid Token. Admin Authentication Token does not match"
          ) {
            sessionStorage.setItem("authError", err.response.data.message)
            dispatch(logout())
          }
          setAlertType("error")
          setAlertMessage(err.response.data.message)
        })

      setButtonsHidden(false)
      setIsProcessing(false)
    }
  }

  const [paymentData, setPaymentData] = useState<PaymentData[]>([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [tableError, setTableError] = useState("")
  const [totalPaymentValue, setTotalPaymentValue] = useState(0)
  const [grossValueRefund, setGrossValueRefund] = useState(0)
  const [isCustomGrossValue, setIsCustomGrossValue] = useState(false)
  const [recalculateDisabled, setRecalculateDisabled] = useState(true)
  const [approveDisabled, setApproveDisabled] = useState(false)

  const getPaymentBasedRefund = async (
    newRefundAmount?: number,
    recalculate?: boolean
  ) => {
    setPaymentData([])
    setLoadingTable(true)
    setAlertType("")
    setAlertMessage("")
    setTableError("")
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/refund-request/get-payment-based-refund`,
        {
          id: data.id,
          refund_value: newRefundAmount ? newRefundAmount : data.refund_amount,
        }
      )
      .then((res) => {
        const paymentData = res.data.data
        if (paymentData.payment_breakdown.length < 1) {
          setAlertType("error")
          setAlertMessage("No payment history found. Please check with admin.")
        } else {
          setPaymentData(paymentData.payment_breakdown)
        }

        if (recalculate) {
          setIsCustomGrossValue(false)
          setGrossValueRefund(Number(paymentData.gross_refund_value))
        } else if (paymentData.custom_refund_value) {
          setGrossValueRefund(paymentData.custom_refund_value)
          setIsCustomGrossValue(true)
        } else {
          setGrossValueRefund(Number(paymentData.gross_refund_value))
        }

        const calculateTotalAmount = (data: PaymentData[]) => {
          let totalAmount = 0

          for (let i = 0; i < data.length; i++) {
            totalAmount += data[i].amount
          }

          return totalAmount
        }

        setTotalPaymentValue(
          calculateTotalAmount(paymentData.payment_breakdown)
        )
      })
      .catch((err) => {
        if (data.approval_level === "PENDING") {
          setAlertType("error")
          setAlertMessage(err.response.data.message)
        } else {
          setTableError(err.response.data.message)
        }
      })

    setLoadingTable(false)
    if (recalculate) {
      setRecalculateDisabled(true)
      setApproveDisabled(false)
    }
  }

  useEffect(() => {
    data.refund_amount && setAmount(data.refund_amount.toFixed(2))
    data.refund_type && setRefundSelectedItem(data.refund_type)
    if (data.approval_level && isApproval) getPaymentBasedRefund()
  }, [data])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen(false)
          setAlertType("")
          setAlertMessage("")
          setNotes("")
          setFile(new FormData())
          setFileName("")
          setTableError("")
          setIsCustomGrossValue(false)
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start justify-center">
                  <div
                    className={`${
                      isApproval ? "bg-green-100" : "bg-red-100"
                    } mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {isApproval ? (
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <XIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {isApproval ? "Approve" : "Reject"} Refund Request
                    </Dialog.Title>
                    <div className="mt-2 mb-6">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to{" "}
                        {isApproval ? "approve" : "reject"} this request to the
                        next level?
                      </p>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 font-bold">
                          Patient Name:{" "}
                          <span className="font-normal">
                            {data.patient_name}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Opportunity ID:{" "}
                          <span className="font-normal">
                            {data.opportunity_id}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Refund Type:{" "}
                          <span className="font-normal">
                            {data.refund_type}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Cash Value Refund:{" "}
                          <span className="font-normal">
                            {data.refund_amount?.toFixed(2)
                              ? `${
                                  data.country === "UK" ? "€/£" : "$"
                                }${data.refund_amount?.toFixed(2)}`
                              : "Pending"}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Reason:{" "}
                          <span className="font-normal">{data.reason}</span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Last actioned by:{" "}
                          <span className="font-normal">
                            {data?.lastActionedBy?.first_name +
                              " " +
                              data?.lastActionedBy?.last_name}
                          </span>
                        </p>
                      </div>
                    </div>
                    {isApproval &&
                      (data.approval_level === "LEVEL_1" ||
                        data.approval_level === "LEVEL_2") &&
                      (loadingTable ? (
                        <div className="flex items-center justify-center mb-4">
                          <img src={spinner} className="w-8" />
                          <p className="font-medium text-navy-theme">
                            Loading payment history...
                          </p>
                        </div>
                      ) : paymentData.length > 0 ? (
                        <>
                          <div className="w-full mb-6 border-l border-r border-b rounded-md">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-navy-theme">
                                <tr className="text-center">
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider border-none rounded-tl-md"
                                  >
                                    Payment
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider"
                                  >
                                    Amount deducted
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider"
                                  >
                                    Deducted from
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider border-none rounded-tr-md"
                                  >
                                    Type
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentData.map((payment) => (
                                  <tr className="text-center" key={payment.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {`${
                                        data.country === "UK" ? "€/£" : "$"
                                      }${payment.amount?.toFixed(2)}`}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {`${
                                        data.country === "UK" ? "€/£" : "$"
                                      }${payment.refund_amount_portion?.toFixed(
                                        2
                                      )}`}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {payment.sales_person_email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {payment.payment_type ?? "N/A"}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="w-full mb-4 flex flex-col justify-center">
                            <p className="w-full block text-sm font-medium text-gray-700">
                              Gross Value Refund (Inclusive of tax)
                            </p>
                            {(data.approval_level === "LEVEL_1" ||
                              data.approval_level === "LEVEL_2") && (
                              <p className="text-sm text-gray-700 mb-2">
                                To change gross value refund, tick the
                                &quot;Custom Gross Value&quot; checkbox to
                                update the value.
                              </p>
                            )}
                            <div className="flex items-center space-x-8">
                              <input
                                type="number"
                                className={`${
                                  !isCustomGrossValue
                                    ? "border-gray-300 bg-gray-100 text-black"
                                    : "border-c4c4c4 bg-E0E0E0 text-828282"
                                } rounded-md h-10 flex items-center pl-2 border border-transparent focus:outline-none focus:ring-0 font-medium`}
                                value={grossValueRefund}
                                onChange={(e) =>
                                  setGrossValueRefund(+e.target.value)
                                }
                                onWheel={(
                                  e: React.WheelEvent<HTMLInputElement>
                                ) => (e.target as HTMLInputElement).blur()}
                                disabled={!isCustomGrossValue}
                              />
                              <div className="flex items-center space-x-2">
                                <label
                                  htmlFor="custom_gross_value"
                                  className="text-sm text-gray-700 select-none"
                                >
                                  Custom Gross Value
                                </label>
                                <input
                                  type="checkbox"
                                  name="custom_gross_value"
                                  id="custom_gross_value"
                                  value="custom_gross_value"
                                  className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                                  checked={isCustomGrossValue}
                                  onClick={() =>
                                    setIsCustomGrossValue(!isCustomGrossValue)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="flex items-center justify-center mb-4">
                          <p className="font-medium text-red-700">
                            {`Error: ${tableError}` ||
                              "No payment history data available."}
                          </p>
                        </div>
                      ))}
                    <div className="w-full mb-4">
                      <label
                        htmlFor="notes"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Notes
                      </label>
                      <textarea
                        className="mt-2 w-full h-36 bg-E0E0E0 border-c4c4c4 border rounded-md focus:border-c4c4c4 focus:outline-none focus:ring-0 font-medium text-828282 leading-5"
                        style={{ resize: "none" }}
                        placeholder={`Enter reason for ${
                          isApproval ? "approval" : "rejection"
                        }`}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </div>
                    {isApproval && (
                      <div className="w-full mb-4 relative">
                        <label
                          htmlFor="amount"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cash Value Refund (Inclusive of tax)
                        </label>
                        {(data.approval_level === "LEVEL_1" ||
                          data.approval_level === "LEVEL_2") && (
                          <p className="text-sm text-gray-700">
                            If cash value refund is changed, please click
                            &quot;Recalculate&quot; to update table and gross
                            value refund before approving. Note: This will reset
                            the gross value refund to an autocalculated value.
                          </p>
                        )}
                        <input
                          type="number"
                          className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 sm:w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value)
                            if (
                              data.approval_level === "LEVEL_1" ||
                              data.approval_level === "LEVEL_2"
                            ) {
                              setRecalculateDisabled(false)
                              setApproveDisabled(true)
                            }
                          }}
                          onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                            (e.target as HTMLInputElement).blur()
                          }
                        />
                        {(data.approval_level === "LEVEL_1" ||
                          data.approval_level === "LEVEL_2") && (
                          <button
                            className={`${
                              recalculateDisabled
                                ? "bg-gray-300 text-black cursor-auto"
                                : "text-white bg-navy-theme hover:bg-navy-theme_hover cursor-pointer"
                            } h-12 absolute right-0 top-[68px] font-medium p-2.5 rounded-r-md `}
                            onClick={() => {
                              getPaymentBasedRefund(+amount, true)
                              setApproveDisabled(false)
                            }}
                            disabled={recalculateDisabled}
                          >
                            Recalculate
                          </button>
                        )}
                      </div>
                    )}
                    {isApproval && data.approval_level === "LEVEL_1" && (
                      <div className="w-full mb-4">
                        <label
                          htmlFor="type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Refund Type
                        </label>
                        <div className={`relative mt-2`}>
                          <div
                            className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
                            onClick={refundOpenHandler}
                            ref={dropDownRef}
                          >
                            <div className="flex items-center pl-4 font-medium text-828282 w-full">
                              {refundSelectedItem}
                            </div>
                            <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
                              <Arrow />
                            </div>
                            {isRefundOpen && (
                              <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
                                {refund_select.map(({ id }, index) => (
                                  <div
                                    className={`${
                                      index === 0
                                        ? ""
                                        : "border-t border-F0F0F0"
                                    } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                                    key={id}
                                    onClick={() => refundSelectHandler(id)}
                                  >
                                    {id}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {isApproval && data.approval_level === "LEVEL_2" && (
                      <>
                        <div className="w-full mb-4">
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Upload Screenshot of Refund
                          </label>
                          <label
                            htmlFor="file-upload"
                            className="mt-2 relative block cursor-pointer text-828282 bg-E0E0E0 border border-c4c4c4 pl-10 py-3 text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                          >
                            <UploadScreenshot className="w-5 h-5 absolute fill-828282 left-3 top-3.5" />
                            {fileName ? fileName : "Upload File"}
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                            className="hidden"
                          />
                          {fileErrorMessage && (
                            <div className="mt-2 text-red-600">
                              Uploaded file is not an image. Please try again
                              with an image.
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {alertType === "error" && (
                  <div className="my-4 rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          {alertMessage}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}

                {alertType === "success" && (
                  <div className="my-4 rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">
                          {alertMessage}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}

                {!approvalComplete && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <div className="relative">
                      {isApproval && (
                        <button
                          type="button"
                          className={`${
                            isProcessing ||
                            loadingTable ||
                            paymentData.length < 1 ||
                            approveDisabled
                              ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                              : "bg-green-600 hover:bg-green-700 text-white w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                          }`}
                          onClick={() => {
                            if (
                              !isProcessing &&
                              !loadingTable &&
                              paymentData.length > 0 &&
                              !approveDisabled
                            ) {
                              handleAction(isApproval)
                            }
                          }}
                        >
                          {isProcessing ? "Processing..." : "Approve"}
                        </button>
                      )}
                      {!isApproval && (
                        <button
                          type="button"
                          className={`${
                            isProcessing
                              ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                              : "bg-red-600 hover:bg-red-700 text-white w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                          }`}
                          onClick={() => {
                            if (!isProcessing) {
                              handleAction(isApproval)
                            }
                          }}
                        >
                          {isProcessing ? "Processing..." : "Reject"}
                        </button>
                      )}
                    </div>
                    {!buttonsHidden && (
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false)
                          setAlertType("")
                          setAlertMessage("")
                        }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ApprovalModal
