import { FC, useEffect } from "react"
import { ReactComponent as Check } from "../../../../assets/common/check.svg"
import "../../../../styles/fixstyles.css"

interface PropTypes {
  getRefundRequests: () => void
}

const RefundRequestSuccess: FC<PropTypes> = ({ getRefundRequests }) => {
  const region = sessionStorage.getItem("country")

  useEffect(() => {
    getRefundRequests()
  }, [])

  return (
    <>
      <div id="capture" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            <div className="text-3fc69d text-3xl sm:text-4xl font-semibold my-8">
              Request Received
            </div>

            <Check />
            <div className="text-center px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
              The refund request has been received and will next be actioned by
              a Level 1 access user.
            </div>

            <div className="w-full sm:w-full mt-7 mb-2">
              <dl>
                <div className="bg-c4c4c4-20 px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    Refund Type
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("refund_type")}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    Reason
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("refund_reason")}
                  </dd>
                </div>
                <div className="bg-c4c4c4-20 px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    Opportunity ID
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("opportunity_id")}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:grid sm:grid-cols-9 sm:px-6">
                  <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                    Notes
                  </dt>
                  <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                    {sessionStorage.getItem("notes")}
                  </dd>
                </div>
              </dl>
            </div>

            {/* AU */}
            {region === "AU" && (
              <div>
                <a
                  href={`https://crm.zoho.com/crm/org52657875/tab/Potentials/${sessionStorage.getItem(
                    "opportunity_id"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className={`px-4 mt-6 mb-8 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                  >
                    View Opportunity
                  </button>
                </a>
              </div>
            )}

            {/* CA */}
            {region === "CA" && (
              <div>
                <a
                  href={`https://crm.zoho.com/crm/org760139034/tab/Potentials/${sessionStorage.getItem(
                    "opportunity_id"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className={`px-4 mt-6 mb-8 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                  >
                    View Opportunity
                  </button>
                </a>
              </div>
            )}

            {/* NZ */}
            {region === "NZ" && (
              <div>
                <a
                  href={`https://crm.zoho.com/crm/org771947106/tab/Potentials/${sessionStorage.getItem(
                    "opportunity_id"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className={`px-4 mt-6 mb-8 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                  >
                    View Opportunity
                  </button>
                </a>
              </div>
            )}

            {/* UK */}
            {region === "UK" && (
              <div>
                <a
                  href={`https://crm.zoho.eu/crm/org20067563176/tab/Potentials/${sessionStorage.getItem(
                    "opportunity_id"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className={`px-4 mt-6 mb-8 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                  >
                    View Opportunity
                  </button>
                </a>
              </div>
            )}

            {/* PCA */}
            {region === "PCA" && (
              <div>
                <a
                  href={`https://crm.zoho.com/crm/org803222882/tab/Potentials/${sessionStorage.getItem(
                    "opportunity_id"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className={`px-4 mt-6 mb-8 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                  >
                    View Opportunity
                  </button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RefundRequestSuccess
