import { FC, Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { PencilAltIcon } from "@heroicons/react/outline"

interface PropTypes {
  open: boolean
  setOpen: (args: boolean) => void
  notes: Note[]
  screenshotUrl: string
}

type Note = {
  actioned_by: string
  description: string
  timestamp: string
  email?: string
  authority?: string
}

const NotesModal: FC<PropTypes> = ({ open, setOpen, notes, screenshotUrl }) => {
  const cancelButtonRef = useRef(null)
  const formatDateString = (dateString: string) => {
    const date = new Date(dateString)

    const month = date.getMonth() + 1 // January is 0, so we add 1
    const day = date.getDate()
    const year = date.getFullYear()

    let hours = date.getHours()
    let minutes: string | number = date.getMinutes()

    const ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // Handle midnight (0 hours)

    // Add leading zeros to minutes if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes

    const formattedDate = month + "/" + day + "/" + year
    const formattedTime = hours + ":" + minutes + ampm

    return formattedDate + " " + formattedTime
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-md sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    <PencilAltIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      View History
                    </Dialog.Title>
                    {notes.map((note, index) => (
                      <div className="mt-2 mb-6 border border-gray-200 rounded p-3 shadow-lg" key={index}>
                        <p className="text-sm text-FF5733 font-bold">
                          {formatDateString(note?.timestamp)}
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Actioned by:{" "}
                          <span className="font-normal">
                            {note?.actioned_by}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Description:{" "}
                          <span className="font-normal">
                            {note?.description}
                          </span>
                        </p>
                        {note?.email && (
                          <p className="text-sm text-gray-500 font-bold">
                            Email:{" "}
                            <span className="font-normal">{note?.email}</span>
                          </p>
                        )}
                        {note?.authority && (
                          <p className="text-sm text-gray-500 font-bold">
                            Authority:{" "}
                            <span className="font-normal">
                              {note?.authority}
                            </span>
                          </p>
                        )}
                        {note?.authority === "LEVEL_3" && screenshotUrl && (
                          <a
                            href={screenshotUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={screenshotUrl}
                              alt="Refund screenshot"
                              className="mt-3"
                            />
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default NotesModal
