import { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import ct from "countries-and-timezones"
import ShiftSchedule from "./components/ShiftSchedule"
import { Link } from "react-router-dom"
import { formatSchedule } from "./helpers/formatSchedule"

const CreateUser = () => {
  const initialValues = {
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    type: "FRONTEND SALES",
    pay_rate: 0,
    time_zone: "Africa/Abidjan",
    currency: "AUD",
    salary_currency: "AUD",
    xero_bill_region: "GLOBAL",
    is_monthly_contracted: false,
    region_access: [],
    refund_access: [],
    additional_access: [],
    time_schedule: [],
  }

  const [response, setResponse] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [auChecked, setAUChecked] = useState(false)
  const [caChecked, setCAChecked] = useState(false)
  const [nzChecked, setNZChecked] = useState(false)
  const [ukChecked, setUKChecked] = useState(false)
  const [pcaChecked, setPCAChecked] = useState(false)
  const [level1Checked, setLevel1Checked] = useState(false)
  const [level2Checked, setLevel2Checked] = useState(false)
  const [level3Checked, setLevel3Checked] = useState(false)
  const [eftApproverChecked, setEftApproverChecked] = useState(false)
  const [timeClockChecked, setTimeClockChecked] = useState(false)
  const [overtimeApproverChecked, setOvertimeApproverChecked] = useState(false)

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Email address is required"),
    password: Yup.string().required("Password is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, actions) => {
      if (values.region_access.length < 1) {
        setError("Please select at least 1 region")
        return
      } else if (level1Checked && (level2Checked || level3Checked)) {
        setError("Refund Level 1 can't also be Level 2 or 3")
        return
      } else {
        setLoading(true)
        setError("")
        setResponse("")

        const formattedSchedule = formatSchedule(schedule)

        fetch(`${process.env.REACT_APP_API_URL}admin/v1/account/create-user`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authToken") || "",
          },
          body: JSON.stringify({
            email: values.email,
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
            type: values.type,
            pay_rate: values.pay_rate,
            time_zone: values.time_zone,
            currency: values.currency,
            salary_currency: values.salary_currency,
            xero_bill_region: values.xero_bill_region,
            is_monthly_contracted: values.is_monthly_contracted,
            region_access: values.region_access,
            refund_access: values.refund_access,
            additional_access: values.additional_access,
            time_schedule: formattedSchedule,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status == 200) {
              actions.resetForm()
              setLoading(false)
              setResponse(response.message)
              setError("")
              setAUChecked(false)
              setCAChecked(false)
              setNZChecked(false)
              setUKChecked(false)
              setPCAChecked(false)
              setLevel1Checked(false)
              setLevel2Checked(false)
              setLevel3Checked(false)
              setEftApproverChecked(false)
              setTimeClockChecked(false)
              setOvertimeApproverChecked(false)
              setTimeout(() => {
                setResponse("")
              }, 3000)
            } else {
              throw new Error(response.message)
            }
          })
          .catch((error) => {
            if (error) {
              setLoading(false)
              setError(error.message)
              setResponse("")
            }
          })
      }
    },
  })

  const timezonesArr = Object.values(ct.getAllTimezones())

  const [schedule, setSchedule] = useState([
    {
      name: "MON",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "TUE",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "WED",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "THU",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "FRI",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "SAT",
      checked: false,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "SUN",
      checked: false,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
  ])

  return (
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:align-middle w-full">
        <div className="relative mt-3 text-center sm:mt-5">
          <Link to="/manage-users">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 absolute cursor-pointer hover:text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
          </Link>

          <h1 className="text-2xl leading-6 font-medium text-gray-900">
            Create New User
          </h1>
        </div>

        <form
          className="space-y-6"
          method="POST"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
            <div className="mt-8 mx-auto w-full max-w-[1200px]">
              <div className="flex w-full gap-20 mb-10">
                <fieldset className="w-full">
                  <legend className="text-lg mb-2 font-medium text-center">
                    Personal Info
                  </legend>
                  <div className="pb-5">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        {...formik.getFieldProps("email")}
                        id="email"
                        name="email"
                        type="text"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...formik.getFieldProps("password")}
                        id="password"
                        name="password"
                        type="password"
                        required
                        autoComplete="current-password"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                      />
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="invalid-feedback">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        {...formik.getFieldProps("first_name")}
                        id="first_name"
                        name="first_name"
                        type="text"
                        autoComplete="first_name"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                      />
                    </div>
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        {...formik.getFieldProps("last_name")}
                        id="last_name"
                        name="last_name"
                        type="text"
                        autoComplete="last_name"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                      />
                    </div>
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                </fieldset>
                <fieldset className="w-full">
                  <legend className="text-lg mb-2 font-medium text-center">
                    Admin Access
                  </legend>
                  <div className="pb-5">
                    <label
                      htmlFor="type"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Type
                    </label>
                    <select
                      {...formik.getFieldProps("type")}
                      id="type"
                      name="type"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                    >
                      <option value="FRONTEND SALES">Frontend Sales</option>
                      <option value="ADMIN">Admin</option>
                      <option value="PERFORMANCE MANAGER">
                        Performance Manager
                      </option>
                      <option value="PM TEAM MANAGER">PM Team Manager</option>
                      <option value="FE TEAM MANAGER">FE Team Manager</option>
                      <option value="FINANCE MANAGER">Finance Manager</option>
                    </select>
                  </div>
                  <div className="flex gap-8">
                    <fieldset className="pb-5 w-1/3 border-gray-700 border-r pr-4">
                      <legend className="block text-sm font-medium text-gray-700">
                        Region Access
                      </legend>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="AU"
                              className="font-medium text-gray-700 select-none"
                            >
                              AU
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("region_access")}
                              id="AU"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="AU"
                              checked={auChecked}
                              onClick={() => setAUChecked(!auChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="CA"
                              className="font-medium text-gray-700 select-none"
                            >
                              CA
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("region_access")}
                              id="CA"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="CA"
                              checked={caChecked}
                              onClick={() => setCAChecked(!caChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="NZ"
                              className="font-medium text-gray-700 select-none"
                            >
                              NZ
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("region_access")}
                              id="NZ"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="NZ"
                              checked={nzChecked}
                              onClick={() => setNZChecked(!nzChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="UK"
                              className="font-medium text-gray-700 select-none"
                            >
                              UK
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("region_access")}
                              id="UK"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="UK"
                              checked={ukChecked}
                              onClick={() => setUKChecked(!ukChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start py-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="PCA"
                              className="font-medium text-gray-700 select-none"
                            >
                              PCA
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("region_access")}
                              id="PCA"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="PCA"
                              checked={pcaChecked}
                              onClick={() => setPCAChecked(!pcaChecked)}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="pb-5 w-1/3 border-gray-700 border-r pr-4">
                      <legend className="block text-sm font-medium text-gray-700">
                        Refund Level
                      </legend>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="LEVEL_1"
                              className="font-medium text-gray-700 select-none"
                            >
                              Level 1
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("refund_access")}
                              id="LEVEL_1"
                              name="refund_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="LEVEL_1"
                              checked={level1Checked}
                              onClick={() => setLevel1Checked(!level1Checked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="LEVEL_2"
                              className="font-medium text-gray-700 select-none"
                            >
                              Level 2
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("refund_access")}
                              id="LEVEL_2"
                              name="refund_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="LEVEL_2"
                              checked={level2Checked}
                              onClick={() => setLevel2Checked(!level2Checked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="LEVEL_3"
                              className="font-medium text-gray-700 select-none"
                            >
                              Level 3
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("refund_access")}
                              id="LEVEL_3"
                              name="refund_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="LEVEL_3"
                              checked={level3Checked}
                              onClick={() => setLevel3Checked(!level3Checked)}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="pb-5 w-1/3 pr-4">
                      <legend className="block text-sm font-medium text-gray-700">
                        Additional Access
                      </legend>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="EFT_APPROVER"
                              className="font-medium text-gray-700 select-none"
                            >
                              EFT Approver
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("additional_access")}
                              id="EFT_APPROVER"
                              name="additional_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="EFT_APPROVER"
                              checked={eftApproverChecked}
                              onClick={() =>
                                setEftApproverChecked(!eftApproverChecked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="TIME_CLOCK"
                              className="font-medium text-gray-700 select-none"
                            >
                              Time Clock
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("additional_access")}
                              id="TIME_CLOCK"
                              name="additional_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="TIME_CLOCK"
                              checked={timeClockChecked}
                              onClick={() =>
                                setTimeClockChecked(!timeClockChecked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="OVERTIME_APPROVER"
                              className="font-medium text-gray-700 select-none"
                            >
                              Overtime Approver
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              {...formik.getFieldProps("additional_access")}
                              id="OVERTIME_APPROVER"
                              name="additional_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="OVERTIME_APPROVER"
                              checked={overtimeApproverChecked}
                              onClick={() =>
                                setOvertimeApproverChecked(
                                  !overtimeApproverChecked
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </fieldset>
              </div>

              <div className="flex w-full gap-20">
                <fieldset className="w-full">
                  <legend className="text-lg mb-2 font-medium text-center">
                    Billing Info
                  </legend>
                  <div className="pb-5">
                    <label
                      htmlFor="pay_rate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pay Rate
                    </label>
                    <div className="mt-1">
                      <input
                        {...formik.getFieldProps("pay_rate")}
                        id="pay_rate"
                        name="pay_rate"
                        type="number"
                        autoComplete="pay_rate"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                      />
                    </div>
                    {formik.touched.pay_rate && formik.errors.pay_rate ? (
                      <div className="invalid-feedback">
                        {formik.errors.pay_rate}
                      </div>
                    ) : null}
                  </div>

                  <div className="pb-5">
                    <div className="relative flex items-start">
                      <div className="min-w-0 text-sm">
                        <label
                          htmlFor="monthly_contracted"
                          className="font-medium text-gray-700 select-none"
                        >
                          Monthly Contracted?
                        </label>
                      </div>
                      <div className="ml-3 flex items-center h-5">
                        <input
                          {...formik.getFieldProps("is_monthly_contracted")}
                          id="monthly_contracted"
                          type="checkbox"
                          className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="time_zone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Timezone
                    </label>
                    <select
                      {...formik.getFieldProps("time_zone")}
                      id="time_zone"
                      name="time_zone"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                    >
                      {timezonesArr.map((timezone) => (
                        <option value={timezone.name} key={timezone.name}>
                          {timezone.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="currency"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Currency
                    </label>
                    <select
                      {...formik.getFieldProps("currency")}
                      id="currency"
                      name="currency"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                    >
                      <option value="AUD">AUD</option>
                      <option value="CAD">CAD</option>
                      <option value="NZD">NZD</option>
                      <option value="GBP">GBP</option>
                      <option value="PHP">PHP</option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                    </select>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="salary_currency"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Payout Salary Currency
                    </label>
                    <select
                      {...formik.getFieldProps("salary_currency")}
                      id="salary_currency"
                      name="salary_currency"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                    >
                      <option value="AUD">AUD</option>
                      <option value="PHP">PHP</option>
                      <option value="GBP">GBP</option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="NZD">NZD</option>
                    </select>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="xero_bill_region"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Xero Bill Region
                    </label>
                    <select
                      {...formik.getFieldProps("xero_bill_region")}
                      id="xero_bill_region"
                      name="xero_bill_region"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                    >
                      <option value="GLOBAL">GLOBAL</option>
                      <option value="AU">AU</option>
                      <option value="PCA">PCA</option>
                      <option value="NZ">NZ</option>
                    </select>
                  </div>
                </fieldset>
                <ShiftSchedule schedule={schedule} setSchedule={setSchedule} />
              </div>

              {response && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5 text-center">
                  <span className="block sm:inline">{response}</span>
                </div>
              )}
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5 text-center">
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <div className="mt-5 sm:mt-6 flex justify-end">
                <Link to="/manage-users">
                  <button
                    type="button"
                    className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="submit"
                  className={`${
                    loading || response !== ""
                      ? "bg-gray-300 text-black cursor-auto"
                      : "bg-navy-theme text-white"
                  } inline-flex justify-center w-30 rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none sm:text-sm`}
                  disabled={loading || response !== ""}
                >
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateUser
