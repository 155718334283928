import { useEffect, useState } from "react"
import dayjs from "dayjs"
import axios from "axios"
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid"
import spinner from "../../assets/common/spinnerNavy.svg"
import relativeTime from "dayjs/plugin/relativeTime"

declare module "dayjs" {
  interface Dayjs {
    fromNow(): string
  }
}

const ServiceStatus = () => {
  dayjs.extend(relativeTime)

  const [loading, setLoading] = useState(true)
  const [lastRefreshed, setLastRefreshed] = useState<Date>()
  const [services, setServices] = useState<object[]>([
    { ZOHO_AU: "LOADING..." },
    { ZOHO_CA: "LOADING..." },
    { ZOHO_NZ: "LOADING..." },
    { ZOHO_UK: "LOADING..." },
    { DB: "LOADING..." },
    { ADMIN: "LOADING..." },
    { USER: "LOADING..." },
  ])
  const [allSystemsOperational, setAllSystemsOperational] =
    useState<boolean>(true)

  useEffect(() => {
    getServiceStatus()
  }, [])

  const getServiceStatus = async () => {
    setLastRefreshed(new Date())

    await axios
      .get(`${process.env.REACT_APP_API_URL}public/v1/server-status`)
      .then((res) => {
        const servicesArr = Object.entries(res.data.data).map((entry) => {
          return { [entry[0]]: entry[1] }
        })
        setServices(servicesArr)

        servicesArr.every(
          (service) => String(Object.values(service)) === "ACTIVE"
        )
          ? setAllSystemsOperational(true)
          : setAllSystemsOperational(false)

        setLoading(false)
      })
      .catch()
  }

  return (
    <div className="relative max-w-7xl mx-auto">
      <h1 className="text-3xl leading-6 font-medium text-gray-900 mb-6">
        Service Status
      </h1>
      <>
        {loading ? (
          <div className="text-slate-700 bg-slate-200 p-4 rounded-lg border border-slate-300 mb-6 flex justify-between">
            <h2>Fetching service statuses...</h2>
          </div>
        ) : allSystemsOperational ? (
          <div className="text-green-700 bg-green-200 p-4 rounded-lg border border-green-300 mb-6 flex justify-between">
            <h2>All Systems Operational</h2>
            <p>Refreshed {dayjs(lastRefreshed).fromNow()}</p>
          </div>
        ) : (
          <div className="text-orange-700 bg-orange-200 p-4 rounded-lg border border-orange-300 mb-6 flex justify-between">
            <h2>Partial System Outages</h2>
            <p>Refreshed {dayjs(lastRefreshed).fromNow()}</p>
          </div>
        )}
        <div className="flex flex-col">
          <div className="bg-gray-200 p-4 border border-gray-300 flex flex-col justify-between sm:flex-row">
            <p className="font-semibold">Current status by service</p>
            <div className="flex gap-x-6">
              <div className="flex gap-x-1 items-center">
                <CheckCircleIcon className="w-5 h-5 text-green-700" />
                <p>No issues</p>
              </div>
              <div className="flex gap-x-1 items-center">
                <ExclamationIcon className="w-5 h-5 text-orange-700" />
                <p>Outage</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            {services.map((service, index) => (
              <div
                className="flex justify-between items-center w-full sm:w-1/2 bg-white border border-gray-300 p-2"
                key={index}
              >
                <div className="flex flex-col">
                  <p className="font-semibold">{Object.keys(service)}</p>
                  <p className="text-slate-500 text-sm">
                    {Object.values(service)}
                  </p>
                </div>
                {String(Object.values(service)) === "LOADING..." ? (
                  <img src={spinner} className="w-8" />
                ) : String(Object.values(service)) === "ACTIVE" ? (
                  <CheckCircleIcon className="w-8 h-8 text-green-700" />
                ) : (
                  <ExclamationIcon className="w-8 h-8 text-orange-700" />
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  )
}

export default ServiceStatus
