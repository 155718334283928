export const tableData = [
  {
    name: "Email",
    sort: "email"
  },
  {
    name: "Region",
    sort: "country",
  },
  {
    name: "Payment Type",
    sort: "payment_type",
  },
  {
    name: "Payment Amount",
    sort: "amount",
  },
  {
    name: "Salesperson",
    sort: "salesperson",
  },
  {
    name: "Date Submitted",
    sort: "date",
  },
  {
    name: "Actioned by",
    sort: "updateById",
  },
  {
    name: "Date Actioned",
    sort: "updatedAt",
  },
  {
    name: "Module",
    sort: "module_name",
  },
  {
    name: "Customer ID",
    sort: "id",
  }
];
