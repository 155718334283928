import { FC, Fragment, useRef } from "react"
import { useHistory } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import { UserIcon } from "@heroicons/react/outline"

interface CustomerInfoModalProps {
  customerInfoModalOpen: boolean
  setCustomerInfoModalOpen: (args: boolean) => void
  fromZoho?: boolean
}

const CustomerInfoModal: FC<CustomerInfoModalProps> = ({
  customerInfoModalOpen,
  setCustomerInfoModalOpen,
  fromZoho = false,
}) => {
  const history = useHistory()
  const cancelButtonRef = useRef(null)

  const dateOfBirth = sessionStorage.getItem("date_of_birth") || ""
  const [year, month, day]: string[] = dateOfBirth.split("-")
  const formattedDateOfBirth = [day, month, year].join("/")

  return (
    <Transition.Root show={customerInfoModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setCustomerInfoModalOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                  <UserIcon
                    className="h-6 w-6 text-yellow-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Confirm customer information
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 mb-2">
                      Please confirm available information with the customer on
                      the phone now:
                    </p>
                    <ul className="flex flex-col gap-1 text-gray-900">
                      <li className="text-sm">
                        <strong>Full name:</strong>{" "}
                        {sessionStorage.getItem("full_name")}
                      </li>
                      <li className="text-sm">
                        <strong>Email:</strong>{" "}
                        {sessionStorage.getItem("email")}
                      </li>
                      <li className="text-sm">
                        <strong>Mobile:</strong>{" "}
                        {sessionStorage.getItem("mobile") !== "null"
                          ? sessionStorage.getItem("mobile")
                          : "N/A"}
                      </li>
                      <li className="text-sm">
                        <strong>Date of Birth:</strong>{" "}
                        {sessionStorage.getItem("date_of_birth") !== "N/A"
                          ? formattedDateOfBirth
                          : "N/A"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    if (fromZoho) {
                      if (sessionStorage.getItem("country") === "CA") {
                        history.push("/payment-portal/province-select")
                      } else if (sessionStorage.getItem("country") === "UK") {
                        history.push("/payment-portal/currency-select")
                      } else if (
                        sessionStorage.getItem("country") === "AU" ||
                        sessionStorage.getItem("country") === "NZ" ||
                        sessionStorage.getItem("country") === "PCA"
                      ) {
                        history.push("/payment-portal/product-plan")
                      }
                    } else {
                      history.push("/payment-portal/transaction-type")
                    }
                  }}
                >
                  Proceed
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setCustomerInfoModalOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CustomerInfoModal
