import Decimal from "decimal.js";
import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PerformanceManagerSearchMenu from "./components/PerformanceManagerSearchMenu";
import ProductPlanSearchMenu from "./components/ProductPlanSearchMenu";
import HelpVideo from "../../components/help-video/HelpVideo";
import "react-datepicker/dist/react-datepicker.css";
import ProductTypeSelectMenu from "./components/ProductTypeSelectMenu";
import PharmacySelectMenu from "./components/PharmacySelectMenu";
import InfoBlock from "../../components/layout/info-block/InfoBlock";
import axios from "axios";
import { ReactComponent as Arrow } from "../../../../assets/common/arrowDown.svg";
import spinnerNavy from "../../../../assets/common/spinnerNavy.svg";

interface Opportunity {
  opportunity_id: string;
  name: string;
  product_type: string;
  plan_info: {
    id: string;
    name: string;
  };
  stripe_subscription: {
    amount: number;
  };
  is_medication: boolean;
}

const CreateOpportunityQuebec = () => {
  const region = sessionStorage.getItem("country") as string;
  // const contactID = sessionStorage.getItem("id") as string
  // const contactName = sessionStorage.getItem("full_name") as string

  const [, setPmName] = useState<string>("");
  const [pmID, setPmID] = useState<string>("");
  const [productPlanID, setProductPlanID] = useState<string>("");
  const [, setProductPlanName] = useState<string>("");
  const currency = "CAD";

  const [productType, setProductType] = useState<string>("");
  const [pharmacyID, setPharmacyID] = useState<string>("");
  const [, setPharmacyName] = useState<string>("");
  const [, setMedicineTotalCost] = useState<number>(0);
  const [medicineCount, setMedicineCount] = useState<number>(1);
  const [helpVideoOpen, setHelpVideoOpen] = useState<boolean>(false);

  const [parentOpportunityId, setParentOpportunityId] = useState("");
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [loadingOpportunities, setLoadingOpportunities] = useState(false);
  const [opportunityError, setOpportunityError] = useState<
    string | JSX.Element
  >("");

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const history = useHistory();

  const handleProceed = () => {
    history.push("/payment-portal/create-opportunity/quebec-details");
  };

  const [isMedicineCountValid, setIsMedicineCountValid] = useState(true);

  const fetchOpportunities = async () => {
    setLoadingOpportunities(true);
    setOpportunityError("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
        {
          contact_id: sessionStorage.getItem("id"),
          country: sessionStorage.getItem("country"),
        }
      );

      console.log("response", response.data.data);

      const planOpportunities = response.data.data.filter(
        (opp: Opportunity) => !opp.is_medication
      );
      // const planOpportunities = response.data.data;
      setOpportunities(planOpportunities);
      setLoadingOpportunities(false);
    } catch (err) {
      setLoadingOpportunities(false);
      setOpportunityError(
        (err as { response?: { data?: { message?: string } } })?.response?.data
          ?.message || "Error fetching opportunities"
      );
    }
  };

  useEffect(() => {
    if (productType === "Medication") {
      fetchOpportunities();
    }
  }, [productType, pmID]);

  const handleMedicineCount = (count: number) => {
    const cost30Pack = Number(
      sessionStorage.getItem("pharmacy_30_capsule_cost")
    );
    const costSingle = Number(sessionStorage.getItem("pharmacy_capsule_cost"));

    setMedicineCount(count);

    // Validate if input needs to be multiple of 30
    if (cost30Pack && !costSingle) {
      const isValid = count > 0 && count % 30 === 0;
      setIsMedicineCountValid(isValid);

      if (!isValid) {
        sessionStorage.removeItem("pharmacy_medicine_count");
        sessionStorage.removeItem("plan_price");
        return;
      }
    } else {
      const isValid = count > 0;
      setIsMedicineCountValid(isValid);

      if (!isValid) {
        sessionStorage.removeItem("pharmacy_medicine_count");
        sessionStorage.removeItem("plan_price");
        return;
      }
    }

    // Calculate total medication cost
    let totalMedicationCost = 0;
    if (cost30Pack) {
      const packs30 = Math.floor(count / 30);
      const remainingUnits = count % 30;
      totalMedicationCost =
        packs30 * cost30Pack + remainingUnits * (costSingle || 0);
      // Add 40% cut
      totalMedicationCost = totalMedicationCost + 0.4 * totalMedicationCost;
    } else if (costSingle) {
      totalMedicationCost = count * costSingle;
      // Add 40% cut
      totalMedicationCost = totalMedicationCost + 0.4 * totalMedicationCost;
    }

    setMedicineTotalCost(+totalMedicationCost);

    sessionStorage.setItem("plan_price", totalMedicationCost.toFixed(2));
    sessionStorage.setItem("pharmacy_medicine_count", String(count));
  };

  useEffect(() => {
    setProductPlanID("");
    setProductPlanName("");
    setPharmacyID("");
    setPharmacyName("");

    sessionStorage.removeItem("plan_id");
    sessionStorage.removeItem("plan_name");
    sessionStorage.removeItem("plan_type");
    sessionStorage.removeItem("plan_price");
  }, [productType]);

  useEffect(() => {
    if (!pmID || !productPlanID) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      productType === "Medication" &&
      (!pharmacyID || !isMedicineCountValid)
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (
      sessionStorage.getItem("product_type") === "Medication" &&
      !parentOpportunityId
    ) {
      setNextButtonDisabled(true);
      return;
    }

    if (productType === "Medication") {
      const planPrice = Number(sessionStorage.getItem("plan_price"));
      const dispensingFee = Number(
        sessionStorage.getItem("pharmacy_dispensing_fee")
      );
      const groundDeliveryFee = Number(
        sessionStorage.getItem("pharmacy_ground_delivery_fee")
      );
      const taxRate = Number(sessionStorage.getItem("tax_rate"));

      const totalPrice =
        Number(planPrice) +
        (taxRate
          ? Number(groundDeliveryFee + dispensingFee) +
            Number(
              new Decimal(((groundDeliveryFee + dispensingFee) * taxRate) / 100)
            )
          : Number(groundDeliveryFee + dispensingFee));

      if (parentOpportunityId) {
        const parentOpportunity = opportunities.find(
          (opp) => opp.opportunity_id === parentOpportunityId
        );

        if (parentOpportunity?.stripe_subscription?.amount) {
          const parentAmount = Number(
            parentOpportunity.stripe_subscription.amount
          );

          console.log("totalPrice", totalPrice);
          console.log("parentAmount", parentAmount);

          if (totalPrice > parentAmount) {
            setNextButtonDisabled(true);
            setOpportunityError(
              <span>
                Total medication price cannot be greater than current parent
                opportunity amount.
                <br />
                Current parent opportunity amount: ${parentAmount}
                <br />
                Medication plan price: ${planPrice}
                <br />
                Medication ground delivery fee: ${groundDeliveryFee}
                <br />
                Medication dispensing fee: ${dispensingFee}
                <br />
                Medication tax rate: {taxRate}%<br />
                Medication total price: ${totalPrice}
              </span>
            );
            return;
          }
        }
      }
    }

    setNextButtonDisabled(false);
    setOpportunityError("");
  }, [
    pmID,
    productPlanID,
    productType,
    pharmacyID,
    isMedicineCountValid,
    parentOpportunityId,
    medicineCount,
  ]);

  useEffect(() => {
    if (pharmacyID) {
      handleMedicineCount(medicineCount);
    }
  }, [pharmacyID]);

  useEffect(() => {
    const removeKeys = ["opportunity_id", "stage", "isDiscount"];
    removeKeys.forEach((key) => sessionStorage.removeItem(key));
  }, []);

  const [isParentOpportunityOpen, setIsParentOpportunityOpen] = useState(false);
  const parentOpportunityRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!parentOpportunityRef.current?.contains(e.target as Node)) {
        setIsParentOpportunityOpen(false);
      }
    },
    [setIsParentOpportunityOpen]
  );

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h3 className="text-3xl leading-6 font-medium text-gray-900">
        Create Opportunity
      </h3>
      <button
        className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
        onClick={() => setHelpVideoOpen(true)}
      >
        Need Help?
      </button>
      <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />

      <div className="flex gap-x-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            <div className="w-full px-4 sm:px-6 lg:px-8 my-6">
              <PerformanceManagerSearchMenu
                setPmName={setPmName}
                setPmID={setPmID}
                region={region}
              />
            </div>

            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="currency"
                className="block text-sm font-medium text-gray-700"
              >
                Are you charging for a Product Plan or Medication?
              </label>
              <ProductTypeSelectMenu setProductType={setProductType} />
            </div>

            {productType && pmID && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <ProductPlanSearchMenu
                  setProductPlanID={setProductPlanID}
                  setProductPlanName={setProductPlanName}
                  region={region}
                  currency={currency}
                  key={productType}
                />
              </div>
            )}

            {productType === "Medication" && productPlanID && pmID && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <PharmacySelectMenu
                  setPharmacyID={setPharmacyID}
                  setPharmacyName={setPharmacyName}
                  productPlanID={productPlanID}
                  region={region}
                  key={productPlanID}
                />
              </div>
            )}
            {productType === "Medication" &&
              pharmacyID &&
              productPlanID &&
              pmID && (
                <>
                  <div className="mb-8 sm:mt-0 w-full px-4 sm:px-6 lg:px-8">
                    <div className="block text-sm font-medium text-gray-700">
                      Medicine count
                      {Number(
                        sessionStorage.getItem("pharmacy_30_capsule_cost")
                      ) &&
                      !Number(
                        sessionStorage.getItem("pharmacy_capsule_cost")
                      ) ? (
                        <span
                          className={`ml-2 ${
                            isMedicineCountValid
                              ? "text-gray-500"
                              : "text-red-500"
                          }`}
                        >
                          (Must be a multiple of 30)
                        </span>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`mt-2 rounded-md border w-full h-12 bg-E0E0E0 flex pl-2 text-828282 focus:outline-none focus:ring-0 font-medium ${
                        isMedicineCountValid
                          ? "border-c4c4c4 focus:border-c4c4c4"
                          : "border-red-500 focus:border-red-500"
                      }`}
                      value={Number(medicineCount).toString()}
                      onChange={(e) => handleMedicineCount(+e.target.value)}
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                        (e.target as HTMLInputElement).blur()
                      }
                    />
                  </div>

                  <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                    <div className="flex">
                      <label
                        htmlFor="parent_opportunity"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Select Parent Opportunity
                      </label>
                      {loadingOpportunities && (
                        <div className="flex items-center">
                          <img src={spinnerNavy} alt="" width="20px" />
                          <div className="block text-sm font-medium text-gray-700">
                            Fetching opportunities...
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="relative mt-2">
                      <div
                        className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
                        onClick={() =>
                          !loadingOpportunities &&
                          setIsParentOpportunityOpen(!isParentOpportunityOpen)
                        }
                        ref={parentOpportunityRef}
                      >
                        <div className="flex items-center pl-4 font-medium text-828282 w-full">
                          {opportunities.find(
                            (opp) => opp.opportunity_id === parentOpportunityId
                          )?.plan_info.name || "Select a parent opportunity"}
                        </div>
                        <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
                          <Arrow />
                        </div>
                        {isParentOpportunityOpen && !loadingOpportunities && (
                          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
                            {opportunities.map((opp, index) => (
                              <div
                                className={`${
                                  index === 0 ? "" : "border-t border-F0F0F0"
                                } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                                key={opp.opportunity_id}
                                onClick={() => {
                                  setParentOpportunityId(opp.opportunity_id);
                                  sessionStorage.setItem(
                                    "parent_opportunity_id",
                                    opp.opportunity_id
                                  );
                                  sessionStorage.setItem(
                                    "parent_opportunity_name",
                                    opp.plan_info.name
                                  );
                                }}
                              >
                                {opp.plan_info.name} Product Plan
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {opportunityError && (
                      <p className="mt-2 text-sm text-red-600">
                        {opportunityError}
                      </p>
                    )}
                  </div>
                </>
              )}
          </div>

          <div className="flex justify-between w-full px-4 sm:px-6 lg:px-8">
            <button
              type="button"
              className={`text-white bg-FF5733 hover:bg-FF5733_hover my-6 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
              onClick={() => history.goBack()}
            >
              Back
            </button>
            <button
              type="button"
              className={`${
                nextButtonDisabled
                  ? "bg-E0E0E0 text-828282"
                  : "text-white bg-FF5733 hover:bg-FF5733_hover"
              } my-6 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
              onClick={() => handleProceed()}
              disabled={nextButtonDisabled}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <InfoBlock />
    </div>
  );
};

export default CreateOpportunityQuebec;
