import { FC, useEffect, useState } from "react"
import UpdateLeadOwnerModal from "./components/UpdateLeadOwnerModal"
import PaymentLinkConfirmationModal from "./components/PaymentLinkConfirmationModal"
import AdditionalPercentModal from "../../components/additional-percent-modal/AdditionalPercentModal"
import ExpressPaymentModal from "../../components/express-payment-modal/ExpressPaymentModal"
import Layout from "../../components/layout/Layout"

interface PropTypes {}

const PaymentLink: FC<PropTypes> = () => {

  const createOpportunitySkipped =
    sessionStorage.getItem("createOpportunitySkipped") === "Yes"

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [amount, setAmount] = useState("")
  const [amountErrorMessage, setAmountErrorMessage] = useState(false)
  const [amountZeroMessage, setAmountZeroMessage] = useState(false)
  const [paymentLinkConfirmationModalOpen, setPaymentLinkConfirmationModalOpen] =
    useState(false)
  const [updateLeadOwnerModalOpen, setUpdateLeadOwnerModalOpen] =
    useState(false)
  const [checkPaymentSuccess, setCheckPaymentSuccess] = useState("")

  const [isNoAddCharge, setIsNoAddCharge] = useState(false)

  const isPlanAddPercent =
    Boolean(sessionStorage.getItem("plan_add_percent")) && (((sessionStorage.getItem("country") === "PCA" || sessionStorage.getItem("province") === "Quebec") && sessionStorage.getItem("module") === "LEADS") 
      ? !(sessionStorage.getItem("isFullPayment") === "Yes" || sessionStorage.getItem("isFullPayment") !== "Yes" && Number(amount) >= Number(sessionStorage.getItem("total_program_price")))
      : Number(amount) < Number(sessionStorage.getItem("total_program_price")))
  const [additionalPercentModalOpen, setAdditionalPercentModalOpen] = useState(false)

  const isExpressPaymentConfirmation =
  !createOpportunitySkipped &&
  sessionStorage.getItem("opportunity_add_percent") !== "" &&
  sessionStorage.getItem("transaction_type") === "Collection PM - Express Payment" &&
  sessionStorage.getItem("module") === "CONTACTS"
  const [expressPaymentModalOpen, setExpressPaymentModalOpen] = useState(false)

  const amountRemaining = Number(sessionStorage.getItem("amount_remaining")).toFixed(2);
  const opportunityAddPercent = Number(sessionStorage.getItem("opportunity_add_percent")).toFixed(2);
  const discountAmount = Number(Number(amountRemaining) * Number(opportunityAddPercent) / 100).toFixed(2);
  const discountedBalance = Number((Number(amountRemaining) - Number(discountAmount)).toFixed(2));

  const handleProceed = () => {
    if (!Number(amount)) {
      setAmountZeroMessage(true)
      return
    }

    if (sessionStorage.getItem("module") === "LEADS") {
      if (sessionStorage.getItem("country") === "CA") {
        if (+amount > Number(sessionStorage.getItem("total_program_price"))) {
          setAmountErrorMessage(true)
          return
        }
      }

      if (sessionStorage.getItem("country") !== "CA") {
        if (+amount > Number(sessionStorage.getItem("plan_price"))) {
          setAmountErrorMessage(true)
          return
        }
      }

      setAmountErrorMessage(false)
      if (
        isPlanAddPercent && !isNoAddCharge
      ) {
        sessionStorage.setItem("isPlanAddPercent", "Yes")
        setAdditionalPercentModalOpen(true)
      } else {
        sessionStorage.setItem("isPlanAddPercent", "No")
        setUpdateLeadOwnerModalOpen(true)
      }
    }

    else if (sessionStorage.getItem("module") === "CONTACTS") {

      let remainingBalance = Number(sessionStorage.getItem("amount_remaining"))
      if (createOpportunitySkipped) {
        remainingBalance = Number(sessionStorage.getItem("total_program_price"))
      }

      if (+amount > remainingBalance) {
        setAmountErrorMessage(true)
      } else {

        setAmountErrorMessage(false)
        
        if (isPlanAddPercent && !isNoAddCharge) {
          sessionStorage.setItem("isPlanAddPercent", "Yes")
          sessionStorage.setItem("isDiscount", "No")
          setAdditionalPercentModalOpen(true)
        } else if (isExpressPaymentConfirmation && +amount === discountedBalance) {
          sessionStorage.setItem("isPlanAddPercent", "No")
          sessionStorage.setItem("isDiscount", "Yes")
          setExpressPaymentModalOpen(true)
        } else {
          sessionStorage.setItem("isPlanAddPercent", "No")
          sessionStorage.setItem("isDiscount", "No")
          setPaymentLinkConfirmationModalOpen(true)
        }
      }
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem("check_payment_msg")) {
      setCheckPaymentSuccess("SUCCESS")
    }
    setTimeout(() => {
      setCheckPaymentSuccess("")
    }, 4000)
  }, [])

  useEffect(() => {
    amount && setNextButtonDisabled(false)
  }, [amount])

  return (
    <Layout
      pageNumber={12}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <UpdateLeadOwnerModal
        updateLeadOwnerModalOpen={updateLeadOwnerModalOpen}
        setUpdateLeadOwnerModalOpen={setUpdateLeadOwnerModalOpen}
        setPaymentLinkConfirmationModalOpen={setPaymentLinkConfirmationModalOpen}
      />
      <PaymentLinkConfirmationModal
        paymentLinkConfirmationModalOpen={paymentLinkConfirmationModalOpen}
        setPaymentLinkConfirmationModalOpen={setPaymentLinkConfirmationModalOpen}
        amount={amount}
      />
      <AdditionalPercentModal
        additionalPercentModalOpen={additionalPercentModalOpen}
        setAdditionalPercentModalOpen={setAdditionalPercentModalOpen}
        nextModalOpen={sessionStorage.getItem("module") === "LEADS" ? setUpdateLeadOwnerModalOpen : setPaymentLinkConfirmationModalOpen}
      />
      <ExpressPaymentModal
        expressPaymentModalOpen={expressPaymentModalOpen}
        setExpressPaymentModalOpen={setExpressPaymentModalOpen}
        nextModalOpen={setPaymentLinkConfirmationModalOpen}
      />

      <div className="w-full h-full flex flex-col justify-evenly items-center">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          {sessionStorage.getItem("check_payment_msg") &&
            checkPaymentSuccess === "SUCCESS" && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center mb-4">
                <span className="block sm:inline">
                  {sessionStorage.getItem("check_payment_msg")}
                </span>
              </div>
            )}
        </div>

        {isPlanAddPercent && (
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-6 relative flex items-start">
            <div className="mr-3 flex items-center h-5">
              <input
                type="checkbox"
                className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                checked={isNoAddCharge}
                onClick={() => setIsNoAddCharge(!isNoAddCharge)}
              />
            </div>
            <div className="text-sm">
              <label
                htmlFor="region_access"
                className="font-medium text-gray-700 select-none"
              >
                Are you collecting full payment after doctors appointment? (No additional charge will be applied if this is the case)
              </label>
            </div>
          </div>
        )}

        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="block text-sm font-medium text-gray-700">
            Amount (
            {sessionStorage.getItem("currency") === "EUR"
              ? "€"
              : sessionStorage.getItem("currency") === "GBP"
              ? "£"
              : "$"}
            )
          </div>
          <input
            type="number"
            className="mt-2 rounded-md border-c4c4c4 h-12 bg-E0E0E0 flex items-center pl-2 text-828282 w-308 sm:w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
              (e.target as HTMLInputElement).blur()
            }
          />
          {(isPlanAddPercent && !isNoAddCharge) && (
            <>
              <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
                <div>
                  {" "}
                  Additional percent charge ({sessionStorage.getItem("plan_add_percent")}%) will be applied as payment being collected is on a payment plan.
                  Total program price will be{" "}
                  {
                    sessionStorage.getItem("currency") === "EUR" ? "€"
                    : sessionStorage.getItem("currency") === "GBP" ? "£"
                    : "$"
                  }
                  {Number(sessionStorage.getItem("total_program_price_add_percent"))}
                  {" "}
                </div>
              </div>
              <div className="text-center text-md text-amber-600 mt-2 px-4 sm:px-6 lg:px-8">
                <div>
                  {" "}
                  Discounted price is{" "}
                  {
                    sessionStorage.getItem("currency") === "EUR" ? "€"
                    : sessionStorage.getItem("currency") === "GBP" ? "£"
                    : "$"
                  }
                  {Number(sessionStorage.getItem("total_program_price"))}
                  {" "}
                </div>
              </div>
            </>
          )}
          {(isExpressPaymentConfirmation) && (
            <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
              <div>
                {" "}
                Discount will be applied for remaining balance and must be paid in full. Discounted remaining balance is
                {" "}
                {
                  sessionStorage.getItem("currency") === "EUR" ? "€"
                  : sessionStorage.getItem("currency") === "GBP" ? "£"
                  : "$"
                }
                {discountedBalance.toFixed(2)}
                {" "}
                and discount amount is
                {" "}
                {
                  sessionStorage.getItem("currency") === "EUR" ? "€"
                  : sessionStorage.getItem("currency") === "GBP" ? "£"
                  : "$"
                }
                {discountAmount}
              </div>
            </div>
          )}
          
          {amountErrorMessage ? (
            sessionStorage.getItem("module") === "LEADS" || createOpportunitySkipped ? (
              <div className="mt-2 text-red-600">
                Cannot process amount higher than the selected program price (
                {`${sessionStorage.getItem("currency")}${Number(sessionStorage.getItem("total_program_price")).toFixed(2)}`}
                )
              </div>
            ) : (
              sessionStorage.getItem("module") === "CONTACTS" && (
                <div className="mt-2 text-red-600">
                  Cannot process amount higher than the remaining amount for this
                  opportunity (
                  {`${sessionStorage.getItem("currency")}${Number(
                    sessionStorage.getItem("amount_remaining")
                  ).toFixed(2)}`}
                  )
                </div>
              )
            )
          ) : <></>}
          {amountZeroMessage && (
            <div className="mt-2 text-red-600">
              Please enter an amount greater than zero.
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PaymentLink