import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import {
  CheckCircleIcon,
  XCircleIcon,
  BadgeCheckIcon,
  PhotographIcon,
  EyeIcon,
  ClockIcon,
} from "@heroicons/react/solid"

interface MyProps {
  payments: EftPayment[]
  setModalData: (args: EftPayment) => void
  setDetailsModalOpen: (args: boolean) => void
  setApprovalModalOpen: (args: boolean) => void
  setApprovalModalType: (args: string) => void
}

type EftPayment = {
  performance_manager: {
    id: string
    name: string
  }
  product_plan: {
    id: string
    name: string
    type: string
  }
  id: number
  module_name: string
  email: string
  country: string
  payment_type: string
  opportunity_id: string
  lead_id: string
  contact_id: string
  admin_id: string
  url: string
  amount: number
  eft_status: string
  stage: string
  program_start: string
  program_end: string
  province: string
  currency: string
  status: true
  createdById: string
  updatedById: string
  createdAt: string
  updatedAt: string
  admin: {
    id: string
    first_name: string
    last_name: string
  }
  createdBy: {
    first_name: string
    last_name: string
    email: string
  }
  updatedBy: {
    first_name: string
    last_name: string
    email: string
  }
  salesperson: string
  date: string
}

const Payments: FC<MyProps> = ({
  payments,
  setModalData,
  setDetailsModalOpen,
  setApprovalModalOpen,
  setApprovalModalType,
}) => {
  const [access, setAccess] = useState(false)
  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )

  const handleAccess = () => {
    const valuesArr = userInformation.admins_features.map(
      (item: { value: string }) => item.value
    )

    if (valuesArr.includes("EFT_APPROVER")) setAccess(true)
  }

  useEffect(() => {
    handleAccess()
  }, [userInformation])

  const handleDetailsModal = (payment: EftPayment) => {
    setModalData(payment)
    setDetailsModalOpen(true)
  }

  const formatDateString = (date: string) => {
    return (
      new Date(Date.parse(date)).toISOString().split("T")[0] +
      " " +
      new Date(Date.parse(date)).toISOString().split("T")[1].slice(0, -5)
    )
  }

  const returnRowClass = (payment: EftPayment) => {
    if (payment.eft_status === "CONVERTED" || payment.eft_status === "APPROVED")
      return "bg-green-200"
    if (payment.eft_status === "REJECTED") return "bg-red-200"
  }

  return (
    <>
      {payments?.map((payment, paymentIdx) => (
        <>
          <tr className={returnRowClass(payment)} key={paymentIdx}>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div className="flex justify-around">
                {payment.eft_status === "APPROVED" && (
                  <div className="w-28 mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 focus:outline-none focus:ring-0 justify-center">
                    <CheckCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Approved
                  </div>
                )}
                {payment.eft_status === "REJECTED" && (
                  <div className="w-28 mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 focus:outline-none focus:ring-0 justify-center">
                    <XCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Rejected
                  </div>
                )}
                {payment.eft_status === "CONVERTED" && (
                  <div className="w-30 mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-900 focus:outline-none focus:ring-0 justify-center">
                    <BadgeCheckIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Converted
                  </div>
                )}
                {payment.eft_status === "PENDING" ? (
                  userInformation.type === "MASTER" ||
                  userInformation.type === "ADMIN" ||
                  access ||
                  (payment.country === "CA" &&
                    payment.admin_id === userInformation.id) ? (
                    <>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(payment)
                          setApprovalModalType("APPROVE")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <CheckCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Approve
                      </button>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(payment)
                          setApprovalModalType("REJECT")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <XCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Reject
                      </button>
                    </>
                  ) : (
                    <div className="w-28 mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 focus:outline-none focus:ring-0 justify-center">
                      <ClockIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Pending
                    </div>
                  )
                ) : null}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {payment.country !== "UK" && (
                <a
                  href={
                    payment.module_name === "LEADS"
                      ? `https://crm.zoho.com/crm/${
                          payment.country === "AU"
                            ? "org52657875"
                            : payment.country === "CA"
                            ? "org760139034"
                            : payment.country === "NZ"
                            ? "org771947106"
                            : ""
                        }/tab/Leads/${payment.lead_id}`
                      : `https://crm.zoho.com/crm/${
                          payment.country === "AU"
                            ? "org52657875"
                            : payment.country === "CA"
                            ? "org760139034"
                            : payment.country === "NZ"
                            ? "org771947106"
                            : ""
                        }/tab/Contacts/${payment.contact_id}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gray-500"
                >
                  {payment.email}
                </a>
              )}
              {payment.country === "UK" && (
                <a
                  href={
                    payment.module_name === "LEADS"
                      ? `https://crm.zoho.eu/crm/org20067563176/tab/Leads/${payment.lead_id}`
                      : `https://crm.zoho.eu/crm/org20067563176/tab/Contacts/${payment.contact_id}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-gray-500"
                >
                  {payment.email}
                </a>
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.country}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.payment_type}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              ${payment.amount.toFixed(2)}
            </td>
            <td className="flex flex-col px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div>{payment.salesperson}</div>
              <div>{payment.createdBy.email}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.date}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.eft_status === "PENDING" ? (
                "N/A"
              ) : (
                <div className="flex flex-col">
                  <div>
                    {payment.updatedBy.first_name +
                      " " +
                      payment.updatedBy.last_name}
                  </div>
                  <div>{payment.updatedBy.email}</div>
                </div>
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.eft_status === "PENDING"
                ? "N/A"
                : formatDateString(payment.updatedAt)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.module_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {payment.lead_id}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <EyeIcon
                className="cursor-pointer mx-auto h-6 w-6 hover:text-gray-400"
                aria-hidden="true"
                onClick={() => handleDetailsModal(payment)}
              />
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <a href={payment.url} target="_blank" rel="noreferrer">
                <PhotographIcon
                  className="cursor-pointer mx-auto h-6 w-6 hover:text-gray-400"
                  aria-hidden="true"
                />
              </a>
            </td>
          </tr>
        </>
      ))}
    </>
  )
}

export default Payments
