import { useState, useEffect } from "react"
import "@stripe/stripe-js"
import { Stripe, loadStripe } from "@stripe/stripe-js"
import Layout from "../../components/layout/Layout"
import LeadConfirmationModal from "./components/LeadConfirmationModal"
import { ReactComponent as Visa } from "../../../../assets/paymentCollection/visa.svg"
import { ReactComponent as Mastercard } from "../../../../assets/paymentCollection/mastercard.svg"
import { ReactComponent as Amex } from "../../../../assets/paymentCollection/amex.svg"
import { ReactComponent as Discover } from "../../../../assets/paymentCollection/discover.svg"
import { ReactComponent as Calendar } from "../../../../assets/common/calendar.svg"
import { ReactComponent as CVV } from "../../../../assets/common/lockIcon.svg"
import UpdateLeadOwnerModal from "./components/UpdateLeadOwnerModal"
import CALeadConfirmationModal from "./components/CALeadConfirmationModal"
import UKLeadConfirmationModal from "./components/UKLeadConfirmationModal"
import PCALeadConfirmationModal from "./components/PCALeadConfirmationModal"
import NZLeadConfirmationModal from "./components/NZLeadConfirmationModal"
import AdditionalPercentModal from "../../components/additional-percent-modal/AdditionalPercentModal"

const CardDetails = () => {
  const nextButtonDisabled = false
  const [checkPaymentMessage, setCheckPaymentMessage] = useState(true)
  const [cardName, setCardName] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [cardMonth, setCardMonth] = useState("")
  const [cardYear, setCardYear] = useState("")
  const [cardCVC, setCardCVC] = useState("")
  const [cardAmount, setCardAmount] = useState("")
  const [cardError, setCardError] = useState(false)
  const [cardErrorName, setCardErrorName] = useState("")
  const [updateLeadOwnerModalOpen, setUpdateLeadOwnerModalOpen] =
    useState(false)
  const [leadConfirmationModalOpen, setLeadConfirmationModalOpen] =
    useState(false)
  const [caLeadConfirmationModalOpen, setCALeadConfirmationModalOpen] =
    useState(false)
  const [ukLeadConfirmationModalOpen, setUKLeadConfirmationModalOpen] =
    useState(false)
  const [pcaLeadConfirmationModalOpen, setPCALeadConfirmationModalOpen] =
    useState(false)
  const [nzLeadConfirmationModalOpen, setNZLeadConfirmationModalOpen] =
    useState(false)

  const [isNoAddCharge, setIsNoAddCharge] = useState(false)

  const isPlanAddPercent =
    Boolean(sessionStorage.getItem("plan_add_percent")) && ((sessionStorage.getItem("country") === "PCA" && sessionStorage.getItem("module") === "LEADS") 
      ? !(sessionStorage.getItem("isFullPayment") === "Yes" || sessionStorage.getItem("isFullPayment") !== "Yes" && Number(cardAmount) >= Number(sessionStorage.getItem("total_program_price")))
      : Number(cardAmount) < Number(sessionStorage.getItem("total_program_price")))
  const [additionalPercentModalOpen, setAdditionalPercentModalOpen] = useState(false)

  const [stripe, setStripe] = useState<Stripe | null>()
  const initializeStripe = async () => {
    if (process.env.NODE_ENV === "production") {
      await loadStripe(
        "pk_live_51Km48rGa6h2jnoFCtF0ifGbKtOLsF355FGMTQJDMlthGscRMPslI3MEIsEB2jfFSEFeT0kwmaJJoz3Ku5ehQ3j5M00Zz7fOsyu"
      ).then((res) => setStripe(res))
    } else if (process.env.NODE_ENV === "development") {
      await loadStripe(
        "pk_test_51Km48rGa6h2jnoFCnxVdHSMUKIBtgBFsjhBLxBRLlu4jTSUlYwiamvv15ReXTT0a6eLZEWlZ5A5buxE8NMLbGn7D00drmO5Kug"
      ).then((res) => setStripe(res))
    }
  }

  useEffect(() => {
    initializeStripe()
  }, [])

  const handleProceed = () => {
    if (cardName === "") {
      setCardError(true)
      setCardErrorName("Please enter cardholder name")
      return
    }

    if (!validateCard(cardNumber) || cardNumber === "") {
      setCardError(true)
      setCardErrorName("Please enter valid card number")
      return
    }

    if (
      !validateNumber(cardMonth) ||
      !validateNumber(cardYear) ||
      cardMonth === "" ||
      cardYear === ""
    ) {
      setCardError(true)
      setCardErrorName("Please enter valid expiration date")
      return
    }

    if (!validateNumber(cardCVC) || cardCVC === "") {
      setCardError(true)
      setCardErrorName("Please enter valid CVV")
      return
    }

    if (
      sessionStorage.getItem("isScheduledDeposit") !== "Yes" &&
      sessionStorage.getItem("isRecurring") !== "Yes"
    ) {
      if (!Number(cardAmount)) {
        setCardError(true)
        setCardErrorName("Please enter payment amount greater than zero")
        return
      }
    }

    if (
      Number(cardAmount) >
      (sessionStorage.getItem("tax_rate")
        ? Number(
            (
              Number(sessionStorage.getItem("plan_price")) +
              +Number(
                sessionStorage.getItem("tax_rate")
                  ? Number(
                      (Number(sessionStorage.getItem("plan_price")) *
                        Number(sessionStorage.getItem("tax_rate"))) /
                        100
                    )
                  : 0
              ).toFixed(2)
            ).toFixed(2)
          )
        : Number(sessionStorage.getItem("plan_price")))
    ) {
      setCardError(true)
      setCardErrorName(
        "Please enter an amount that is less than the program price"
      )
      return
    }

    if (
      isPlanAddPercent && !isNoAddCharge
    ) {
      sessionStorage.setItem("isPlanAddPercent", "Yes")
      setAdditionalPercentModalOpen(true)
    } else {
      sessionStorage.setItem("isPlanAddPercent", "No")
      setUpdateLeadOwnerModalOpen(true)
    }

    setCardError(false)
    setCardErrorName("")
    sessionStorage.setItem("card_number", cardNumber)
    sessionStorage.setItem("amount", cardAmount)
    sessionStorage.setItem("last_four_digits", cardNumber.slice(-4))
  }

  const validateCard = (value: string) => {
    if (/[^0-9-\s]+/.test(value)) return false

    let nCheck = 0,
      bEven = false
    value = value.replace(/\D/g, "")

    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n)
      let nDigit = parseInt(cDigit, 10)

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9
      }

      nCheck += nDigit
      bEven = !bEven
    }

    return nCheck % 10 == 0
  }

  const validateNumber = (value: string) => {
    if (/[^0-9-\s]+/.test(value)) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setCheckPaymentMessage(false)
    }, 4000)

    setCardName("")
    setCardNumber("")
    setCardMonth("")
    setCardYear("")
    setCardCVC("")
    setCardAmount("")
    setCardError(false)
    setCardErrorName("")
    setLeadConfirmationModalOpen(false)
    const removeKeys = ["card_number", "amount", "last_four_digits", "client_error"]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  return (
    <Layout pageNumber={8} nextButtonDisabled={nextButtonDisabled} handleProceed={handleProceed}>
      <div className="px-16">
        {checkPaymentMessage && (
          <div
            className={`bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center mb-4 ${
              sessionStorage.getItem("country") === "PCA" &&
              (sessionStorage.getItem("isScheduledDeposit") === "Yes" ||
                sessionStorage.getItem("isRecurring") === "Yes")
            }`}
          >
            <div className="block">{sessionStorage.getItem("check_payment_msg")}</div>
          </div>
        )}
        {isPlanAddPercent && (
          <div className="px-4 sm:px-0 flex items-start">
            <div className="mr-3 flex items-center h-5">
              <input
                type="checkbox"
                className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                checked={isNoAddCharge}
                onClick={() => setIsNoAddCharge(!isNoAddCharge)}
              />
            </div>
            <div className="text-sm">
              <label
                htmlFor="region_access"
                className="font-medium text-gray-700 select-none"
              >
                Are you collecting full payment after doctors appointment? (No additional charge will be applied if this is the case)
              </label>
            </div>
          </div>
        )}
        <div className="mt-5 px-4 sm:px-0">
          <div className="block text-sm font-medium text-gray-700">Credit/Debit Card</div>
          <div className="flex items-center">
            <Visa className="mr-3.5" />
            <Mastercard className="mr-3.5" />
            <Amex className="mr-3.5" />
            <Discover />
          </div>
        </div>
        <div className="mt-5 px-4 sm:px-0">
          <div className="block text-sm font-medium text-gray-700">Cardholder&apos;s Name</div>
          <input
            type="text"
            className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
            placeholder="First Name & Last Name"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
          />
        </div>
        <div className="mt-5 px-4 sm:px-0">
          <div className="block text-sm font-medium text-gray-700">Card Number</div>
          <input
            type="text"
            className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            maxLength={19}
          />
        </div>
        <div className="mt-5">
          <div className="flex flex-wrap sm:flex-nowrap px-4 sm:px-0">
            <div className="relative mr-6 sm:mr-7 sm:px-0">
              <div className="block text-sm font-medium text-gray-700">Expiration Date</div>
              <div className="pl-9 mt-2 rounded-md border-c4c4c4 w-32 h-12 bg-E0E0E0 flex items-center text-828282  border border-transparent focus:outline-none focus:ring-0">
                <input
                  type="text"
                  className="bg-E0E0E0 px-0 py-1 w-7 border-none focus:border-c4c4c4 focus:outline-none focus:ring-0 text-right"
                  placeholder="MM"
                  maxLength={2}
                  value={cardMonth}
                  onChange={(e) => setCardMonth(e.target.value)}
                />
                <span className="pl-1 pb-1px">/</span>
                <input
                  type="text"
                  className="ml-1 bg-E0E0E0 px-0 py-1 w-8 border-none focus:border-c4c4c4 focus:outline-none focus:ring-0"
                  placeholder="YY"
                  maxLength={2}
                  value={cardYear}
                  onChange={(e) => setCardYear(e.target.value)}
                />
              </div>
              <Calendar className="absolute top-42px left-3" />
            </div>
            <div
              className={`relative ${
                sessionStorage.getItem("country") === "PCA" &&
                (sessionStorage.getItem("isRecurring") === "Yes" ||
                  sessionStorage.getItem("isScheduledDeposit") === "Yes")
                  ? null
                  : "sm:mr-7"
              }`}
            >
              <div className="block text-sm font-medium text-gray-700">CVV</div>
              <input
                type="text"
                className="pl-11 mt-2 rounded-md border-c4c4c4 w-32 h-12 bg-E0E0E0 flex items-center text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                maxLength={4}
                value={cardCVC}
                onChange={(e) => setCardCVC(e.target.value)}
              />
              <CVV className="absolute top-42px left-4" />
            </div>
            {sessionStorage.getItem("country") === "PCA" &&
            (sessionStorage.getItem("isRecurring") === "Yes" ||
              sessionStorage.getItem("isScheduledDeposit") === "Yes") ? null : (
              <div className="mt-4 sm:mt-0">
                <div className="block text-sm font-medium text-gray-700">
                  Amount ({sessionStorage.getItem("currency") === "EUR" ? "€" : sessionStorage.getItem("currency") === "GBP" ? "£" : "$"})
                </div>
                <input
                  type="number"
                  className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex items-center pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                  value={cardAmount}
                  onChange={(e) => setCardAmount(e.target.value)}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {(isPlanAddPercent && !isNoAddCharge) && (
        <>
          <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
            <div>
              {" "}
              Additional percent charge ({sessionStorage.getItem("plan_add_percent")}%) will be applied as payment being collected is on a payment plan.
              Total program price will be{" "}
              {
                sessionStorage.getItem("currency") === "EUR" ? "€"
                : sessionStorage.getItem("currency") === "GBP" ? "£"
                : "$"
              }
              {Number(sessionStorage.getItem("total_program_price_add_percent"))}
              {" "}
            </div>
          </div>
          <div className="text-center text-md text-amber-600 mt-2 px-4 sm:px-6 lg:px-8">
            <div>
              {" "}
              Discounted price is{" "}
              {
                sessionStorage.getItem("currency") === "EUR" ? "€"
                : sessionStorage.getItem("currency") === "GBP" ? "£"
                : "$"
              }
              {Number(sessionStorage.getItem("total_program_price"))}
              {" "}
            </div>
          </div>
        </>
      )}

      <div className="text-center text-md text-E84545 font-bold mt-4">{cardError && cardErrorName}</div>

      <UpdateLeadOwnerModal
        updateLeadOwnerModalOpen={updateLeadOwnerModalOpen}
        setUpdateLeadOwnerModalOpen={setUpdateLeadOwnerModalOpen}
        setLeadConfirmationModalOpen={setLeadConfirmationModalOpen}
        setCALeadConfirmationModalOpen={setCALeadConfirmationModalOpen}
        setUKLeadConfirmationModalOpen={setUKLeadConfirmationModalOpen}
        setPCALeadConfirmationModalOpen={setPCALeadConfirmationModalOpen}
        setNZLeadConfirmationModalOpen={setNZLeadConfirmationModalOpen}
      />

      <LeadConfirmationModal
        leadConfirmationModalOpen={leadConfirmationModalOpen}
        setLeadConfirmationModalOpen={setLeadConfirmationModalOpen}
        cardName={cardName}
        cardNumber={cardNumber}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCVC={cardCVC}
        cardAmount={cardAmount}
      />

      <CALeadConfirmationModal
        caLeadConfirmationModalOpen={caLeadConfirmationModalOpen}
        setCALeadConfirmationModalOpen={setCALeadConfirmationModalOpen}
        cardName={cardName}
        cardNumber={cardNumber}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCVC={cardCVC}
        cardAmount={cardAmount}
      />

      <UKLeadConfirmationModal
        ukLeadConfirmationModalOpen={ukLeadConfirmationModalOpen}
        setUKLeadConfirmationModalOpen={setUKLeadConfirmationModalOpen}
        cardName={cardName}
        cardNumber={cardNumber}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCVC={cardCVC}
        cardAmount={cardAmount}
        stripe={stripe}
      />

      <PCALeadConfirmationModal
        pcaLeadConfirmationModalOpen={pcaLeadConfirmationModalOpen}
        setPCALeadConfirmationModalOpen={setPCALeadConfirmationModalOpen}
        cardName={cardName}
        cardNumber={cardNumber}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCVC={cardCVC}
        cardAmount={cardAmount}
      />

      <NZLeadConfirmationModal
        nzLeadConfirmationModalOpen={nzLeadConfirmationModalOpen}
        setNZLeadConfirmationModalOpen={setNZLeadConfirmationModalOpen}
        cardName={cardName}
        cardNumber={cardNumber}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCVC={cardCVC}
        cardAmount={cardAmount}
      />

      <AdditionalPercentModal
        additionalPercentModalOpen={additionalPercentModalOpen}
        setAdditionalPercentModalOpen={setAdditionalPercentModalOpen}
        nextModalOpen={setUpdateLeadOwnerModalOpen}
      />

    </Layout>
  )
}

export default CardDetails