import { FC, Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

interface PropTypes {
  classNames: (arg1: string, arg2: string) => string
  person: {
    city: string
    clientId: string
    country: string
    createdById: string
    email: string
    gaconnector: GaConnector
    location: string
    mobile: string
    name: string
    role: string
    residentialAddress: string
    shippingAddress: string
    type: string
    updatedAt: string
    updatedById: string
    userId: string
  }
}

type GaConnector = {
  gaconnectorfields1__All_Traffic_Sources: string
  gaconnectorfields1__Browser: string
  gaconnectorfields1__City_from_IP_address: string
  gaconnectorfields1__Country_from_IP_address: string
  gaconnectorfields1__First_Click_Campaign: string
  gaconnectorfields1__First_Click_Channel: string
  gaconnectorfields1__First_Click_Content: string
  gaconnectorfields1__First_Click_Landing_Page: string
  gaconnectorfields1__First_Click_Medium: string
  gaconnectorfields1__First_Click_Referrer: string
  gaconnectorfields1__First_Click_Source: string
  gaconnectorfields1__First_Click_Term: string
  gaconnectorfields1__GA_Client_ID: string
  gaconnectorfields1__GCLID_GA_Connector: string
  gaconnectorfields1__Last_Click_Campaign: string
  gaconnectorfields1__Last_Click_Channel: string
  gaconnectorfields1__Last_Click_Content: string
  gaconnectorfields1__Last_Click_Landing_Page: string
  gaconnectorfields1__Last_Click_Referrer: string
  gaconnectorfields1__Last_Click_Source: string
  gaconnectorfields1__Last_Click_Term: string
  gaconnectorfields1__Latitude: string
  gaconnectorfields1__Longitude: string
  gaconnectorfields1__Number_of_Website_Visits: string
  gaconnectorfields1__Operating_System: string
  gaconnectorfields1__Pages_Visited: string
  gaconnectorfields1__Time_Spent_on_Website: string
  gaconnectorfields1__Time_Zone: string
  Local_Storage_Params: string
}

const GAConnectorData: FC<PropTypes> = ({ classNames, person }) => {
  return (
    <Popover className="z-0 relative mb-8">
      {({ open }) => (
        <>
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 mb-8"></div>
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg">
              <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group rounded-md inline-flex items-center text-base font-medium"
                  )}
                >
                  <h3 className="text-lg leading-6 font-medium text-white">
                    GA Connector Data
                  </h3>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-white" : "text-gray-600",
                      "ml-2 h-5 w-5 group-hover:text-gray-400"
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="z-10 inset-x-0 transform w-full mx-auto px-4 sm:px-6 lg:px-8 bg-F3F4F6">
              <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-3">
                  <span className="font-bold">Local Storage Params:</span>{" "}
                  {person.gaconnector?.Local_Storage_Params}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">All Traffic Sources:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__All_Traffic_Sources}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Browser:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Browser}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">City from IP:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__City_from_IP_address}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Country from IP:</span>{" "}
                  {
                    person.gaconnector
                      ?.gaconnectorfields1__Country_from_IP_address
                  }
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Campaign:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Campaign}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Channel:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Channel}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Content:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Content}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Landing Page:</span>{" "}
                  {
                    person.gaconnector
                      ?.gaconnectorfields1__First_Click_Landing_Page
                  }
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Medium:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Medium}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Referrer:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Referrer}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Source:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Source}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">First Click Term:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__First_Click_Term}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">GA Client ID:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__GA_Client_ID}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">GCLID GA Connector:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__GCLID_GA_Connector}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Campaign:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Last_Click_Campaign}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Channel:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Last_Click_Channel}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Content:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Last_Click_Content}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Landing Page:</span>{" "}
                  {
                    person.gaconnector
                      ?.gaconnectorfields1__Last_Click_Landing_Page
                  }
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Referrer:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Last_Click_Referrer}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Source:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Last_Click_Source}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Last Click Term:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Last_Click_Term}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Latitude:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Latitude}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Longitude:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Longitude}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Number of Website Visits:</span>{" "}
                  {
                    person.gaconnector
                      ?.gaconnectorfields1__Number_of_Website_Visits
                  }
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Operating System:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Operating_System}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Pages Visited:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Pages_Visited}
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Time Spent on Website:</span>{" "}
                  {
                    person.gaconnector
                      ?.gaconnectorfields1__Time_Spent_on_Website
                  }
                </div>
                <div className="px-4 py-3">
                  <span className="font-bold">Time Zone:</span>{" "}
                  {person.gaconnector?.gaconnectorfields1__Time_Zone}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default GAConnectorData
