import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: { error: "", type: "" },
}

export const apiSlice = createSlice({
  name: "api",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      const result = action.payload
      state.loading = result
    },
    setError: (state, action) => {
      const result = action.payload
      state.error = { error: result.message, type: result.response }
    },
    clearError: (state) => {
      state.error = { error: "", type: "" }
    },
  },
})

export const { actions } = apiSlice
