import { FC, Fragment, useState, useRef, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import axios from "axios"

interface MyProps {
  passwordOpen: boolean
  setPasswordOpen: (args: boolean) => void
  originalUsers: User[]
  selectedUsers: object
}

type User = {
  client_id: string
  country: string
  email: string
  first_name: string
  hasTracking: boolean
  id: string
  index: number
  last_name: string
  mobile: string
  profileImage: string
  role: string
  type: string
  city: string
  createdById: string
  location: string
  residentialAddress: string
  shippingAddress: string
  updatedAt: string
  updatedById: string
  userId: string
  gaconnector: {
    gaconnectorfields1__GA_Client_ID: string
  }
}

const PasswordModal: FC<MyProps> = ({
  passwordOpen,
  setPasswordOpen,
  originalUsers,
  selectedUsers,
}) => {
  const handleCancel = () => {
    setPasswordOpen(false)
  }

  const [responseMsg, setResponseMsg] = useState<string>("")
  const [errorMsg, setErrorMsg] = useState<string>("")
  const [passwordText, setPasswordText] = useState<string>("")
  const cancelButtonRef = useRef(null)
  const selectedUserCount = Object.values(selectedUsers).filter(
    (user) => user === true
  ).length

  useEffect(() => {
    selectedUserCount <= 0 && handleCancel()
  })

  const handlePassword = async (indexes: string[]) => {
    const indexesAsNumbers = indexes.map((index: string) => parseInt(index))
    const ids = originalUsers
      .filter((_, key: number) => indexesAsNumbers.includes(key))
      .map((user) => user.id)

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}admin/v1/user/reset-password-bulk`,
        {
          user_id: ids,
          password: passwordText,
        }
      )

      setResponseMsg("User password successfully changed")
      setErrorMsg("")
      setTimeout(() => {
        setPasswordOpen(false)
      }, 3000)
      setTimeout(() => {
        setResponseMsg("")
        setPasswordText("")
      }, 4000)
    } catch (_) {
      setErrorMsg("Server request failed")
      setResponseMsg("")
      setTimeout(() => {
        setPasswordOpen(false)
      }, 3000)
      setTimeout(() => {
        setErrorMsg("")
        setPasswordText("")
      }, 4000)
    }
  }

  return (
    <Transition.Root show={passwordOpen && selectedUserCount > 0} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setPasswordOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Reset{" "}
                    {selectedUserCount === 1
                      ? `User's Password`
                      : `Users' Passwords`}
                  </Dialog.Title>
                </div>
              </div>

              <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                  {errorMsg && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2 text-center">
                      <span className="block sm:inline">{errorMsg}</span>
                    </div>
                  )}
                  {responseMsg && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-2 text-center">
                      <span className="block sm:inline">{responseMsg}</span>
                    </div>
                  )}

                  <div className="bg-white py-8 px-4 rounded-md sm:px-7">
                    <div className="pb-5">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          autoComplete="current-password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                          value={passwordText}
                          onChange={(e) => setPasswordText(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                      onClick={handleCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-30 rounded-md border border-transparent shadow-sm px-4 py-2 bg-navy-theme text-base font-medium text-white focus:outline-none sm:text-sm"
                      onClick={() => {
                        const userIdxs: string[] = []
                        Object.entries(selectedUsers).forEach(
                          (selectedUser) => {
                            const userIdx = selectedUser[0]
                            const isSelected = selectedUser[1]
                            if (isSelected) {
                              userIdxs.push(userIdx)
                            }
                          }
                        )
                        handlePassword(userIdxs)
                      }}
                    >
                      {selectedUserCount === 1
                        ? "Reset Password"
                        : "Reset Passwords"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PasswordModal
