import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DesktopLogo, MobileLogo } from '../assets/common/icons'
import OtpInput from 'react-otp-input'
import Axios, { AxiosError, AxiosResponse } from 'axios'

type Config = {
    method: string
    credentials: string
    url: string
    headers: {
        "Content-Type": string
    }
    data: string
}

export default function Verification() {
    const history = useHistory()
    const [otp, setOtp] = useState('')
    const [resmsg, setresmsg] = useState('')
    const [errmsg, seterrmsg] = useState('')
    useEffect(() => {
        if (!localStorage.getItem('otp')) {
            history.push('/signin')
        }
    }, [history])
    const resend = () => {
        const email = JSON.parse(localStorage.getItem('email') || '')
        const data = JSON.stringify({ email: email.email })
        const config: Config = {
            method: 'post',
            credentials: 'include',
            url: `${process.env.REACT_APP_API_URL}admin/v1/forgot-password`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        }
        Axios(config)
            .then(function (response: AxiosResponse) {
                if (response) {
                    setresmsg(response.data.message)
                    seterrmsg('')
                }
            })
            .catch((error: AxiosError) => {
                const responseData = error.response?.data as { message: string }
                const errorMessage = responseData?.message || "An error occurred"
                alert(errorMessage)
            })
    }
    const checkotp = () => {
        const email = JSON.parse(localStorage.getItem('email') || '')
        Axios.post(`${process.env.REACT_APP_API_URL}admin/v1/check-otp`, {
            email: email.email,
            otp: otp,
        })
            .then((res) => {
                localStorage.removeItem('otp')
                JSON.stringify(
                    localStorage.setItem('token', res.data.data.authorization)
                )
                localStorage.setItem('otpsuccess', 'true')
                if (res) {
                    seterrmsg('')
                    setresmsg('OTP matched successfully')
                }

                setTimeout(() => {
                    history.push('/createpassword')
                }, 1000)
            })
            .catch((error: AxiosError) => {
                const responseData = error.response?.data as { message: string }
                const errorMessage = responseData?.message || "An error occurred"
                seterrmsg(errorMessage)
                setresmsg("")
            })
    }

    return (
        <>
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-5 relative">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <span className="m-auto mx-auto h-12 w-auto desktop-logo">
                        <DesktopLogo />
                    </span>

                    <span className="m-auto mx-auto h-12 w-auto mobile-logo">
                        <MobileLogo />
                    </span>

                    <h2 className="mt-6 text-center font-semibold text-3xl text-333333">
                        Email verification required
                    </h2>
                    <p className="text-center font-normal text-lg text-4F4F4F">
                        Please enter the code sent to your email address to
                        continue.
                    </p>
                </div>
                {resmsg && (
                    <div className="w-1/3 sm:mx-auto my-5 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5 text-center">
                        <span className="block sm:inline">{resmsg}</span>
                    </div>
                )}
                {errmsg && (
                    <div className="w-1/3 my-5 sm:mx-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5 text-center">
                        <span className="block sm:inline">{errmsg}</span>
                    </div>
                )}

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                    <div className="custom_otp">
                        <form className="space-y-6" action="#" method="POST">
                            <div className="otp_numbers">
                                <OtpInput
                                    className="numberotps"
                                    value={otp}
                                    numInputs={5}
                                    onChange={(otp: string) => setOtp(otp)}
                                />
                            </div>
                        </form>
                    </div>

                    <div className="pt-10 d-received-code text-center">
                        <p>
                            Didn’t recieve the code?{' '}
                            <a href="#" className="resendcode" onClick={resend}>
                                Resend
                            </a>
                        </p>
                    </div>

                    <div className="mt-10">
                        <button
                            onClick={checkotp}
                            type="submit"
                            className="bg-navy-theme relative h-70 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
                        >
                            Verify
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
