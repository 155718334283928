export function DesktopLogo() {
  return (
    <svg
      className="mx-auto h-12 w-auto"
      width="197"
      height="41"
      viewBox="0 0 197 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152.337 8.92634V6.606H155.728C156.204 6.606 156.501 6.33827 156.501 5.87718C156.501 5.41609 156.204 5.13349 155.728 5.13349H152.337V2.8429C152.337 2.59005 152.426 2.5008 152.634 2.5008H157.111C157.602 2.5008 157.9 2.2182 157.9 1.75711C157.9 1.28114 157.602 0.998536 157.111 0.998536H151.504C151.013 0.998536 150.701 1.28114 150.701 1.75711V10.027C150.701 10.5178 151.013 10.7856 151.504 10.7856H157.186C157.662 10.7856 157.974 10.503 157.974 10.027C157.974 9.55104 157.662 9.26844 157.186 9.26844H152.634C152.426 9.26844 152.337 9.19407 152.337 8.92634Z"
        fill="#030654"
      />
      <path
        d="M172.495 2.9024C172.361 3.46761 172.064 3.80971 171.498 4.06256C171.275 4.16668 171.201 4.31542 171.201 4.49391C171.201 4.70214 171.379 4.8955 171.707 4.8955C171.885 4.8955 172.108 4.83601 172.317 4.71702C173.254 4.2113 173.789 3.11063 173.789 2.23307C173.789 1.26627 173.194 0.805176 172.48 0.805176C171.796 0.805176 171.305 1.26627 171.305 1.89097C171.305 2.61979 171.945 2.97677 172.495 2.9024Z"
        fill="#030654"
      />
      <path
        d="M180.078 0.834923C177.43 0.834923 176.047 2.29257 176.047 3.80971C176.047 5.99617 177.802 6.39777 179.617 6.75474C181.416 7.11172 182.205 7.31995 182.205 8.13802C182.205 8.88172 181.476 9.37255 180.122 9.37255C179.066 9.37255 178.055 9.0602 177.222 8.64373C177.014 8.53962 176.806 8.45037 176.597 8.45037C176.166 8.45037 175.839 8.86684 175.839 9.29819C175.839 9.49155 175.913 9.75928 176.211 9.96751C176.984 10.4732 178.457 10.9641 180.033 10.9641C182.472 10.9641 183.945 9.67003 183.945 8.10827C183.945 6.11517 182.309 5.72844 180.509 5.37147C178.754 5.02937 177.787 4.77651 177.787 3.77996C177.787 3.03626 178.576 2.38181 180.003 2.38181C180.911 2.38181 181.759 2.66442 182.383 2.96189C182.606 3.08089 182.755 3.14038 182.993 3.14038C183.454 3.14038 183.707 2.73879 183.707 2.33719C183.707 1.99509 183.439 1.78685 183.172 1.62324C182.651 1.31089 181.402 0.834923 180.078 0.834923Z"
        fill="#030654"
      />
      <path
        d="M152.349 23.9065V21.5862H155.74C156.216 21.5862 156.514 21.3184 156.514 20.8573C156.514 20.3963 156.216 20.1137 155.74 20.1137H152.349V17.8231C152.349 17.5702 152.438 17.481 152.647 17.481H157.124C157.614 17.481 157.912 17.1984 157.912 16.7373C157.912 16.2613 157.614 15.9787 157.124 15.9787H151.516C151.025 15.9787 150.713 16.2613 150.713 16.7373V25.0072C150.713 25.498 151.025 25.7657 151.516 25.7657H157.198C157.674 25.7657 157.986 25.4831 157.986 25.0072C157.986 24.5312 157.674 24.2486 157.198 24.2486H152.647C152.438 24.2486 152.349 24.1742 152.349 23.9065Z"
        fill="#030654"
      />
      <path
        d="M170.965 16.6034V25.0072C170.965 25.498 171.277 25.7657 171.768 25.7657H177.242C177.733 25.7657 178.03 25.4831 178.03 25.0072C178.03 24.5312 177.733 24.2486 177.242 24.2486H172.899C172.69 24.2486 172.601 24.1742 172.601 23.9065V16.6034C172.601 16.1721 172.259 15.8895 171.783 15.8895C171.307 15.8895 170.965 16.1721 170.965 16.6034Z"
        fill="#030654"
      />
      <path
        d="M181.12 17.481V25.141C181.12 25.5724 181.462 25.8699 181.938 25.8699C182.414 25.8699 182.756 25.5724 182.756 25.141V17.481H185.731C186.222 17.481 186.52 17.1984 186.52 16.7373C186.52 16.2613 186.222 15.9787 185.731 15.9787H178.131C177.64 15.9787 177.327 16.2613 177.327 16.7373C177.327 17.1984 177.64 17.481 178.116 17.481H181.12Z"
        fill="#030654"
      />
      <path
        d="M144.567 30.7953C141.696 30.7953 139.465 32.9371 139.465 35.8673C139.465 38.5892 141.503 40.9095 144.686 40.9095C146.426 40.9095 147.883 40.0766 148.687 39.2883C148.895 39.08 149.029 38.8718 149.029 38.5892C149.029 38.2025 148.746 37.8157 148.285 37.8157C148.032 37.8157 147.869 37.9496 147.72 38.0537C147.184 38.5148 146.247 39.3031 144.76 39.3031C142.692 39.3031 141.25 37.8752 141.25 35.8226C141.25 33.8295 142.663 32.3868 144.522 32.3868C145.474 32.3868 146.173 32.6991 146.694 32.9669C146.946 33.0858 147.14 33.1751 147.348 33.1751C147.824 33.1751 148.077 32.7735 148.077 32.4016C148.077 32.0595 147.854 31.8513 147.571 31.6579C147.11 31.3307 145.95 30.7953 144.567 30.7953Z"
        fill="#030654"
      />
      <path
        d="M150.701 31.5836V39.9873C150.701 40.4782 151.013 40.7459 151.504 40.7459H156.977C157.468 40.7459 157.766 40.4633 157.766 39.9873C157.766 39.5114 157.468 39.2288 156.977 39.2288H152.634C152.426 39.2288 152.337 39.1544 152.337 38.8867V31.5836C152.337 31.1522 151.995 30.8696 151.519 30.8696C151.043 30.8696 150.701 31.1522 150.701 31.5836Z"
        fill="#030654"
      />
      <path
        d="M159.869 31.4946V40.0322C159.869 40.4635 160.211 40.761 160.687 40.761C161.163 40.761 161.505 40.4635 161.505 40.0322V31.4946C161.505 31.0632 161.163 30.7657 160.687 30.7657C160.211 30.7657 159.869 31.0632 159.869 31.4946Z"
        fill="#030654"
      />
      <path
        d="M174.419 31.4946V40.0322C174.419 40.4635 174.761 40.761 175.237 40.761C175.713 40.761 176.055 40.4635 176.055 40.0322V31.4946C176.055 31.0632 175.713 30.7657 175.237 30.7657C174.761 30.7657 174.419 31.0632 174.419 31.4946Z"
        fill="#030654"
      />
      <path
        d="M183.515 30.7591C180.644 30.7591 178.413 32.901 178.413 35.8311C178.413 38.553 180.451 40.8734 183.634 40.8734C185.374 40.8734 186.832 40.0404 187.635 39.2521C187.843 39.0439 187.977 38.8356 187.977 38.553C187.977 38.1663 187.695 37.7796 187.233 37.7796C186.981 37.7796 186.817 37.9135 186.668 38.0176C186.133 38.4787 185.196 39.267 183.708 39.267C181.641 39.267 180.198 37.8391 180.198 35.7865C180.198 33.7934 181.611 32.3506 183.47 32.3506C184.422 32.3506 185.121 32.663 185.642 32.9307C185.895 33.0497 186.088 33.1389 186.296 33.1389C186.772 33.1389 187.025 32.7373 187.025 32.3655C187.025 32.0234 186.802 31.8152 186.52 31.6218C186.058 31.2946 184.898 30.7591 183.515 30.7591Z"
        fill="#030654"
      />
      <path
        d="M166.619 23.2632L167.337 25.1385C167.491 25.5404 167.772 25.7636 168.11 25.7636C168.574 25.7636 168.926 25.4362 168.926 24.9748C168.926 24.8557 168.883 24.692 168.813 24.4985L165.86 16.9229C165.537 16.0894 165.129 15.7769 164.482 15.7769H164.383C163.751 15.7769 163.329 16.0894 163.019 16.9229L160.066 24.439C159.982 24.6474 159.926 24.826 159.94 25.0195C159.968 25.4511 160.277 25.7636 160.727 25.7636C161.093 25.7636 161.332 25.5553 161.487 25.1534L162.218 23.2632H166.619ZM162.752 21.7749L164.412 17.414H164.454L166.071 21.7749H162.752Z"
        fill="#030654"
      />
      <path
        d="M146.706 21.543V25.0354C146.706 25.4664 146.965 25.7636 147.455 25.7636C147.944 25.7636 148.204 25.4664 148.204 25.0354V16.5051C148.204 16.0741 147.944 15.7769 147.455 15.7769C146.965 15.7769 146.706 16.0741 146.706 16.5051V20.0272H141.213V16.5051C141.213 16.0741 140.953 15.7769 140.464 15.7769C139.974 15.7769 139.715 16.0741 139.715 16.5051V25.0354C139.715 25.4664 139.974 25.7636 140.464 25.7636C140.953 25.7636 141.213 25.4664 141.213 25.0354V21.543H146.706Z"
        fill="#030654"
      />
      <path
        d="M195.141 21.543V25.0354C195.141 25.4664 195.401 25.7636 195.89 25.7636C196.38 25.7636 196.639 25.4664 196.639 25.0354V16.5051C196.639 16.0741 196.38 15.7769 195.89 15.7769C195.401 15.7769 195.141 16.0741 195.141 16.5051V20.0272H189.648V16.5051C189.648 16.0741 189.389 15.7769 188.899 15.7769C188.41 15.7769 188.15 16.0741 188.15 16.5051V25.0354C188.15 25.4664 188.41 25.7636 188.899 25.7636C189.389 25.7636 189.648 25.4664 189.648 25.0354V21.543H195.141Z"
        fill="#030654"
      />
      <path
        d="M168.676 9.90781V1.52605C168.676 1.09508 168.34 0.797852 167.871 0.797852C167.403 0.797852 167.066 1.09508 167.066 1.52605V8.07988L162.398 1.63008C161.929 0.991048 161.666 0.812713 161.168 0.812713H161.08C160.583 0.812713 160.188 1.19911 160.188 1.79356V10.0564C160.188 10.4874 160.524 10.7846 160.992 10.7846C161.461 10.7846 161.797 10.4874 161.797 10.0564V3.39857L166.466 10.0416C166.832 10.5617 167.154 10.7846 167.652 10.7846H167.71C168.223 10.7846 168.676 10.428 168.676 9.90781Z"
        fill="#030654"
      />
      <path
        d="M172.172 39.8653V31.4836C172.172 31.0526 171.836 30.7554 171.367 30.7554C170.899 30.7554 170.562 31.0526 170.562 31.4836V38.0374L165.894 31.5876C165.425 30.9486 165.162 30.7702 164.664 30.7702H164.576C164.079 30.7702 163.684 31.1566 163.684 31.7511V40.0139C163.684 40.4449 164.02 40.7421 164.489 40.7421C164.957 40.7421 165.294 40.4449 165.294 40.0139V33.3561L169.962 39.9991C170.328 40.5192 170.65 40.7421 171.148 40.7421H171.206C171.719 40.7421 172.172 40.3855 172.172 39.8653Z"
        fill="#030654"
      />
      <path
        d="M146.74 3.41782V10.0558C146.74 10.5023 147.046 10.7851 147.472 10.7851C147.898 10.7851 148.204 10.5023 148.204 10.0558V1.75088C148.204 1.17043 147.831 0.79834 147.352 0.79834H147.246C146.74 0.79834 146.487 1.14066 146.221 1.57228L144.012 5.21872H143.999L141.777 1.58716C141.511 1.14066 141.192 0.79834 140.686 0.79834H140.58C140.087 0.79834 139.715 1.17043 139.715 1.75088V10.0558C139.715 10.5023 140.021 10.7851 140.447 10.7851C140.872 10.7851 141.178 10.5023 141.178 10.0558V3.41782H141.218L143.134 6.55822C143.414 7.00473 143.613 7.18333 143.959 7.18333C144.318 7.18333 144.531 7.00473 144.797 6.55822L146.713 3.41782H146.74Z"
        fill="#030654"
      />
      <path
        d="M0.398639 5.13072C0.39425 4.56055 0.500649 3.99518 0.71164 3.46754C0.922632 2.93989 1.234 2.46052 1.62761 2.05734C2.02122 1.65415 2.48921 1.33521 3.00432 1.11909C3.51943 0.902965 4.07137 0.793978 4.628 0.798473H5.56176C7.37434 0.798473 8.63766 1.75494 9.40664 3.04899L19.623 20.1029L29.8943 2.99898C30.7732 1.53614 31.9816 0.804725 33.6782 0.804725H34.6119C35.1686 0.799406 35.7207 0.907577 36.2361 1.12294C36.7515 1.3383 37.2199 1.65655 37.6141 2.05915C38.0083 2.46176 38.3203 2.94067 38.5321 3.468C38.7438 3.99533 38.851 4.56054 38.8474 5.13072V36.4129C38.8518 36.9831 38.7454 37.5484 38.5344 38.0761C38.3234 38.6037 38.012 39.0831 37.6184 39.4863C37.2248 39.8895 36.7568 40.2084 36.2417 40.4245C35.7266 40.6407 35.1747 40.7496 34.618 40.7451C32.3111 40.7451 30.4436 38.7759 30.4436 36.4129V16.7834L23.0285 28.4798C22.1497 29.8301 21.0511 30.6741 19.5132 30.6741C17.9752 30.6741 16.8767 29.8301 15.9979 28.4798L8.69869 16.9459V36.5254C8.69792 37.0841 8.58902 37.6371 8.37828 38.1523C8.16754 38.6676 7.85915 39.135 7.47095 39.5274C7.08274 39.9198 6.62243 40.2295 6.11664 40.4385C5.61085 40.6475 5.06962 40.7518 4.52425 40.7451C3.98126 40.7515 3.4425 40.6467 2.93956 40.437C2.43661 40.2273 1.97961 39.9168 1.59535 39.5238C1.21109 39.1307 0.907307 38.6631 0.70181 38.1482C0.496313 37.6333 0.393242 37.0816 0.398639 36.5254V5.13072Z"
        fill="#030654"
      />
      <path
        d="M48.834 5.1173C48.834 3.97171 49.2788 2.87304 50.0705 2.06299C50.8622 1.25294 51.936 0.797852 53.0556 0.797852C54.1752 0.797852 55.249 1.25294 56.0407 2.06299C56.8324 2.87304 57.2772 3.97171 57.2772 5.1173V16.6732H72.8478V5.1173C72.8478 3.97171 73.2926 2.87304 74.0843 2.06299C74.876 1.25294 75.9498 0.797852 77.0694 0.797852C78.189 0.797852 79.2628 1.25294 80.0545 2.06299C80.8462 2.87304 81.291 3.97171 81.291 5.1173V36.4193C81.291 37.5649 80.8462 38.6635 80.0545 39.4736C79.2628 40.2836 78.189 40.7387 77.0694 40.7387C75.9498 40.7387 74.876 40.2836 74.0843 39.4736C73.2926 38.6635 72.8478 37.5649 72.8478 36.4193V24.6452H57.2772V36.4255C57.2772 37.5711 56.8324 38.6698 56.0407 39.4798C55.249 40.2899 54.1752 40.745 53.0556 40.745C51.936 40.745 50.8622 40.2899 50.0705 39.4798C49.2788 38.6698 48.834 37.5711 48.834 36.4255V5.1173Z"
        fill="#030654"
      />
      <path
        d="M90.7793 20.7732V20.6635C90.7793 9.74287 99.0109 0.797852 110.808 0.797852C116.57 0.797852 120.467 2.33442 123.65 4.70025C124.162 5.09509 124.577 5.6022 124.862 6.18256C125.148 6.76291 125.296 7.40103 125.296 8.04777C125.295 8.59268 125.186 9.13204 124.976 9.63463C124.765 10.1372 124.457 10.5931 124.069 10.9758C123.681 11.3586 123.222 11.6606 122.716 11.8645C122.211 12.0684 121.67 12.17 121.125 12.1636C119.973 12.1636 119.205 11.7246 118.601 11.3404C116.241 9.58434 113.772 8.59655 110.753 8.59655C104.278 8.59655 99.6146 13.9745 99.6146 20.5598V20.6696C99.6146 27.2549 104.169 32.7426 110.753 32.7426C114.32 32.7426 116.68 31.6451 119.095 29.7244C119.801 29.1277 120.695 28.7975 121.619 28.7914C123.759 28.7914 125.57 30.5475 125.57 32.6877C125.566 33.2642 125.435 33.8327 125.189 34.3538C124.943 34.875 124.586 35.3364 124.144 35.706C120.686 38.7243 116.625 40.5352 110.485 40.5352C99.1755 40.5291 90.7793 31.8036 90.7793 20.7732Z"
        fill="#030654"
      />
      <path
        d="M126.128 32.4396C126.128 31.5586 125.855 30.6991 125.347 29.9797C124.838 29.2604 124.119 28.7167 123.288 28.4237C122.457 28.1307 121.555 28.1029 120.708 28.3441C119.861 28.5853 119.109 29.0835 118.557 29.7701C118.112 30.2079 115.814 32.2091 110.996 32.2091C105.644 32.2091 103.182 29.5726 103.079 29.4604C103.118 29.5043 103.136 29.5274 103.136 29.5274C102.396 28.7156 101.371 28.2206 100.274 28.1457C99.1778 28.0707 98.0947 28.4216 97.2505 29.1251C96.4063 29.8287 95.866 30.8308 95.7421 31.9227C95.6182 33.0146 95.9202 34.1123 96.5853 34.9871C96.6023 35.0079 96.6267 35.0371 96.656 35.0701C96.8338 35.2973 97.034 35.506 97.2536 35.6932C98.2167 36.6099 99.2885 37.4053 100.445 38.0615C103.494 39.8139 107.139 40.7444 111.002 40.7444C114.866 40.7444 118.456 39.8578 121.409 38.1834C123.758 36.8493 124.978 35.4249 125.198 35.1542L125.173 35.1323C125.793 34.3723 126.131 33.4206 126.128 32.4396Z"
        fill="#FF5733"
      />
    </svg>
  )
}

export function LockIcon() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7V5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5V7C13.1046 7 14 7.89543 14 9V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V9C0 7.89543 0.895431 7 2 7ZM10 5V7H4V5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5Z"
        fill="#3FB1FC"
      />
    </svg>
  )
}

export function MobileLogo() {
  return (
    <svg
      className="mx-auto h-12 w-auto"
      width="81"
      height="26"
      viewBox="0 0 81 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000128733 2.69903C-0.00267487 2.34382 0.0652924 1.9916 0.200072 1.66288C0.334853 1.33417 0.533752 1.03552 0.785188 0.784343C1.03662 0.533163 1.33557 0.334466 1.66462 0.199823C1.99367 0.0651805 2.34625 -0.0027175 2.70183 8.32448e-05H3.2983C4.45617 8.32448e-05 5.26317 0.595954 5.75439 1.40213L12.2806 12.0266L18.8418 1.37098C19.4032 0.459644 20.1751 0.00397784 21.2589 0.00397784H21.8554C22.211 0.000664149 22.5637 0.0680536 22.8929 0.202222C23.2221 0.33639 23.5214 0.534656 23.7732 0.785474C24.025 1.03629 24.2243 1.33465 24.3596 1.66317C24.4948 1.99169 24.5633 2.34381 24.561 2.69903V22.1875C24.5638 22.5427 24.4958 22.8949 24.3611 23.2237C24.2263 23.5524 24.0274 23.851 23.7759 24.1022C23.5245 24.3534 23.2256 24.5521 22.8965 24.6867C22.5675 24.8214 22.2149 24.8893 21.8593 24.8865C20.3857 24.8865 19.1927 23.6597 19.1927 22.1875V9.95853L14.456 17.2453C13.8946 18.0865 13.1928 18.6123 12.2104 18.6123C11.228 18.6123 10.5262 18.0865 9.96483 17.2453L5.30216 10.0598V22.2576C5.30167 22.6057 5.2321 22.9502 5.09748 23.2712C4.96286 23.5922 4.76587 23.8834 4.51788 24.1278C4.2699 24.3723 3.97586 24.5652 3.65276 24.6954C3.32966 24.8257 2.98393 24.8906 2.63555 24.8865C2.28869 24.8904 1.94453 24.8252 1.62325 24.6945C1.30198 24.5638 1.01005 24.3704 0.764583 24.1256C0.51912 23.8807 0.325063 23.5894 0.193793 23.2686C0.0625225 22.9478 -0.00331911 22.6041 0.000128733 22.2576V2.69903Z"
        fill="#030654"
      />
      <path
        d="M30.9414 2.69097C30.9414 1.97728 31.2255 1.29282 31.7313 0.788168C32.237 0.283512 32.9229 0 33.6381 0C34.3534 0 35.0393 0.283512 35.545 0.788168C36.0508 1.29282 36.3349 1.97728 36.3349 2.69097V9.8902H46.2813V2.69097C46.2813 1.97728 46.5654 1.29282 47.0712 0.788168C47.5769 0.283512 48.2628 0 48.9781 0C49.6933 0 50.3792 0.283512 50.8849 0.788168C51.3907 1.29282 51.6748 1.97728 51.6748 2.69097V22.1918C51.6748 22.9055 51.3907 23.5899 50.8849 24.0946C50.3792 24.5993 49.6933 24.8828 48.9781 24.8828C48.2628 24.8828 47.5769 24.5993 47.0712 24.0946C46.5654 23.5899 46.2813 22.9055 46.2813 22.1918V14.8567H36.3349V22.1957C36.3349 22.9094 36.0508 23.5938 35.545 24.0985C35.0393 24.6031 34.3534 24.8866 33.6381 24.8866C32.9229 24.8866 32.237 24.6031 31.7313 24.0985C31.2255 23.5938 30.9414 22.9094 30.9414 22.1957V2.69097Z"
        fill="#030654"
      />
      <path
        d="M57.7344 12.4445V12.3761C57.7344 5.57266 62.9927 0 70.5288 0C74.2097 0 76.6986 0.957267 78.7318 2.43115C79.059 2.67713 79.324 2.99306 79.5063 3.35462C79.6886 3.71617 79.7834 4.11371 79.7835 4.51663C79.783 4.8561 79.7135 5.19211 79.579 5.50522C79.4445 5.81833 79.2477 6.10233 78.9999 6.34078C78.7521 6.57923 78.4584 6.7674 78.1355 6.89441C77.8127 7.02142 77.4673 7.08475 77.1192 7.08074C76.3831 7.08074 75.8923 6.80723 75.5067 6.56791C73.9993 5.47389 72.4218 4.85851 70.4938 4.85851C66.3572 4.85851 63.3783 8.20894 63.3783 12.3115V12.3799C63.3783 16.4825 66.2879 19.9013 70.4938 19.9013C72.7724 19.9013 74.2798 19.2175 75.8222 18.0209C76.2735 17.6492 76.8441 17.4435 77.4347 17.4397C78.8019 17.4397 79.9587 18.5337 79.9587 19.8671C79.9556 20.2262 79.8725 20.5804 79.7151 20.9051C79.5578 21.2297 79.3299 21.5172 79.0473 21.7474C76.8388 23.6278 74.2447 24.756 70.3224 24.756C63.0979 24.7522 57.7344 19.3163 57.7344 12.4445Z"
        fill="#030654"
      />
      <path
        d="M80.3162 19.713C80.3166 19.1641 80.1423 18.6287 79.8174 18.1805C79.4924 17.7324 79.0329 17.3937 78.5021 17.2111C77.9713 17.0286 77.3955 17.0113 76.8542 17.1615C76.3129 17.3118 75.8328 17.6222 75.4801 18.0499C75.1958 18.3227 73.7281 19.5694 70.6503 19.5694C67.2312 19.5694 65.6584 17.9269 65.5929 17.857C65.6179 17.8843 65.6295 17.8988 65.6295 17.8988C65.1564 17.393 64.5015 17.0846 63.8011 17.0379C63.1008 16.9912 62.4089 17.2098 61.8697 17.6481C61.3304 18.0864 60.9852 18.7107 60.9061 19.391C60.8269 20.0712 61.0198 20.7551 61.4447 21.3001C61.4556 21.313 61.4712 21.3312 61.4899 21.3518C61.6035 21.4933 61.7314 21.6234 61.8716 21.74C62.4869 22.3111 63.1715 22.8066 63.9103 23.2154C65.8578 24.3071 68.1862 24.8868 70.6542 24.8868C73.1221 24.8868 75.4155 24.3345 77.3022 23.2914C78.8026 22.4602 79.5816 21.5728 79.7226 21.4042L79.7062 21.3905C80.1025 20.917 80.3181 20.3242 80.3162 19.713Z"
        fill="#FF5733"
      />
    </svg>
  )
}
