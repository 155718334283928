import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Provider } from "react-redux"
import { persistor, store } from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"
import * as _redux from "./redux/index.ts"
import axios from "axios"

_redux.setupAxios(axios, store)

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <PersistGate persistor={persistor}>
      <Provider persistor={persistor} store={store}>
        <App />
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById("root")
)
