import { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import QuizAssessmentData from "./QuizAssessmentData/QuizAssessmentData"
import GoogleAnalyticsData from "./GoogleAnalyticsData/GoogleAnalyticsData"
import GAConnectorData from "./GAConnectorData/GAConnectorData"
import TrackingData from "./TrackingData/TrackingData"
import axios from "axios"

type TrackingDataUser = {
  createdAt: string
  id: number
  index: number
  link: string
  screen: string
  updatedAt: string
  user_id: string
  user: {
    first_name: string
    last_name: string
  }
}

type Person = {
  city: string
  clientId: string
  country: string
  createdById: string
  email: string
  gaconnector: GaConnector
  location: string
  mobile: string
  name: string
  role: string
  residentialAddress: string
  shippingAddress: string
  type: string
  updatedAt: string
  updatedById: string
  userId: string
}

type GaConnector = {
  gaconnectorfields1__All_Traffic_Sources: string
  gaconnectorfields1__Browser: string
  gaconnectorfields1__City_from_IP_address: string
  gaconnectorfields1__Country_from_IP_address: string
  gaconnectorfields1__First_Click_Campaign: string
  gaconnectorfields1__First_Click_Channel: string
  gaconnectorfields1__First_Click_Content: string
  gaconnectorfields1__First_Click_Landing_Page: string
  gaconnectorfields1__First_Click_Medium: string
  gaconnectorfields1__First_Click_Referrer: string
  gaconnectorfields1__First_Click_Source: string
  gaconnectorfields1__First_Click_Term: string
  gaconnectorfields1__GA_Client_ID: string
  gaconnectorfields1__GCLID_GA_Connector: string
  gaconnectorfields1__Last_Click_Campaign: string
  gaconnectorfields1__Last_Click_Channel: string
  gaconnectorfields1__Last_Click_Content: string
  gaconnectorfields1__Last_Click_Landing_Page: string
  gaconnectorfields1__Last_Click_Referrer: string
  gaconnectorfields1__Last_Click_Source: string
  gaconnectorfields1__Last_Click_Term: string
  gaconnectorfields1__Latitude: string
  gaconnectorfields1__Longitude: string
  gaconnectorfields1__Number_of_Website_Visits: string
  gaconnectorfields1__Operating_System: string
  gaconnectorfields1__Pages_Visited: string
  gaconnectorfields1__Time_Spent_on_Website: string
  gaconnectorfields1__Time_Zone: string
  Local_Storage_Params: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const UserOverview = () => {
  const { state: person }: {state: Person} = useLocation()
  const [quizData, setQuizData] = useState([])
  const [trackingData, setTrackingData] = useState<TrackingDataUser[]>([])

  const fetchQuiz = async () => {
    const {
      data: { data: quizData },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/assessment/get?user_id=${person.userId}`
    )

    setQuizData(quizData)
  }

  const fetchTracking = async () => {
    const {
      data: { data: trackingData },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/tracking/get?user_id=${person.userId}`
    )

    trackingData.map(
      (user: TrackingDataUser, index: number) => (user.index = index + 1)
    )
    setTrackingData(trackingData)
  }

  useEffect(() => {
    fetchQuiz()
    fetchTracking()
  }, [])

  const [googleAnalytics, setGoogleAnalytics] = useState<
    [string, string, string][]
  >([])
  const CLIENT_ID = String(person.gaconnector?.gaconnectorfields1__GA_Client_ID)
  const query_id = "ga%3A255879847"
  const query_dimensions = "ga%3AclientID%2Cga%3AeventLabel%2Cga%3AexitPagePath"
  const query_metrics = "ga%3Asessions"

  const query_sort = "-ga%3Asessions"
  const query_startDate = "2005-01-01"
  const query_endDate = "yesterday"
  const query_filters = `ga%3AclientID%3D%3D${CLIENT_ID}`

  useEffect(() => {
    fetchGA()
  }, [])

  const fetchGA = async () => {
    const access_token = localStorage.getItem("access_token")
    const {
      data: { rows },
    } = await axios.get(
      `https://www.googleapis.com/analytics/v3/data/ga?ids=${query_id}&dimensions=${query_dimensions}&metrics=${query_metrics}&sort=${query_sort}&start-date=${query_startDate}&end-date=${query_endDate}&filters=${query_filters}&access_token=${access_token}`
    )

    const filtered = rows.filter((item: [string, string, string]) =>
      item[2].startsWith("/assessment/quiz")
    )

    setGoogleAnalytics(filtered)
  }

  return (
    <div className="flex flex-col">
      <div className="md:flex md:items-center md:justify-between px-8 mb-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            <NavLink to="/dashboard" className="mr-4">
              &larr;
            </NavLink>
            {person.name}
          </h2>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row mb-8">
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg mb-8 sm:m-0">
            <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-white">
                Contact Information
              </h3>
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Email:</span> {person.email}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Mobile:</span> {person.mobile}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">City:</span> {person.city}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Country:</span> {person.country}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Location:</span> {person.location}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Residential Address:</span>{" "}
              {person.residentialAddress}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Shipping Address:</span>{" "}
              {person.shippingAddress}
            </div>
          </div>
        </div>
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg mb-8 sm:m-0">
            <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-white">
                Patient Information
              </h3>
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Role:</span> {person.role}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Type:</span> {person.type}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Created by:</span>{" "}
              {person.createdById}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Updated by:</span>{" "}
              {person.updatedById}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Updated at:</span> {person.updatedAt}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">User ID:</span> {person.userId}
            </div>
            <div className="px-4 py-3">
              <span className="font-bold">Client ID:</span>{" "}
              {person.gaconnector?.gaconnectorfields1__GA_Client_ID}
            </div>
          </div>
        </div>
      </div>

      <QuizAssessmentData classNames={classNames} quizData={quizData} />
      <GoogleAnalyticsData
        classNames={classNames}
        googleAnalytics={googleAnalytics}
      />
      <GAConnectorData classNames={classNames} person={person} />
      <TrackingData classNames={classNames} trackingData={trackingData} />
    </div>
  )
}

export default UserOverview
