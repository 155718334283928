import { FC, Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ClockIcon } from "@heroicons/react/outline"
import { Entry } from "../../types/entry"
import axios from "axios"
import { formatDate, formatTime } from "../../helpers/timeClockFunctions"

interface ApproveOvertimeModalProps {
  open: boolean
  setOpen: (args: boolean) => void
  entry: Entry
  getTimeEntriesReport: (args: string) => void
  adminId: string
}

const ApproveOvertimeModal: FC<ApproveOvertimeModalProps> = ({
  open,
  setOpen,
  entry,
  getTimeEntriesReport,
  adminId,
}) => {
  const cancelButtonRef = useRef(null)
  const noFocusRef = useRef(null)

  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const updateEntry = async () => {
    setLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/time-entries/approve-time-entry-overtime`,
        {
          time_entry_id: entry.id,
          description: description,
        }
      )
      .then(() => {
        getTimeEntriesReport(adminId)
        setOpen(false)
      })
      .catch((err) => setError(err.message))
    setLoading(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
        initialFocus={noFocusRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="flex">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-[#ffdcd4] sm:mx-0 sm:h-10 sm:w-10">
                  <ClockIcon
                    className="h-6 w-6 text-FF5733"
                    aria-hidden="true"
                  />
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                  <input ref={noFocusRef} type="text" className="hidden" />
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 mb-2"
                  >
                    Approve Overtime
                  </Dialog.Title>
                  {entry?.start_time && (
                    <div className="flex flex-col text-sm mb-4">
                      <p>
                        <span className="font-medium">Date:</span>{" "}
                        {formatDate(entry?.start_time)}
                      </p>
                      <p>
                        <span className="font-medium">Start time:</span>{" "}
                        {formatTime(entry?.start_time)}
                      </p>
                      <p>
                        <span className="font-medium">End time:</span>{" "}
                        {formatTime(entry?.finish_time)}
                      </p>
                      <p>
                        <span className="font-medium">Duration:</span>{" "}
                        {entry?.billable_hours} hrs
                      </p>
                    </div>
                  )}
                  <div className="w-full mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Enter description
                    </label>
                    <textarea
                      rows={4}
                      className="appearance-none block py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm w-full resize-none"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded text-center absolute translate-y-4 left-1/2 transform -translate-x-1/2">
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              <div className="mt-8 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`${
                    !loading
                      ? "bg-FF5733 text-white hover:bg-FF5733_hover"
                      : "text-black bg-gray-300"
                  } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium sm:ml-3 sm:w-auto sm:text-sm`}
                  onClick={updateEntry}
                  disabled={loading}
                >
                  {!loading ? "Approve" : "Processing..."}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ApproveOvertimeModal
