import axios from "axios"
import { useEffect, useState } from "react"
import PasswordModal from "./modal/PasswordModal"
import DeleteModal from "./modal/DeleteModal"
import { handleSearch } from "../../helpers/handleSearch"
import { sortData } from "../../helpers/sortData"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { useDocTitle } from "../../hooks/useDocTitle"
import { User } from "../../types/user"
import { Link } from "react-router-dom"
import { CalendarIcon, ClipboardIcon } from "@heroicons/react/outline"
import ViewScheduleModal from "./modal/ViewScheduleModal"

const ManageUsers = () => {
  useDocTitle("Manage Users - MHC Dashboard")
  const [data, setData] = useState<User[]>([])
  const [loading, setLoading] = useState(false)
  const [passwordOpen, setPasswordOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [toggleSelectAll, setToggleSelectAll] = useState(false)
  const [userCheckStatus, setUserCheckStatus] = useState<
    Record<number, boolean>
  >({})
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    const userCheckStatusValues = Object.values(userCheckStatus)
    if (
      userCheckStatusValues.some((user) => user === false) ||
      userCheckStatusValues.length === 0
    ) {
      const initialUserCheckStatus: Record<number, boolean> = {}
      data.forEach((_, userIdx) => {
        initialUserCheckStatus[userIdx] = true
      })
      setUserCheckStatus(initialUserCheckStatus)
    } else if (
      userCheckStatusValues.length === data.length &&
      userCheckStatusValues.every((user) => user === true)
    ) {
      const initialUserCheckStatus: Record<number, boolean> = {}
      data.forEach((_, userIdx) => {
        initialUserCheckStatus[userIdx] = false
      })
      setUserCheckStatus(initialUserCheckStatus)
    }
  }, [toggleSelectAll])

  const fetchData = async () => {
    setLoading(true)
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}admin/v1/account/get`)

    data.rows.map(
      (user: User) =>
        (user.user_access = user.admins_features
          .map((item) => item.value)
          .toString()
          .replaceAll(",", ", "))
    )

    setData(data.rows)
    setOriginalData(data.rows)
    data.rows.map((user: User, index: number) => (user.index = index + 1))
    setLoading(false)
  }

  const handleDelete = async (index: string) => {
    const result = data.filter((_, key: number) => key === +index)

    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/account/remove-user`, {
        id: result[0].id,
      })
      .then(() => {
        fetchData()
        setDeleteOpen(false)
        setUserCheckStatus({})
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // SEARCH TABLE FUNCTION
  const [query, setQuery] = useState("")
  const [searchParam] = useState([
    "first_name",
    "last_name",
    "email",
    "mobile",
    "type",
    "user_access",
    "id",
  ])
  const [originalData, setOriginalData] = useState([])

  useEffect(() => {
    handleSearch(query, originalData, searchParam, setData)
  }, [query])

  // SORT BY CLICKING COLUMN HEADER
  const [order, setOrder] = useState("ASC")
  const [sortingColumn, setSortingColumn] = useState("")

  // VIEW SCHEDULE MODAL
  const [viewScheduleModal, setViewScheduleModal] = useState(false)
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <PasswordModal
        passwordOpen={passwordOpen}
        setPasswordOpen={setPasswordOpen}
        originalUsers={data}
        selectedUsers={userCheckStatus}
      />
      <DeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDelete={handleDelete}
        selectedUsers={userCheckStatus}
        errorMessage={errorMessage}
      />
      <ViewScheduleModal
        isOpen={viewScheduleModal}
        setIsOpen={setViewScheduleModal}
        user={currentUser}
      />

      <div className="flex justify-between">
        <div>
          <Link to="/create-user">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4"
            >
              Create User
            </button>
          </Link>

          <select
            value="PLACEHOLDER"
            onChange={(e) => {
              if (e.target.value === "DELETE") {
                setDeleteOpen(true)
              } else {
                setPasswordOpen(true)
              }
            }}
            className="inline-flex items-center pl-3 pr-7 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4 ml-6"
          >
            <option value="PLACEHOLDER" hidden>
              Actions
            </option>
            <option value="CHANGE_PASSWORD">Change Password</option>
            <option value="DELETE">Delete</option>
          </select>
        </div>

        <div className="">
          <input
            type="text"
            className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md mb-4"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setUserCheckStatus({})
              setQuery(e.target.value)
            }}
          />
        </div>
      </div>

      <p className="mt-1 mb-4 max-w-2xl text-sm text-gray-500">
        Sort columns by clicking the column header.
      </p>

      <div className="flex flex-col overflow-y-auto overflow-x-hidden">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-navy-theme">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-center text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        setToggleSelectAll(
                          (prevToggleSelectAll) => !prevToggleSelectAll
                        )
                      }
                    >
                      Selected
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "number",
                          order,
                          data,
                          "index",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        #
                        {sortingColumn === "index" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      Update Profile
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      View Schedule
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "string",
                          order,
                          data,
                          "email",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        Email
                        {sortingColumn === "email" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "string",
                          order,
                          data,
                          "first_name",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        First Name
                        {sortingColumn === "first_name" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "string",
                          order,
                          data,
                          "last_name",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        Last Name
                        {sortingColumn === "last_name" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "string",
                          order,
                          data,
                          "type",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        Type
                        {sortingColumn === "type" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "string",
                          order,
                          data,
                          "user_access",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        User Access
                        {sortingColumn === "user_access" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() =>
                        sortData(
                          "string",
                          order,
                          data,
                          "id",
                          setData,
                          setOrder,
                          setSortingColumn
                        )
                      }
                    >
                      <div className="flex items-center">
                        Admin ID
                        {sortingColumn === "id" ? (
                          order === "DSC" ? (
                            <ChevronUpIcon className={`h-6`} />
                          ) : (
                            <ChevronDownIcon className={`h-6`} />
                          )
                        ) : null}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Loading accounts...
                      </td>
                    </tr>
                  ) : (
                    data.map((admin, adminIdx) => (
                      <tr
                        key={admin.email}
                        className={
                          adminIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td className="flex items-center">
                          <input
                            className="mx-auto my-4"
                            type="checkbox"
                            checked={userCheckStatus?.[adminIdx] ?? false}
                            onChange={() =>
                              setUserCheckStatus((prevUserCheckStatus) => {
                                return {
                                  ...prevUserCheckStatus,
                                  [`${adminIdx}`]:
                                    !prevUserCheckStatus[adminIdx],
                                }
                              })
                            }
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {+admin.index}
                        </td>
                        <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <Link to={`/update-user/${admin.id}`} target="_blank">
                            <ClipboardIcon className="w-5 h-5 mx-auto cursor-pointer hover:text-gray-500" />
                          </Link>
                        </td>
                        <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <CalendarIcon
                            className="w-5 h-5 mx-auto cursor-pointer hover:text-gray-500"
                            onClick={() => {
                              setCurrentUser(admin)
                              setViewScheduleModal(true)
                            }}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {admin.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.first_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.last_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.user_access}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.id}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageUsers
