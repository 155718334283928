import PrematureEjaculationQuiz from "./PrematureEjaculationQuiz"
import ChronicPainQuiz from "./ChronicPainQuiz"
import ErectileDysfunctionQuiz from "./ErectileDysfunctionQuiz"
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { FC } from "react"

interface PropTypes {
  classNames: (arg1: string, arg2: string) => string
  quizData: {
    category_id: number
    id: number
    mhc_quiz_assessments: {
      assessment_answer: {
        assessment_options: {
          id: number
          option: string
        }
      }[]
      assessment_questions: {
        id: number
        question: string
      }
      id: number
    }[]
    mhc_quiz_category: {
      category_description: string
      category_name: string
      id: number
    }
    user_id: string
  }[]
}

const QuizAssessmentData: FC<PropTypes> = ({ classNames, quizData }) => {
  return (
    <Popover className="z-0 relative">
      {({ open }) => (
        <>
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg">
              <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group rounded-md inline-flex items-center text-base font-medium"
                  )}
                >
                  <h3 className="text-lg leading-6 font-medium text-white">
                    Quiz Assessment Data
                  </h3>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-white" : "text-gray-600",
                      "ml-2 h-5 w-5 group-hover:text-gray-400"
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="z-10 inset-x-0 transform w-full mx-auto px-4 sm:px-6 lg:px-8 bg-F3F4F6">
              <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg">
                {quizData.length > 0 &&
                  quizData.map((quiz, index) => (
                    <div className="px-4 py-5 sm:px-6" key={index}>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Quiz {index + 1}
                      </h3>
                      {quiz?.category_id === 1 ? (
                        <ErectileDysfunctionQuiz quizData={quiz} />
                      ) : quiz?.category_id === 2 ? (
                        <PrematureEjaculationQuiz quizData={quiz} />
                      ) : (
                        <ChronicPainQuiz quizData={quiz} />
                      )}
                    </div>
                  ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default QuizAssessmentData
