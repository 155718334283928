import { Link } from "react-router-dom"
import { User } from "../../types/user"
import { ApiSchedule } from "../../types/schedule"
import { ChangeEvent, useEffect, useState } from "react"
import ct from "countries-and-timezones"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import axios from "axios"
import ShiftSchedule from "./components/ShiftSchedule"
import { formatSchedule } from "./helpers/formatSchedule"
import { useParams } from "react-router-dom"

type FormDataType = {
  first_name: string
  last_name: string
  email: string
  type: string
  pay_rate: number
  time_zone: string
  currency: string
  salary_currency: string
  xero_bill_region: string
  is_monthly_contracted: boolean
  region_access: string[]
  refund_access: string[]
  additional_access: string[]
  time_schedule: ApiSchedule[]
}

const UpdateUser = () => {
  const [response, setResponse] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState<User | null>(null)
  const [monthlyContractedChecked, setMonthlyContractedChecked] =
    useState(false)
  const [auChecked, setAUChecked] = useState(false)
  const [caChecked, setCAChecked] = useState(false)
  const [nzChecked, setNZChecked] = useState(false)
  const [ukChecked, setUKChecked] = useState(false)
  const [pcaChecked, setPCAChecked] = useState(false)
  const [level1Checked, setLevel1Checked] = useState(false)
  const [level2Checked, setLevel2Checked] = useState(false)
  const [level3Checked, setLevel3Checked] = useState(false)
  const [eftApproverChecked, setEftApproverChecked] = useState(false)
  const [timeClockChecked, setTimeClockChecked] = useState(false)
  const [overtimeApproverChecked, setOvertimeApproverChecked] = useState(false)
  const [schedule, setSchedule] = useState([
    {
      name: "MON",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "TUE",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "WED",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "THU",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "FRI",
      checked: true,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "SAT",
      checked: false,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
    {
      name: "SUN",
      checked: false,
      startHours: 0,
      startMinutes: 0,
      startTimeOfDay: "AM",
      endHours: 0,
      endMinutes: 0,
      endTimeOfDay: "AM",
      allowedOvertime: false,
      maxOvertime: 0,
    },
  ])
  const [formData, setFormData] = useState<FormDataType>({
    first_name: "",
    last_name: "",
    email: "",
    type: "",
    pay_rate: 0,
    time_zone: "",
    currency: "",
    salary_currency: "",
    xero_bill_region: "",
    is_monthly_contracted: false,
    region_access: [],
    refund_access: [],
    additional_access: [],
    time_schedule: [],
  })

  const {
    email,
    first_name,
    last_name,
    type,
    pay_rate,
    time_zone,
    currency,
    salary_currency,
    xero_bill_region,
  } = formData

  const handleSubmit = async () => {
    setLoading(true)
    setError("")
    setResponse("")

    formData.region_access = []
    formData.refund_access = []
    formData.additional_access = []

    if (monthlyContractedChecked) {
      formData.is_monthly_contracted = true
    }

    if (auChecked) {
      formData.region_access.push("AU")
    }
    if (caChecked) {
      formData.region_access.push("CA")
    }
    if (nzChecked) {
      formData.region_access.push("NZ")
    }
    if (ukChecked) {
      formData.region_access.push("UK")
    }
    if (pcaChecked) {
      formData.region_access.push("PCA")
    }
    if (level1Checked) {
      formData.refund_access.push("LEVEL_1")
    }
    if (level2Checked) {
      formData.refund_access.push("LEVEL_2")
    }
    if (level3Checked) {
      formData.refund_access.push("LEVEL_3")
    }
    if (eftApproverChecked) {
      formData.additional_access.push("EFT_APPROVER")
    }
    if (timeClockChecked) {
      formData.additional_access.push("TIME_CLOCK")
    }
    if (overtimeApproverChecked) {
      formData.additional_access.push("OVERTIME_APPROVER")
    }

    if (level1Checked && (level2Checked || level3Checked)) {
      setError("Refund Level 1 can't also be Level 2 or 3")
      return
    }

    const formattedSchedule = formatSchedule(schedule)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/account/update-admin-profile`,
        {
          admin_id: profile!.id,
          first_name: formData.first_name,
          last_name: formData.last_name,
          type: formData.type,
          pay_rate: formData.pay_rate,
          time_zone: formData.time_zone || "Africa/Abidjan",
          currency: formData.currency || "AUD",
          salary_currency: formData.salary_currency || "AUD",
          xero_bill_region: formData.xero_bill_region || "GLOBAL",
          is_monthly_contracted: formData.is_monthly_contracted,
          region_access: formData.region_access,
          refund_access: formData.refund_access,
          additional_access: formData.additional_access,
          time_schedule: formattedSchedule,
        }
      )
      .then(() => {
        setResponse("Profile updated")
        setError("")
        setTimeout(() => {
          setResponse("")
        }, 3000)
      })
      .catch((err) => {
        setError(err.response.data.message)
        setResponse("")
        setTimeout(() => {
          setError("")
        }, 3000)
      })

    setLoading(false)
  }

  const timezonesArr = Object.values(ct.getAllTimezones())
  const userType = useSelector(
    (store: RootState) => store.userData.userInformation.type
  )

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  const [userLoading, setUserLoading] = useState(true)
  const params: { user_id: string } = useParams()
  const { user_id } = params

  const fetchUser = async () => {
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}admin/v1/account/get`)

    const filteredUser = data.rows.filter(
      (user: User) => user.id === user_id
    )[0]

    const updatedUser = {
      ...filteredUser,
      ["user_access"]: filteredUser.admins_features
        .map((item: { value: string }) => item.value)
        .toString()
        .replaceAll(",", ", "),
    }

    setProfile(updatedUser)

    setUserLoading(false)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      first_name: profile?.first_name ?? "",
      last_name: profile?.last_name ?? "",
      email: profile?.email ?? "",
      type: profile?.type ?? "",
      pay_rate: profile?.pay_rate ?? 0,
      time_zone: profile?.time_zone ?? "",
      currency: profile?.currency ?? "",
      salary_currency: profile?.salary_currency ?? "",
      xero_bill_region: profile?.xero_bill_region ?? "",
    }))
    profile?.is_monthly_contracted
      ? setMonthlyContractedChecked(true)
      : setMonthlyContractedChecked(false)
    profile?.user_access?.includes("AU")
      ? setAUChecked(true)
      : setAUChecked(false)
    profile?.user_access?.includes("CA")
      ? setCAChecked(true)
      : setCAChecked(false)
    profile?.user_access?.includes("NZ")
      ? setNZChecked(true)
      : setNZChecked(false)
    profile?.user_access?.includes("UK")
      ? setUKChecked(true)
      : setUKChecked(false)
    profile?.user_access?.includes("PCA")
      ? setPCAChecked(true)
      : setPCAChecked(false)
    profile?.user_access?.includes("LEVEL_1")
      ? setLevel1Checked(true)
      : setLevel1Checked(false)
    profile?.user_access?.includes("LEVEL_2")
      ? setLevel2Checked(true)
      : setLevel2Checked(false)
    profile?.user_access?.includes("LEVEL_3")
      ? setLevel3Checked(true)
      : setLevel3Checked(false)
    profile?.user_access?.includes("EFT_APPROVER")
      ? setEftApproverChecked(true)
      : setEftApproverChecked(false)
    profile?.user_access?.includes("TIME_CLOCK")
      ? setTimeClockChecked(true)
      : setTimeClockChecked(false)
    profile?.user_access?.includes("OVERTIME_APPROVER")
      ? setOvertimeApproverChecked(true)
      : setOvertimeApproverChecked(false)
  }, [profile])

  useEffect(() => {
    const updateSchedule = (apiData: ApiSchedule[]) => {
      const updatedSchedule = schedule.map((daySchedule) => {
        const apiEntry = apiData.find((entry) => entry.day === daySchedule.name)

        if (apiEntry) {
          const [startHours, startMinutes] = apiEntry.start_time.split(":")
          const [endHours, endMinutes] = apiEntry.finish_time.split(":")
          const startTimeOfDay = parseInt(startHours) >= 12 ? "PM" : "AM"
          const endTimeOfDay =
            parseInt(endHours) >= 12 && parseInt(endHours) !== 24 ? "PM" : "AM"

          return {
            ...daySchedule,
            checked: true,
            startHours: parseInt(startHours) % 12 || 12,
            startMinutes: parseInt(startMinutes),
            startTimeOfDay,
            endHours: parseInt(endHours) % 12 || 12,
            endMinutes: parseInt(endMinutes),
            endTimeOfDay,
            allowedOvertime: apiEntry.allowed_overtime,
            maxOvertime: apiEntry.max_overtime || 0,
          }
        }

        return {
          ...daySchedule,
          checked: false,
        }
      })

      setSchedule(updatedSchedule)
    }

    if (profile?.time_schedules) {
      updateSchedule(profile?.time_schedules)
    }
  }, [profile])

  if (userLoading)
    return (
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:align-middle w-full">
          <div className="relative mt-3 text-center sm:mt-5">
            <h1 className="text-2xl leading-6 font-medium text-gray-900">
              Loading user information...
            </h1>
          </div>
        </div>
      </div>
    )

  return (
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:align-middle w-full">
        <div className="relative mt-3 text-center sm:mt-5">
          <Link to="/manage-users">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 absolute cursor-pointer hover:text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
          </Link>

          <h1 className="text-2xl leading-6 font-medium text-gray-900">
            Update User:{" "}
            <span className="font-normal">
              {profile?.first_name + " " + profile?.last_name}
            </span>
          </h1>
        </div>

        <form>
          <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
            <div className="mt-8 mx-auto w-full max-w-[1200px]">
              <div className="flex w-full gap-20 mb-10">
                <fieldset className="w-full">
                  <legend className="text-lg mb-2 font-medium text-center">
                    Personal Info
                  </legend>
                  <div className="pb-5">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                        value={email || ""}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="pb-5">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        autoComplete="first_name"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                        value={first_name || ""}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="pb-5">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        autoComplete="last_name"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                        value={last_name || ""}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset className="w-full">
                  <legend className="text-lg mb-2 font-medium text-center">
                    Admin Access
                  </legend>
                  <div className="pb-5">
                    <label
                      htmlFor="type"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Type
                    </label>
                    <select
                      id="type"
                      name="type"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                      value={type || ""}
                      onChange={onChange}
                    >
                      <option value="FRONTEND SALES">Frontend Sales</option>
                      <option value="ADMIN">Admin</option>
                      <option value="PERFORMANCE MANAGER">
                        Performance Manager
                      </option>
                      <option value="PM TEAM MANAGER">PM Team Manager</option>
                      <option value="FE TEAM MANAGER">FE Team Manager</option>
                      <option value="FINANCE MANAGER">Finance Manager</option>
                    </select>
                  </div>
                  <div className="flex gap-8">
                    <fieldset className="pb-5 w-1/3 border-gray-700 border-r pr-4">
                      <legend className="block text-sm font-medium text-gray-700">
                        Region Access
                      </legend>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="AU"
                              className="font-medium text-gray-700 select-none"
                            >
                              AU
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="AU"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="AU"
                              checked={auChecked}
                              onChange={() => setAUChecked(!auChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="CA"
                              className="font-medium text-gray-700 select-none"
                            >
                              CA
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="CA"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="CA"
                              checked={caChecked}
                              onClick={() => setCAChecked(!caChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="NZ"
                              className="font-medium text-gray-700 select-none"
                            >
                              NZ
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="NZ"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="NZ"
                              checked={nzChecked}
                              onClick={() => setNZChecked(!nzChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="UK"
                              className="font-medium text-gray-700 select-none"
                            >
                              UK
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="UK"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="UK"
                              checked={ukChecked}
                              onClick={() => setUKChecked(!ukChecked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start py-2">
                          <div className="min-w-0 text-sm flex-1">
                            <label
                              htmlFor="PCA"
                              className="font-medium text-gray-700 select-none"
                            >
                              PCA
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="PCA"
                              name="region_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="PCA"
                              checked={pcaChecked}
                              onClick={() => setPCAChecked(!pcaChecked)}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="pb-5 w-1/3 border-gray-700 border-r pr-4">
                      <legend className="block text-sm font-medium text-gray-700">
                        Refund Level
                      </legend>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="LEVEL_1"
                              className="font-medium text-gray-700 select-none"
                            >
                              Level 1
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="LEVEL_1"
                              name="refund_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="LEVEL_1"
                              checked={level1Checked}
                              onClick={() => setLevel1Checked(!level1Checked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="LEVEL_2"
                              className="font-medium text-gray-700 select-none"
                            >
                              Level 2
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="LEVEL_2"
                              name="refund_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="LEVEL_2"
                              checked={level2Checked}
                              onClick={() => setLevel2Checked(!level2Checked)}
                            />
                          </div>
                        </div>
                        <div className="relative flex items-start pt-2">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="LEVEL_3"
                              className="font-medium text-gray-700 select-none"
                            >
                              Level 3
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="LEVEL_3"
                              name="refund_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="LEVEL_3"
                              checked={level3Checked}
                              onClick={() => setLevel3Checked(!level3Checked)}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="pb-5 w-1/3 pr-4">
                      <legend className="block text-sm font-medium text-gray-700">
                        Additional Access
                      </legend>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="EFT_APPROVER"
                              className="font-medium text-gray-700 select-none"
                            >
                              EFT Approver
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="EFT_APPROVER"
                              name="additional_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="EFT_APPROVER"
                              checked={eftApproverChecked}
                              onClick={() =>
                                setEftApproverChecked(!eftApproverChecked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="TIME_CLOCK"
                              className="font-medium text-gray-700 select-none"
                            >
                              Time Clock
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="TIME_CLOCK"
                              name="additional_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="TIME_CLOCK"
                              checked={timeClockChecked}
                              onClick={() =>
                                setTimeClockChecked(!timeClockChecked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="relative flex items-start">
                          <div className="min-w-0 flex-1 text-sm">
                            <label
                              htmlFor="OVERTIME_APPROVER"
                              className="font-medium text-gray-700 select-none"
                            >
                              Overtime Approver
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                            <input
                              id="OVERTIME_APPROVER"
                              name="additional_access"
                              type="checkbox"
                              className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                              value="OVERTIME_APPROVER"
                              checked={overtimeApproverChecked}
                              onClick={() =>
                                setOvertimeApproverChecked(
                                  !overtimeApproverChecked
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </fieldset>
              </div>

              <div className="flex w-full gap-20">
                <fieldset className="w-full">
                  <legend className="text-lg mb-2 font-medium text-center">
                    Billing Info
                  </legend>
                  <div className="pb-5">
                    <label
                      htmlFor="pay_rate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pay Rate
                    </label>
                    <div className="mt-1">
                      <input
                        id="pay_rate"
                        name="pay_rate"
                        type="number"
                        autoComplete="pay_rate"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 sm:text-sm"
                        value={pay_rate}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="pb-5">
                    <div className="relative flex items-start">
                      <div className="min-w-0 text-sm">
                        <label
                          htmlFor="monthly_contracted"
                          className="font-medium text-gray-700 select-none"
                        >
                          Monthly Contracted?
                        </label>
                      </div>
                      <div className="ml-3 flex items-center h-5">
                        <input
                          id="monthly_contracted"
                          type="checkbox"
                          className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                          checked={monthlyContractedChecked}
                          onChange={() =>
                            setMonthlyContractedChecked(
                              !monthlyContractedChecked
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="time_zone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Timezone
                    </label>
                    <select
                      id="time_zone"
                      name="time_zone"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                      value={time_zone}
                      onChange={onChange}
                    >
                      {timezonesArr.map((timezone) => (
                        <option value={timezone.name} key={timezone.name}>
                          {timezone.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="currency"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Currency
                    </label>
                    <select
                      id="currency"
                      name="currency"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                      value={currency}
                      onChange={onChange}
                      disabled={userType !== "MASTER"}
                    >
                      <option value="AUD">AUD</option>
                      <option value="CAD">CAD</option>
                      <option value="NZD">NZD</option>
                      <option value="GBP">GBP</option>
                      <option value="PHP">PHP</option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                    </select>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="salary_currency"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Payout Salary Currency
                    </label>
                    <select
                      id="salary_currency"
                      name="salary_currency"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                      value={salary_currency}
                      onChange={onChange}
                      disabled={userType !== "MASTER"}
                    >
                      <option value="AUD">AUD</option>
                      <option value="PHP">PHP</option>
                      <option value="GBP">GBP</option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                      <option value="NZD">NZD</option>
                    </select>
                  </div>

                  <div className="pb-5">
                    <label
                      htmlFor="xero_bill_region"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Xero Bill Region
                    </label>
                    <select
                      id="xero_bill_region"
                      name="xero_bill_region"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                      value={xero_bill_region}
                      onChange={onChange}
                      disabled={userType !== "MASTER"}
                    >
                      <option value="GLOBAL">Global - UK,CA</option>
                      <option value="AU">AU</option>
                      <option value="PCA">PCA</option>
                      <option value="NZ">NZ</option>
                    </select>
                  </div>
                </fieldset>
                <ShiftSchedule schedule={schedule} setSchedule={setSchedule} />
              </div>

              {response && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5 text-center">
                  <span className="block sm:inline">{response}</span>
                </div>
              )}
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5 text-center">
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <div className="mt-5 sm:mt-6 flex justify-end">
                <Link to="/manage-users">
                  <button
                    type="button"
                    className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={`${
                    loading || response !== ""
                      ? "bg-gray-300 text-black cursor-auto"
                      : "bg-navy-theme text-white"
                  } inline-flex justify-center w-30 rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  focus:outline-none sm:text-sm`}
                  disabled={loading || response !== ""}
                >
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default UpdateUser
