import { useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import HelpVideo from "../../../components/help-video/HelpVideo"
import { ReactComponent as Arrow } from "../../../../../assets/common/arrowDown.svg"
import spinnerNavy from "../../../../../assets/common/spinnerNavy.svg"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"
import ProductPlanSearchMenu from "../../../components/product-plan-search-menu/ProductPlanSearchMenu"
import ProgramInfo from "../../../components/layout/program-info/ProgramInfo"
import SubscriptionModal from "../../../components/subscription-modal/SubscriptionModal"

type Opportunity = {
  stripe_subscription?: {
    id: string
    start_date_unix: number
    end_date_unix: number
    amount: number
    frequency: string
  }
  stripe_scheduled?: string
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
  additional_price_percent?: number
}

type ProductPlan = {
  Books_Item_Id: null
  Description: string
  Product_Name: string
  id: string
  Product_Active: boolean
  Unit_Price: number
  Product_Region: string
}

type Customer = {
  first_name: string
  last_name: string
  email: string
  phone: string
  date_of_birth: string
  address: string
}

function OpportunityPayment() {
  const [isDisabled, setIsDisabled] = useState(true)
  const [fetchingCustomer, setFetchingCustomer] = useState(true)
  const [fetchError, setFetchError] = useState("")
  const [customer, setCustomer] = useState<Customer>()
  const [loading, setLoading] = useState(true)
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [opportunitiesError, setOpportunitiesError] = useState("")
  const [transactionSelectedItem, setTransactionSelectedItem] = useState(
    "Select transaction type"
  )
  const [isTransactionOpened, setIsTransactionOpened] = useState<boolean>(false)
  const [description, setDescription] = useState("")
  const [helpVideoOpen, setHelpVideoOpen] = useState<boolean>(false)

  const product_plan_list = [
    {
      Books_Item_Id: "",
      Description: "",
      Product_Name: "",
      Product_Type: "",
      Unit_Price: 0,
      id: "",
    },
  ]
  const [productPlanList, setProductPlanList] = useState(product_plan_list)
  const [productPlanSelectedItem, setProductPlanSelectedItem] = useState("")
  const [productPlanDropdownOpen, setProductPlanDropdownOpen] =
    useState<boolean>(false)
  const [productPlanSearchLoading, setProductPlanSearchLoading] =
    useState(false)
  const [queryError, setQueryError] = useState(false)
  const [queryErrorMessage, setQueryErrorMessage] = useState("")
  const [isProductPlanSelected, setIsProductPlanSelected] = useState(false)
  const [selectProductPlanError, setSelectProductPlanError] = useState(false) 

  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  const [recurringStartDateUnix, setRecurringStartDateUnix] = useState(0)
  const [recurringEndDateUnix, setRecurringEndDateUnix] = useState(0)
  const [recurringAmount, setRecurringAmount] = useState(0)
  const [recurringFrequency, setRecurringFrequency] = useState("")
  const [scheduledPayment, setScheduledPayment] = useState("")

  const history = useHistory()
  const params: { contact_id: string; opportunity_id: string; region: string } =
    useParams()
  const { contact_id, opportunity_id, region } = params
  const dropDownRef = useRef<HTMLDivElement>(null)

  const transactionListContact = [
    { id: "Sale FE" },
    { id: "Sale PM" },
    { id: "Sale PCA" },
    { id: "Sale WMP" },
    { id: "Collection SPC" },
    { id: "Collection PM - Current" },
    { id: "Collection PM - Arrears" },
    { id: "Collection PM - Express Payment" },
    { id: "Renewal" },
  ]
  const transactionListContactPCA = [
    { id: "Sale FE" },
    { id: "Sale PM" },
    { id: "Sale PCA" },
    { id: "Sale WMP" },
    { id: "Collection SPC" },
    { id: "Collection PM - Current" },
    { id: "Collection PM - Arrears" },
    { id: "Collection PM - Express Payment" },
    { id: "Renewal" },
    { id: "Sale - Subscription" },
    { id: "Sale - Insurance" },
  ]
  const [transactionList, setTransactionList] = useState(transactionListContact)

  const handleProceed = () => {
    if (
      sessionStorage.getItem("country") === "CA" &&
      transactionSelectedItem === "Upgrade" &&
      !isProductPlanSelected
    ) {
      setSelectProductPlanError(true)
      return
    }
    sessionStorage.setItem("email", customer?.email as string)
    sessionStorage.setItem(
      "full_name",
      customer?.first_name + " " + customer?.last_name
    )
    sessionStorage.setItem("mobile", customer?.phone as string)
    sessionStorage.setItem("note", description)
    sessionStorage.setItem("opportunity_id", opportunities[0]?.opportunity_id)
    sessionStorage.setItem("card_name", opportunities[0]?.name_on_card)
    sessionStorage.setItem(
      "last_four_digits",
      opportunities[0]?.last_four_digit
    )
    sessionStorage.setItem("card_brand", opportunities[0]?.brand)
    sessionStorage.setItem("plan_name", opportunities[0]?.plan_info?.name)
    sessionStorage.setItem("plan_id", opportunities[0]?.plan_info?.id)
    sessionStorage.setItem(
      "total_price",
      opportunities[0]?.total_price.toString()
    )
    sessionStorage.setItem(
      "amount_paid",
      String(opportunities[0]?.total_price - opportunities[0]?.amount_paid)
    )
    sessionStorage.setItem(
      "amount_remaining",
      opportunities[0]?.amount_paid.toString()
    )
    if (opportunities[0]?.currency) {
      sessionStorage.setItem("currency", opportunities[0]?.currency)
    }

    switch (sessionStorage.getItem("country")) {
      case "AU":
        sessionStorage.setItem("currency", "AU$")
        break
      case "CA":
        sessionStorage.setItem("currency", "CA$")
        break
      case "NZ":
        sessionStorage.setItem("currency", "NZ$")
        break
      case "PCA":
        sessionStorage.setItem("currency", "AU$")
        break
    }

    history.push("/payment-portal/payment-type")
  }

  const fetchCustomer = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/fetch-customer`,
        {
          country:
            region === "52657875"
              ? "AU"
              : region === "760139034"
                ? "CA"
                : region === "771947106"
                  ? "NZ"
                  : region === "20067563176"
                    ? "UK"
                    : region === "803222882"
                      ? "PCA"
                      : "",
          module: "CONTACTS",
          id: contact_id,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setFetchingCustomer(false)
        setCustomer(data)
      })
      .catch((err) => {
        setFetchError(err.response.data.message)
        setFetchingCustomer(false)
      })
  }

  const getOpportunity = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
        {
          contact_id: contact_id,
          country: sessionStorage.getItem("country"),
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setLoading(false)
        const opportunity = data.filter(
          (item: Opportunity) => item.opportunity_id === opportunity_id
        )

        sessionStorage.setItem("opportunity_id", opportunity[0].opportunity_id)
        sessionStorage.setItem("invoice_id", opportunity[0].invoice_id)
        sessionStorage.setItem("full_name", opportunity[0].opportunity_name)
        sessionStorage.setItem("plan_name", opportunity[0].plan_info.name)
        sessionStorage.setItem("total_price", opportunity[0].total_price)
        sessionStorage.setItem(
          "amount_paid",
          (opportunity[0].total_price - opportunity[0].amount_paid).toString()
        )
        sessionStorage.setItem("amount_remaining", opportunity[0].amount_paid)
        sessionStorage.setItem("invoice_number", opportunity[0].invoice_number)

        if (opportunity[0].stripe_subscription) {
          setRecurringStartDateUnix(opportunity[0].stripe_subscription.start_date_unix)
          setRecurringEndDateUnix(opportunity[0].stripe_subscription.end_date_unix)
          setRecurringAmount(opportunity[0].stripe_subscription.amount)
          setRecurringFrequency(opportunity[0].stripe_subscription.frequency)
        }
        if (opportunity[0].stripe_scheduled) {
          setScheduledPayment(opportunity[0].stripe_scheduled)
        }
        sessionStorage.setItem("opportunity_add_percent", opportunity[0].additional_price_percent?.toString() || "")
        setOpportunities(opportunity)
      })
      .catch((err) => {
        setLoading(false)
        setOpportunitiesError(err.response.data.message)
      })
  }

  const transactionOpenHandler = (): void => {
    setIsTransactionOpened(!isTransactionOpened)
  }

  const transactionSelectHandler = (id: string) => {
    const selected = transactionList.find((el) => el.id === id)
    setTransactionSelectedItem(selected?.id as string)
    sessionStorage.setItem("transaction_type", selected?.id || "")
  }

  const searchPlans = async () => {
    setProductPlanSearchLoading(true)
    setQueryError(false)
    setQueryErrorMessage("")
    setSelectProductPlanError(false)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-product`,
        {
          country: sessionStorage.getItem("country"),
          searchString: productPlanSelectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setProductPlanSearchLoading(false)
        if (sessionStorage.getItem("country") === "UK") {
          setProductPlanList(
            data.filter((plan: ProductPlan) =>
              sessionStorage.getItem("currency") === "GBP"
                ? plan.Product_Region === "United Kingdom"
                : sessionStorage.getItem("currency") === "EUR"
                  ? plan.Product_Region === "Ireland"
                  : plan
            )
          )
        } else {
          setProductPlanList(data)
        }
        setProductPlanDropdownOpen(true)
      })
      .catch((err) => {
        setProductPlanSearchLoading(false)
        setProductPlanDropdownOpen(false)
        setQueryError(true)
        if (typeof err?.response?.data.message === "string") {
          setQueryErrorMessage(err.response.data.message)
          return
        }
      })
  }

  useEffect(() => {
    if (region === "52657875") {
      sessionStorage.setItem("country", "AU")
    } else if (region === "760139034") {
      sessionStorage.setItem("country", "CA")
    } else if (region === "771947106") {
      sessionStorage.setItem("country", "NZ")
    } else if (region === "20067563176") {
      sessionStorage.setItem("country", "UK")
    } else if (region === "803222882") {
      sessionStorage.setItem("country", "PCA")
    }
    sessionStorage.setItem("module", "CONTACTS")
    sessionStorage.setItem("id", contact_id)
    fetchCustomer()
    getOpportunity()

    if (region === "803222882") {
      setTransactionList(transactionListContactPCA)
    }
    else {
      setTransactionList(transactionListContact)
    }
  }, [])

  useEffect(() => {
    if (customer) {
      getOpportunity()
    }
  }, [customer])

  useEffect(() => {
    if (sessionStorage.getItem("country") === "PCA") {
      if (
        !customer ||
        opportunities[0]?.amount_paid <= 0 ||
        transactionSelectedItem === "Select transaction type" ||
        description === ""
      ) {
        setIsDisabled(true)
      } else if (
        customer &&
        opportunities[0]?.amount_paid > 0 &&
        sessionStorage.getItem("transaction_type") &&
        transactionSelectedItem !== "Select transaction type" &&
        description !== ""
      ) {
        setIsDisabled(false)
      }
    } else if (
      !customer ||
      opportunities[0]?.amount_paid <= 0 ||
      sessionStorage.getItem("invoice_id") === "null" ||
      sessionStorage.getItem("invoice_number") === "null" ||
      transactionSelectedItem === "Select transaction type" ||
      description === ""
    ) {
      setIsDisabled(true)
    } else if (
      customer &&
      opportunities[0]?.amount_paid > 0 &&
      sessionStorage.getItem("invoice_id") !== "null" &&
      sessionStorage.getItem("invoice_number") !== "null" &&
      sessionStorage.getItem("transaction_type") &&
      transactionSelectedItem !== "Select transaction type" &&
      description !== ""
    ) {
      setIsDisabled(false)
    }
  }, [customer, transactionSelectedItem, description, opportunities])

  useEffect(() => {
    const removeKeys = [
      "opportunity_id",
      "card_name",
      "last_four_digits",
      "card_brand",
      "plan_name",
      "plan_id",
      "plan_type",
      "plan_price",
      "total_price",
      "amount_paid",
      "amount_remaining",
      "currency",
      "transaction_type",
      "note",
      "email",
      "full_name",
      "mobile",
      "createOpportunitySkipped",
      "isCreateOpportunity",
      "isUpgradeOpportunity",
      "isRenewOpportunity",
      "isDiscount",
      "opportunity_add_percent",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  const createInvoice = async (opportunityId: string, index: number) => {
    setInvoiceMessage("")
    setInvoiceLoading(true)
    setInvoiceIndex(index)
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/invoice/create`, {
        country: sessionStorage.getItem("country"),
        email: customer?.email,
        opportunity_id: opportunityId,
      })
      .then((res) => {
        setInvoiceMessage(res.data.message)
        setInvoiceSuccess(true)
        getOpportunity()
      })
      .catch((err) => {
        setInvoiceMessage(err.response.data.message)
        setInvoiceSuccess(false)
      })
    setInvoiceLoading(false)
  }

  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceIndex, setInvoiceIndex] = useState(99)
  const [invoiceSuccess, setInvoiceSuccess] = useState(true)
  const [invoiceMessage, setInvoiceMessage] = useState("")

  return (
    <>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        <SubscriptionModal
          open={subscriptionModalOpen}
          setOpen={setSubscriptionModalOpen}
          recurring_start_date_unix={recurringStartDateUnix}
          recurring_end_date_unix={recurringEndDateUnix}
          recurring_amount={recurringAmount}
          recurring_frequency={recurringFrequency}
          scheduled_payment={scheduledPayment}
        />

        <h3 className="text-3xl leading-6 font-medium text-gray-900">
          Opportunity Payment
        </h3>
        <button
          className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
          onClick={() => setHelpVideoOpen(true)}
        >
          Need Help?
        </button>
        <ProgramInfo />
        <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />

        {fetchingCustomer ? (
          <div className="flex mt-2">
            <img
              width={40}
              className="inline"
              src={spinnerNavy}
              alt="Loading..."
            />
            <h4 className="mt-2 text-lg font-medium text-navy-theme">
              Loading customer information...
            </h4>
          </div>
        ) : fetchError !== "" ? (
          <h4 className="mt-2 text-lg font-medium text-E84545">{fetchError}</h4>
        ) : (
          <>
            <h4 className="mt-2 text-lg font-medium text-gray-500">
              Name: {customer?.first_name + " " + customer?.last_name}
            </h4>
            <h4 className="text-lg font-medium text-gray-500">
              Email: {customer?.email}
            </h4>
            <h4 className="text-lg font-medium text-gray-500">
              Contact ID: {contact_id}
            </h4>
          </>
        )}
      </div>

      <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
        <div className="w-full h-full flex flex-col justify-evenly items-center">
          <div className="w-full px-4 sm:px-6 lg:px-8 my-6">
            {loading ? (
              <>
                <img
                  width={40}
                  className="inline"
                  src={spinnerNavy}
                  alt="Loading..."
                />
                <span className="font-medium text-navy-theme">
                  Opportunity loading...
                </span>
              </>
            ) : fetchError !== "" ? (
              <div className={`font-bold text-E84545`}>{fetchError}</div>
            ) : opportunitiesError !== "" ? (
              <div className={`font-bold text-E84545`}>
                {opportunitiesError}
              </div>
            ) : (
              <>
                {opportunities.map(
                  (opportunity: Opportunity, index: number) => (
                    <div key={index}>
                      <div
                        className={`border-2 overflow-hidden shadow rounded-lg mt-2 p-4 relative bg-white`}
                      >
                        <div className="ml-4 grid grid-rows-5 grid-cols-2 gap-y-1 grid-flow-col">
                          <p className="text-sm font-semibold text-gray-500 ">
                            Name:{" "}
                            <span className="font-normal">
                              {opportunity.opportunity_name}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Enquiry:{" "}
                            <span className="font-normal">
                              {opportunity.enquiry}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Opp ID:{" "}
                            <span className="font-normal">
                              {opportunity.opportunity_id}
                            </span>
                          </p>
                          {sessionStorage.getItem("country") !== "PCA" && (
                            <>
                              <p className="text-sm font-semibold text-gray-500">
                                Invoice ID:{" "}
                                <span className="font-normal">
                                  {opportunity.invoice_id || "Unavailable"}
                                </span>
                              </p>
                              <p className="text-sm font-semibold text-gray-500">
                                Invoice #:{" "}
                                <span className="font-normal">
                                  {opportunity.invoice_number || "Unavailable"}
                                </span>
                              </p>
                            </>
                          )}
                          <p className="text-sm font-semibold text-gray-500">
                            Plan Name:{" "}
                            <span className="font-normal">
                              {opportunity.plan_info?.name}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Total price:{" "}
                            <span className="font-normal">
                              {opportunity?.currency === "EUR"
                                ? "€"
                                : opportunity?.currency === "GBP"
                                  ? "£"
                                  : "$"}
                              {opportunity.total_price?.toFixed(2)}
                            </span>
                          </p>
                          <p className="text-sm font-semibold text-gray-500">
                            Amount paid:{" "}
                            <span className="font-normal">
                              {opportunity?.currency === "EUR"
                                ? "€"
                                : opportunity?.currency === "GBP"
                                  ? "£"
                                  : "$"}
                              {(
                                opportunity.total_price -
                                opportunity.amount_paid
                              )?.toFixed(2)}
                            </span>
                          </p>
                          {opportunity.amount_paid == 0 ? (
                            <p className="text-sm font-bold text-3fc69d">
                              Paid in full
                            </p>
                          ) : opportunity.amount_paid < 0 ? (
                            <p className="text-sm font-bold text-E84545">
                              Customer overcharged{" "}
                              {opportunity?.currency === "EUR"
                                ? "€"
                                : opportunity?.currency === "GBP"
                                  ? "£"
                                  : "$"}
                              {Number(
                                String(opportunity.amount_paid).substring(1)
                              )?.toFixed(2)}
                            </p>
                          ) : (
                            <p className="text-sm font-semibold text-gray-500">
                              Amount remaining:{" "}
                              <span className="font-normal">
                                {opportunity?.currency === "EUR"
                                  ? "€"
                                  : opportunity?.currency === "GBP"
                                    ? "£"
                                    : "$"}
                                {opportunity.amount_paid?.toFixed(2)}
                              </span>
                            </p>
                          )}
                          {sessionStorage.getItem("country") === "CA" && (       
                            <button 
                              className={`${
                                (!opportunity.stripe_subscription && !opportunity.stripe_scheduled)
                                  ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto"
                                  : "bg-3fc69d hover:bg-3fc69d_hover text-white"
                              } text-sm font-semibold text-center py-1 rounded-md w-200`}
                              onClick={() => setSubscriptionModalOpen(true)}
                              disabled={(!opportunity.stripe_subscription && !opportunity.stripe_scheduled)}
                            >
                              View Subscription
                            </button>               
                          )}
                          <div
                            className={`h-full w-4 absolute top-0 left-0 bg-3FB1FC ${opportunity.amount_paid == 0 && "bg-3fc69d"
                              } ${opportunity.amount_paid < 0 && "bg-E84545"} ${(!opportunity.invoice_id ||
                                !opportunity.invoice_number) &&
                              sessionStorage.getItem("country") !== "PCA" &&
                              "bg-E84545"
                              }`}
                          />
                        </div>
                        {(!opportunity.invoice_id ||
                          !opportunity.invoice_number) && (
                            <>
                              <p className="ml-4 mt-4 text-sm font-bold text-E84545">
                                Invoice data is unavailable within the CRM.
                                Please click on the &quot;Create Invoice&quot;
                                button to proceed.
                              </p>
                              {invoiceMessage && index === invoiceIndex ? (
                                <button
                                  className={`w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-auto ${invoiceSuccess ? "bg-3fc69d" : "bg-E84545"
                                    } text-white rounded-lg h-10`}
                                >
                                  {invoiceMessage}
                                </button>
                              ) : (
                                <>
                                  {invoiceLoading && index === invoiceIndex && (
                                    <img
                                      width={40}
                                      className="absolute left-[194px] bottom-4 invert border-none"
                                      src={spinnerBlack}
                                      alt="Loading..."
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      createInvoice(
                                        opportunity.opportunity_id,
                                        index
                                      )
                                    }
                                    className="w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-pointer bg-navy-theme text-white rounded-lg h-10"
                                  >
                                    {invoiceLoading && index === invoiceIndex
                                      ? "Creating invoice..."
                                      : "Create Invoice"}
                                  </button>
                                </>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
        <div className="w-full h-full flex flex-col justify-evenly items-center my-6">
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
            <label
              htmlFor="transaction_type"
              className="block text-sm font-medium text-gray-700"
            >
              Transaction Type
            </label>
            <div className={`relative mt-2`}>
              <div
                className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
                onClick={transactionOpenHandler}
                ref={dropDownRef}
              >
                <div className="flex items-center pl-4 font-medium text-828282 w-full">
                  {transactionSelectedItem}
                </div>
                <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
                  <Arrow />
                </div>
                {isTransactionOpened && (
                  <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
                    {transactionList.map(({ id }, index) => (
                      <div
                        className={`${index === 0 ? "" : "border-t border-F0F0F0"
                          } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                        key={id}
                        onClick={() => transactionSelectHandler(id)}
                      >
                        {id}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {sessionStorage.getItem("country") === "CA" &&
            transactionSelectedItem === "Upgrade" && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
                <div className="flex">
                  <label
                    htmlFor="product_plan"
                    className="block text-sm font-medium text-gray-700 mr-2"
                  >
                    Product Plan
                  </label>
                  {selectProductPlanError && (
                    <span className="text-sm text-red-500 font-bold">
                      Please select product plan before proceeding
                    </span>
                  )}
                  {productPlanSearchLoading && (
                    <>
                      <img
                        width={24}
                        className="inline"
                        src={spinnerNavy}
                        alt="Loading..."
                      />
                      <span className="font-medium text-sm text-navy-theme">
                        Searching for plans...
                      </span>
                    </>
                  )}
                  {queryError && (
                    <span className="font-medium text-sm text-E84545 ml-2">
                      {queryErrorMessage !== ""
                        ? queryErrorMessage
                        : "No results found. Please try again with a different query."}
                    </span>
                  )}
                </div>
                <ProductPlanSearchMenu
                  searchPlans={searchPlans}
                  productPlanList={productPlanList}
                  productPlanSelectedItem={productPlanSelectedItem}
                  setProductPlanSelectedItem={setProductPlanSelectedItem}
                  productPlanDropdownOpen={productPlanDropdownOpen}
                  setProductPlanDropdownOpen={setProductPlanDropdownOpen}
                  setIsProductPlanSelected={setIsProductPlanSelected}
                />
              </div>
            )}
          <div className="w-full px-4 sm:px-6 lg:px-8">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              className="mt-2 w-full h-36 bg-E0E0E0 border-c4c4c4 border rounded-md focus:border-c4c4c4 focus:outline-none focus:ring-0 font-medium text-828282 leading-5"
              style={{ resize: "none" }}
              placeholder="Enter details of payment"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col">
        <button
          type="button"
          className={`${isDisabled
            ? "bg-E0E0E0 text-828282"
            : "text-white bg-FF5733 hover:bg-FF5733_hover"
            } mt-4 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 mx-auto`}
          onClick={() => {
            handleProceed()
          }}
          disabled={isDisabled}
        >
          Proceed
        </button>
      </div>
    </>
  )
}

export default OpportunityPayment