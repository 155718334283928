import { useEffect, useState } from "react"
import Layout from "../../components/layout/Layout"
import ConfirmProvinceModal from "./components/ConfirmProvinceModal"
import ProvinceSelectMenu from "./components/ProvinceSelectMenu"

const CurrencySelect = () => {
  const provinceList = [
    { id: "Alberta", tax_rate: 5 },
    { id: "British Columbia", tax_rate: 5 },
    { id: "Manitoba", tax_rate: 5 },
    { id: "New Brunswick", tax_rate: 15 },
    { id: "Newfoundland and Labrador", tax_rate: 15 },
    { id: "Northwest Territories", tax_rate: 5 },
    { id: "Nova Scotia", tax_rate: 15 },
    { id: "Nunavut", tax_rate: 5 },
    { id: "Ontario", tax_rate: 13 },
    { id: "Quebec", tax_rate: 14.975 },
    { id: "Prince Edward Island", tax_rate: 15 },
    { id: "Saskatchewan", tax_rate: 5 },
    { id: "Yukon", tax_rate: 5 },
  ]

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [provinceSelectedItem, setProvinceSelectedItem] =
    useState("Select province")
  const [provinceModalOpen, setProvinceModalOpen] = useState(false)

  useEffect(() => {
    setProvinceModalOpen(false)
    setProvinceSelectedItem("Select province")

    const removeKeys = ["province", "tax_rate"]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  const handleProceed = () => setProvinceModalOpen(true)

  return (
    <Layout
      pageNumber={4}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="province"
          className="block text-sm font-medium text-gray-700"
        >
          Province
        </label>
        <ProvinceSelectMenu
          provinceList={provinceList}
          provinceSelectedItem={provinceSelectedItem}
          setProvinceSelectedItem={setProvinceSelectedItem}
          setNextButtonDisabled={setNextButtonDisabled}
        />
        <ConfirmProvinceModal
          open={provinceModalOpen}
          setOpen={setProvinceModalOpen}
        />
      </div>
    </Layout>
  )
}

export default CurrencySelect