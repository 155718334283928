import { FC, Fragment, useEffect, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"

interface MyProps {
  deleteOpen: boolean
  selectedUsers: object
  setDeleteOpen: (args: boolean) => void
  handleDelete: (index: string[]) => void
}

const DeleteModal: FC<MyProps> = ({
  deleteOpen,
  setDeleteOpen,
  handleDelete,
  selectedUsers,
}) => {
  const cancelButtonRef = useRef(null)
  const selectedUserCount = Object.values(selectedUsers).filter(
    user => user === true
  ).length

  useEffect(() => {
    selectedUserCount <= 0 && setDeleteOpen(false)
  })

  return (
    <Transition.Root
      show={deleteOpen && selectedUserCount > 0}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setDeleteOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0 sm:items-end">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Delete User{" "}
                    {selectedUserCount === 1 ? "Account" : "Accounts"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {selectedUserCount === 1
                        ? "Are you sure you want to delete this user account?"
                        : "Are you sure you want to delete these user accounts?"}{" "}
                      <span className="font-bold">
                        This process cannot be undone.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    const userIdxs: string[] = []
                    Object.entries(selectedUsers).forEach(selectedUser => {
                      const userIdx = selectedUser[0]
                      const isSelected = selectedUser[1]
                      if (isSelected) {
                        userIdxs.push(userIdx)
                      }
                    })
                    handleDelete(userIdxs)
                  }}
                >
                  Delete {selectedUserCount === 1 ? "Account" : "Accounts"}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DeleteModal
