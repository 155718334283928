import { FC, useCallback, useEffect, useRef, useState, ChangeEvent } from "react"
import { ReactComponent as Search } from "../../../../../assets/common/search.svg"
import SpinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"

interface ProductPlanSearchMenuProps {
  setProductPlanID: (args: string) => void
  setProductPlanName: (args: string) => void
  region: string | null
  currency: string
}

type ProductPlan = {
  Books_Item_Id: string
  Description: string
  Product_Name: string
  id: string
  Product_Active: boolean
  Unit_Price: number
  Product_Region: string
  Product_Type: string
  Pharmacy_Details?: [
    {
      Pharmacy_Name: string
      Pharmacy_Location: string
    }
  ]
}

const ProductPlanSearchMenu: FC<ProductPlanSearchMenuProps> = ({
  setProductPlanID,
  setProductPlanName,
  region,
  currency,
}) => {
  const product_plan_list: ProductPlan[] = [
    {
      Books_Item_Id: "",
      Description: "",
      Product_Active: false,
      Product_Name: "",
      Product_Region: "",
      Product_Type: "",
      Unit_Price: 0,
      id: "",
      Pharmacy_Details: [
        {
          Pharmacy_Name: "",
          Pharmacy_Location: "",
        }
      ]
    },
  ]

  const [loading, setLoading] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<string>("")
  const [selectList, setSelectList] = useState(product_plan_list)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [queryError, setQueryError] = useState<string>("")

  const searchProductPlans = async () => {
    setLoading(true)
    setQueryError("")

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-product`,
        {
          country: region,
          searchString: selectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setLoading(false)
        if (region === "UK") {
          setSelectList(
            data.filter((plan: ProductPlan) =>
              currency === "GBP"
                ? plan.Product_Region === "United Kingdom"
                : currency === "EUR"
                  ? plan.Product_Region === "Ireland"
                  : plan
            )
          )
        }
        if (region === "CA" && sessionStorage.getItem("province") === "Quebec") {
          setSelectList(
            data.filter((plan: ProductPlan) =>
              sessionStorage.getItem("product_type") === "Product Plan"
                ? plan.Product_Type === "MHC"
                : plan.Product_Type === "Medication"
            )
          )
        }
        else {
          setSelectList(
            data.filter((plan: ProductPlan) =>
              plan.Product_Type !== "Medication"
            )
          )
        }
        setIsOpen(true)
      })
      .catch((err) => {
        setLoading(false)
        setIsOpen(false)
        if (typeof err?.response?.data.message === "string") {
          setQueryError(err.response.data.message)
          return
        }
      })
  }

  const dropDownRef = useRef<HTMLDivElement>(null)
  const openHandler = (): void => {
    if (selectedItem !== "") {
      setIsOpen(!isOpen)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedItem(e.target.value)
  }

  const selectPlanHandler = (id: string) => {
    const selected = selectList.find((el: ProductPlan) => el.id === id)
    setProductPlanID(selected!.id)
    setProductPlanName(selected!.Product_Name)
    setSelectedItem(selected!.Product_Name)
    setIsOpen(false)

    sessionStorage.setItem("plan_id", selected!.id)
    sessionStorage.setItem("plan_name", selected!.Product_Name)
    sessionStorage.setItem("plan_type", selected!.Product_Type)

    if (selected!.Product_Type !== "Medication") {
      sessionStorage.setItem("plan_price", selected!.Unit_Price.toString())
    }
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setIsOpen(false)
      }
    },
    [setIsOpen]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    if (selectList.length < 1) {
      setQueryError("No records found. Please try again.")
    } else {
      setQueryError("")
    }
  }, [selectList])

  return (
    <div>
      <div>
        <div className="flex">
          <label
            htmlFor="product_plan"
            className="block text-sm font-medium text-gray-700"
          >
            {sessionStorage.getItem("product_type") !== "Medication" ? "Product Plan" : "Medication"}
          </label>
          {loading && (
            <div className="flex items-center">
              <img src={SpinnerBlack} alt="" width="20px" />
              <div className="block text-sm font-medium text-gray-700">
                Searching for plans...
              </div>
            </div>
          )}
          {queryError && (
            <span className="block text-sm font-medium text-red-700 ml-2">
              {queryError}
            </span>
          )}
        </div>
        <div className={`relative mt-2`}>
          <div
            className="bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border"
            onClick={openHandler}
            ref={dropDownRef}
          >
            <div className="ml-4 flex items-center justify-center min-w-2.375 w-2.375 h-full">
              <Search />
            </div>
            <input
              className="bg-E0E0E0 flex items-center pl-2 text-828282 w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
              placeholder="Enter the name or type of product plan"
              value={selectedItem}
              onChange={handleChange}
              onKeyPress={(event) =>
                event.key === "Enter" && searchProductPlans()
              }
            />
            {isOpen && (
              <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-40">
                {selectList?.map((item: ProductPlan, index: number) => (
                  <div
                    className={`${index === 0 ? "" : "border-t border-F0F0F0"
                      } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                    key={index}
                    onClick={() => {
                      selectPlanHandler(item.id)
                    }}
                  >
                    {
                      <div className="flex text-sm">
                        <div className="w-64">{item.Product_Name}</div>
                        <div className="w-36">{item.Product_Type}</div>
                        {sessionStorage.getItem("product_type") !== "Medication" && (
                          <div className="w-36">
                            {sessionStorage.getItem("currency") === "EUR"
                              ? "€"
                              : sessionStorage.getItem("currency") === "GBP"
                                ? "£"
                                : "$"}
                            {item?.Unit_Price?.toFixed(2)}
                          </div>
                        )}
                      </div>
                    }
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductPlanSearchMenu