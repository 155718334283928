import * as requestFromServer from "./crud"
import { authSlice } from "./slice"
import { apiSlice } from "../api/apiSlice"
import { Dispatch } from "redux"

const { actions } = authSlice

export const login =
  (obj: string) => (dispatch: Dispatch) => {
    return requestFromServer
      .login(obj)
      .then((response) => {
        if (response.data.status === 200) {
          localStorage.setItem("authToken", response.data.data.authorization)
          dispatch(actions.loginUser({ user: response.data }))
          dispatch(actions.clearError())
        }
      })
      .catch((error) => {
        dispatch(
          actions.catchError({
            error: error.response.data.message,
            type: "signin",
          })
        )
      })
  }

export const getProfile = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiSlice.actions.setLoading(true))
    const response = await requestFromServer.getProfile()
    if (response.data.status === 200) {
      dispatch(actions.setProfile({ profile: response.data.data }))
    }
  } catch (e) {
    dispatch(apiSlice.actions.setError(e))
    dispatch(actions.logout())
  } finally {
    dispatch(apiSlice.actions.setLoading(false))
  }
}

export const updateProfile = (obj: object) => (dispatch: Dispatch) => {
  return requestFromServer.updateProfile(obj).then(() => {
    requestFromServer.getProfile().then((response) => {
      if (response.data.status === 200) {
        dispatch(actions.setProfile({ profile: response.data.data }))
        dispatch(actions.setFileUrl({ fileUrl: "" }))
      }
    })
  })
}

export const updateProfilePic = (obj: object) => (dispatch: Dispatch) => {
  return requestFromServer.updateProfile(obj).then((response) => {
    dispatch(actions.setFileUrl({ fileUrl: response.data.data.fileURLs[0] }))
  })
}

export const setShow = () => (dispatch: Dispatch) => {
  dispatch(actions.setShow())
}

export const hideShow = () => (dispatch: Dispatch) => {
  dispatch(actions.hideShow())
}

export const logout = () => (dispatch: Dispatch) => {
  dispatch(actions.logout())
}
