import { FC, Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

interface PropTypes {
  classNames: (arg1: string, arg2: string) => string
  trackingData: TrackingDataUser[]
}

type TrackingDataUser = {
  createdAt: string
  id: number
  index: number
  link: string
  screen: string
  updatedAt: string
  user_id: string
  user: {
    first_name: string
    last_name: string
  }
}

const TrackingData: FC<PropTypes> = ({ classNames, trackingData }) => {
  return (
    <Popover className="z-0 relative">
      {({ open }) => (
        <>
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="border-2 divide-y divide-gray-200 bg-white overflow-hidden shadow rounded-lg">
              <div className="bg-navy-theme px-4 py-5 border-b border-gray-200 sm:px-6">
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group rounded-md inline-flex items-center text-base font-medium"
                  )}
                >
                  <h3 className="text-lg leading-6 font-medium text-white">
                    Tracking Data
                  </h3>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-white" : "text-gray-600",
                      "ml-2 h-5 w-5 group-hover:text-gray-400"
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="z-10 inset-x-0 transform w-full mx-auto px-4 sm:px-6 lg:px-8 bg-F3F4F6">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="flex flex-wrap bg-white px-5 py-6 justify-around">
                  {trackingData.slice(-100).map((item) => (
                    <div
                      key={item.id}
                      className={`${
                        item.screen === "user has logged in"
                          ? "bg-green-300"
                          : item.screen === "user has logged out"
                          ? "bg-red-300"
                          : "bg-white"
                      } border-2 divide-y divide-gray-200 overflow-hidden shadow-lg rounded-lg my-2 w-48%`}
                    >
                      <div className="px-4 py-3 flex flex-col">
                        <span className="font-bold">ID: {item.index}</span>
                        <div>
                          <span className="font-bold">Created at:</span>{" "}
                          {item.createdAt.substring(0, 10)}{" "}
                          {item.createdAt.substring(11, 19)}
                        </div>
                        <div>
                          <span className="font-bold">Screen:</span>{" "}
                          {item.screen}
                        </div>
                        <div>
                          <span className="font-bold">Link:</span> {item.link}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default TrackingData
