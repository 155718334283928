import { FC, useState } from "react"
import DeleteModal from "../modal/DeleteModal"
import {
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
  TrashIcon,
  EyeIcon,
} from "@heroicons/react/solid"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import NotesModal from "../modal/NotesModal"

interface MyProps {
  requests: RefundRequest[]
  setModalData: (args: RefundRequest) => void
  setApprovalModalOpen: (args: boolean) => void
  setApprovalModalType: (args: string) => void
  masterAdmin: boolean
  setRefundRequestsData: (args: RefundRequest[]) => void
}

type RefundRequest = {
  description: {
    description: string
    timestamp: string
    actioned_by: string
  }[]
  id: number
  country: string
  contact_id: string
  opportunity_id: string
  reason: string
  program_price: number
  program_name: string
  patient_name: string
  refund_type: string
  last_actioned_id: string
  file_url: string
  refund_amount: number
  gross_refund_amount: number
  approval_level: string
  status: boolean
  lastActionedBy: {
    first_name: string
    last_name: string
  }
}

type Note = {
  actioned_by: string
  description: string
  timestamp: string
  email?: string
  authority?: string
}

const Requests: FC<MyProps> = ({
  requests,
  setModalData,
  setApprovalModalOpen,
  setApprovalModalType,
  masterAdmin,
  setRefundRequestsData,
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [notesOpen, setNotesOpen] = useState(false)
  const [id, setId] = useState(0)
  const [screenshotUrl, setScreenshotUrl] = useState("")
  const [notes, setNotes] = useState<Note[]>([
    {
      actioned_by: "",
      description: "",
      timestamp: "",
      email: "",
      authority: "",
    },
  ])

  const userInformation = useSelector(
    (store: RootState) => store.userData.userInformation
  )
  const valuesArr = userInformation.admins_features.map(
    (item: { value: string }) => item.value
  )

  const handleDeleteModal = (id: number) => {
    setId(id)
    setDeleteOpen(true)
  }

  const handleNotesModal = (request: RefundRequest) => {
    setScreenshotUrl(request.file_url)
    const notesCopy = [...request.description].reverse()

    setNotes(notesCopy)
    setNotesOpen(true)
  }

  const returnRowClass = (request: RefundRequest) => {
    if (request.approval_level === "LEVEL_3") return "bg-green-200"
    if (request.approval_level === "REJECTED") return "bg-red-200"
  }

  return (
    <>
      <DeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        id={id}
        requests={requests}
        setRefundRequestsData={setRefundRequestsData}
      />
      <NotesModal
        open={notesOpen}
        setOpen={setNotesOpen}
        notes={notes}
        screenshotUrl={screenshotUrl}
      />
      {requests?.map((request, requestIdx) => (
        <>
          <tr className={returnRowClass(request)} key={requestIdx}>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div className="flex justify-around">
                {/* REFUNDED OR REJECTED */}
                {request.approval_level === "LEVEL_3" && (
                  <div className="w-28 mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 focus:outline-none focus:ring-0 justify-center">
                    <CheckCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Refunded
                  </div>
                )}
                {request.approval_level === "REJECTED" && (
                  <div className="w-28 mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 focus:outline-none focus:ring-0 justify-center">
                    <XCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Rejected
                  </div>
                )}

                {/* LEVEL 2 */}
                {request.approval_level === "LEVEL_2" &&
                  (valuesArr.includes("LEVEL_3") ? (
                    <>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(request)
                          setApprovalModalType("APPROVE")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <CheckCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Approve
                      </button>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(request)
                          setApprovalModalType("REJECT")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <XCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Reject
                      </button>
                    </>
                  ) : (
                    <div className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 focus:outline-none focus:ring-0">
                      <ClockIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Pending
                    </div>
                  ))}

                {/* LEVEL 1 */}
                {request.approval_level === "LEVEL_1" &&
                  (valuesArr.includes("LEVEL_2") ? (
                    <>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(request)
                          setApprovalModalType("APPROVE")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <CheckCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Approve
                      </button>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(request)
                          setApprovalModalType("REJECT")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <XCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Reject
                      </button>
                    </>
                  ) : (
                    <div className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 focus:outline-none focus:ring-0">
                      <ClockIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Pending
                    </div>
                  ))}

                {/* PENDING */}
                {request.approval_level === "PENDING" &&
                  (valuesArr.includes("LEVEL_1") ? (
                    <>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(request)
                          setApprovalModalType("APPROVE")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <CheckCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Approve
                      </button>
                      <button
                        type="button"
                        className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-0"
                        onClick={() => {
                          setModalData(request)
                          setApprovalModalType("REJECT")
                          setApprovalModalOpen(true)
                        }}
                      >
                        <XCircleIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Reject
                      </button>
                    </>
                  ) : (
                    <div className="mx-1 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 focus:outline-none focus:ring-0">
                      <ClockIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Pending
                    </div>
                  ))}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {request.country !== "UK" && (
                <a
                  href={`https://crm.zoho.com/crm/${
                    request.country === "AU"
                      ? "org52657875"
                      : request.country === "CA"
                      ? "org760139034"
                      : request.country === "NZ"
                      ? "org771947106"
                      : request.country === "PCA"
                      ? "org803222882"
                      : ""
                  }/tab/Potentials/${request.opportunity_id}`}
                  target="_blank"
                  className="hover:text-gray-500"
                  rel="noreferrer"
                >
                  {request.patient_name}
                </a>
              )}
              {request.country === "UK" && (
                <a
                  href={`https://crm.zoho.eu/crm/org20067563176/tab/Potentials/${request.opportunity_id}`}
                  target="_blank"
                  className="hover:text-gray-500"
                  rel="noreferrer"
                >
                  {request.patient_name}
                </a>
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.approval_level}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.country}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.refund_type}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.refund_amount?.toFixed(2)
                ? `${
                    request.country === "UK" ? "€/£" : "$"
                  }${request.refund_amount?.toFixed(2)}`
                : "Pending"}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.reason}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <EyeIcon
                className="cursor-pointer mx-auto h-6 w-6 hover:text-gray-400"
                aria-hidden="true"
                onClick={() => handleNotesModal(request)}
              />
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.lastActionedBy.first_name +
                " " +
                request.lastActionedBy.last_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.program_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.country === "UK" ? "€/£" : "$"}
              {request.program_price.toFixed(2)}
            </td>
            {masterAdmin &&
              (request.approval_level === "LEVEL_3" ||
              request.approval_level === "REJECTED" ? (
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="w-28 mx-1 flex justify-center items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-700 focus:outline-none focus:ring-0">
                    Can&apos;t Delete
                  </div>
                </td>
              ) : (
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div
                    className="cursor-pointer w-28 mx-1 flex justify-center items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-FF5733 focus:outline-none focus:ring-0 hover:bg-FF5733_hover"
                    onClick={() => handleDeleteModal(request.id)}
                  >
                    <TrashIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Delete
                  </div>
                </td>
              ))}
          </tr>
        </>
      ))}
    </>
  )
}

export default Requests
